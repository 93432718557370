import React, {useMemo} from 'react';
import {Listing} from 'components/Form/Listing';
import {
    Delete,
    LastPageOutlined,
    OpenInBrowserOutlined,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import userImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_417452843.jpg';
import {Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {listWorkingTimeModelAssignmentsByModel, listWorkingTimeModelAssignmentsByUser} from 'graphql/timeBuddy/WorkingTimeModelAssignment/queries';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';
import {formatDateTimeString} from 'helper/date';
import {deleteWorkingTimeModelAssignment} from 'graphql/timeBuddy/WorkingTimeModelAssignment/mutations';
import {useCanAccess} from 'hooks/useCanAccess';

/** @type {import('components/Form/Listing').ListingSchemaItem} */
const startTimeItem = {
    title: 'Start Datum',
    renderItem: (item) => (
        <>
            <LastPageOutlined color="info" />
            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDate, 'dd.MM.yyyy')}</Typography>
        </>
    ),
};
/** @type {import('components/Form/Listing').ListingSchemaColumn[]} */
const userListSchemaCols = [{
    itemConfigurations: [
        {
            title: 'Benutzerbild',
            renderItem: (item) => (<img style={{maxHeight: '40px'}} src={item.image?.url ?? userImg} alt="user" />),
        },
    ],
    boxProps: {style: {width: '80px'}},
},
{
    itemConfigurations: [

        {
            title: 'Name',
            renderItem: (item) => (
                <Typography noWrap fontWeight="bold" fontStyle={item.active && 'italic'}>
                    {item.user?.contactFirstName}
                    {' '}
                    {item.user?.contactLastName}
                </Typography>
            ),
        }, {
            title: 'E-Mail Adresse',
            renderItem: (item) => (
                <Typography noWrap>{item.user?.contactEMailAddress}</Typography>
            ),
        },
        startTimeItem,
    ],
    boxProps: {sx: {flex: {xs: 4, lg: 2}}},
}];
/** @type {import('components/Form/Listing').ListingSchemaColumn[]} */
const modelListSchemaCols = [
    {
        itemConfigurations: [startTimeItem],
        boxProps: {style: {width: '100px'}},
    }, {
        itemConfigurations: [
            {
                title: 'Name',
                renderItem: (item) => (
                    <Typography noWrap fontWeight="bold">
                        {item.model?.name}
                        {item.model.revision > 0 && <Typography component="span" color="GrayText">{` #${item.model.revision}`}</Typography>}
                    </Typography>
                ),
            },
        ],
        boxProps: {sx: {flex: {xs: 4, lg: 2}}},
    }];

/**
 * Formular for list working time models assignments
 * @param {object} props - props for the listing
 * @param {object} [props.modelId] - modelId of model to list by
 * @param {object} [props.userId] - userId of user to list by
 * @param {any} [props.listDataRef] - ref to attach to the list data
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The component to render
 */
function WorkingTimeModelAssignmentsListFormular({modelId, userId, listDataRef}) {
    const messageKey = 'WorkingTimeModel_List';
    const canDeleteAssignments = useCanAccess('deleteWorkingTimeModelAssignment');

    const loadConfig = useMemo(() => (userId ? ({
        query: listWorkingTimeModelAssignmentsByUser,
        variables: {direct: {userId}, global: {tenantId: 'tenantId'}},
        mask: {userId: true, startDate: false, tenantId: true},
    }) : ({
        query: listWorkingTimeModelAssignmentsByModel,
        variables: {direct: {modelId}, global: {tenantId: 'tenantId'}},
        mask: {modelId: true, startDate: false, tenantId: true},
    })), [modelId, userId]);

    return (
        <ListData
            loadConfig={loadConfig}
            deleteConfig={{
                mutation: deleteWorkingTimeModelAssignment,
                mask: {id: true},
            }}
            ref={listDataRef}
        >
            {userId
            && (
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#UserForm${userId}action-button-frame-timemodel_history-actions`}
                    formWrapperIdPattern={`User#${userId}`}
                >
                    <Refresh />
                </FormElementActionButton>
            )}
            {modelId
            && (
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#WorkingTimeModelForm${modelId}action-button-frame-timemodel-actions`}
                    formWrapperIdPattern={`WorkingTimeModel#${modelId}`}
                >
                    <Refresh />
                </FormElementActionButton>
            )}
            <ListFilterProvider path="startDate" id="model-filter" messageKey={messageKey}>
                <FilterElementDate path="lt" label="Bevor Datum" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                        /** @type {import('components/Form/Listing').ListingSchema} */
                        const listingSchema = {
                            columns: !userId ? userListSchemaCols : modelListSchemaCols,
                            actions: [
                                {
                                    icon: <Delete />,
                                    isVisible: (item) => canDeleteAssignments && (item.grants?.deletable ?? false),
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <OpenInBrowserOutlined />,
                                    routeId: !userId ? 'beyondbuddy_settings_general_user_route' : 'timebuddy_workingtime_model_route',
                                    routeParams: (item) => (!userId ? ({id: item.userId}) : ({id: item.modelId})),
                                },
                            ],
                            routeId: !userId ? 'beyondbuddy_settings_general_user_route' : 'timebuddy_workingtime_model_route',
                            routeParams: (item) => (!userId ? ({id: item.userId}) : ({id: item.modelId})),
                        };
                        return (<Listing schema={listingSchema} />);
                    }
                }

            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkingTimeModelAssignmentsListFormular};
