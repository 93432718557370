const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    EMAIL_REGEX, UUID_REGEX,
    FILE_KEY_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema<import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/app/peaceBuddy/Cemetery').CemeteryEntity>}
 */
const schema = {
    frontend: {
        nr: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        name: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        eMailAddress: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        address: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        zip: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        city: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        countryCode: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['format', 'required'],
            },
        ],
        nr: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required'],
            },
        ],
        name: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        eMailAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        address: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        zip: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        city: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        countryCode: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },

    attributes: {
        id: {
            format: UUID_REGEX,
            required: true, // "create", "update"
        },
        nr: {
            type: 'String',
            required: true,
        },
        name: {
            type: 'String',
            length: {lte: 50},
            required: true,
        },
        eMailAddress: {
            type: 'String',
            length: {lte: 200},
            format: EMAIL_REGEX,
        },
        address: {
            type: 'String',
        },
        zip: {
            type: 'Number',
        },
        city: {
            type: 'String',
        },
        countryCode: {
            type: 'String',
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
