import React, {
    useCallback, useContext, useMemo, useState,
} from 'react';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {FILEEXTENSIONS_IMAGE} from 'beyond-validators/constants';
import {
    Box, Grid, IconButton, Link, List, ListItem, ListItemText, Modal, Skeleton,
} from '@mui/material';

import classes from 'components/Form/FormElements/formElementFiles.module.scss';
import ImageGallery from 'react-image-gallery';
import {
    Delete, Download, OpenInFull, Edit, MoreVert,
} from '@mui/icons-material';
import {ActionMenu} from 'assets/theme/components/ActionMenu/ActionMenu';
import {updateFileInformation} from 'graphql/beyondBuddy/FileInformation/mutations';
import {ItemData} from 'components/Form/ItemData';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {getFileInformation} from 'graphql/beyondBuddy/FileInformation/queries';
import {LoadingButton} from '@mui/lab';

/**
 * Checks if a filekey has the expected extension
 * @param {string} value - value to be checked. Should be a filekey
 * @param {Array<import('beyond-validators').FileExtensionImage | import('beyond-validators').FileExtensionDocument>} extensions - allowed extensions
 * @returns {boolean} - wether the value has one of the provided extensions
 */
const isImageFileExtension = (value, extensions) => {
    const fileExtensionRegex = new RegExp(`^.*\\.(${extensions.join('|')})$`, 'i');
    return fileExtensionRegex.test(value);
};

/**
 * Ensures the filename ends with the correct extension extracted from the fileKey.
 * @param {string} fileKey - The full file path including the filename and extension.
 * @param {string} fileName - The filename that needs to be validated or corrected.
 * @returns {string} - The corrected filename with the proper extension.
 */
const getCorrectFileName = (fileKey, fileName) => {
    // Extract the file name from the fileKey
    const extractedFileName = _.last(_.split(fileKey, '/'));

    // Get the extension of the extracted file name
    const extractedExtension = _.last(_.split(extractedFileName, '.'));

    // Check if fileName is undefined or doesn't have the correct extension
    if (!fileName || !_.endsWith(fileName, `.${extractedExtension}`)) {
        return extractedFileName;
    }

    // If fileName is set and has the correct extension, return it
    return fileName;
};

/**
 * Modal that can be opened to edit the properties of a file
 * @param {object} props - props for the FileEditModal
 * @param {import('helper/helper').FileInformation} [props.file] - FileInfo about the currently selected file, if there is one
 * @param {()=>void} [props.onClose] - callback to the modal being closed
 * @param {(result: import('helper/helper').FileInformation) => void} [props.onFileChange] - function that get's triggered when a file is changed
 * @returns {React.ReactElement} element to be rendered
 */
function FileEditModal({file, onClose, onFileChange}) {
    return (
        <Modal open={Boolean(file)} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                // maxHeight: '80%',
                boxShadow: 4,
                backgroundColor: 'background.paper',
                padding: '1rem',
                border: 'solid 1px',
                borderRadius: '0.7rem',
            }}
            >
                <ItemData
                    loadConfig={{
                        query: getFileInformation,
                        mask: {key: true},
                        variables: {direct: {key: file?.key}},
                    }}
                    saveConfig={{
                        mutation: updateFileInformation,
                        mask: {
                            key: true, filename: false, description: false, options: false,
                        },
                    }}
                >
                    <FormWrapper
                        messageKey="FileInfo_Update"
                        onSaveCallback={(result) => {
                            if (!result?.errors) {
                                onFileChange?.(result);
                            }
                        }}
                        noChangeTrack
                    >
                        <Grid container spacing={2} data-test="FileEditModal">
                            <Grid item xs={12}>
                                <FormElementTextField label="Dateiname" attribute="filename" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementTextField label="Beschreibung" attribute="description" rows={4} />
                            </Grid>
                            <Grid item xs={5} sm={4} md={3}>
                                <FormElementLoadingButton label="Speichern" />
                            </Grid>
                            <Grid item xs={5} sm={4} md={3}>
                                <LoadingButton data-test="FileInfo_close" onClick={onClose} variant="text">Schließen</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormWrapper>
                </ItemData>
            </Box>
        </Modal>
    );
}

/**
 * This element will list all files
 * .Components.Form
 * @param {object} props - properties passed to the component.
 * @param {string} [props.attribute] - attribute key of the form source. The default value for the attribute is "attachmentKeys".
 * @param {Array<import('beyond-validators').FileExtensionImage | import('beyond-validators').FileExtensionDocument>} [props.extensions] - extensions to visualize
 * @param {'Image'|'ImageList'|'Table'} [props.variant] - visualization variant
 * @param {string} [props.fileReference] - fileReference key of the form source. The default is "attachments"
 * @returns {React.ReactElement} The upload component for multiple files
 */
function FormElementFilesList({
    attribute = 'attachmentKeys',
    fileReference = 'attachments',
    extensions = FILEEXTENSIONS_IMAGE,
    variant = 'Image',
}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        get, isLoading, changeHandler, isReadonly,
    } = useContext(FormContext);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [galleryStartIndex, setGalleryStartIndex] = useState(0);
    const [modalSelectedFile, setModalSelectedFile] = useState(null);
    /**
     * Mapping between key and name of files.
     * This avoids changing the value of the reference in the context, triggering data loss
     */
    const [fileNameReassignments, setFileReassignments] = useState({});

    /**
     * Retrieving the value of the fileReference from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     * @type {import('helper/helper').FileInformation[]}
     */
    const fileReferenceValue = useMemo(
        () => _.get(get(fileReference), 'value'),
        [fileReference, get],
    );

    const files = useMemo(
        () => _.filter(fileReferenceValue, (file) => isImageFileExtension(file.key, extensions)),
        [fileReferenceValue, extensions],
    );

    /**
     * @type {(file: import('helper/helper').FileInformation) => void}
     */
    const downloadFile = useCallback((file) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.setAttribute('target', '_blank');
        a.setAttribute('href', file.url);
        a.setAttribute('download', file.filename ?? file.key);
        a.click();
        a.remove();
    }, []);

    /** @type {(file: import('helper/helper').FileInformation) => void} */
    const removeFile = useCallback((file) => {
        const newFileReferenceValue = _.filter(fileReferenceValue, (f) => f.key !== file.key);
        changeHandler({
            attribute,
            value: JSON.stringify(newFileReferenceValue.map((p) => p.key)),
            displayValue: null,
            interacted: true,
        });
        if (fileReference) {
            changeHandler({
                attribute: fileReference,
                value: newFileReferenceValue,
                displayValue: null,
                interacted: true,
            });
        }
    }, [fileReferenceValue, fileReference, changeHandler]);

    if (variant === 'Image') {
        /**
         * Displays a loading animation if the context is still loading.
         */
        if (isLoading?.load) {
            return (
                <>
                    <Box className={classes.container}>
                        <Skeleton variant="rectangular" animation="wave" className={classes.container} />
                    </Box>
                    <Box className={classes.container}>
                        <Skeleton variant="rectangular" animation="wave" className={classes.container} />
                    </Box>
                </>
            );
        }
        return (
            <>
                <FileEditModal
                    file={modalSelectedFile}
                    onClose={() => setModalSelectedFile(null)}
                    onFileChange={() => setModalSelectedFile(null)}
                />
                <Modal
                    open={galleryOpen}
                    onClose={() => { setGalleryOpen(false); }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        // maxHeight: '80%',
                        boxShadow: 4,
                        backgroundColor: 'background.paper',
                        border: 'solid 1px',
                    }}
                    >
                        <ImageGallery items={_.map(files, (file) => ({original: file.url}))} startIndex={galleryStartIndex} />
                    </Box>
                </Modal>
                {_.map(files, (file, index) => (
                    <Box className={classes.container} data-test={`${file.key}`} key={file.key}>
                        <Box className={classes.container}>
                            <Box component="img" alt={file.description ?? file.key} src={file.url} />
                            <Box style={{
                                position: 'absolute',
                                bottom: '0.5rem',
                                width: '100%',
                                display: 'flex',
                                alignContent: 'space-between',
                                zIndex: 3,
                                color: 'black',
                            }}
                            >
                                <IconButton
                                    color="inherit"
                                    className={classes.control}
                                    onClick={() => {
                                        setGalleryStartIndex(index);
                                        setGalleryOpen(true);
                                    }}
                                >
                                    <OpenInFull />
                                </IconButton>
                                <ActionMenu
                                    disabled={isReadonly}
                                    actions={[
                                        {icon: <Download />, label: 'Herunterladen', action: () => downloadFile(file)},
                                        {icon: <Edit />, label: 'Bearbeiten', action: () => setModalSelectedFile(file)},
                                        {icon: <Delete />, label: 'Entfernen', action: () => removeFile(file)},
                                    ]}
                                >
                                    <MoreVert style={{color: 'black'}} />

                                </ActionMenu>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </>
        );
    }

    // Variant Documents
    return (
        <>
            <FileEditModal
                file={modalSelectedFile}
                onClose={() => setModalSelectedFile(null)}
                onFileChange={(result) => {
                    setFileReassignments((current) => ({
                        ...current,
                        [result.key]: result.filename,
                    }));
                    setModalSelectedFile(null);
                }}
            />
            <List
                style={{transition: 'all ease 0.6s'}}
            >
                {_.map(files, (file) => {
                    const fileName = getCorrectFileName(file.key, fileNameReassignments[file.key] ?? file.filename);
                    return (
                        <ListItem
                            data-test={`${file.key}`}
                            key={file.key}
                            style={{borderBottom: 'solid 1px'}}
                            disableGutters
                            secondaryAction={(
                                <ActionMenu
                                    disabled={isReadonly}
                                    actions={[
                                        {icon: <Download />, label: 'Herunterladen', action: () => downloadFile(file)},
                                        {icon: <Edit />, label: 'Bearbeiten', action: () => setModalSelectedFile(file)},
                                        {icon: <Delete />, label: 'Entfernen', action: () => removeFile(file)},
                                    ]}
                                >
                                    <MoreVert />

                                </ActionMenu>
                            )}
                        >
                            <ListItemText primary={<Link style={{wordWrap: 'break-word'}} href={file.url} target="_blank">{fileName}</Link>} />
                        </ListItem>
                    );
                })}
            </List>

        </>
    );
}

export {FormElementFilesList};
