/**
 * listEvacuations
 * @property {string} tenantId - tenant id.
 * @property {string} nextToken - token to continue a listing
 * @property {object} filter - filter with arguments applicable to evacuationsList
 * @returns {object} The evacuations.
 */
export const listEvacuations = /* GraphQL */ `
  query ListEvacuations(
    $tenantId: ID!
    $nextToken: String
    $filter: EvacuationListFilterInput
  ) {
    listEvacuations(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        id
        createdByUserId
        completedAt
        createdAt
        presentEntries {
          arrivalTime
          evacuationPointId
          enteredByUserId
          userId
        }
      }
    }
  }
`;

/**
 * getEvacuation
 * @property {string} id - evacuation id.
 * @returns {object} The evacuation
 */
export const getEvacuation = /* GraphQL */ `
  query GetEvacuation($id: ID!) {
    getEvacuation(id: $id) {
      id
      completedAt
      presentEntries {
        arrivalTime
        evacuationPointId
        evacuationPoint{
          name
        }
        enteredByUserId
        userId
        user{
          contactFirstName
          contactLastName
          organizationalUnit{
            name
          }
          image{
            url
          }
        }
      }
    }
  }
`;

// id: ID!
//     tenantId: ID!

//     createdAt: AWSTimestamp!
//     createdByUserId: ID!
//     createdByUser: User!

//     completedAt: AWSDateTime
//     completedByUserId: ID
//     completedByUser: User

//     presentEntries: [EvacuationPresentEntry!]!
//     grants: ObjectGrants!

export const subscribeOnUpdateEvacuation = /* GraphQL */ `
  subscription SubscribeOnUpdateEvacuation($id: ID!) {
    onUpdateEvacuation(id: $id) {
      id
      completedAt
      completedByUserId
      completedByUser{
        id
        contactFirstName
        contactLastName
        contactEMailAddress
      }
      presentEntries {
        arrivalTime
        evacuationPointId
        evacuationPoint{
          name
        }
        enteredByUserId
        enteredByUser{
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
        userId
        user{
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
      }
    }
  }
`;
