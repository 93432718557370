import React from 'react';
import {
    Badge, Box, ButtonBase, Typography,
} from '@mui/material';
import {useRouteNavigate} from 'hooks/useRouteNavigation';

import classes from 'assets/theme/components/Tile/tile.module.scss';
import classNames from 'classnames';
import {useFindRoute} from 'hooks/useFindRoute';
import {getRoutePath} from 'routes';

/**
 * The Tile component
 * @param {import('@mui/material').BoxProps} props - props for the component.
 * @returns {React.ReactElement} The LayoutContainer component.
 */
function Tile({children, ...rest}) {
    return (
        <Box {...rest} className={classNames(classes.tile, classes.tileContent, rest.className)}>
            {children}
        </Box>
    );
}

/**
 * A full area button overlay for the Tile component
 * @param {import('@mui/material').ButtonBaseProps & {href?: string}} props - props for the component.
 * @returns {React.ReactElement} The TileButton component.
 */
function TileButton(props) {
    return (
        <ButtonBase
            className={classNames(classes.tileButton, classes.tileBox)}
            {...props}
        />
    );
}

/**
 * A full area button overlay for the Tile component with a route target
 * @param {object} props - props for the component.
 * @param {string} props.routeId - unique identifier of the route
 * @param {Record<string, string>} [props.routeParams] - params to be replaced in the dynamic route
 * @param {object} [props.routeState] - state object to be passed to the route
 * @param {import('routeinfo').QueryParams} [props.queryParams] - query params for the path
 * @returns {React.ReactElement} The TileRouteButton component.
 */
function TileRouteButton({
    routeId, routeParams, routeState, queryParams,
}) {
    const routeNavigate = useRouteNavigate();
    const clickHandler = (evt) => {
        evt.preventDefault();
        routeNavigate(
            routeId,
            routeParams,
            routeState,
            queryParams,
        );
    };

    const findRoute = useFindRoute();
    const to = getRoutePath(findRoute(routeId), routeParams, queryParams);
    return (
        <TileButton href={`/${to}`} onClick={clickHandler} />
    );
}

/**
 * A badge in the right down corner
 * @param {import('@mui/material').BadgeProps} props - badge props for the component.
 * @returns {React.ReactElement} The TileBadge component.
 */
function TileBadge(props) {
    return (
        <Box className={classNames(classes.tileBadge, classes.tileBox)}>
            <Badge color="info" {...props} />
        </Box>
    );
}

/**
 * A description text
 * @param {{label: string, abbreviation?: string, style?: React.CSSProperties}} props - text props for the component.
 * @returns {React.ReactElement} The TileImageBackground component.
 */
function TileLabel({label, abbreviation, style}) {
    if (label.length > 14) {
        // eslint-disable-next-line no-console
        console.warn(`Label for tile exeeds max recommendet length of 14 characters: ${label}`);
    }
    return (
        <Box className={classNames(classes.tileLabel, classes.tileBox)}>
            <Typography noWrap variant="h2" style={style} sx={{display: {xs: abbreviation ? 'none' : 'unset', sm: 'unset'}}}>{label}</Typography>
            <Typography noWrap variant="h2" sx={{display: {xs: abbreviation ? 'unset' : 'none', sm: 'none'}}}>{abbreviation}</Typography>
        </Box>
    );
}

/**
 * A full area image background
 * @param {React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>} props - image props for the component.
 * @returns {React.ReactElement} The TileImageBackground component.
 */
function TileImageBackground({className, ...rest}) {
    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img {...rest} className={classNames(classes.tileImage, classes.tileBox, className)} />
    );
}

/**
 * A central icon
 * @param {object} props - props for the component.
 * @param {React.ReactElement} props.icon - source of the image
 * @param {string} [props.className] - css classname to be applied on the component
 * @returns {React.ReactElement} The TileIconBackground component.
 */
function TileIconBackground({icon, className, ...rest}) {
    return (
        <Box className={classNames(classes.tileIcon, classes.tileBox, className)} {...rest}>
            {icon}
        </Box>
    );
}

export {
    Tile, TileLabel, TileBadge, TileButton, TileRouteButton, TileImageBackground, TileIconBackground,
};
