import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from 'assets/theme/layout/Menu/mainMenu.module.scss';
import {MenuLink} from 'components/MenuLink';
import EmptyMenu from 'assets/images/EmptyMenu.svg';

/**
 * The wrapper for the MenuButtonSingle component.
 * @param {object} props - properties passed to the component.
 * @param {import('routeinfo').MenuInfo} props.menu - information of the menu
 * @param {boolean} props.active - indicator wether the menu is active or not
 * @returns {React.ReactElement} - MenuButtonSingle component.
 */
function MenuButtonSingle({
    menu: {
        id, icon, label, path, enabled,
    }, active,
}) {
    const disabledColor = !enabled && 'test.disabled';
    return (
        <Box
            className={classes.menuButtonSingle}
            data-test={`${id}_button`}
            sx={{
                ':hover *': {color: disabledColor || 'info.main'},
            }}
        >
            <MenuLink to={path} disabled={!enabled}>
                <>
                    <Typography sx={{color: (active ? 'info.main' : disabledColor || 'primary.main')}}>{icon}</Typography>
                    <Typography sx={{color: disabledColor || (active ? 'info.main' : 'text.main')}}>{label}</Typography>
                </>
            </MenuLink>
        </Box>
    );
}

/**
 * The MenuWrapper
 * @param {object} props - properties passed to the component.
 * @param {import('routeinfo').MenuInfo} props.menu - menu object.
 * @param {(id: string)=>boolean} props.isMenuActive - function to check wether the menu item is active or not
 * @returns {React.ReactElement} - MenuWrapper component.
 */
function MenuWrapper({menu, isMenuActive}) {
    if (!_.isEmpty(menu.menus)) { // menu has a submenu
        return (
            <Box className={classes.groupContainer}>
                <MenuButtonSingle menu={menu} active={isMenuActive(menu.id)} />
                {_.map(menu.menus, (m, index) => <MenuWrapper key={index} menu={m} isMenuActive={isMenuActive} />)}
            </Box>
        );
    } // menu has no submenu
    return <MenuButtonSingle menu={menu} active={isMenuActive(menu.id)} />;
}

/**
 * This component returns a menu for a given module.
 * @param {object} props - properties passed to the component
 * @param {(id: string)=>boolean} props.isModuleActive - function to check wether the module item is active or not
 * @param {(id: string)=>boolean} props.isMenuActive - function to check wether the menu item is active or not
 * @param {Array<import('routeinfo').ModuleMenuInfo>} props.modules - menu object.
 * @returns {React.ReactComponentElement} - MainMenu component.
 */
function ModulesMenu({modules, isModuleActive, isMenuActive}) {
    const menuAmount = _.sumBy(modules, ({menus}) => menus.length);
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        setExpanded((e) => _.fromPairs(_.map(modules, ({moduleId}) => [moduleId, menuAmount < 5 || !!isModuleActive(moduleId) || (e[moduleId] ?? false)])));
    }, [menuAmount, modules, isModuleActive]);

    return (!_.isEmpty(modules)) ? (
        <>
            { modules.filter(({menus}) => menus.length).map(({moduleId, moduleName, menus}) => (
                <Accordion
                    disableGutters
                    className={classes.moduleAccordion}
                    expanded={expanded[moduleId] ?? false}
                    key={moduleId}
                    data-test={moduleId}
                    style={{marginTop: '1rem'}}
                >
                    <AccordionSummary
                        className={classes.moduleAccordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() => setExpanded((c) => ({...c, [moduleId]: !(c[moduleId] ?? false)}))}
                    >
                        <Typography color="primary" fontWeight="bold">{moduleName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        className={classes.moduleAccordionDetails}
                    >
                        {
                            _.map(menus, (menu) => (
                                <MenuWrapper key={menu.id} menu={menu} isMenuActive={isMenuActive} />
                            ))
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    ) : (
        <Box
            sx={{
                flexGrow: 1,
                display: 'grid',
                alignItems: 'center',
                zIndex: 1,
            }}
            data-test="ModulesMenu_Placeholder"
        >
            <Box
                component="img"
                alt="Empty Menu"
                src={EmptyMenu}
            />
            <Typography variant="h6" color="text.secondary" textAlign="center">
                Kein Menü verfügbar,
                <br />
                bitte wähle eine Applikation aus.
            </Typography>
        </Box>
    );
}

export {ModulesMenu};
