import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkingTimeHolidaysListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeHoliday/WorkingTimeHolidayListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of WorkingTimeHoliday.
 * .TimeBuddy.Pages
 * @returns {React.ReactElement} The WorkingTimeHolidaysListPage component
 */
function WorkingTimeHolidayListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Feiertage</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <WorkingTimeHolidaysListFormular />
            </div>
        </LayoutContainer>
    );
}
export {WorkingTimeHolidayListPage};
