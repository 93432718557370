// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../fragments';

/**
 * createUser
 * @property {string} contactFirstName  - first name of the user.
 * @property {string} contactLastName - last name of the user.
 * @property {string} contactEMailAddress - email address of the user.
 * @property {string} tenantId - parent tenant (id).
 * @returns {object} The newly created user.
 */
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $tenantId: ID!
    $contactFirstName: String!
    $active: Boolean
    $cognito: Boolean
    $microsoft: Boolean
    $contactLastName: String
    $contactEMailAddress: String!
    $imageKey: String
    $tenantAdministrator: Boolean
    $lineManagerUserId: ID
    $organizationalUnitId: ID
    $emailNotifications: Boolean
    $entryDate: AWSDateTime
    $exitDate: AWSDateTime
    $personalNumber: String
    ) {
    createUser(
      tenantId: $tenantId
      contactFirstName: $contactFirstName
      active: $active
      cognito: $cognito
      microsoft: $microsoft
      contactLastName: $contactLastName
      contactEMailAddress: $contactEMailAddress
      tenantAdministrator: $tenantAdministrator
      imageKey: $imageKey
      lineManagerUserId: $lineManagerUserId
      organizationalUnitId: $organizationalUnitId
      emailNotifications: $emailNotifications
      entryDate: $entryDate
      exitDate: $exitDate
      personalNumber: $personalNumber
    ) {
      id
      active
      contactFirstName
      contactLastName
      contactEMailAddress
      tenantAdministrator
      imageKey
      image {
        ...FileInformation
      }
      lineManagerUserId
      lineManager {
        id
        contactFirstName
        contactLastName
      }
      personalNumber
      organizationalUnitId
      notVerified
      exitDate
      organizationalUnit {
        id
        name
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
      entryDate
      exitDate
    }
  }
  ${FileInformationFragment}
`;

/**
 * updateUser
 * @property {string} id - user id of the user
 * @property {string} tenantId - parent tenant (id)
 * @property {string} contactFirstName  - first name of the user
 * @property {string} contactLastName - last name of the user
 * @property {string} imageKey - S3 key of the user image
 * @property {string} lineManagerUserId - id of the linemanger
 * @returns {object} The updated user
 */
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $id: ID!
    $active: Boolean
    $cognito: Boolean,
    $microsoft: Boolean,
    $contactFirstName: String
    $contactLastName: String
    $imageKey: String
    $lineManagerUserId: ID
    $organizationalUnitId: ID
    $tenantAdministrator: Boolean
    $emailNotifications: Boolean
    $entryDate: AWSDateTime
    $exitDate: AWSDateTime
    $personalNumber: String
  ) {
    updateUser(
      id: $id
      active: $active
      cognito: $cognito
      microsoft: $microsoft
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      imageKey: $imageKey
      tenantAdministrator: $tenantAdministrator
      lineManagerUserId: $lineManagerUserId
      organizationalUnitId: $organizationalUnitId
      emailNotifications: $emailNotifications
      entryDate: $entryDate
      exitDate: $exitDate
      personalNumber: $personalNumber
    ) {
      id
      active
      contactFirstName
      contactLastName
      contactEMailAddress
      tenantAdministrator
      image {
        ...FileInformation
      }
      lineManagerUserId
      lineManager {
        id
        contactFirstName
        contactLastName
      }
      personalNumber
      organizationalUnitId
      organizationalUnit {
        id
        name
      }
      notVerified
      emailNotifications
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
      entryDate
      exitDate
    }
  }
  ${FileInformationFragment}
`;

/**
 * update the users color mode
 * @property {string} id - id of the user.
 * @property {string} colorMode - light or dark
 * @returns {object} The updated user.
 */
export const updateUserColorMode = /* GraphQL */ `
 mutation UpdateUserColorMode(
   $id: ID!
   $darkMode: Boolean
 ) {
   updateUserColorMode: updateUser(
     id: $id,
     darkMode: $darkMode
   ){
    id
   }
 }
`;

/**
 * updates the users organizational unit
 * @property {string} id - id of the user.
 * @property {string} organizationalUnitId - id of the new organizational unit
 * @returns {object} The updated user.
 */
export const updateUserOrganizationalUnit = /* GraphQL */ `
  mutation UpdateUserOrganizationalUnit(
    $id: ID!
    $organizationalUnitId: ID
  ) {
    updateUserOrganizationalUnit: updateUser(
      id: $id
      organizationalUnitId: $organizationalUnitId
    ) {
      id
      organizationalUnitId
    }
  }
`;

/**
 * update the users tenant administrator status
 * @property {string} id - id of the user
 * @property {boolean} tenantAdministrator - true if the user should be a tenant administrator
 * @returns {object} the updated fields of the user
 */
export const updateUserTenantAdminStatus = /* GraphQL */ `
  mutation UpdateUserTenantAdminStatus(
    $id: ID!
    $tenantAdministrator: Boolean!
  ) {
    updateUserTenantAdminStatus: updateUser(
      id: $id,
      tenantAdministrator: $tenantAdministrator
    ){
      id
      tenantAdministrator
    }
  }
`;

/**
 * update the users `active` status
 * @property {string} id - id of the user
 * @property {boolean} active - true if the user should be active
 * @returns {object} the updated fields of the user
 */
export const userSetActive = /* GraphQL */ `
  mutation UserSetActive(
    $id: ID!
    $active: Boolean!
  ) {
    userSetActive: updateUser(
      id: $id
      active: $active
    ) {
      id
      notVerified
    }
  }
`;

/**
 * update the users `notVerified` status
 * @property {string} id - id of the user
 * @property {boolean} notVerified - true if the user should be a tenant administrator
 * @returns {object} the updated fields of the user
 */
export const userVerify = /* GraphQL */ `
  mutation UserVerify(
    $id: ID!
  ) {
    userVerify: updateUser(
      id: $id
      notVerified: null
    ) {
      id
      notVerified
    }
  }
`;

/**
 * deleteUser
 * @property {string} id - user id.
 * @returns {object} The deleted user.
 */
export const deleteUser = /* GraphQL */ `
 mutation DeleteUser(
     $id: ID!
   ) {
   deleteUser(
     id: $id
     ) {
     id
   }
 }
`;

/**
 *
 * @returns {boolean} success message
 */
export const reevaluateUserAccess = /* GraphQL */ `
 mutation ReevaluateUserAccess {
  reevaluateUserAccess
 }
`;
