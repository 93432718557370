import React, {useEffect, useState} from 'react';
import {Amplify} from 'aws-amplify';
import {Link} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {Box} from '@mui/material';
import {ArrowForward} from '@mui/icons-material';
import {awsConfigAPIKEY} from 'aws-exports';
import {TenantFormular} from 'applications/beyondbuddy/settings/forms/tenant/TenantFormular';

import classes from 'applications/beyondbuddy/settings/pages/tenant/tenantCreatePage.module.scss';

/**
 * The Register component.
 * @returns {React.ReactElement} The Register component.
 */
function TenantCreatePage() {
    const {user, signOut} = useAuthenticator();
    const [redirectOrigin, setRedirectOrigin] = useState(null);

    useEffect(() => {
        if (redirectOrigin === null) {
            Amplify.configure(awsConfigAPIKEY);
        }
    }, [redirectOrigin, awsConfigAPIKEY]);

    return (
        <Box className={classes.layoutContainer}>
            <h2>Willkommen in der BEYOND FAMILIE</h2>
            <hr />
            <Box sx={{marginTop: '1.5rem'}}>
                {redirectOrigin === null
                    ? (
                        <TenantFormular
                            id="create"
                            onSave={(result) => {
                                if ('id' in result && 'name' in result) {
                                    if (user !== null) {
                                        signOut();
                                    }
                                    const {protocol, host, hostname} = window.location;
                                    if (host.startsWith('localhost') || /^\d+.\d+.\d+.\d+$/.test(hostname)) {
                                        localStorage.setItem('test-url', `${result.name}.localhost`);
                                        setRedirectOrigin('');
                                    } else {
                                        setRedirectOrigin(`${protocol}//${result.name}.${host}`);
                                    }
                                }
                            }}
                        />
                    )
                    : (
                        <Box data-test="Register_SuccessMessage_Container">
                            <p>Sie haben sich erfolgreich registriert! Bitte prüfen Sie Ihr Postfach.</p>

                            <Box className={classes.link}>
                                <Link to={`${redirectOrigin}/`} data-test="Register_Success_ForwardButton">
                                Weiter zur Anmeldung
                                    {' '}
                                    <ArrowForward />
                                </Link>
                            </Box>
                        </Box>
                    )}
            </Box>
        </Box>
    );
}

export {TenantCreatePage};
