import React from 'react';
import {Alert, Box} from '@mui/material';
import {FeedbackFormular} from 'applications/beyondbuddy/forms/feedback/FeedbackFormular';
import feedbackImg from 'applications/beyondbuddy/pages/images/AdobeStock_396612806.png';
import {Feedback} from '@mui/icons-material';

/**
 * This page shows a create feedback page
 * @param {object} props - properties of the feedback page
 * @param {boolean} props.open indicates that the feedback is open
 * @param {()=>void} props.closeFeedback - function to close the feedback
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The FeedbackPage component.
 */
function FeedbackPage({closeFeedback, open}) {
    return (
        <>
            <Box
                component="img"
                alt="Feedback is important"
                src={feedbackImg}
                style={{
                    width: '100%', maxHeight: '12rem', objectFit: 'cover', objectPosition: 'top',
                }}
            />
            <hr />
            <Alert severity="success" icon={<Feedback />}>
                Vielen Dank, dass Du uns hilfst das Produkt zu verbessern! Wir sind bestrebt alles daran zu setzen, die best mögliche Lösung herzustellen.
            </Alert>
            <hr style={{marginBottom: '2rem'}} />
            <Box sx={{marginTop: '1.5rem'}}>
                {open && <FeedbackFormular onSave={closeFeedback} />}
            </Box>
        </>
    );
}
export {FeedbackPage};
