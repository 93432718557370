/**
 * getWorkplace
 * @property {string} id - ID of the requested workplace.
 * @property {string} tenantId - ID of the tenant.
 * @returns {object} The requested workplace.
 */
export const getWorkplace = /* GraphQL */ `
  query GetWorkplace(
      $id: ID!
    ) {
    getWorkplace(
      id: $id
    ) {
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * listWorkplaces
 * @property {string} tenantId - id of the tenant
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All workplaces in the given tenant.
 */
export const listWorkplaces = /* GraphQL */ `
 query ListWorkplaces (
      $tenantId: ID!
      $nextToken: String
      $filter: WorkplaceFilterInput
   ) {
   listWorkplaces(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
     items {
       id
       name
       grants {
        deletable
        permissionsUpdatable
       }
     }
     nextToken
   }
 }
`;

export const listWorkplaceOptions = /* GraphQL */ `
  query ListWorkplaceOptions (
    $tenantId: ID!
    $filter: String
  ) {
    listWorkplaces(
      tenantId: $tenantId
      filter: { name: $filter }
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
