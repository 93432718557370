import React, {useMemo} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {Box, Grid} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {schema as validatorSchema} from 'beyond-validators/driveBuddy/VehicleProtocolEntry';
import {createVehicleProtocolEntry, updateVehicleProtocolEntry} from 'graphql/driveBuddy/Vehicle/mutations';
import {getVehicleProtocolEntry} from 'graphql/driveBuddy/Vehicle/queries';

import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {Refresh} from '@mui/icons-material';
import {FormReset} from 'components/Form/FormReset';

const causeDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'REFUEL',
        de: 'Tanken',
    }, {
        value: 'PARK',
        de: 'Parken',
    }, {
        value: 'ACCIDENT',
        de: 'Unfall',
    }, {
        value: 'GARAGE',
        de: 'Werkstatt',
    }, {
        value: 'OTHER',
        de: 'Sonstiges',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};

/**
 * The VehicleProtocolEntryFormular formular for creating and updating a vehicle protocol entries
 * @param {object} props - props passed to the component
 * @param {string} props.vehicleId - identifier of the vehicle of the protocol entry
 * @param {string} [props.id] - identifier of the entity. Pass 'create' for a new entity
 * @param {(result: object) => void} [props.onSave] - a function to be called after saving
 * @param {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [props.actionButtonProps] - properties for actionButtons
 * @returns {React.ReactElement} The VehicleProtocolEntryFormular component.
 */
function VehicleProtocolEntryFormular({
    id, vehicleId, onSave, actionButtonProps, ...rest
}) {
    const isNewItem = id === 'create';

    const saveConfig = useMemo(() => ({
        ...(id !== 'create') ? {
            mask: {
                id: true,
                userId: true,
                vehicleId: true,
                vehicleKM: false,
                eventDateTime: false,
                cause: true,
                description: false,
                attachmentKeys: false,
            },
            mutation: updateVehicleProtocolEntry,
            variables: {global: {userId: 'userId'}, direct: {vehicleId}},
        } : {
            mask: {
                userId: true,
                vehicleId: true,
                vehicleKM: false,
                eventDateTime: false,
                cause: true,
                description: false,
                attachmentKeys: false,
            },
            mutation: createVehicleProtocolEntry,
            variables: {global: {userId: 'userId'}, direct: {vehicleId}},
        },
    }), [id, vehicleId]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getVehicleProtocolEntry,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    return (
        <ItemData {...!isNewItem ? {loadConfig} : {}} saveConfig={saveConfig}>
            <FormWrapper
                {...rest}
                isNewItem={isNewItem}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (id !== 'create') ? 'update' : 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={!isNewItem ? 'VehicleProtocolEntry_Update' : 'VehicleProtocolEntry_Create'}
                context={`VehicleProtocolEntry#${id}Base`}
            >
                <FormReset shouldClear={id === 'create'} />
                {actionButtonProps && (
                    <FormElementActionButton
                        reload
                        {...actionButtonProps}
                        disabled={isNewItem}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                )}

                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12}>
                            <FormElementTextField label="Beschreibung" attribute="description" rows={4} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementDateTimePicker label="Datum und Uhrzeit" attribute="eventDateTime" />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormElementTextField label="Fahrzeug KM" attribute="vehicleKM" type="int" />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormElementAutocomplete attribute="cause" label="Grund" dataSchema={causeDataSchema} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementFilesUpload />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormElementLoadingButton label="Speichern" />
                        </Grid>
                    </Grid>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {VehicleProtocolEntryFormular, causeDataSchema as vehicleProtocolEntryCauseDataSchema};
