import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {OrganizationalUnitsListFormular} from 'applications/beyondbuddy/settings/forms/organizationalUnit/OrganizationalUnitsListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of organizationalUnits
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function OrganizationalUnitListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Organisationseinheiten</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <OrganizationalUnitsListFormular />
            </div>
        </LayoutContainer>
    );
}
export {OrganizationalUnitListPage};
