import allCountries from 'helper/countries.json';
import _ from 'lodash';

const contries = _.sortBy(allCountries, 'suggested', 'ranking');

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const countryCodeDataSchema = {
    dataKey: 'value',
    options: contries.map((country) => ({
        value: country.abbr,
        de: `${country.nameDe ?? country.nameEn} (${country.abbr})`,
    })),
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};
