import React from 'react';
import {IconButton, LinearProgress, Typography} from '@mui/material';
import {RouteLink} from 'components/RouteLink';
import _ from 'lodash';
import {useGlobalState} from 'hooks/useGlobalState';
import {OpenInBrowserOutlined} from '@mui/icons-material';
import {DashboardContainer} from 'components/Dashboard/DashboardContainer';
import {useLogMessage} from 'hooks/useLogMessage';

/**
 * This page shows a applications or modules dashboard
 * When an applicationId is provided, the dashboards of the modules will be provided
 * .BeyondBuddy.Pages
 * @param {object} props - props of the tiles dashboard
 * @param {string} [props.applicationId] - id of the application, the module tile dashboards should be listed for
 * @returns {React.ReactElement | undefined} The OrganizationalUnitListPage component
 */
function Dashboards({applicationId}) {
    const {getGlobal} = useGlobalState();
    const {logMessage} = useLogMessage();
    /** @type {import('applications/configuration').ApplicationConfiguration[]} */
    const authorizedApplicationConfigurations = getGlobal('authorizedApplicationConfigurations');
    if (!authorizedApplicationConfigurations) {
        return <LinearProgress />;
    }

    // not authorized for any application
    if (!(applicationId === 'beyondbuddy') && authorizedApplicationConfigurations.length <= 1) {
        return (
            <DashboardContainer boxProps={{style: {padding: '1rem'}}}>
                <Typography variant="h2">Aktuell hast Du noch keine Anwendungen aktiviert!</Typography>
                <RouteLink routeId="beyondbuddy_settings_modules_route" style={{width: '100%'}}>
                    <Typography variant="h2" color="primary">Anwendungen aktivieren</Typography>
                </RouteLink>
            </DashboardContainer>
        );
    }

    const applicationConfiguration = !!applicationId && _.find(authorizedApplicationConfigurations, {id: applicationId});
    if (!!applicationId && !applicationConfiguration) {
        return (
            <DashboardContainer boxProps={{style: {padding: '1rem'}}}>
                <Typography variant="h2">Aktuell hast Du keinen Zugriff auf diese Anwendung!</Typography>
                <RouteLink routeId="beyondbuddy_settings_general_dashboard_route" style={{width: '100%'}}>
                    <Typography variant="h2" color="primary">Anwendung aktivieren</Typography>
                </RouteLink>
            </DashboardContainer>
        );
    }
    if (applicationConfiguration) {
        const applicationModuleDashboards = applicationConfiguration.modules?.filter((m) => m.tileDashboard);

        if (applicationModuleDashboards.length) {
            return (
                <>
                    {_.map(applicationModuleDashboards, (module) => (
                        <DashboardContainer
                            key={module.id}
                            head={(
                                <>
                                    <RouteLink routeId={module.defaultRouteId}><IconButton><OpenInBrowserOutlined /></IconButton></RouteLink>
                                    <Typography variant="h2">{module.name}</Typography>
                                    {/* <Typography variant="h2">{application.description}</Typography> */}
                                </>
                            )}
                        >
                            {module.tileDashboard}
                        </DashboardContainer>
                    ))}
                </>
            );
        }

        logMessage(
            'PageNotFound',
            {
                level: 'WARNING',
                message: `Tile dashboards were requested for the modules of the application ${applicationConfiguration.id} ${applicationConfiguration.name}, but none were provided`,
            },
            false,
        );
        return (
            <DashboardContainer boxProps={{style: {padding: '1rem'}}}>
                <Typography variant="h2">{`Leider stellt kein Modul der Anwendung ${applicationConfiguration.name} eine Übersicht zur Verfügung.`}</Typography>
            </DashboardContainer>
        );
    }

    const applicationDashboards = authorizedApplicationConfigurations.filter((a) => a.tileDashboard);

    if (applicationDashboards.length) {
        return (
            <>
                {_.map(applicationDashboards, (application) => (
                    <DashboardContainer
                        key={application.id}
                        head={(
                            <>
                                <RouteLink routeId={application.route.id}><IconButton><OpenInBrowserOutlined /></IconButton></RouteLink>
                                <Typography variant="h2">{application.name}</Typography>
                                <Typography variant="h2">{application.description}</Typography>
                            </>
                        )}
                    >
                        {application.tileDashboard}
                    </DashboardContainer>
                ))}
            </>
        );
    }
}
export {Dashboards};
