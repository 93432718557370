import React from 'react';
import {
    Alert, Box, Divider, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

const getFormAttributeDescription = (label, description) => (
    <li>
        <strong>{label}</strong>
        {`: ${description}`}
    </li>
);

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideCemeteryPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Friedhof</Typography>
            <hr />
            <Box>
                Das Formular zur Anlage und zum Bearbeiten eines Friedhofs umfasst den Bereich der Basisinformationen,
                sowie bei bestehenden Friedhöfen zusätzlich die
                <QuickGuideLink id="beyondbuddy_general_permissions"> (eingehenden) Berechtigungen</QuickGuideLink>
                .
                <br />
                <br />
                <Alert severity="info">Beim Erstellen eines Friedhofs, wird automatich der gesammte Mandant berechtigt diesen zu lesen und zu bearbeiten.</Alert>
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Objektnummer (Obj.Nr.)',
                        'Uneindeutige Identifikationsnummer',
                    )}
                    {getFormAttributeDescription(
                        'Name',
                        'Bezeichnung des Friedhofs',
                    )}
                </ul>

                <br />
                <Typography variant="h2" color="primary">Weitere Felder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Referenz-ID',
                        'Zusätzliche Identifikationsnummer für Drittsysteme',
                    )}
                    {getFormAttributeDescription(
                        'Adresse',
                        'Straße und Hausnummer des Friedhofs',
                    )}
                    {getFormAttributeDescription(
                        'PLZ (Postleitzahl)',
                        'Postleitzahl des Friedhofs',
                    )}
                    {getFormAttributeDescription(
                        'Stadt',
                        'Stadt, in der sich der Friedhof befindet',
                    )}
                    {getFormAttributeDescription(
                        'Ländercode',
                        'z.B. AT für Österreich',
                    )}
                    {getFormAttributeDescription(
                        'E-Mail-Adresse',
                        'Kontakt-E-Mail-Adresse',
                    )}
                    {getFormAttributeDescription(
                        'Telefonnummer',
                        'Kontakttelefonnummer.',
                    )}
                    {getFormAttributeDescription(
                        'Ruhefrist Urnengrab',
                        'Ruhefrist für Urnengräber in Jahren',
                    )}
                    {getFormAttributeDescription(
                        'Ruhefrist Sarggrab',
                        'Ruhefrist für Sarggräber in Jahren',
                    )}
                </ul>

                <br />
                <Alert severity="info">
                    Die Ruhefristen werden beim Erstellen von Sarg- oder Urnengräbern als Vorschlag verwendet.
                    Änderungen der Ruhefristen wirken sich nicht rückwirkend auf bereits angelegte Gräber aus.
                </Alert>

                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit ein repräsentatives Bild des Friedhofs hochzuladen.
                Dies kann beispielsweise ein Bild aus der Vogelperspektive oder vom Haupttor sein.
                Zusätzlich können multimediale Inhalte wie Dokumente (z.B. die Friedhofsordnung) oder weitere Bilder hochgeladen werden,
                um Informationen zum Friedhof bereitzustellen.

            </Box>
        </>
    );
}
export {GuideCemeteryPage};
