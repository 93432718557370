import React from 'react';
import classes from 'assets/theme/layout/LayoutContainer/layoutContainer.module.scss';
import {Box} from '@mui/material';

/**
 * The Wrapper for the LayoutContainer component(s).
 * @param {object} props - props for the component.
 * @returns {React.ReactElement} The LayoutContainer component.
 */
function LayoutContainer(props) {
    return (
        <Box
            data-test="layoutContainer"
            className={classes.layoutContainer}
        >
            <Box className={classes.layoutContainerContent} {...props} />
        </Box>
    );
}

export {LayoutContainer};
