import React, {useEffect, useState} from 'react';
import {
    Tile, TileBadge, TileButton, TileIconBackground, TileLabel, TileRouteButton,
} from 'assets/theme/components/Tile/Tile';

import {DashboardTilesContainer} from 'components/Dashboard/DashboardTilesContainer';
import {
    AccessTime,
    CalendarMonth, HistoryToggleOff, MoreTime, PlayCircleOutline, StopCircleOutlined,
} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import {useToggleWorking} from 'applications/timebuddy/modules/workingtime/hooks/useToggleWorking';
import {useCanAccess} from 'hooks/useCanAccess';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {useGlobalState} from 'hooks/useGlobalState';
import {CancelException} from 'hooks/useCancellablePromise';
import {listWorkingTimeLogs} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {useLogChanged} from 'hooks/useLogChanged';
import {idByKind} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';

const workLogKinds = new Set([
    'NORMAL',
    'TRAVEL_ACTIVE',
    'TRAVEL_PASSIVE',
    'BREAK',
]);

/**
 * Tile that enables a user to stop and start time tracking as needed
 * @returns {React.ReactElement} tile
 */
function TrackTimeTile() {
    const {tracking, toggleWorking} = useToggleWorking();

    return tracking === null
        ? <Tile><TileIconBackground icon={<CircularProgress />} /></Tile>
        : (
            <Tile>
                <TileLabel label={!tracking ? 'Starten' : 'Stoppen'} />
                <TileButton onClick={toggleWorking} />
                <TileIconBackground icon={!tracking ? <PlayCircleOutline color="primary" /> : <StopCircleOutlined color="primary" />} />
            </Tile>
        );
}

/**
 * This page shows a dashboard for working time
 * .TimeBuddy.Pages
 * @returns {React.ReactElement} The page to render
 */
function WorkingTimeTileDashboard() {
    const {listItems} = AWSAppSyncProvider();
    const {getGlobal} = useGlobalState();

    const tenantId = getGlobal('tenantId');
    const userId = getGlobal('userId');

    const timeBuddyTrackTimeTile = useCanAccess('createWorkingTimeLog', 'updateWorkingTimeLog') && <TrackTimeTile />;
    const timeBuddyCreateWorkingTimeLogTile = useCanAccess('createWorkingTimeLog') && (
        <Tile>
            <TileLabel label="Zeitbuchung" />
            <TileRouteButton routeId="timebuddy_workingtime_work_log_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<MoreTime color="primary" />} />
        </Tile>
    );
    const timeBuddyListWorkingTimeLogsTile = useCanAccess('readWorkingTimeLog') && (
        <Tile>
            <TileLabel label="Übersicht" />
            <TileRouteButton routeId="timebuddy_workingtime_work_logs_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<CalendarMonth color="primary" />} />
        </Tile>
    );
    const timeAccountTile = useCanAccess('readWorkingTimeLog') && (
        <Tile>
            <TileLabel label="Zeitkonto" abbreviation="Zeit" />
            <TileIconBackground icon={<AccessTime color="primary" />} />
            <TileRouteButton
                routeId="beyondbuddy_settings_general_user_profile_route"
                queryParams={{
                    hashFragment: 'timeAccount',
                }}
            />
        </Tile>
    );
    const [drafts, setDrafts] = useState(null);
    useEffect(() => {
        listItems(listWorkingTimeLogs, {tenantId, filter: {draft: true, userId}}).then((items) => {
            setDrafts(items);
        }).catch((error) => {
            if (error instanceof CancelException || ('name' in error && error.name === 'AuthorizationError')) {
                // skip
            } else {
                throw error;
            }
        });
    }, [tenantId, userId, listItems]);
    const openDraftRouteConfig = drafts?.length === 1 ? {
        routeId: idByKind[drafts[0].kind] ?? 'timebuddy_workingtime_work_log_route',
        routeParams: {id: drafts[0].id},
    } : {
        routeId: drafts?.some?.((draft) => workLogKinds.has(draft.kind))
            ? 'timebuddy_workingtime_work_logs_route'
            : 'timebuddy_workingtime_absence_logs_menu',
        routeState: {initialFilters: {draft: true}},
    };
    useLogChanged({
        drafts,
        routeId: idByKind[drafts?.[0]?.kind] ?? 'timebuddy_workingtime_work_log_route',
        routeParams: {id: drafts?.[0]?.id},
    });
    const openDraftRecords = useCanAccess('readWorkingTimeLog') && drafts?.length > 0 && (
        <Tile>
            <TileLabel label={drafts.length === 1 ? 'Entwurf' : 'Entwürfe'} />
            <TileRouteButton {...openDraftRouteConfig} />
            <TileBadge badgeContent={drafts.length} />
            <TileIconBackground icon={<HistoryToggleOff color="primary" />} />
        </Tile>
    );

    /** @type {{severity: import('@mui/material').AlertColor, message:string}} */
    const notification = {severity: 'success', message: 'Alles im grünen Bereich! Es liegen keine Notifizierungen vor.'};
    const elements = [
        {
            tile: timeBuddyTrackTimeTile,
        }, {
            routeId: 'timebuddy_workingtime_work_log_route',
            tile: timeBuddyCreateWorkingTimeLogTile,
        }, {
            routeId: 'timebuddy_workingtime_work_logs_route',
            tile: timeBuddyListWorkingTimeLogsTile,
        }, {
            routeId: 'beyondbuddy_settings_general_user_profile_route',
            tile: timeAccountTile,
        }, {
            tile: openDraftRecords,
        },
    ];
    return (
        <DashboardTilesContainer
            notification={notification}
            elements={elements}
        />
    );
}
export {WorkingTimeTileDashboard};
