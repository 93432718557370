/**
 * viewNotification
 * @property {string} id - ID of the Notification.
 * @returns {object} The viewed Notification.
 */
export const viewNotification = /* GraphQL */ `
  mutation ViewNotification(
    $id: ID!
  ) {
    viewNotification: updateNotification(
      id: $id, 
      viewed: true
    ) { 
      id
    }
  }
`;

/**
 * deleteNotification
 * @property {string} id - ID of the Notification.
 * @returns {object} The deleted Notification.
 */
export const deleteNotification = /* GraphQL */ `
 mutation DeleteNotification(
     $id: ID!
   ) {
   deleteNotification(
     id: $id,
     ) {
      id
   }
 }
`;
