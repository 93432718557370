const {FILEEXTENSIONS_IMAGE} = require('../constants');
const {FILE_KEY_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        name: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        manufacturer: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        model: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        vin: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        licensenumber: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        imageKey: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['fileExtensions'],
            },
        ],

    },
    backend: {
        name: [
            {
                actions: ['create', 'update'],
                configurations: ['unique', 'type', 'length', 'required'],
            },
        ],
        manufacturer: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        model: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        vin: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        licensenumber: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        imageKey: [
            {
                actions: ['create', 'update'],
                configurations: ['format', 'fileExtensions'],
            },
        ],

    },
    attributes: {
        name: {
            type: 'String', // "String", "Number",...
            required: true, // "create", "update"
            unique: true,
            length: {lte: 50, gte: 3}, // eq, lt, lte, gt, gte,
            // message: 'Der Name muss zwischen 3 und 50 Zeichen lang sein, ist einzigartig und verpflichtend.',
        },
        manufacturer: {
            type: 'String',
            length: {lte: 30},
        },
        model: {
            type: 'String',
            length: {lte: 30},
        },
        vin: {
            type: 'String',
            unique: true,
            length: {lte: 17},
            // format: /^([A-Z0-9-()_# ]+)$/,
        },
        licensenumber: {
            type: 'String',
            length: {lte: 9},
            // format: /^([A-Za-z0-9-()_# ]+)$/,
            //  format: /^[A-Z]{1,2}-([^Q][A-Z1-9]{1,6})$/,
        },
        imageKey: {
            format: FILE_KEY_REGEX,
            fileExtensions: FILEEXTENSIONS_IMAGE,
        },
    },
};

module.exports = {schema};
