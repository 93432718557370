import React, {
    useCallback, useContext, useState,
    useEffect,
} from 'react';
import {TextField} from '@mui/material';
import {ListFilterContext} from 'components/Form/ListFilterProvider';
import _ from 'lodash';
/**
 * ## Text Filter Element
 * Component to provide the ability to filter according to input text.
 * Requires a `ListFilterContext` around it
 * @param {object} props - props that are passed to the component
 * @param {string} props.label - label to be shown
 * @param {string} props.path - path for the filter
 * @param {import('@mui/material').TextFieldProps} [props.textFieldProps] - optional props for the textfield
 * @returns {React.ReactElement} - react element to be rendered
 */
function FilterElementText({path, label, textFieldProps}) {
    const {updateFilter, filterValues$} = useContext(ListFilterContext);
    const [value, setValue] = useState('');
    const [placeholderValue, setPlaceholderValue] = useState('');
    useEffect(() => {
        const subscription = filterValues$?.subscribe((values) => {
            setValue(_.get(values, path));
            setPlaceholderValue(_.get(values, 'search'));
        });
        return () => subscription?.unsubscribe();
    }, [filterValues$, setValue]);

    const onChangeValue = useCallback((event) => {
        let newValue = event?.target?.value;

        if (textFieldProps?.type === 'number') {
            newValue = (_.isNumber(newValue) && newValue) || null;
            if (newValue) {
                newValue = Number(newValue);
            }
            setValue(newValue);
            updateFilter(path, newValue, true);
        } else {
            setValue(newValue ?? '');
            updateFilter(path, newValue || null, true);
        }
    }, [path, updateFilter, setValue]);

    return (
        <TextField
            key={path}
            label={label || ''}
            value={value ?? ''}
            data-test={`FilterElementText_${path}`}
            placeholder={placeholderValue ?? ''}
            onChange={(e) => onChangeValue(e)}
            {...textFieldProps}
            InputLabelProps={
                placeholderValue
                    ? {shrink: true, ...textFieldProps?.InputLabelProps}
                    : textFieldProps?.InputLabelProps
            }
        />
    );
}

export {FilterElementText};
