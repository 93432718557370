/**
 * createEvacuationPoint
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the EvacuationPoint.
 * @property {string} managerUserId - the id of user that manages the EvacuationPoint.
 * @returns {object} The newly created EvacuationPoint.
 */
export const createEvacuationPoint = /* GraphQL */ `
  mutation CreateEvacuationPoint(
    $name: String!
    ) {
    createEvacuationPoint(
      name: $name, 
    ) {
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * updateEvacuationPoint
 * @property {string} id - ID of the EvacuationPoint.
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the EvacuationPoint.
 * @returns {object} The updated EvacuationPoint.
 */
export const updateEvacuationPoint = /* GraphQL */ `
  mutation UpdateEvacuationPoint(
    $id: ID!
    $name: String!
  ) {
    updateEvacuationPoint(
      id: $id
      name: $name
    ) { 
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * deleteEvacuationPoint
 * @property {string} id - ID of the EvacuationPoint.
 * @returns {object} The deleted EvacuationPoint.
 */
export const deleteEvacuationPoint = /* GraphQL */ `
 mutation DeleteEvacuationPoint(
     $id: ID!
   ) {
   deleteEvacuationPoint(
     id: $id,
     ) {
      id
   }
 }
`;
