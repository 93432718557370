import _ from 'lodash';

/**
 * Checks if the given string is a valid GUID (Globally Unique Identifier) format.
 * @param {string} guid - The string to be validated as a GUID or literally "create"
 * @returns {boolean} True if the string is a valid GUID, false otherwise.
 * @see XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
 * @example isValidGUID("12345678-1234-1234-1234-123456789abc");
 * @example isValidGUID("create");
 */
export const isValidGUID = (guid) => {
    if (guid === 'create') {
        return true;
    }
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return _.isString(guid) && guidRegex.test(guid);
};
