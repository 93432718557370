import _ from 'lodash';

/**
 *
 * @param {Partial<Record<'gender'|'titlePrefixed'|'firstName'|'lastName'|'titlePostfixed', string>>} nameParts all parts of the name
 * @returns {string} the full name
 */
const getFullName = ({
    gender, titlePrefixed, firstName, lastName, titlePostfixed,
}) => {
    let nameString = '';
    switch (gender) {
    case 'male':
    case 'Mann': nameString = 'Herr'; break;
    case 'female':
    case 'Frau': nameString = 'Frau'; break;
    default: break;
    }
    const name = _.trim(_.join([titlePrefixed, firstName, lastName, titlePostfixed], ' '));
    nameString = _.trim(_.join([nameString, name], ' '));

    return nameString;
};

export {getFullName};
