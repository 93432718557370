/* eslint-disable max-len */
import React from 'react';
import {
    Box, Divider, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle

const getFormAttributeDescription = (label, description) => (
    <li>
        <strong>{label}</strong>
        {`: ${description}`}
    </li>
);

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGraveRecordPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Beisetzung</Typography>
            <hr />
            <Box>
                Ein Grabeintrag bildet eine Beisetzung einer verstorbenen Person ab.
                Diese werden ausschließlich von Gräbern aus erstellt und verstorbenen Personen zugeordnet.
                <br />
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Verstorbener',
                        'Die beigesetzte Person',
                    )}
                    {getFormAttributeDescription(
                        'Sachbearbeiter',
                        'Benutzer, welcher aus Verwaltungssicht die Beisetzung bearbeitet hat',
                    )}
                    {getFormAttributeDescription(
                        'Datum der Beisetzung',
                        'Datum, an dem die Beisetzung stattgefunden hat',
                    )}
                    {getFormAttributeDescription(
                        'Ruhefrist Ende',
                        'Gesetzliche Ruhefrist der beigesetzten Person',
                    )}
                    {getFormAttributeDescription(
                        'Grab Position',
                        'Lage und Tiefe der Beisetzung innerhalb des Grabes',
                    )}
                </ul>
                <br />
                <Typography variant="h2" color="primary">Weitere Felder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Vermerk',
                        'Beliebiges Kommentar',
                    )}
                </ul>

                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit multimediale Inhalte wie Dokumente oder Bilder hochzuladen,
                um Informationen zum Grabeintrag bereitzustellen.
                <br />
                <br />
                <Typography variant="h2" color="primary">Ruhefrist vorzeitig aufheben</Typography>
                <Divider />
                Wird die Ruhefrist auf ein Datum in der Vergangenheit oder den aktuellen Tag gesetzt,
                so gilt der Grabeintrag als historisch und die Grabposition wird freigegeben.
            </Box>
        </>
    );
}
export {GuideGraveRecordPage};
