/**
 * createWorkingTimeAggregateCorrection
 * @property {string} userId - id of the user (required)
 * @property {object} type - type of aggregate to load
 * @property {string} time - time for aggregate
 * @returns {object} All users.
 */
export const createWorkingTimeAggregateCorrection = /* GraphQL */ `
  mutation CreateWorkingTimeAggregateCorrection (
    $userId: ID!
    $type: WorkingTimeAggregateCorrectionType!
    $value: Float!
    $time: AWSDateTime!
    $comment: String
  ) {
    createWorkingTimeAggregateCorrection(
      userId: $userId
      time: $time
      value: $value
      type: $type
      comment: $comment
    ) {
        id
        value
        time
        tenantId
    }
  }
`;

/**
 * deleteWorkingTimeAggregateCorrection
 * @property {string} id - id of the correction to delete
 */
export const deleteWorkingTimeAggregateCorrection = /* GraphQL */ `
  mutation DeleteWorkingTimeAggregateCorrection(
    $id: ID!
  ) {
    deleteWorkingTimeAggregateCorrection(id: $id) {
      id
    }
  }
`;
