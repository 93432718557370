import React from 'react';
import {
    Box, Divider, List, Typography,
} from '@mui/material';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';

/**
 * This page shows a quick guide on the active property of users
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideUserActivityPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Aktive / Inaktive Benutzer</Typography>
            <hr />
            <Box>
                Ein Benutzer kann Aktiv oder Inaktiv geschaltet werden.
                <br />
                Inaktive Benutzer haben zwar hinterlegte Daten, können sich allerdings nicht anmelden, oder für neue Berechtigungen ausgewählt werden.
                Allerdings fallen für Inaktiv geschaltete Benutzer auch keine Kosten an.
                <br />
                <br />
                Es kann dazu kommen dass ein Benutzer automatisch auf Inaktiv geschaltet wird. Dies ist der Fall wenn nach dem ursprünglichen
                erstellen und Aktiv schalten des Benutzers die Registrierung zu spät unternommen wird.  Nach drei Tagen verliert das versante temporäre Passwort seine
                Gültigkeit, und um kosten für einen Nicht nutzbaren Benutzer zu vermeiden wird dieser automatisch inaktiv gesetzt.
                Mit dem umschalten auf Aktiv wird ein neues temporäres Passwort versendet.
                <br />
                <br />
                <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissions', label: 'Berechtigungssystem'})}
                    {createQuickGuideListItem({id: 'beyondbuddy_general_organization', label: 'Abbilden der Unternehmensstruktur in der Aufbauorganisation'})}
                </List>
            </Box>
        </>
    );
}
export {GuideUserActivityPage};
