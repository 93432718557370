/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const allowedApplicationsDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'PeaceBuddy',
        de: 'PeaceBuddy',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};
