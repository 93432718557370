import style from 'assets/theme/style.module.scss';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const theme = {
    palette: {
        mode: 'light',
        primary: {main: style.primary},
        secondary: {main: style.secondary},
        error: {main: style.danger},
        warning: {main: style.warning},
        info: {main: style.info},
        success: {main: style.success},
        text: {primary: style.black},
        background: {
            default: style.background_default_light,
            paper: style.background_default_light,
        },
    },
    typography: {
        allVariants: {
            color: style.black,
        },
    },
};

export {theme};
