import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import {
    AddCircleOutline,
    Apps, ExpandMore, GroupsOutlined, MoreHoriz,
} from '@mui/icons-material';
import {GridMenuIcon} from '@mui/x-data-grid/material/icons';

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideGeneralLayoutPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Allgemeines Layout</Typography>
            <hr />
            <Box>
                Unsere Plattform verfügt über die nachfolgenden Menüs.
                Durch die klare Trennung der Menüs wird die Navigation auf der Plattform erleichtert und Du kannst schnell und einfach auf die gewünschten Funktionen zugreifen.
                <List>
                    <ListItem>
                        <ListItemIcon><Apps /></ListItemIcon>
                        <ListItemText primary="Anwendungsmenü" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><GridMenuIcon /></ListItemIcon>
                        <ListItemText primary="Hauptmenü" />
                    </ListItem>
                </List>
                <Typography variant="h2" color="primary">Anwendungsmenü</Typography>
                <Divider />
                <Typography>
                    Dieses Menü bietet eine Auswahl verschiedener Anwendungen, die auf der Plattform verfügbar und für Dich freigeschaltet sind.
                    Dazu gehören beispielsweise das innovative Fahrtenbuch. Dieses Menü befindet sich&nbsp;
                    <b>im oberen Bereich der Anwendung</b>
                    &nbsp;(Toolbar) und ist als Drop-Down-Menü implementiert,
                    was bedeutet, dass Du auf das entsprechende Symbol (siehe oben) klicken kannst, um eine Liste von Anwendungen anzuzeigen.
                    Dies spart Platz auf der Seite und ermöglicht es Dir, schnell zwischen verschiedenen Anwendungen zu wechseln.
                </Typography>
                <br />
                <Typography>
                    Zusätzlich kannst Du Dich innerhalb dieses Menüs&nbsp;
                    <b>abmelden</b>
                    , sowie die Darstellung der Seite anpassen.
                    Im Gegensatz zur hellen Ansicht, hast Du in der dunklen Ansicht eine geringere Augenbelastung
                    und ein angenehmeres Benutzererlebnis in dunklen Umgebungen oder bei längerer Nutzung.
                    Der Dunkelmodus kann auch&nbsp;
                    <b>Energie sparen</b>
                    , insbesondere auf Geräten mit OLED- oder AMOLED-Displays, da dunkle Pixel weniger Energie benötigen als helle Pixel.
                </Typography>
                <br />
                <Typography variant="h2" color="primary">Hauptmenü</Typography>
                <Divider />
                Hier sind neben&nbsp;
                <b>Deinem Profil</b>
                &nbsp; auch die Funktionen der jeweiligen Anwendung aufrufbar. Das Menü ist je nach Bildschirmgröße (abhängig von der Breite) entweder
                immer am linken Bildschirmrand oder als Symbol (siehe oben)&nbsp;
                <b>im oberen Bereich der Anwendung</b>
                &nbsp;(Toolbar) zu finden.
                <Box style={{
                    display: 'flex', gap: '1rem', margin: '2rem 0 0 2rem', borderBottom: 'solid 1px', width: '160px',
                }}
                >
                    <Typography sx={{color: 'primary.main'}}><GroupsOutlined /></Typography>
                    <Typography sx={{color: 'text.main'}}>Gruppe</Typography>
                </Box>

                <Box style={{display: 'flex', gap: '1rem', margin: '0.5rem 0 0 2rem'}}>
                    <Typography sx={{color: 'info.main'}}><AddCircleOutline /></Typography>
                    <Typography sx={{color: 'info.main'}}>Gruppe anlegen</Typography>
                </Box>
                <br />
                Das Menü in dem man sich aktuell befindet wird&nbsp;
                <Typography sx={{display: 'inline', color: 'info.main'}}>farblich hervorgehoben</Typography>
                &nbsp;um es besser von den restlichen Einträgen unterscheiden zu können.
                <br />
                <br />
                Das jeweilige Modul, respektive die dazugehörigen Einstellungen, werden in Bereichen angezeigt, welche zusammengeklappt werden können.
                Sind besonders viele Module einer Anwendung aktiv, werden manche Module direkt zusammengeklappt.
                <br />
                <br />
                <Accordion
                    disableGutters
                    defaultExpanded
                    // className={classes.moduleAccordion}
                >
                    <AccordionSummary
                        // className={classes.moduleAccordionSummary}
                        expandIcon={<ExpandMore />}
                    >
                        <Typography color="primary" fontWeight="bold">Einstellungen</Typography>
                    </AccordionSummary>
                    <AccordionDetails><MoreHoriz /></AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
}
export {GuideGeneralLayoutPage};
