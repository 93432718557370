import React, {} from 'react';
import {
    Tile, TileIconBackground, TileImageBackground, TileLabel, TileRouteButton,
} from 'assets/theme/components/Tile/Tile';

import {DashboardTilesContainer} from 'components/Dashboard/DashboardTilesContainer';
import createDrivingRecordImg from 'applications/drivebuddy/modules/logbook/pages/images/AdobeStock_421070547.jpg';
import {AddCircleOutline, DirectionsCar} from '@mui/icons-material';

/**
 * This page shows a dashboard for all applications
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function DriveBuddyTileDashboard() {
    const driveBuddyLogbookTile = (
        <Tile>
            <TileRouteButton routeId="drivebuddy_drive_logbook_dashboard_route" />
            <TileImageBackground src={createDrivingRecordImg} />
        </Tile>
    );
    const driveBuddyVehiclesTile = (
        <Tile>
            <TileLabel label="Fahrzeuge" />
            <TileRouteButton routeId="drivebuddy_drive_settings_vehicles_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<DirectionsCar color="primary" />} />
        </Tile>
    );
    const driveBuddyVehicleCreateTile = (
        <Tile>
            <TileLabel label="Fahrzeug" />
            <TileRouteButton routeId="drivebuddy_drive_settings_vehicle_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<AddCircleOutline color="primary" />} />
        </Tile>
    );
    /** @type {{severity: import('@mui/material').AlertColor, message:string}} */
    const notification = {severity: 'success', message: 'Alles im grünen Bereich! Es liegen keine Notifizierungen vor.'};
    const elements = [
        {
            routeId: 'drivebuddy_drive_logbook_dashboard_route',
            tile: driveBuddyLogbookTile,
        }, {
            routeId: 'drivebuddy_drive_settings_vehicles_route',
            tile: driveBuddyVehiclesTile,
        }, {
            routeId: 'drivebuddy_drive_settings_vehicle_route',
            tile: driveBuddyVehicleCreateTile,
        },
    ];
    return (
        <DashboardTilesContainer
            notification={notification}
            elements={elements}
        />
    );
}
export {DriveBuddyTileDashboard};
