import _ from 'lodash';

const dateZero = new Date(0, 0, 1, 0, 0, 0, 0);

const preProcess = (record) => {
    const {range} = record;
    const startDiff = Date.parse(range.startDateTime) - dateZero.valueOf();
    return ({
        ...record,
        startDateTime: range.startDateTime,
        endDateTime: range.endDateTime,
        shifts: Object.values(record.shifts ?? {}).map((shift) => {
            if (!shift.participantIds) {
                Object.assign(shift, {participantIds: []});
            }
            const added = Date.parse(shift.from) + startDiff;
            const added2 = Date.parse(shift.until) + startDiff;
            const from = new Date(added).toISOString();
            const until = new Date(added2).toISOString();
            return {..._.omit(shift, 'participants'), from, until};
        }),
    });
};
const postProcess = (record) => {
    const startDiff = Date.parse(record.startDateTime) - dateZero.valueOf();
    const participantsById = _.mapKeys(record.participants, (item) => item.id);
    return ({
        range: {
            startDateTime: record.startDateTime,
            endDateTime: record.endDateTime,
        },
        ...record,
        participants: participantsById,
        places: _.mapKeys(record.places, (item) => item.id),
        shifts: _.chain(record.shifts)
            .mapValues((item) => {
                const from = new Date(Date.parse(item.from) - startDiff).toISOString();
                const until = new Date(Date.parse(item.until) - startDiff).toISOString();
                const participants = item.participantIds?.map((id) => participantsById[id]) ?? [];
                return {
                    ...item, from, until, participants,
                };
            })
            .mapKeys((item) => item.id)
            .value(),
    });
};

export {preProcess as preProcessSchedule, postProcess as postProcessSchedule};
