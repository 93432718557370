import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Box} from '@mui/material';
import {WorkingTimeScheduleListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeSchedule/WorkingTimeScheduleListFormular';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of schedules.
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The WorkingTimeScheduleListPage component
 */
function WorkingTimeScheduleListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Dienstpläne</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <WorkingTimeScheduleListFormular />
            </div>
        </LayoutContainer>
    );
}
export {WorkingTimeScheduleListPage};
