import React, {useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {
    CrisisAlert, EmergencyShareOutlined, GroupsOutlined, PersonOutline,
} from '@mui/icons-material';

/**
 * @param {string} tabId the id of the tab
 * @param {object} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (tabId, props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        actionButtonProps={{portalAnchorSelector: `#BeyondBuddyPermissionsaction-button-frame-${tabId}-actions`}}
        // entityChooserProps={{showCostsWarning: true}}
        {...props}
    />
);

/**
 * This page shows a create and update form for BeyondBuddy object permissions
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The BeyondBuddyPermissionsPage component.
 */
function BeyondBuddyPermissionsPage() {
    const [currentTab, setCurrentTab] = useState(0);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: 'BeyondBuddyPermissions',
            tabs: [{
                id: 'user',
                label: 'Benutzer',
                icon: <PersonOutline />,
                getChildren: () => getPermissionFormular('user', {entityTypeId: 'Objecttype#User', context: 'BeyondBuddyUserPermissions'}),
                formWrapperId: 'BeyondBuddyUserPermissions',
            }, {
                id: 'group',
                label: 'Gruppe',
                icon: <GroupsOutlined />,
                getChildren: () => getPermissionFormular('group', {entityTypeId: 'Objecttype#Group', context: 'BeyondBuddyGroupPermissions'}),
                formWrapperId: 'BeyondBuddyGroupPermissions',
            }, {
                id: 'organizationalunit',
                label: 'Organisationseinheit',
                icon: <GroupsOutlined />,
                getChildren: () => getPermissionFormular('organizationalunit', {entityTypeId: 'Objecttype#OrganizationalUnit', context: 'BeyondBuddyOrganizationalUnitPermissions'}),
                formWrapperId: 'BeyondBuddyOrganizationalUnitPermissions',
            }, {
                id: 'evacuation',
                label: 'Evakuierung',
                icon: <EmergencyShareOutlined />,
                getChildren: () => getPermissionFormular('evacuation', {entityTypeId: 'Objecttype#Evacuation', context: 'BeyondBuddyEvacuationPermissions'}),
                formWrapperId: 'BeyondBuddyEvacuationPermissions',
            }, {
                id: 'evacuation_point',
                label: 'Evakuierungspunkt',
                icon: <CrisisAlert />,
                getChildren: () => getPermissionFormular('evacuation_point', {entityTypeId: 'Objecttype#EvacuationPoint', context: 'BeyondBuddyEvacuationPointPermissions'}),
                formWrapperId: 'BeyondBuddyEvacuationPointPermissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [currentTab, setCurrentTab, getPermissionFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {BeyondBuddyPermissionsPage};
