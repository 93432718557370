import React, {useState} from 'react';
import {Box, Typography, Collapse} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import notFoundImageLight from 'applications/pages/images/undraw_page_not_found_light.svg';
import notFoundImageDark from 'applications/pages/images/undraw_page_not_found_dark.svg';
import {useGlobalState} from 'hooks/useGlobalState';
import {useNavigate} from 'react-router-dom';
import {useMessage} from 'hooks/useMessage';

import {useLogMessage} from 'hooks/useLogMessage';
import {Messages} from 'messages/Messages';
/**
 * This page shows a 404 error
 * @param {object} props - properties of the link
 * @param {boolean} [props.showImage] - if false, the image will not be shown (default true)
 * @param {string} [props.dashBoardRoute] - the route to the dashboard (default /)
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function NotFound({showImage, dashBoardRoute}) {
    const [hasBeenSend, setHasBeenSend] = useState(false);

    const {enqueueMessage} = useMessage();
    const {logMessage, isSending} = useLogMessage();
    const navigate = useNavigate();
    const {getGlobal} = useGlobalState();
    const globalUser = getGlobal('user');

    const sendFeedback = async () => {
        logMessage(
            'PageNotFound',
            {
                level: 'WARN',
                message: '404 - Page not found',
            },
            false,
        );
        enqueueMessage('Feedback_Message', Messages.API_LOG_SUCCESSFUL);
        setHasBeenSend(true);
    };

    const navigateToTheDashboard = () => {
        navigate(dashBoardRoute ?? '/');
    };

    return (
        <LayoutContainer>
            <Box sx={{
                marginTop: '1.5rem',
                height: 'calc(100% - 128px)',
            }}
            >
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}
                >
                    {(showImage ?? true) && <img src={globalUser?.darkMode ? notFoundImageDark : notFoundImageLight} alt="404" height="350rem" />}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            variant="h1"
                            align="center"
                            sx={{
                                fontSize: '2rem', marginTop: '1rem',
                            }}
                        >
                        Ups, hier geht es nicht weiter...
                        </Typography>
                        <Typography variant="h3" align="center">
                            Sollte hier etwas sein, dass Du gerne sehen möchtest?
                        </Typography>
                        <Box
                            sx={{
                                marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}
                        >
                            <Collapse
                                in={!hasBeenSend}
                            >
                                <LoadingButton
                                    variant="contained"
                                    loading={isSending}
                                    onClick={sendFeedback}
                                    data-test="LoadingButton_SendFeedback"
                                >
                                    Benachrichtigung senden
                                </LoadingButton>
                            </Collapse>
                            <Collapse
                                in={hasBeenSend}
                            >
                                <LoadingButton
                                    variant="contained"
                                    loading={isSending}
                                    onClick={navigateToTheDashboard}
                                    data-test="LoadingButton_Dashboard"
                                >
                                    Zum Dashboard
                                </LoadingButton>
                            </Collapse>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </LayoutContainer>
    );
}
export {NotFound};
