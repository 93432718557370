import {IconButton, Menu, MenuItem} from '@mui/material';
import React from 'react';
import fileClasses from 'components/Form/FormElements/formElementFiles.module.scss';

/**
 * Props for menu, to position it correctly.
 *  @type {Partial<import('@mui/material').MenuProps>}
 */
const menuProps = {
    PaperProps: {
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
            },
        },
    },
    transformOrigin: {horizontal: 'right', vertical: 'top'},
    anchorOrigin: {horizontal: 'right', vertical: 'bottom'},
};

/**
 * Component that on click displays a menu with additional actions.
 * Actions are registered via Icons, labels, and callbacks
 * @param {object} props - props for the file detail
 * @param {boolean} props.disabled - indicator that the component should not allow pressing any buttons
 * @param {import("./types").MenuAction[]} props.actions - actions to be displayed. Duplicate labels are not allowed
 * @param {React.ReactElement} [props.children] - child element that will be entered into a iconButton. Should be an icon
 * @returns {React.ReactElement} button and menu to render
 */
function ActionMenu({actions, children, disabled}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton disabled={disabled} onClick={handleClick} className={fileClasses.control}>{children}</IconButton>
            <Menu
                onClose={handleClose}
                onClick={handleClose}
                {...menuProps}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                open={open}
                anchorEl={anchorEl}
            >
                {actions.map(({label, action, icon}) => (
                    <MenuItem
                        key={label}
                        data-test={`ActionMenu_${label}`}
                        onClick={() => {
                            handleClose();
                            action();
                        }}
                    >
                        {icon}
                        {' '}
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export {ActionMenu};
