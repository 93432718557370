import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';
import _ from 'lodash';

const moment = extendMoment(momentWithoutRange);

export const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

/**
 * Calculates the relative time slots of a work week according to the specified model as absolute time ranges within a time span
 * @param {import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeModelHours} modelHours - model to consider for range calculation
 * @param {moment.Moment} startMoment - start of the range consiered
 * @param {moment.Moment} endMoment - end of the range considered
 * @returns {({range: import("moment-range").DateRange, weight: number})[]} ranges
 */
const getMultiplierRanges = (modelHours, startMoment, endMoment) => {
    if (!modelHours || _.isEmpty(modelHours)) {
        return [];
    }
    const endDate = endMoment.clone().startOf('day');
    const span = moment.range(startMoment.clone().startOf('day'), endMoment);
    const days = Array.from(span.by('day'));
    days[0] = startMoment;
    const outputDailyRanges = [];
    /** @type {moment.Moment}  */
    let current;

    do {
        current = days.shift();
        const weekday = current.day();
        const relevantShifts = modelHours[weekdays[weekday]];
        if (relevantShifts) {
            // eslint-disable-next-line no-loop-func
            outputDailyRanges.push(...relevantShifts.map((shift) => {
                const [startHour, startMinute] = shift.from.split(':');
                const [endHour, endMinute] = shift.until.split(':');
                return {
                    range: moment.range(
                        current.clone().hour(startHour).minute(startMinute),
                        current.clone().hour(endHour).minute(endMinute),
                    ),
                    weight: shift.multiplier ?? 1,
                };
            }));
        }
    } while (current.isBefore(endDate));
    return outputDailyRanges;
};

/**
 * Calculates the "with" and "without travel" times spent in all provided events
 * @param {import("@fullcalendar/core").EventInput[]} itemEvents - events to check
 * @param {import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeModelEntity} [workingTimeModel] - model to multiply records with
 * @returns {{withoutTravel: number, withTravel: number}} results
 */
export const durationOfEvents = (itemEvents, workingTimeModel) => {
    if (itemEvents.length === 0) {
        return {withoutTravel: 0, withTravel: 0};
    }
    const starts = itemEvents.map((item) => moment(item.startDateTime).tz('Europe/Vienna'));
    const ends = itemEvents.map((item) => moment(item.endDateTime).tz('Europe/Vienna'));
    const multiplierRanges = workingTimeModel ? getMultiplierRanges(
        workingTimeModel.workHoursMultiplication,
        moment.min(...starts),
        moment.max(...ends),
    ) : [];
    let withoutTravel = 0;
    let withTravel = 0;
    itemEvents.forEach((item) => {
        if (!item.startDateTime || !item.endDateTime || item.kind === 'BREAK') {
            return;
        }
        const range = moment.range(
            moment(item.startDateTime),
            moment(item.endDateTime),
        );
        let reducedRange = [range];
        let multipliedDuration = 0;
        multiplierRanges.forEach((mRange) => {
            const intersection = range.intersect(mRange.range);
            const hoursDuration = intersection.duration('hours', true);
            multipliedDuration += hoursDuration * mRange.weight;
            reducedRange = reducedRange.flatMap((r) => r.subtract(mRange.range));
        });

        const restDurations = reducedRange.reduce((sum, rRange) => sum + rRange.duration('hours', true), 0);
        withTravel += multipliedDuration + restDurations;
        if (!item.extendedProps?.kind?.startsWith('TRAVEL')) {
            withoutTravel += multipliedDuration + restDurations;
        }
    });

    return {withoutTravel, withTravel};
};

/**
 * Extracts the relevant shifts out of a list of schedules
 * @param {import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeScheduleEntity[]} schedules - schedules available
 * @param {string} userId - user id for which to check
 * @returns {import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeShift[]} shifts
 */
export const getRelevantShifts = (schedules, userId) => schedules
    .flatMap((schedule) => schedule.shifts
        .filter((shift) => shift.participantIds.includes(userId)));

/**
 * Confirms that two dates or date likes are on the same day
 * @param {Date | number | string} dateInput - first date
 * @param {Date | number | string} other - second date
 * @returns {boolean} true if they are on the same day
 */
export const isOnSameDay = (dateInput, other) => {
    const first = new Date(dateInput);
    const second = new Date(other);
    first.setHours(0, 0, 0, 0);
    second.setHours(0, 0, 0, 0);
    return first.valueOf() === second.valueOf();
};
