/**
 * createWorkingTimeSchedule
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} userId - id of the user for which to create the log.
 * @property {string} comment - comment on the work log
 * @property {boolean} draft - flag that makes the log a draft
 * @property {string} kind - kind of the worktime log
 * @property {string} workplaceId - id of the place
 * @property {string} templateId - id of the log template
 * @returns {object} - the created WorkingTimeLog
 */
export const createWorkingTimeSchedule = /* GraphQL */ `
  mutation CreateWorkingTimeSchedule (
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    $shifts: [WorkingTimeScheduleShiftInput!]!
    $tenantId: ID!
    $userId: ID!
  ) {
    createWorkingTimeSchedule(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      shifts: $shifts
      tenantId: $tenantId
      userId: $userId
    ) {
      id
      startDateTime
      endDateTime
      shifts {
        from
        until
        participantIds
        placeId
        id
      }
      participantIds
      participants {
        id
        contactFirstName
        contactLastName
      }
      placeIds
      places {
        id
        name
      }
      grants {
        updatable
      }
    }
  }
`;

/**
 * updateWorkingTimeSchedule
 * @property {string} id - id.
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} comment - comment on the work log
 * @property {boolean} draft - flag that makes the log a draft
 * @property {string} kind - kind of the worktime log
 * @property {string} workplaceId - id of the place
 * @property {string} templateId - id of the log template
 * @returns {object} - the updated WorkingTimeLog
 */
export const updateWorkingTimeSchedule = /* GraphQL */ `
  mutation UpdateWorkingTimeSchedule(
    $id: ID!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    $shifts: [WorkingTimeScheduleShiftInput!]!
  ) {
    updateWorkingTimeSchedule(
      id: $id
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      shifts: $shifts
    ) {
      id
      startDateTime
      endDateTime
      shifts {
        from
        until
        participantIds
        placeId
        id
      }
      participantIds
      participants {
        id
        contactFirstName
        contactLastName
      }
      placeIds
      places {
        id
        name
      }
      grants {
        updatable
      }
    }
  }
`;

/**
 * deleteWorkingTimeSchedule
 * @property {string} id - WorkingTimeLog id.
 * @returns {object} The deleted WorkingTimeLog.
 */
export const deleteWorkingTimeSchedule = /* GraphQL */ `
  mutation DeleteWorkingTimeSchedule(
    $id: ID!
    ) {
    deleteWorkingTimeSchedule(
      id: $id
    ) {
      id
    }
  }
`;
