import React from 'react';
import {
    Alert, Box, Divider, List, Typography,
} from '@mui/material';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

/**
 * This page shows a quick guide on organizational structures
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuidePermissionsPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Berechtigungen</Typography>
            <hr />
            <Box>
                Die Berechtigungen werden vom (Mandanten-) Administrator und autorisierten Benutzern festgelegt und können je nach Rolle oder Aufgabe
                der Benutzers variieren. Eine korrekte Berechtigungsvergabe ist wichtig, um die Sicherheit und den Datenschutz
                zu gewährleisten und sicherzustellen, dass nur autorisierte Personen Zugriff haben.
                <br />
                <br />
                <Alert severity="info">
                    Benutzer können über den Mandanten, Organisationseinheiten und Gruppen indirekt berechtigt werden.
                </Alert>
                <br />
                <Typography variant="h2" color="primary">Modulfreigabe</Typography>
                <Divider />
                Um die verfügbaren Anwendungen im BeyondBuddy nutzen zu können, müssen die Module der jeweiligen Anwendung für Objekte der&nbsp;
                <QuickGuideLink id="beyondbuddy_general_organization">Aufbauorganisation</QuickGuideLink>
                &nbsp;
                <QuickGuideLink id="beyondbuddy_general_permissionChooser">freigegeben</QuickGuideLink>
                &nbsp;werden.
                <br />
                <br />
                <Alert severity="warning">
                    Werden Module für Benutzer freigeschaltet, so werden dafür ggf. Lizenzgebühren fällig. Es kommen die AGB zur Anwendung.
                </Alert>
                <br />
                <Typography variant="h2" color="primary">(Erweiterte) Berechtigungen</Typography>
                <Divider />
                Innerhalb der jeweiligen Anwendungen (z.B. DriveBuddy) können erweiterte Berechtigungen vergeben werden.
                Dies ist beispielsweise für Leiter einer Organisationseinheit (Abteilungsleiter) interessant, um der Abteilung neue Mitarbeiter (Benutzer) hinzufügen
                und deren Berechtigung verwalten zu können.
                <br />
                <br />
                <Typography variant="h2" color="primary">Ein- & Ausgehende Berechtigungen</Typography>
                <Divider />
                Auf den erstellten Objekten (z.B. Benutzer) können zusätzliche Berechtigungen für Objekte der&nbsp;
                <QuickGuideLink id="beyondbuddy_general_organization">Aufbauorganisation</QuickGuideLink>
                &nbsp; vergeben werden.
                So ist es beispielsweise möglich, dass alle Benutzer einer Organisationseinheit ihre Kollegen sehen, jedoch nicht bearbeiten können.
                <br />
                <br />
                <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissionChooser', label: 'Auswahl von Objekten im Berechtigungssystem'})}
                    {createQuickGuideListItem({id: 'beyondbuddy_general_organization', label: 'Abbilden der Unternehmensstruktur in der Aufbauorganisation'})}
                </List>
            </Box>
        </>
    );
}
export {GuidePermissionsPage};
