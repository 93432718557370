import {useGlobalState} from 'hooks/useGlobalState';
import {useMemo} from 'react';

/** @typedef {import('applications/configuration').AccessCondition} AccessCondition */

/**
 * Hook that checks if the current user is permitted to access some resource.
 * Requires globalState to be available
 * @param {...AccessCondition} requiredAccesses - accesses being checked
 * @returns {boolean} true if the access can be used by the current user
 */
function useCanAccess(...requiredAccesses) {
    const {getGlobal} = useGlobalState();

    const currentUser = getGlobal('user');
    return useMemo(() => {
        const possibleAccesses = new Set(requiredAccesses);
        if (currentUser?.tenantAdministrator) {
            possibleAccesses.delete('TenantAdmin');
        }
        if (currentUser?.isLineManager) {
            possibleAccesses.delete('LineManager');
        }
        currentUser?.accessGrants?.applications
            .concat(currentUser?.accessGrants?.modules)
            .concat(currentUser?.accessGrants?.objectAccess)
            ?.forEach((item) => {
                possibleAccesses.delete(item);
            });
        return possibleAccesses.size === 0;
    }, [currentUser?.accessGrants, currentUser?.isLineManager, currentUser?.tenantAdministrator, requiredAccesses.join(',')]);
}

export {useCanAccess};
