import React, {useState, useEffect} from 'react';

import {ArrowUpward} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';

import classes from 'assets/theme/components/ScrollToTop/scrollToTop.module.scss';
import classNames from 'classnames';

/**
 * The ScrollToTop component renders a button that scrolls to the top of the page.
 * @param {object} props - the props object
 * @param {string} props.colorMode - the preferred color mode
 * .Components
 * @returns {React.ReactElement} The ScrollToTop component
 */
function ScrollToTop({colorMode}) {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.querySelector('main')?.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        document.querySelector('main').scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        document.querySelector('main').addEventListener('scroll', toggleVisible);
    }, []);

    return (
        <Box
            className={classNames(classes.scrollToTop, {
                [classes.fadeIn]: visible,
            })}
            sx={{
                display: visible ? 'flex' : 'none',
            }}
            data-test="FormElement_ScrollToTop"
        >
            <IconButton
                onClick={scrollToTop}
                size="large"
                color="primary"
                sx={{
                    backgroundColor: colorMode === 'dark' ? classes.background_default_dark : classes.background_default_light,
                    ':hover': {
                        backgroundColor: colorMode === 'dark' ? classes.background_default_dark : classes.background_default_light,
                    },
                }}
            >
                <ArrowUpward fontSize="inherit" />
            </IconButton>
        </Box>
    );
}

export {ScrollToTop};
