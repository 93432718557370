import React from 'react';
import {Box} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkingTimeLogTemplateListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLogTemplate/WorkingTimeLogTemplateListFormular';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 *
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeLogTemplateListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Vorlagen</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <WorkingTimeLogTemplateListFormular />
            </div>
        </LayoutContainer>
    );
}

export {WorkingTimeLogTemplateListPage};
