const _ = require('lodash');
const {ISO8601_REGEX, UUID_REGEX} = require('../regex');
const {schema: workingTimeLogSchema} = require('./WorkingTimeWorkLog');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        name: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'length', 'type'],
            },
        ],
        validityStart: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format'],
            },
        ],
        validityEnd: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format'],
            },
        ],
        modifiable: [
            {
                formType: ['create', 'update'],
                actions: ['save'],
                configurations: ['type'],
            },
        ],
        checkoutEnforcement: [
            {
                formType: ['create', 'update'],
                actions: ['save'],
                configurations: ['type'],
            },
        ],
        comment: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        workplaceId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format'],
            },
        ],
        kind: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
    },
    backend: {
        tenantId: [
            {
                actions: ['update', 'create'],
                configurations: ['format', 'required'],
            },
        ],
        id: [
            {
                actions: ['update'],
                configurations: ['format', 'required'],
            },
        ],
        name: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        validityStart: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        validityEnd: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        modifiable: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        checkoutEnforcement: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        comment: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        workplaceId: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        kind: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
    },
    attributes: {
        id: {
            format: UUID_REGEX,
            required: true,
        },
        name: {
            type: 'String',
            length: {lte: 50},
            required: true,
        },
        tenantId: {
            format: UUID_REGEX,
            required: true,
        },
        validityStart: {
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        validityEnd: {
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        modifiable: {
            type: 'Boolean',
        },
        checkoutEnforcement: {
            type: 'Boolean',
        },
        ..._.pick(workingTimeLogSchema.attributes, 'comment', 'workplaceId', 'kind'),
    },
};

module.exports = {schema};
