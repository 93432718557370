import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {CustomerListFormular} from 'applications/beyondbuddy/settings/forms/customer/CustomerListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of customers
 * @param {object} props CustomerListPage properties
 * @param {string} [props.customerRouteId] the route identifier for the customer page (create)
 * @param {object} [props.initialFilters] first filter that should be applied (not applicable to FilterElementAutoComplete)
 * @param {string} [props.title] a custom title for the page. Default: Kunden
 * @returns {React.ReactElement} The CustomerListPage component
 */
function CustomerListPage(props) {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>{props?.title ?? 'Kunden'}</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <CustomerListFormular {...props} />
            </div>
        </LayoutContainer>
    );
}
export {CustomerListPage};
