// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../fragments';

/**
 * createOrganizationalUnit
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the organizational unit.
 * @property {string} imageKey - image key of the organizational unit.
 * @property {string} parentOrganizationalUnitId - parent organizational unit id.
 * @property {Array} permissions - permissions of the organizational unit.
 * @returns {object} The newly created OrganizationalUnit.
 */
export const createOrganizationalUnit = /* GraphQL */ `
  mutation CreateOrganizationalUnit(
    $tenantId: ID!
    $name: String!
    $imageKey: String
    $parentOrganizationalUnitId: ID
    ) {
    createOrganizationalUnit(
      tenantId: $tenantId,
      name: $name,
      imageKey: $imageKey,
      parentOrganizationalUnitId: $parentOrganizationalUnitId
    ) {
      id
      name
      image {
        ...FileInformation
      }
      parentOrganizationalUnitId
      parentOrganizationalUnit {
        id
        name
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * updateOrganizationalUnit
 * @property {string} id -  OrganizationalUnit id.
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the organizational unit.
 * @property {string} imageKey - image key of the organizational unit.
 * @property {string} parentOrganizationalUnitId - parent organizational unit id.
 * @property {Array} permissions - permissions of the organizational unit.
 * @returns {object} The updated organizationalunit.
 */
export const updateOrganizationalUnit = /* GraphQL */ `
  mutation UpdateOrganizationalUnit(
    $id: ID!
    $tenantId: ID!
    $parentOrganizationalUnitId: ID
    $name: String!
    $imageKey: String
  ) {
    updateOrganizationalUnit(
      id: $id, 
      tenantId: $tenantId,
      parentOrganizationalUnitId: $parentOrganizationalUnitId,
      name: $name,
      imageKey: $imageKey
    ) {
      id
      name
      image {
        ...FileInformation
      }
      parentOrganizationalUnitId
      parentOrganizationalUnit {
        id
        name
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * updateOrganizationalUnitName
 * @property {string} id -  OrganizationalUnit id.
 * @property {string} name - name of the organizational unit.
 * @returns {object} The id and name of the updated organizational unit
 */
export const updateOrganizationalUnitName = /* GraphQL */ `
mutation UpdateOrganizationalUnitName(
  $id: ID!
  $name: String!
) {
  updateOrganizationalUnitName: updateOrganizationalUnit(
    id: $id, 
    name: $name,
  ) {
    id
    name
  }
}
`;

/**
 * deleteOrganizationalUnit
 * @property {string} id - organizational unit id.
 * @returns {object} The deleted organizational unit.
 */
export const deleteOrganizationalUnit = /* GraphQL */ `
 mutation DeleteOrganizationalUnit(
     $id: ID!
   ) {
   deleteOrganizationalUnit(
     id: $id,
     ) {
      id
   }
 }
`;
