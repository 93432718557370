import {add, format} from 'date-fns';
import {deAT} from 'date-fns/locale';

const formatDateTimeString = (dateString, formatString = 'dd.MM.yyyy HH:mm') => {
    const value = Date.parse(dateString);

    if (!Number.isNaN(value)) {
        return format(value, formatString, {locale: deAT});
    }
    return dateString;
};

/**
 * Changes a date to the specified hours, and returns it
 * @param {Date} date - date that will be modified
 * @param  {[h: number, m?: number, s?: number, ms?: number]} hours - hours input
 * @returns {Date} the same date as the input
 */
const dateAtHour = (date, ...hours) => {
    date.setHours(...hours);
    return date;
};

/**
 * Function that attempts to convert an unknown object into a date object
 * @param {unknown} date - date string or timestamp or an existing date object
 * @returns {Date} resulting date
 */
const toDate = (date) => {
    if (date instanceof Date || typeof date === 'number') {
        return new Date(date);
    }
    if (typeof date === 'string') {
        return new Date(Date.parse(date));
    }
    throw new Error(`Could not convert to date: ${date}`);
};

/**
 * Adds years to the current year and returns the end of that year in ISO string
 * @param {number} [yearsInFuture] - years to be added to the current year
 * @returns {string} the date in the future
 */
const getFutureEndOfYearIsoString = (yearsInFuture) => {
    if (!yearsInFuture) {
        return null;
    }
    // Get the current year
    const currentYear = new Date().getFullYear();
    // Calculate the target year
    const targetYear = currentYear + yearsInFuture;
    // Create a new Date object representing December 31st of the target year
    const endOfYearDate = new Date(targetYear, 11, 31, 23, 59, 59, 999);
    // Convert the date to an ISO string
    const isoString = endOfYearDate.toISOString();
    return isoString;
};

/**
 * Adds years to the current year and returns the end of that year in ISO string
 * @param {number} [years] - years to be added to the current year
 * @returns {string} the date in the future
 */
const getFutureDateIsoString = (years) => {
    if (!years) {
        return null;
    }
    return (add(new Date(), {years})).toISOString();
};

export {
    formatDateTimeString, toDate, dateAtHour, getFutureDateIsoString, getFutureEndOfYearIsoString,
};
