import React, {useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {
    AirlineSeatFlatOutlined, ChurchOutlined, Inventory2Outlined,
} from '@mui/icons-material';

/**
 * @param {string} tabId the id of the tab
 * @param {object} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (tabId, props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        actionButtonProps={{portalAnchorSelector: `#PeaceBuddyPermissionsaction-button-frame-${tabId}-actions`}}
        entityChooserProps={{showCostsWarning: true}}
        {...props}
    />
);

/**
 * This page shows a create and update form for PeaceBuddy object permissions
 * .PeaceBuddy.Pages
 * @returns {React.ReactElement} The PeaceBuddyPermissionsPage component.
 */
function PeacebuddyPermissionsPage() {
    const [currentTab, setCurrentTab] = useState(0);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: 'PeaceBuddyPermissions',
            tabs: [{
                id: 'cemetery',
                label: 'Friedhof',
                icon: <ChurchOutlined />,
                getChildren: () => getPermissionFormular('cemetery', {entityTypeId: 'Objecttype#Cemetery', context: 'PeaceBuddyCemeteryPermissions'}),
                formWrapperId: 'PeaceBuddyCemeteryPermissions',
            }, {
                id: 'grave',
                label: 'Grab',
                icon: <Inventory2Outlined />,
                getChildren: () => getPermissionFormular('grave', {entityTypeId: 'Objecttype#Grave', context: 'PeaceBuddyGravePermissions'}),
                formWrapperId: 'PeaceBuddyGravePermissions',
            }, {
                id: 'graveRecord',
                label: 'Grab Eintrag',
                icon: <Inventory2Outlined />,
                getChildren: () => getPermissionFormular('graveRecord', {entityTypeId: 'Objecttype#GraveRecord', context: 'PeaceBuddyGraveRecordPermissions'}),
                formWrapperId: 'PeaceBuddyGraveRecordPermissions',
            },
            {
                id: 'deceasedPerson',
                label: 'Verstorbene',
                icon: <AirlineSeatFlatOutlined />,
                getChildren: () => getPermissionFormular('deceasedPerson', {entityTypeId: 'Objecttype#DeceasedPerson', context: 'PeaceBuddyDeceasedPersonPermissions'}),
                formWrapperId: 'PeaceBuddyDeceasedPersonPermissions',
            }],
            currentTab,
            setCurrentTab,
        }), [currentTab, setCurrentTab, getPermissionFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {PeacebuddyPermissionsPage};
