import React, {
    useCallback, useContext,
    useMemo,
    useState,
} from 'react';
import _ from 'lodash';
import {
    Box,
    TableCell,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';

// @ts-ignore
import {AttributesFormular} from 'applications/beyondbuddy/settings/forms/permissions/AttributesFormular';
import {useMessage} from 'hooks/useMessage';
import {ItemDataContext} from 'components/Form/ItemData';
import {postProcessLinks} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {getLinks} from 'graphql/beyondBuddy/Links/queries';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {SyncAltOutlined} from '@mui/icons-material';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {useLogMessage} from 'hooks/useLogMessage';
import {updateLinks} from 'graphql/beyondBuddy/Links/mutations';
import {Messages} from 'messages/Messages';
import {CancelException} from 'hooks/useCancellablePromise';
import {Exceptions} from 'messages/Exceptions';
import {Exceptions as PeaceBuddyExceptions} from 'applications/peacebuddy/messages/Exceptions';
import {Warnings as PeaceBuddyWarnings} from 'applications/peacebuddy/messages/Warnings';
import {FormContext} from 'components/Form/FormWrapper';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

/**
 *
 * @returns {React.ReactElement} the button to sync the attributes to other graves
 */
function FormElementSynchronizationButton() {
    const {data, isLoading, isSaving} = useContext(ItemDataContext);
    const {formHasChanges} = useContext(FormContext);
    const [isSyncing, setIsSyncing] = useState(false);
    const disabled = isSyncing || isLoading || isSaving || !!formHasChanges;
    const messageKey = 'SyncAuthorizedCustomers';
    const maskedVariables = {};

    const {editItem} = AWSAppSyncProvider();
    const {enqueueMessage} = useMessage();
    const {logMessage} = useLogMessage();

    const sync = useCallback(async () => {
        try {
            setIsSyncing(true);

            const links = _.flatMap(data?.itemDataExtraData?.links?.links, (connectionLink) => {
                if (connectionLink.attributes?.connected) {
                    return {
                        isIncoming: true,
                        entityTypeId: connectionLink.leftEntityTypeId,
                        context: 'peaceBuddyGraveAuthorizedCustomers',
                        links: _.map(data?.links?.links, (link) => ({
                            ...link,
                            attributes: JSON.stringify(link.attributes),
                            permissions: [],
                            rightEntityTypeId: connectionLink.leftEntityTypeId,
                        })),
                    };
                }
                return undefined;
            });

            await Promise.all(_.map(links, (link) => editItem(updateLinks, link)));

            enqueueMessage(messageKey, Messages.API_SYNC_SUCCESSFUL);
            return true;
        } catch (e) {
            if (e instanceof CancelException) {
                return false;
            }
            logMessage('PageError', e, true);
            // check the error message to determine where it comes from
            e.errors.forEach((error) => {
                if (_.includes(error.message, 'Validation failed!' || 'Error: Validation failed!')) {
                    return enqueueMessage(messageKey, Exceptions.API_VALIDATION_FAILED);
                }
                return enqueueMessage(messageKey, Exceptions.API_SYNC_ERROR, {error, item: maskedVariables});
            });
            return false;
        } finally {
            setIsSyncing(false);
        }
    }, [data, setIsSyncing, enqueueMessage]);

    if (!_.some(data?.itemDataExtraData?.links?.links, (link) => link.attributes?.connected)) {
        return undefined;
    }

    return (
        <Box display="flex" justifyContent="center">
            <FormElementLoadingButton disabled={disabled} onClick={sync} variant="text" startIcon={<SyncAltOutlined />} label="Synchronisieren" />
            <QuickGuideLink id="peacebuddy_general_grave_authorized_customers_synchronization" />
        </Box>
    );
}

/**
 * The authorized customers formular for linking customers to graves
 * @param {import('applications/configuration').FormularProps} props - props passed to the component
 * @returns {React.ReactElement} The CemeteryFormular component.
 */
function AuthorizedCustomersFormular({id, readonly}) {
    const {enqueueMessage} = useMessage();
    const {data} = useContext(ItemDataContext);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    // eslint-disable-next-line function-paren-newline
    const getLineControls = useCallback(
        /**
         * @param {Record<string, any>} attributes - the entity link information
         * @param {boolean} locked - indicates, that no changes can be made
         * @param {(attributes: Record<string, any>)=>void} changeHandler - the form change handler
         * @returns {import('react').ReactNode} - returns the link controls
         */
        (attributes, locked, changeHandler) => {
            const controls = (
                <TextField
                    label="Anteil in %"
                    type="number"
                    value={attributes?.portion || ''}
                    disabled={locked}
                    onChange={(e) => {
                        const portion = Number(e.target.value);
                        if (_.isNaN(portion) || portion < 0 || portion > 100) {
                            return;
                        }
                        changeHandler({
                            portion,
                        });
                    }}
                    data-test="FormElementEntityChooser_link_portion"
                />
            );

            if (!isDesktop) {
                return controls;
            }
            return (
                <TableCell align="center">
                    {controls}
                </TableCell>
            );
        }, [isDesktop]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const extraDataLoadConfig = useMemo(() => ({
        query: getLinks,
        variables: {
            direct: {
                isIncoming: true, // attributes are set only for incoming links
                entityTypeId: `Grave#${id}`, // : `Objecttype#${type}`,
                context: 'peaceBuddyGraveConnections',
            },
        },
        postProcess: (d) => postProcessLinks(d, id),
    }), [id]);

    const schemaOptions = useCallback(() => ({
        Grave: {
            queryVariables: {
                direct: {filter: {cemeteryId: data?.cemeteryId}},
            },
        },
        Customer: {
            label: 'Nutzungsberechtigte',
            routeId: 'peacebuddy_settings_customer_route',
        },
    }), [data?.cemeteryId]);

    // eslint-disable-next-line function-paren-newline
    // const onChangeCallback = useCallback(
    //     /**
    //      * @param {string} attribute
    //      * @param {any} value
    //      * @param {import('components/Form/form').TFormContext['changeHandler']} changeHandler
    //      */
    //     (attribute, value, changeHandler) => {
    //         if (attribute === 'links') {
    //             let sum = _.sumBy(value?.links, (link) => link.attributes?.portion);
    //             if (sum < 100) {
    //                 changeHandler({
    //                     attribute,
    //                     value: {
    //                         ...value,
    //                         links: _.map(value?.links, (link) => {
    //                             if (link.attributes.portion) {
    //                                 return link;
    //                             }
    //                             const newLink = {
    //                                 ...link,
    //                                 attributes: {
    //                                     ...link.attributes,
    //                                     portion: 100 - sum,
    //                                 },
    //                             };
    //                             sum = 100;
    //                             return newLink;
    //                         }),
    //                     },
    //                 });
    //             }
    //         }
    //     }, []);

    return (
        <AttributesFormular
            entityTypeId={`Grave#${id}`}
            context="peaceBuddyGraveAuthorizedCustomers"
            actionButtonProps={{
                portalAnchorSelector: `#GraveForm${id}action-button-frame-authorized_customers-actions`,
            }}
            disabled={readonly}
            tableHeadLabels={[{
                label: 'Anteile',
            }]}
            getLineControls={getLineControls}
            validate={(links) => {
                const customerLinks = (_.filter(links, (link) => link.leftEntityTypeId.startsWith('Customer')
                && _.has(link.attributes, 'portion')));

                if (!_.size(customerLinks)) {
                    return true;
                }

                const sum = _.sumBy(customerLinks, (link) => link.attributes.portion);
                if (sum < 100 && sum !== 100) {
                    enqueueMessage(`grave_${id}_authorized_customers_tab`, PeaceBuddyWarnings.AUTHORIZED_CUSTOMERS_PORTIONS_SUM_TOO_LOW);
                }
                if (sum > 100) {
                    enqueueMessage(`grave_${id}_authorized_customers_tab`, PeaceBuddyExceptions.AUTHORIZED_CUSTOMERS_PORTIONS_SUM_TOO_HIGH);
                    return false;
                }

                return true;
            }}
            entityChooserProps={{
                availableEntities: ['Customer'],
                schemaOptions,
                entityChooserLabel: 'Liste öffnen',
                controls: <FormElementSynchronizationButton />,
            }}
            // onChangeCallback={onChangeCallback}
            extraDataLoadConfig={extraDataLoadConfig}
        />
    );
}

export {AuthorizedCustomersFormular};
