import {useEffect} from 'react';

/**
 * Debug utility.
 *
 * Logs all changed attributes of the specified object.
 * It is expected to always have the same keys, independent of the value
 * @param {Record<string, any>} attributes - attributes to watch for changes
 */
function useLogChanged(attributes) {
    Object.keys(attributes).forEach((key) => {
        // eslint-disable-next-line no-console
        useEffect(() => console.debug('Change', key, attributes[key]), [attributes[key]]);
    });
}

export {useLogChanged};
