const {UUID_REGEX, ISO8601_REGEX} = require('../regex');

const required = true;

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        value: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        time: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['format', 'required'],
            },
        ],
    },
    backend: {
        userId: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        value: [
            {
                actions: ['create'],
                configurations: ['type', 'required'],
            },
        ],
        time: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        type: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
    },
    attributes: {
        userId: {
            required,
            format: UUID_REGEX,
        },
        value: {
            required,
            type: 'Number',
        },
        time: {
            required,
            format: ISO8601_REGEX,
        },
        type: {
            required,
            format: /^VACATION|EXTRA|OVER|WORK|TARGET$/,
        },
    },
};

module.exports = {schema};
