import {Box} from '@mui/material';
import React, {useMemo} from 'react';
import classes from 'components/Form/FormElements/formElementImageContainer.module.scss';

/**
 * An image positioned via file information
 * @param {object} props - props for the positioned image
 * @param {number} [props.width] - width of the component
 * @param {number} [props.height] - height of the component
 * @param {React.CSSProperties} [props.style] - style overwrites
 * @param {string} props.alt - alt attribute of the image
 * @param {string} [props.defaultImg] - image to fall back to, in case file information is incomplete
 * @param {import('helper/helper').FileInformation} props.fileInformation - file information
 * @param {() => void} [props.onImageLoad] - callback for when the image finished loading
 * @returns {React.ReactElement} positioned image component
 */
function PositionedImage({
    width = 250, height = 250, style = {}, alt, defaultImg, fileInformation, onImageLoad,
}) {
    /** @type {{height?: number, width?: number, objectPosition?: string}} */
    const imageStyle = useMemo(() => {
        const position = fileInformation?.options?.image?.position;
        if (position) {
            const x = (position.x / position.width) * -width;
            const y = (position.y / position.height) * -height;
            return {
                height: height / position.height,
                width: width / position.width,
                objectPosition: `${x}px ${y}px`,
                opacity: 1,
            };
        }
        return {
            width,
            height,
            objectPosition: '0px 0px',
            opacity: 1,
        };
    }, [height, width, fileInformation]);

    return (
        <Box style={{height, width, overflow: 'hidden'}}>
            <Box
                className={classes.imageContainer}
                component="img"
                src={fileInformation?.url || defaultImg}
                alt={alt}
                onLoad={onImageLoad}
                style={{
                    ...imageStyle,
                    ...style,
                }}
            />
        </Box>
    );
}

export {
    PositionedImage,
};
