const {ISO8601_REGEX, UUID_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        startDateTime: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        endDateTime: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        kind: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        comment: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
    },
    backend: {
        userId: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        workplaceId: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        startDateTime: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        endDateTime: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        kind: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        comment: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
    },
    attributes: {
        userId: {
            required: true,
            format: UUID_REGEX,
        },
        workplaceId: {
            format: UUID_REGEX,
        },
        startDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        endDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        kind: {
            type: 'String',
            required: true,
            format: /^(NORMAL|BREAK|TRAVEL_(ACTIVE|PASSIVE))$/,
        },
        comment: {
            type: 'String',
        },
    },
};

module.exports = {schema};
