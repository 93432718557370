import FullCalendar from '@fullcalendar/react';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

/** @type {import("@fullcalendar/core").CalendarOptions} */
const basisOptions = {
    locale: 'de-at',
    titleFormat: {day: '2-digit', month: '2-digit'},
    buttonText: {
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
    },
    firstDay: 1,
    headerToolbar: {center: 'title', left: 'prev,today,next', right: 'dayGridMonth,timeGridWeek,timeGridDay'},
};
/** @type {(evt: Event) => void} */
const preventDefault = (evt) => evt.preventDefault();

/**
 * Creates a calendar with beyond styling
 * @param {object} params - params for the calendar
 * @param {import('@fullcalendar/core').CalendarOptions & {ref?: any}} params.calendarOptions - options that are passed to the full calendar
 * @param {(event: import('@fullcalendar/core').EventApi) => undefined | [to: string, state?: any]} [params.routeMapping] - optional route mapping to enable clickable events
 * @returns {React.ReactElement} calendar to render
 */
function BeyondCalendar({calendarOptions, routeMapping}) {
    const theme = useTheme();

    const darkMode = theme.palette.mode === 'dark';
    const style = useMemo(() => ({
        display: 'contents',
        // '--fc-non-business-color': theme.shadows[10],
        '--fc-more-link-bg-color': darkMode ? 'gray' : '#d0d0d0',
        '--fc-page-bg-color': darkMode ? '#302e2e' : 'white',
        '--fc-button-active-bg-color': theme.palette.info.main,
        '--fc-button-hover-bg-color': theme.palette.primary.main,
        '--fc-button-text-color': theme.palette.primary.contrastText,
        '--fc-button-bg-color': theme.palette.primary.main,
        '--fc-event-border-color': darkMode ? 'black' : 'white',
        '--fc-now-indicator-color': theme.palette.primary.main,
        '--fc-border-color': theme.palette.divider,
    }), [theme.palette, darkMode]);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    return (
        <Box
            style={style}
            display="contents"
            sx={{
                '.fc .fc-toolbar-title': {
                    fontSize: '1rem',
                },
                '.fc .event-clickable': {
                    cursor: 'pointer',
                },
            }}
        >
            <FullCalendar
                aspectRatio={isMobile ? 1 : 1.35}
                {...basisOptions}
                {...calendarOptions}
                eventClick={(clickInputs) => {
                    calendarOptions.eventClick?.(clickInputs);
                    const result = routeMapping?.(clickInputs.event);
                    if (result) {
                        const [to, state] = result;
                        navigate(`/${to}`, {state});
                    }
                }}
                eventDidMount={(mountInputs) => {
                    calendarOptions.eventDidMount?.(mountInputs);
                    const {el, event} = mountInputs;
                    const result = routeMapping?.(event);
                    if (result) {
                        const [to] = result;
                        el.setAttribute('href', `/${to}`);
                        el.addEventListener('click', preventDefault);
                    }
                }}
            />
        </Box>
    );
}

export {
    BeyondCalendar,
};
