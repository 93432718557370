import React from 'react';
import {
    Alert,
    Box,
    Button, Divider, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import {Feedback, ScatterPlot} from '@mui/icons-material';
import {useGlobalState} from 'hooks/useGlobalState';
import beyondBuddyLogo from 'assets/images/BEYONDBUDDY_TextLogo_V1.png';

/**
 * Generates a list item for quick guide lists
 * @param {{id: string, label: string}} param - information to be visualized
 * @returns {import('react').ReactElement} - the list item
 */
const createQuickGuideListItem = ({id, label}) => (
    <ListItem key={id}>
        <ListItemIcon><ScatterPlot /></ListItemIcon>
        <ListItemText primary={<QuickGuideLink id={id}>{label}</QuickGuideLink>} />
    </ListItem>
);

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideGeneralPage() {
    const {setGlobal} = useGlobalState();

    const generalGuides = [
        {id: 'beyondbuddy_general_layout', label: 'Allgemeine Informationen zum Layout der Anwendung'},
        {id: 'beyondbuddy_general_dashboard', label: 'Welche Informationen bekommt man auf den Dashboards?'},
    ];
    const organizationGuides = [
        {id: 'beyondbuddy_general_organization', label: 'Abbilden der Unternehmensstruktur in der Aufbauorganisation'},
        {id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'},
        {id: 'beyondbuddy_general_permissionChooser', label: 'Auswahl von Objekten im Berechtigungssystem'},
    ];

    return (
        <>
            <Box component="img" src={beyondBuddyLogo} sx={{paddingX: '1rem'}} style={{width: '95%', display: 'block', margin: 'auto'}} />
            <br />
            <p>
            Wir wissen, dass es manchmal knifflig sein kann, Software zu nutzen.
            Aber keine Sorge, wir sind hier, um Dir zu helfen! Unsere Kurzanleitungen sind dafür da, Dir schnelle und unkomplizierte Antworten auf Deine Fragen zu geben.
            Lass uns gemeinsam Dein Problem lösen, damit Du wieder stressfrei arbeiten kannst. Wir haben viele Themen in kurzen verständlichen Anleitungen erklärt,
            damit du schnell und einfach zu Deiner Lösung kommst.
            </p>
            <p>
                Worauf wartest du noch? Lass uns loslegen:
            </p>
            <List dense>
                {generalGuides.map(createQuickGuideListItem)}
                <Divider />
                {organizationGuides.map(createQuickGuideListItem)}
            </List>

            <Alert severity="success" icon={<Feedback />}>
                Sollten wir Dein Thema noch nicht als Kurzanleitung adressiert haben, hinterlasse uns bitte ein entsprechendes Feedback. Danke für Deine Unterstützung!
                <br />
                <Button onClick={() => setGlobal('showFeedback', true)}>Feedback erfassen</Button>
            </Alert>
        </>
    );
}
export {GuideGeneralPage, createQuickGuideListItem};
