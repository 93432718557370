export const createDeceasedPerson = /* GraphQL */ `
  mutation CreateDeceasedPerson(
      $tenantId: ID!
      $referenceId: String
      $gender: String!
      $titlePrefixed: String
      $firstName: String!
      $lastName: String!
      $titlePostfixed: String
      $nationalityCountryCode: String!
      $dateOfBirth: AWSDateTime!
      $placeOfBirth: String
      $placeOfBirthCountryCode: String
      $dateOfDeath: AWSDateTime!
      $stillbirth: Boolean
      $cremation: Boolean
      $ashCapsuleNr: String
      $burried: Boolean
      $attachmentKeys: AWSJSON
    ) {
    createDeceasedPerson(
      tenantId: $tenantId
      referenceId: $referenceId
      gender: $gender
      titlePrefixed: $titlePrefixed
      firstName: $firstName
      lastName: $lastName
      titlePostfixed: $titlePostfixed
      nationalityCountryCode: $nationalityCountryCode
      dateOfBirth: $dateOfBirth
      placeOfBirth: $placeOfBirth
      placeOfBirthCountryCode: $placeOfBirthCountryCode
      dateOfDeath: $dateOfDeath
      stillbirth: $stillbirth
      cremation: $cremation
      ashCapsuleNr: $ashCapsuleNr
      burried: $burried
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      referenceId
      gender
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      nationalityCountryCode
      dateOfBirth
      placeOfBirth
      placeOfBirthCountryCode
      dateOfDeath
      stillbirth
      cremation
      ashCapsuleNr
      burried
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
    }
  }
`;

export const updateDeceasedPerson = /* GraphQL */ `
  mutation UpdateDeceasedPerson(
      $id: ID!
      $referenceId: String
      $gender: String!
      $titlePrefixed: String
      $firstName: String!
      $lastName: String!
      $titlePostfixed: String
      $nationalityCountryCode: String!
      $dateOfBirth: AWSDateTime!
      $placeOfBirth: String
      $placeOfBirthCountryCode: String
      $dateOfDeath: AWSDateTime!
      $stillbirth: Boolean
      $cremation: Boolean
      $ashCapsuleNr: String
      $burried: Boolean
      $attachmentKeys: AWSJSON
    ) {
    updateDeceasedPerson(
      id: $id
      referenceId: $referenceId
      gender: $gender
      titlePrefixed: $titlePrefixed
      firstName: $firstName
      lastName: $lastName
      titlePostfixed: $titlePostfixed
      nationalityCountryCode: $nationalityCountryCode
      dateOfBirth: $dateOfBirth
      placeOfBirth: $placeOfBirth
      placeOfBirthCountryCode: $placeOfBirthCountryCode
      dateOfDeath: $dateOfDeath
      stillbirth: $stillbirth
      cremation: $cremation
      ashCapsuleNr: $ashCapsuleNr
      burried: $burried
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      referenceId
      gender
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      nationalityCountryCode
      dateOfBirth
      placeOfBirth
      placeOfBirthCountryCode
      dateOfDeath
      stillbirth
      cremation
      ashCapsuleNr
      burried
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
    }
  }
`;

export const deleteDeceasedPerson = /* GraphQL */ `
 mutation DeleteDeceasedPerson(
     $id: ID!
   ) {
   deleteDeceasedPerson(
     id: $id,
     ) {
      id
   }
 }
`;

export const deleteDeceasedPersons = /* GraphQL */ `
 mutation DeleteDeceasedPersons(
     $ids: [ID!]
   ) {
   deleteDeceasedPersons(
     ids: $ids,
     )
 }
`;
