/**
 * @type {import("./types").FileExtensionImage[]}
 */
const FILEEXTENSIONS_IMAGE = ['jpg', 'jpeg', 'png', 'gif', 'tiff'];
/**
 * @type {import("./types").FileExtensionDocument[]}
 */
const FILEEXTENSIONS_DOCUMENT = ['pdf', 'docx', 'xlsx', 'txt'];

module.exports = {
    FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT,
};
