import React, {
    useCallback,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {
    Box, Grid,
} from '@mui/material';
import qrCodeDemo from 'applications/timebuddy/modules/workingtime/pages/images/qr_code.png';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {
    AddCircleOutline, CopyAll, DownloadOutlined, Refresh,
} from '@mui/icons-material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {kindDataSchema, workPlaceDataSchema} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';
import classes from 'components/Form/FormElements/formElementImageContainer.module.scss';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {useFindRoute} from 'hooks/useFindRoute';
import {getRoutePath} from 'routes';
// eslint-disable-next-line import/no-extraneous-dependencies
import {QRCodeSVG} from 'qrcode.react';
import {downloadSVG} from 'helper/download';
import {LoadingButton} from '@mui/lab';
import {useMessage} from 'hooks/useMessage';
import {Messages} from 'messages/Messages';
import {useGlobalState} from 'hooks/useGlobalState';
import {useClipboard} from 'hooks/useClipboard';
import {FormReset} from 'components/Form/FormReset';

const {schema: validatorSchema} = require('beyond-validators/timeBuddy/WorkingTimeLogTemplate');

/**
 * The user formular for creating and updating a user
 * @param {import('applications/configuration').FormularProps} props - props passed to the component
 * @returns {React.ReactElement} The UserFormular component.
 */
function WorkingTimeLogTemplateFormular({id, onSave, ...rest}) {
    const {enqueueMessage} = useMessage();
    const {copyToClipboard} = useClipboard();
    const {getGlobal} = useGlobalState();

    const tenantId = getGlobal('tenantId');

    const findRoute = useFindRoute();
    const checkInRoute = findRoute('timebuddy_workingtime_log_checkpoint_route');
    // eslint-disable-next-line max-len
    const checkInOutURL = useCallback((secret) => {
        const url = `${window.location.origin}/${getRoutePath(checkInRoute, {templateid: id, secret})}?tenantid=${tenantId}`;
        return url;
    }, [id, tenantId, checkInRoute]);

    const isCreate = id === 'create';
    const canCreate = useCanAccess('createWorkingTimeLogTemplate');

    return (
        <FormWrapper
            {...rest}
            isNewItem={isCreate}
            validatorSchema={{
                schema: validatorSchema,
                type: (!isCreate) ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={`WorkingTimeLogTemplate${(!isCreate) ? '_Update' : '_Create'}`}
            context={`WorkingTimeLogTemplate#${id}Base`}
        >
            <FormReset shouldClear={isCreate} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="timebuddy_workingtime_template_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#WorkingTimeLogTemplateForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`WorkingTimeLogTemplate#${id}`}
                    disabled={isCreate || !canCreate}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#WorkingTimeLogTemplateForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`WorkingTimeLogTemplate#${id}`}
                    disabled={isCreate}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Box data-test="FormElementImageContainer">
                    <Box
                        className={classes.imageContainer}
                        style={{
                            width: 255,
                            height: 255,
                        }}
                    >
                        {isCreate
                    && (
                        <Box
                            component="img"
                            src={qrCodeDemo}
                            alt="QR-Demo"
                            width={255}
                            height={255}
                        />
                    )}
                        {!isCreate && (
                            <FormElementContainer
                                attribute="secret"
                                propsMapping={(props) => ({
                                    value: checkInOutURL(props.value),
                                })}
                            >
                                <QRCodeSVG
                                    id={`QRCode_${id}`}
                                    size={256}
                                    style={{
                                        height: '255px',
                                        width: '255px',
                                    }}
                                    level="H"
                                    value="no data"
                                />
                            </FormElementContainer>
                        ) }
                    </Box>
                </Box>
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showDraft />
                        <Grid item xs={12} lg={4}>
                            <FormElementTextField label="Name" attribute="name" />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormElementSwitch
                                attribute="modifiable"
                                label="Buchung bearbeitbar"
                            />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormElementSwitch
                                attribute="checkoutEnforcement"
                                label="QR Check-Out verpflichtend"
                                defaultTrue
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementAutocomplete
                                attribute="kind"
                                label="Art"
                                dataSchema={kindDataSchema}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormElementAutocomplete
                                attribute="workplaceId"
                                label="Ort"
                                optionReference="workplace"
                                dataSchema={workPlaceDataSchema}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementTextField rows={3} attribute="comment" label="Kommentar" />
                        </Grid>
                        <Grid item xs={6} sm={12} md={6}>
                            <FormElementDateTimePicker
                                label="Gültig ab"
                                attribute="validityStart"
                            />
                        </Grid>
                        <Grid item xs={6} sm={12} md={6}>
                            <FormElementDateTimePicker
                                label="Gültig bis"
                                attribute="validityEnd"
                                minTimeAttribute="validityStart"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormElementLoadingButton />
                        </Grid>
                        <Grid item xs={6} sm={4} visibility={isCreate ? 'hidden' : 'initial'}>
                            <FormElementContainer
                                attribute="name"
                                propsMapping={(props) => ({
                                    onClick: () => {
                                        const qr = document.getElementById(`QRCode_${id}`);
                                        if (qr) {
                                            downloadSVG(qr, `BBQR-${props?.value}.svg`);
                                            enqueueMessage('template-download', Messages.DOWNLOAD_SUCCESSFUL);
                                        } else {
                                            throw new Error(`QR Code not found! ID: QRCode_${id}`);
                                        }
                                    },
                                    disabled: !props.value || props.isLoading?.load,
                                })}
                            >
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<DownloadOutlined />}
                                    color="info"
                                >
                                        Download
                                </LoadingButton>
                            </FormElementContainer>
                        </Grid>
                        <Grid item xs={6} sm={4} visibility={isCreate ? 'hidden' : 'initial'}>
                            <FormElementContainer
                                attribute="secret"
                                propsMapping={(props) => ({
                                    onClick: () => copyToClipboard(checkInOutURL(props.value), true),
                                    disabled: !props.value || props.isLoading?.load,
                                })}
                            >
                                <LoadingButton
                                    variant="contained"
                                    startIcon={<CopyAll />}
                                    color="info"
                                >
                                        Link
                                </LoadingButton>
                            </FormElementContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </FormWrapper>
    );
}

export {WorkingTimeLogTemplateFormular};
