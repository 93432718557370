import React, {useCallback} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {EvacuationFormular} from 'applications/beyondbuddy/settings/forms/evacuation/EvacuationFormular';
import {RegisterEvacueeFormular} from 'applications/beyondbuddy/settings/forms/evacuation/RegisterEvacueeFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a create or edit form for a Evacuation.
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The EvacuationPage.
 */
function EvacuationPage() {
    // getting the URL parameters
    const {id} = useParams();
    const navigate = useNavigate();
    /**
     * @type {import('routeinfo').RoutePathInfo}
     */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Evakuierung</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Box sx={{marginTop: '1.5rem'}}>
                <RegisterEvacueeFormular id={id} />
                <EvacuationFormular id={id} onSave={onSave} />
            </Box>
        </LayoutContainer>
    );
}
export {EvacuationPage};
