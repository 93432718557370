/**
 * createWorkingTimeLogTemplate
 * @property {string} name - name of the template
 * @property {string} validityStart - validity start date
 * @property {string} validityEnd - validity end date
 * @property {boolean} modifiable - flag that makes the worktime log editable
 * @property {boolean} checkoutEnforcement - flag that enforces a checkout via QR-Template
 * @property {string} comment - comment on the work log
 * @property {string} workplaceId - id of the place
 * @property {string} kind - kind of the worktime log
 * @returns {object} - the updated WorkingTimeLogTemplate
 */
export const createWorkingTimeLogTemplate = /* GraphQL */ `
  mutation CreateWorkingTimeLogTemplate(
    $tenantId: ID!
    $name: String!
    $validityStart: AWSDateTime
    $validityEnd: AWSDateTime
    $modifiable: Boolean
    $checkoutEnforcement: Boolean
    
    $comment: String
    $workplaceId: ID
    $kind: WorkingTimeLogKind
    ) {
    createWorkingTimeLogTemplate(
      tenantId: $tenantId
      name: $name
      validityStart: $validityStart
      validityEnd: $validityEnd
      modifiable: $modifiable
      checkoutEnforcement: $checkoutEnforcement

      comment: $comment
      workplaceId: $workplaceId
      kind: $kind
    ) {
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      id
      name
      validityStart
      validityEnd
      modifiable
      checkoutEnforcement
      secret

      comment
      workplaceId
      workplace{
        id
        name
      }
      kind
    }
  }
`;

/**
 * updateWorkingTimeLogTemplate
 * @property {string} id - id
 * @property {string} name - name of the template
 * @property {string} validityStart - validity start date
 * @property {string} validityEnd - validity end date
 * @property {boolean} modifiable - flag that makes the worktime log editable
 * @property {boolean} checkoutEnforcement - flag that enforces a checkout via QR-Template
 * @property {string} comment - comment on the work log
 * @property {string} workplaceId - id of the place
 * @property {string} kind - kind of the worktime log
 * @returns {object} - the updated WorkingTimeLogTemplate
 */
export const updateWorkingTimeLogTemplate = /* GraphQL */ `
  mutation UpdateWorkingTimeLogTemplate(
    $id: ID!
    $name: String
    $validityStart: AWSDateTime
    $validityEnd: AWSDateTime
    $modifiable: Boolean
    $checkoutEnforcement: Boolean
    
    $comment: String
    $workplaceId: ID
    $kind: WorkingTimeLogKind
  ) {
    updateWorkingTimeLogTemplate(
      id: $id
      name: $name
      validityStart: $validityStart
      validityEnd: $validityEnd
      modifiable: $modifiable
      checkoutEnforcement: $checkoutEnforcement
      
      comment: $comment
      workplaceId: $workplaceId
      kind: $kind
      
    ) {
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      id
      name
      validityStart
      validityEnd
      modifiable
      checkoutEnforcement
      secret

      comment
      workplaceId
      workplace{
        id
        name
      }
      kind
    }
  }
`;

/**
 * deleteWorkingTimeLogTemplate
 * @property {string} id - WorkingTimeLogTemplate id.
 * @returns {object} - the deleted WorkingTimeLogTemplate.
 */
export const deleteWorkingTimeLogTemplate = /* GraphQL */ `
  mutation DeleteWorkingTimeLogTemplate(
    $id: ID!
    ) {
    deleteWorkingTimeLogTemplate(
      id: $id
    ) {
      id
    }
  }
`;
