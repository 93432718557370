import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Place,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {listWorkplaces} from 'graphql/timeBuddy/Workplace/queries';
import {deleteWorkplace} from 'graphql/timeBuddy/Workplace/mutations';
import {FilterElementText} from 'components/FilterElements/FilterElementText';

/** @type {import('components/Form/Listing').ListingSchema} */
const schema = {
    columns: [
        {
            itemConfigurations: [
                {
                    title: 'Name',
                    renderItem: (item) => (
                        <Place
                            data-test={`Workplace_${item.name}`}
                            color="primary"
                            style={{
                                maxHeight: '40px', height: '100%', width: '100%',
                            }}
                        />
                    ),
                },
            ],
            boxProps: {style: {width: '80px'}},
        },
        {
            itemConfigurations: [
                {
                    renderItem: (item) => <Typography fontWeight="bold" noWrap>{item.name}</Typography>,
                    title: 'Name',
                },
            ],
            boxProps: {sx: {flex: {xs: 1, md: 3, lg: 1}}},
        },
    ],
    actions: [{
        icon: <Edit />,
        routeId: 'timebuddy_workplace_route',
        routeParams: (item) => ({id: item.id}),
    }],
    routeId: 'timebuddy_workplace_route',
    routeParams: (item) => ({id: item.id}),
};

/**
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The WorkplaceListFormular component
 */
function WorkplaceListFormular() {
    const messageKey = 'Workplace_List';
    return (
        <ListData
            loadConfig={{
                query: listWorkplaces,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteWorkplace,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="timebuddy_workplace_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createTimePeriod')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="timeperiod-filter" messageKey={messageKey}>
                <FilterElementText label="Name" path="name" />

            </ListFilterProvider>
            <ListDataContext.Consumer>
                {({deleteItem}) => (
                    <Listing schema={{
                        ...schema,
                        actions: [
                            {
                                icon: <Delete />,
                                safety: true,
                                isVisible: (item) => item.grants?.deletable ?? false,
                                callBack: async (item) => deleteItem({item, messageKey}),
                                buttonProps: {color: 'info'},
                            },
                            ...schema.actions,
                        ],
                    }}
                    />
                )}
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkplaceListFormular};
