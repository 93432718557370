/* eslint-disable jsdoc/valid-types */
import React, {
    useCallback,
    useContext, useEffect, useMemo, useState,
} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import {
    Box, Button, Drawer,
    Toolbar, useMediaQuery, useTheme,
} from '@mui/material';
import _ from 'lodash';
import {
    AddCircleOutline,
    Close, ListAltRounded,
} from '@mui/icons-material';
import {FormElementEntityChooser} from 'components/Form/FormElements/FormElementEntityChooser';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

/**
 * @typedef  {import('applications/configuration').ManagedObject} ManagedObject
 * @typedef {{id: string;} & Record<string, any>} EntityBase
 * @typedef {{locked?: boolean} & Record<string, any>} LinkAttributes
 * @typedef {{leftEntityTypeId?:string, rightEntityTypeId?: string, attributes?: LinkAttributes, permissions?: Array<string>}} LinkBase
 * @typedef {LinkBase & {opposite?: LinkBase[]}} Link
 * @typedef {{links: Array<Partial<Link>>} & {[K in ManagedObject]?: EntityBase}} Links
 * @typedef {import('applications/configuration').PermissionGroup} PermissionGroup
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').Permissions} Permissions
 */

/**
 * @typedef  {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 */

/**
 * @typedef FormElementEntityLinkProps
 * @property {string} attribute - attribute to load/write data from/to
 * @property {ManagedObject[]} availableEntities - the entity types to show
 * @property {ManagedObject} [initialEntityType] - entityType to be selected initially
 * @property {Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} [schemaOptions] - options for the dataschemas
 * @property {boolean} [blockSelfReference] - flag to disable the option to link to the same entity
 * @property {"left" | "right"} linkDirection - direction of the link being read and set
 * @property {boolean} [showCostsWarning] - flag to disable the costs warning information
 * @property {(ManagedObject)=>Permission[]} [processEntityDefaultPermissions] - function that returns the permissions, that are automatically enabled when adding the entity
 * @property {Partial<Record<ManagedObject, LinkAttributes>>} [defaultAttributes] - attribute object to be applied by default
 * @property {string} [entityChooserLabel] - the label of the button, that opens the entity chooser (default Berechtigungsobjekte)
 * @property {import('react').ReactElement} [controls] - additional controls
 * @property {string} [guideId] - id of the quick guide
 * @property {boolean} [disabled] - flag to disable the entity chooser. Viewing and browsing is still possible
 * @property {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [actionButtonProps] - properties for actionButtons
 */

/**
 * FormElementEntityLink
 *
 * Renders a form element, that can open an entity chooser to filter and select entities
 *
 * If items that have been selected in a previous session are supposed to be shown
 * the `permittedObjects` field should be set in the above `ItemData`
 * @param {FormElementEntityLinkProps} props - props of the component
 * @returns {React.ReactElement} - rendered FormElementEntityLink
 */
function FormElementEntityLink({
    attribute,
    availableEntities,
    initialEntityType,
    schemaOptions,
    blockSelfReference, // whether to block self references
    linkDirection, // left or right
    disabled,
    processEntityDefaultPermissions,
    defaultAttributes = {},
    entityChooserLabel,
    controls,
    guideId,
    actionButtonProps,
}) {
    const {get, changeHandler} = useContext(FormContext);
    const [drawerOpen, setDrawerOpen] = useState(false); // indicated wether the drawer is open
    const theme = useTheme();
    const isPortraitMobile = useMediaQuery(theme.breakpoints.down('sm'));

    /** @type {"leftEntityTypeId"|"rightEntityTypeId"} */
    const sideKey = `${linkDirection}EntityTypeId`;

    /**
     * @type {{
     *  ownId: import('components/Form/form').FormDataItem,
     *  value: Links,
     *  displayValue: Record<string, EntityBase & {type: ManagedObject}>,
     *  interacted: boolean
     * }}
     */
    const {
        ownId, value, displayValue, interacted,
    } = useMemo(() => {
        const data = get(attribute);
        // console.log(data);
        return {
            ownId: get('id')?.value,
            value: _.cloneDeep(data?.value) ?? {links: []},
            displayValue: data?.displayValue ?? {},
            interacted: data?.interacted || false,
        };
    }, [attribute, get]);

    const unusedEntitiesFilter = useCallback((items) => {
        const currentIds = new Set(value.links?.map(({[sideKey]: typeId}) => typeId?.split('#').at(-1)));
        return _.filter(items, (i) => !currentIds.has(i.id) && (!blockSelfReference || i.id !== ownId));
    }, [sideKey, value, blockSelfReference, ownId]);

    // eslint-disable-next-line function-paren-newline
    const selectEntity = useCallback(
        /**
         * @param {string} entityType - type of the entity
         * @param {EntityBase} item - entity that should be permitted
         */
        (entityType, item) => {
            // index of the entity in the array. -1 if not found
            const entityValueIndex = _.findIndex(value.links, (link) => link[sideKey] === `${entityType}#${item.id}`);

            // new entity in the list
            if (entityValueIndex === -1) {
                value.links.push({
                    [sideKey]: `${entityType}#${item.id}`,
                    permissions: processEntityDefaultPermissions?.(entityType) ?? [],
                    attributes: defaultAttributes?.[entityType] ?? {},
                });
                _.set(displayValue, item.id, {...item, type: entityType});
                // populate changes
                changeHandler({
                    attribute,
                    value: {
                        ...value,
                        links: Array.from(value.links),
                    },
                    displayValue,
                    interacted: true,
                });
            }
        }, [changeHandler, displayValue, value, displayValue, attribute, sideKey]);

    // Loading entities to displayValue, in case there are any on load
    useEffect(() => {
        if (interacted || !_.isEmpty(displayValue)) {
            return;
        }
        const entityInfos = _.omit(value, 'links');
        const entities = _.flatMap(entityInfos, (items, key) => items.map(((item) => ({...item, type: `${key.at(0).toUpperCase()}${key.slice(1, key.length - 1)}`}))));

        const newDisplayValue = _.mapKeys(entities, 'id');

        if (!_.isEmpty(newDisplayValue)) {
            changeHandler({
                attribute,
                displayValue: newDisplayValue,
            });
        }
    }, [attribute, value, displayValue, changeHandler, interacted]);

    return (
        <>
            {!disabled && (
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    data-test={`FormElementEntityChooser_${attribute}_drawer`}
                    ModalProps={{keepMounted: true}} // Better open performance on mobile.
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: !isPortraitMobile ? '35rem' : '100vw',
                            boxShadow: 2,
                            paddingX: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                        },
                    }}
                >
                    <Toolbar variant="dense" style={{width: '100%', justifyContent: 'flex-end'}}>
                        {guideId && <QuickGuideLink id={guideId} />}
                        <Button endIcon={<Close />} onClick={() => setDrawerOpen(false)}>Schließen</Button>
                    </Toolbar>

                    <FormElementEntityChooser
                        availableEntities={availableEntities}
                        initialEntityType={initialEntityType}
                        active={drawerOpen}
                        dataTest={attribute}
                        disabled={disabled}
                        schemaOptions={schemaOptions}
                        selectEntity={selectEntity}
                        unusedEntitiesFilter={unusedEntitiesFilter}
                    />
                </Drawer>
            )}
            <Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    <Box marginBottom="1rem">
                        {guideId && <QuickGuideLink id={guideId} />}
                        {!actionButtonProps && (
                            <Button
                                disabled={disabled}
                                data-test={`FormElementEntityChooser_links${linkDirection === 'left' ? 'Incomming' : 'Outgoing'}_drawerToggle`}
                                startIcon={<ListAltRounded />}
                                onClick={() => setDrawerOpen(true)}
                                variant="outlined"
                            >
                                {entityChooserLabel ?? 'Berechtigungsobjekte'}
                            </Button>
                        )}
                        {actionButtonProps && (
                            <FormElementActionButton
                                dataTest={`FormElementEntityChooser_links${linkDirection === 'left' ? 'Incomming' : 'Outgoing'}_drawerToggle`}
                                context={FormContext}
                                callback={() => setDrawerOpen(true)}
                                {...actionButtonProps}
                                disableChangeCheck // opens the drawer
                            >
                                <AddCircleOutline />
                            </FormElementActionButton>
                        )}
                    </Box>
                    {controls}
                </Box>
            </Box>
        </>
    );
}

export {
    FormElementEntityLink,
};
