/**
 * createWorkplace
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the workplace.
 * @property {string} managerUserId - the id of user that manages the workplace.
 * @returns {object} The newly created workplace.
 */
export const createWorkplace = /* GraphQL */ `
  mutation CreateWorkplace(
    $tenantId: ID!
    $name: String!
    ) {
    createWorkplace(
      tenantId: $tenantId,
      name: $name, 
    ) {
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * updateWorkplace
 * @property {string} id - ID of the workplace.
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the workplace.
 * @returns {object} The updated workplace.
 */
export const updateWorkplace = /* GraphQL */ `
  mutation UpdateWorkplace(
    $id: ID!
    $name: String
  ) {
    updateWorkplace(
      id: $id, 
      name: $name,
    ) { 
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * deleteWorkplace
 * @property {string} id - ID of the workplace.
 * @returns {object} The deleted workplace.
 */
export const deleteWorkplace = /* GraphQL */ `
 mutation DeleteWorkplace(
     $id: ID!
   ) {
   deleteWorkplace(
     id: $id,
     ) {
      id
   }
 }
`;
