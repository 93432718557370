/**
 * createCustomer
 */
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
      $allowedApplications: AWSJSON
      $tenantId: ID!
      $referenceId: String
      $nr: String
      $companyName: String
      $vat: String
      $companyRegisterNumber: String
      $titlePrefixed: String
      $firstName: String
      $lastName: String!
      $titlePostfixed: String
      $gender: String
      $toHandsTitlePrefixed: String
      $toHandsFirstName: String
      $toHandsLastName: String
      $toHandsTitlePostfixed: String
      $toHandsGender: String
      $address: String!
      $zip: Int!
      $city: String!
      $countryCode: String!
      $deliveryAddress: String
      $deliveryZip: Int
      $deliveryCity: String
      $deliveryCountryCode: String
      $deliveryEMailAddress: String
      $eMailAddress: String
      $phoneNumber: String
      $phoneNumber2: String
    ) {
    createCustomer(
      allowedApplications: $allowedApplications
      tenantId: $tenantId
      referenceId: $referenceId
      nr: $nr
      companyName: $companyName
      vat: $vat
      companyRegisterNumber: $companyRegisterNumber
      titlePrefixed: $titlePrefixed
      firstName: $firstName
      lastName: $lastName
      titlePostfixed: $titlePostfixed
      gender: $gender
      toHandsTitlePrefixed: $toHandsTitlePrefixed
      toHandsFirstName: $toHandsFirstName
      toHandsLastName: $toHandsLastName
      toHandsTitlePostfixed: $toHandsTitlePostfixed
      toHandsGender: $toHandsGender
      address: $address
      zip: $zip
      city: $city
      countryCode: $countryCode
      deliveryAddress: $deliveryAddress
      deliveryZip: $deliveryZip
      deliveryCity: $deliveryCity
      deliveryCountryCode: $deliveryCountryCode
      deliveryEMailAddress: $deliveryEMailAddress
      eMailAddress: $eMailAddress
      phoneNumber: $phoneNumber
      phoneNumber2: $phoneNumber2
    ) {
      id
      allowedApplications
      referenceId
      nr
      companyName
      vat
      companyRegisterNumber
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      gender
      toHandsTitlePrefixed
      toHandsFirstName
      toHandsLastName
      toHandsTitlePostfixed
      toHandsGender
      address
      zip
      city
      countryCode
      deliveryAddress
      deliveryZip
      deliveryCity
      deliveryCountryCode
      deliveryEMailAddress
      eMailAddress
      phoneNumber
      phoneNumber2
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * updateCustomer
 */
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
      $id: ID!
      $allowedApplications: AWSJSON
      $referenceId: String
      $nr: String
      $companyName: String
      $vat: String
      $companyRegisterNumber: String
      $titlePrefixed: String
      $firstName: String
      $lastName: String!
      $titlePostfixed: String
      $gender: String
      $toHandsTitlePrefixed: String
      $toHandsFirstName: String
      $toHandsLastName: String
      $toHandsTitlePostfixed: String
      $toHandsGender: String
      $address: String!
      $zip: Int!
      $city: String!
      $countryCode: String!
      $deliveryAddress: String
      $deliveryZip: Int
      $deliveryCity: String
      $deliveryCountryCode: String
      $deliveryEMailAddress: String
      $eMailAddress: String
      $phoneNumber: String
      $phoneNumber2: String
    ) {
    updateCustomer(
      id: $id
      allowedApplications: $allowedApplications
      referenceId: $referenceId
      nr: $nr
      companyName: $companyName
      vat: $vat
      companyRegisterNumber: $companyRegisterNumber
      titlePrefixed: $titlePrefixed
      firstName: $firstName
      lastName: $lastName
      titlePostfixed: $titlePostfixed
      gender: $gender
      toHandsTitlePrefixed: $toHandsTitlePrefixed
      toHandsFirstName: $toHandsFirstName
      toHandsLastName: $toHandsLastName
      toHandsTitlePostfixed: $toHandsTitlePostfixed
      toHandsGender: $toHandsGender
      address: $address
      zip: $zip
      city: $city
      countryCode: $countryCode
      deliveryAddress: $deliveryAddress
      deliveryZip: $deliveryZip
      deliveryCity: $deliveryCity
      deliveryCountryCode: $deliveryCountryCode
      deliveryEMailAddress: $deliveryEMailAddress
      eMailAddress: $eMailAddress
      phoneNumber: $phoneNumber
      phoneNumber2: $phoneNumber2
    ) {
      id
      allowedApplications
      referenceId
      nr
      companyName
      vat
      companyRegisterNumber
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      gender
      toHandsTitlePrefixed
      toHandsFirstName
      toHandsLastName
      toHandsTitlePostfixed
      toHandsGender
      address
      zip
      city
      countryCode
      deliveryAddress
      deliveryZip
      deliveryCity
      deliveryCountryCode
      deliveryEMailAddress
      eMailAddress
      phoneNumber
      phoneNumber2
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * deleteCustomer
 * @property {string} id - id of the customer.
 * @returns {object} The deleted customer.
 */
export const deleteCustomer = /* GraphQL */ `
 mutation DeleteCustomer(
     $id: ID!
   ) {
   deleteCustomer(
     id: $id,
     ) {
      id
   }
 }
`;
