import React from 'react';
import {
    Alert, Box, Button, List, Typography,
} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import driveBuddyLogo from 'assets/images/DRIVEBUDDY_LOGO_V1.png';
import {Dashboards} from 'components/Dashboard/Dashboards';
import {Feedback} from '@mui/icons-material';
import {useGlobalState} from 'hooks/useGlobalState';
import {DashboardContainer} from 'components/Dashboard/DashboardContainer';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';

/**
 * This page shows a dashboard for all applications
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function LogBookDashboard() {
    const {setGlobal} = useGlobalState();

    const generalGuides = [
        {id: 'driveBuddy_logbook_drivingRecord', label: 'Fahrten KI gestützt erfassen'},
        {id: 'driveBuddy_vehicle_protocolEntry', label: 'Protokolleinträge für Fahrzeuge erfassen'},
    ];

    return (
        <LayoutContainer>
            <Box
                component="img"
                src={driveBuddyLogo}
                style={{display: 'block', margin: '2rem auto 2rem auto', maxHeight: '8rem'}}
            />

            <Dashboards applicationId="drivebuddy" />
            <DashboardContainer head={<Typography variant="h2">Hilfe & Dokumentation</Typography>}>
                <List dense style={{width: '100%'}}>
                    {generalGuides.map(createQuickGuideListItem)}
                </List>
            </DashboardContainer>
            <hr />
            <Alert severity="success" icon={<Feedback />}>
                Du nutzt die erste Version unsere Fahrtenbuchs. Hast Du Anmerkungen, Wünsche oder gar einen Fehler entdeckt?
                <br />
                <Button onClick={() => setGlobal('showFeedback', true)}>Feedback erfassen</Button>
            </Alert>
            <hr style={{marginBottom: '2rem'}} />
        </LayoutContainer>
    );
}
export {LogBookDashboard};
