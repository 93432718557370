import {useCallback, useState} from 'react';
import {useGlobalState} from 'hooks/useGlobalState';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {createLog} from 'graphql/beyondBuddy/Log/mutations';

const mockables = {
    AWSAppSyncProvider,
};

/**
 * @typedef {"PageNotFound" | "PageError" | "PageCrash"} LogMessageStream
 * @typedef {(stream: LogMessageStream, message: object, silent?: boolean)  => Promise<void>} UseLogMessageFn
 */

/**
 * The hook to send a message to cloudwatch.
 * @returns {{logMessage: UseLogMessageFn, isSending: boolean}} - The hook object with the following properties: logMessage, isSending.
 */
const useLogMessage = () => {
    // Router
    const {location} = window;
    // State & Hooks
    const {getGlobal} = useGlobalState();
    const [isSending, setIsSending] = useState(false);
    const globalUser = getGlobal('user');
    const {editItem} = mockables.AWSAppSyncProvider();

    /**
     * Sends a message to cloudwatch.
     * @type {UseLogMessageFn}
     * @returns {Promise<void>}
     */
    const logMessage = useCallback(async (stream, message, silent) => {
        // fake api call to send feedback
        const enrichedMessage = {
            message: {
                path: location.pathname,
                ...message,
            },
            userInformation: {
                tenantId: globalUser?.tenantId,
                userId: globalUser?.id,
            },
            silent: silent ?? true,
        };
        try {
            setIsSending(true);
            await editItem(createLog, {
                stream,
                message: JSON.stringify(enrichedMessage),
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            setIsSending(false);
        }
    }, [location, globalUser, editItem]);

    return {
        logMessage,
        isSending,
    };
};
export {useLogMessage, mockables};
