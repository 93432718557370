const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    UUID_REGEX,
    FILE_KEY_REGEX,
    ISO8601_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        subject: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        protocolDate: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['format', 'required'],
            },
        ],
        subject: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        protocolDate: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],

    },
    attributes: {
        id: {
            format: UUID_REGEX,
            required: true,
        },
        subject: {
            type: 'String',
            length: {lte: 40},
            required: true,
        },
        protocolDate: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
