/**
 * creareDrivingRecord
 * @property {string} tenantId - tenant id.
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} startAddress - start address.
 * @property {string} endAddress - end address.
 * @property {number} startKM - start km.
 * @property {number} endKM - end km.
 * @property {string} reason - reason for the drive
 * @property {boolean} draft - flag that makes a record a draft
 * @property {string} drivingRecordVehicleId - driving record vehicle id.
 * @property {string} drivingRecordUserId - driving record user id.
 * @property {"PRIVATE"| "BUSINESS"} kind - kind of driving record
 * @returns {object} The created driving record.
 */
export const createDrivingRecord = /* GraphQL */ `
  mutation CreateDrivingRecord(
    $tenantId: ID!,
    $startDateTime: AWSDateTime,
    $endDateTime: AWSDateTime,
    $startAddress: String,
    $endAddress: String,
    $startKM: Int,
    $endKM: Int,
    $reason: String, 
    $drivingRecordVehicleId: ID,
    $drivingRecordUserId: ID!,
    $kind: DrivingRecordKind,
    $draft: Boolean,
  ) {
    createDrivingRecord(
      tenantId: $tenantId,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      startAddress: $startAddress,
      endAddress: $endAddress,
      startKM: $startKM,
      endKM: $endKM,
      drivingRecordVehicleId: $drivingRecordVehicleId,
      drivingRecordUserId: $drivingRecordUserId,
      reason: $reason,
      kind: $kind,
      draft: $draft,
    ) {
      id
      draft
      startDateTime
      endDateTime
      startAddress
      endAddress
      startKM
      endKM
      kind
      reason
      drivingRecordVehicleId
      drivingRecordUserId
      kind
      grants {
        updatable
      }
      vehicle{
        id
        name
      }
    }
  }
`;

/**
 * updateDrivingRecord
 * @property {string} tenantId - tenant id.
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} startAddress - start address.
 * @property {string} endAddress - end address.
 * @property {number} startKM - start km.
 * @property {number} endKM - end km.
 * @property {string} reason - reason for the drive
 * @property {boolean} draft - flag that makes a record a draft
 * @property {string} drivingRecordVehicleId - driving record vehicle id.
 * @property {string} drivingRecordUserId - driving record user id.
 * @property {"PRIVATE" | "BUSINESS"} kind - new kind of the driving record
 * @returns {object} The updated driving record.
 */
export const updateDrivingRecord = /* GraphQL */ `
  mutation UpdateDrivingRecord(
    $id: ID!,
    $startDateTime: AWSDateTime,
    $endDateTime: AWSDateTime,
    $startAddress: String,
    $endAddress: String,
    $startKM: Int,
    $endKM: Int,
    $drivingRecordVehicleId: ID,
    $drivingRecordUserId: ID,
    $kind: DrivingRecordKind,
    $reason: String,
    $draft: Boolean
  ) {
    updateDrivingRecord(
      id: $id,
      startDateTime: $startDateTime,
      endDateTime: $endDateTime,
      startAddress: $startAddress,
      endAddress: $endAddress,
      startKM: $startKM,
      endKM: $endKM,
      reason: $reason,
      drivingRecordVehicleId: $drivingRecordVehicleId,
      drivingRecordUserId: $drivingRecordUserId,
      kind: $kind,
      draft: $draft,
    ) {
      id  
      draft
      startDateTime
      endDateTime
      startAddress
      endAddress
      startKM
      endKM
      reason
      kind
      drivingRecordVehicleId
      drivingRecordUserId
      grants {
        updatable
      }
      vehicle{
        id
        name
      }
    }
  }
`;

/**
 * deleteDrivingRecord
 * @property {string} id - driving record id.
 * @returns {object} The deleted driving record.
 */
export const deleteDrivingRecord = /* GraphQL */ `
  mutation DeleteDrivingRecord(
    $id: ID!
    ) {
    deleteDrivingRecord(
      id: $id
    ) {
      id
    }
  }
`;
