/**
 * listWorkingTimeSchedules
 * @property {string} tenantId - tenant id.
 * @property {string} nextToken - token to continue a listing
 * @property {object} filter - filter with arguments applicable to workingTimeLogList
 * @returns {object} The working time logs.
 */
export const listWorkingTimeSchedules = /* GraphQL */ `
  query ListWorkingTimeSchedules(
    $tenantId: ID!
    $nextToken: String
    $filter: WorkingTimeScheduleListFilter
  ) {
    listWorkingTimeSchedules(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        startDateTime
        endDateTime
        shifts {
          id
        }
        participantIds
        grants {
          deletable
        }
        userId
        user {
          id
          contactFirstName
          contactLastName
        }
      }
      nextToken
    }
  }
`;

/**
 * listWorkingTimeSchedulesInDetail
 * @property {string} tenantId - tenant id.
 * @property {string} nextToken - token to continue a listing
 * @property {object} filter - filter with arguments applicable to workingTimeLogList
 * @returns {object} The working time logs.
 */
export const listWorkingTimeSchedulesInDetail = /* GraphQL */ `
  query ListWorkingTimeSchedulesInDetail(
    $tenantId: ID!
    $nextToken: String
    $filter: WorkingTimeScheduleListFilter
  ) {
    listWorkingTimeSchedules(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        startDateTime
        endDateTime
        shifts {
          id
          participantIds
          from
          until
        }
        participantIds
        grants {
          deletable
        }
        userId
        user {
          id
          contactFirstName
          contactLastName
        }
      }
      nextToken
    }
  }
`;

/**
 * getWorkingTimeSchedule
 * @property {string} id - working time log id.
 * @returns {object} The working time log
 */
export const getWorkingTimeSchedule = /* GraphQL */ `
  query GetWorkingTimeSchedule($id: ID!) {
    getWorkingTimeSchedule(id: $id) {
      id
      startDateTime
      endDateTime
      shifts {
        from
        until
        participantIds
        placeId
        id
      }
      participantIds
      participants {
        id
        contactFirstName
        contactLastName
      }
      placeIds
      places {
        id
        name
      }
      grants {
        updatable
      }
    }
  }
`;
