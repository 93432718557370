import React, {
    useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {Box, IconButton, Skeleton} from '@mui/material';

import classes from 'components/Form/FormElements/formElementImageContainer.module.scss';
import fileClasses from 'components/Form/FormElements/formElementFiles.module.scss';
import {
    Add, Crop, Delete, PhotoCamera, Remove, Save,
} from '@mui/icons-material';
import {FormElementFileDropzone} from 'components/Form/FormElements/FormElementFileDropzone';
import {FormElementFileUploadWrapper} from 'components/Form/FormElements/FormElementFileUploadWrapper';
import AvatarEditor from 'react-avatar-editor';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {createFileInformation} from 'graphql/beyondBuddy/FileInformation/mutations';
import {CancelException} from 'hooks/useCancellablePromise';
import {useMessage} from 'hooks/useMessage';
import {Exceptions} from 'messages/Exceptions';
import {S3UploadProvider} from 'helper/bb-s3upload-provider';
import {PositionedImage} from 'assets/theme/components/PositionedImage/PositionedImage';

const scale = {
    min: 1,
    max: 5,
    step: 0.08,
};

const defaultEditorProps = {
    scale: 1,
    position: {x: 0, y: 0},
};

const mockables = {
    AWSAppSyncProvider,
};

/**
 * This element will provide an image. It may also provide a
 * FormElementFileDropzone and a FormElementFileUploadWrapper to update the
 * image with a different file.
 *
 * If `edit` is true, it will also include an avatar editor, to make the image
 * editable
 * .Components.Form
 * @param {import('./formElements').FormElementImageContainerProps} props - properties passed to the component.
 * @returns {React.ReactElement} The image with upload capability.
 */
function FormElementImageContainer({
    alt,
    defaultImg,
    onSave,
    attribute = 'imageKey',
    fileReference = 'image',
    width = 255,
    height = 255,
    edit = false,
    upload: uploadEnabled = true,
}) {
    /** @type {React.MutableRefObject<AvatarEditor>} */
    const editor = useRef();
    const [saveFileInformation, setSaveFileInformation] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [editorActive, setEditorActive] = useState(false);
    const [editorProps, setEditorProps] = useState(defaultEditorProps);
    const {
        get, isLoading, changeHandler, isReadonly,
    } = useContext(FormContext);
    const {editItem} = mockables.AWSAppSyncProvider();
    const {enqueueMessage} = useMessage();
    const {upload, isUploading} = S3UploadProvider();

    /**
     * Retrieving the data from the FormElement context
     */
    const elementData = useMemo(() => get(attribute), [attribute, get]);
    const elementReferenceData = useMemo(() => get(fileReference), [fileReference, get]);

    const saveFileInformationOptions = useCallback(async () => {
        if (editor.current) {
            setSaveFileInformation(true);
            const avatarEditor = editor.current;
            const croppingRect = avatarEditor.getCroppingRect();

            const newValue = {...elementReferenceData.value};
            _.set(newValue, 'options.image.position', croppingRect);

            changeHandler({
                attribute: fileReference,
                value: newValue,
                displayValue: null,
                interacted: true,
            });
            setEditorActive(false);
            try {
                const result = await editItem(createFileInformation, newValue);
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            } catch (e) {
                if (e instanceof CancelException) {
                    return;
                }
                // check the error message to determine where it comes from
                e.errors.forEach((error) => enqueueMessage('Image', Exceptions.API_SAVE_ERROR, {error, item: newValue}));
            } finally {
                setSaveFileInformation(false);
            }
        }
    }, [editor.current, elementReferenceData, width, height, changeHandler]);

    useEffect(() => {
        const {
            height: h, width: w,
        } = elementReferenceData.value?.options?.image?.position ?? {};

        // some position information is available
        if (h && w) {
            setEditorProps((current) => ({
                ...current,
                scale: Math.round((1 / Math.max(h, w)) * 100) / 100,
                position: {
                    x: 0,
                    y: 0,
                },
            }));
        } else {
            setEditorProps(defaultEditorProps);
        }
    }, [
        elementData.value?.key,
        elementReferenceData.value?.options?.image?.position?.height,
        elementReferenceData.value?.options?.image?.position?.width,
        setEditorProps,
    ]);

    return (
        <Box data-test="FormElementImageContainer">
            <Box
                className={classes.imageContainer}
                style={{
                    width,
                    height,
                }}
            >
                {(isLoading.load || (!editorActive && !imgLoaded && elementReferenceData.value?.url))
                && (
                    <Skeleton
                        variant="rectangular"
                        style={{
                            height,
                            width,
                        }}
                    />
                )}
                {(editorActive && elementReferenceData.value?.url)
                && (
                    <AvatarEditor
                        ref={editor}
                        image={elementReferenceData.value?.url}
                        width={width}
                        height={height}
                        border={0}
                        {...editorProps}
                        onPositionChange={(position) => setEditorProps((current) => ({...current, position}))}
                    />
                )}
                {(!isLoading.load && !isReadonly && (!editorActive || !elementReferenceData.value?.url))
                    && (<FormElementFileDropzone attribute={attribute} fileReference={fileReference} upload={upload} disabled={isUploading} />)}
                <PositionedImage
                    alt={alt}
                    defaultImg={defaultImg}
                    fileInformation={elementReferenceData.value}
                    height={height}
                    width={width}
                    onImageLoad={() => setImgLoaded(true)}
                    style={{
                        display: (!editorActive || !elementReferenceData.value?.url) ? 'inherit' : 'none',
                        opacity: Number(Boolean(elementReferenceData.value?.url) || imgLoaded),
                    }}
                />
                <Box style={{
                    position: 'absolute',
                    bottom: '0.5rem',
                    right: '0.5rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    zIndex: 3,
                    color: 'black',
                    gap: '0.7rem',
                }}
                >
                    {(!isReadonly && !isLoading?.load && edit && elementData.value) && (editorActive
                        ? (
                            <>
                                <IconButton
                                    color="inherit"
                                    data-test="FormElementImageContainer_ZoomIn"
                                    disabled={isLoading.save || saveFileInformation}
                                    className={fileClasses.control}
                                    onClick={() => setEditorProps((current) => ({
                                        ...current,
                                        scale: Math.min((parseFloat(`${current.scale}`) * 10 + parseFloat(`${scale.step}`) * 10) / 10, scale.max),
                                    }))}
                                >
                                    <Add />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    data-test="FormElementImageContainer_ZoomOut"
                                    disabled={isLoading.save || saveFileInformation}
                                    className={fileClasses.control}
                                    onClick={() => setEditorProps((current) => ({
                                        ...current,
                                        scale: Math.max((parseFloat(`${current.scale}`) * 10 - parseFloat(`${scale.step}`) * 10) / 10, scale.min),
                                    }))}
                                >
                                    <Remove />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    data-test="FormElementImageContainer_Save"
                                    disabled={isLoading.save || saveFileInformation}
                                    className={fileClasses.control}
                                    onClick={async () => saveFileInformationOptions()}
                                >
                                    <Save />
                                </IconButton>
                            </>
                        ) : (
                            <IconButton
                                color="inherit"
                                data-test="FormElementImageContainer_EditorButton"
                                disabled={isLoading.save || saveFileInformation}
                                className={fileClasses.control}
                                onClick={() => setEditorActive(true)}
                            >
                                <Crop />
                            </IconButton>
                        ))}
                    {!isLoading?.load && !isReadonly && uploadEnabled && (
                        <>
                            <FormElementFileUploadWrapper
                                attribute={attribute}
                                fileReference={fileReference}
                                upload={upload}
                                disabled={isLoading.save}
                                uploading={isUploading}
                            >
                                <IconButton
                                    color="primary"
                                    disabled={isLoading.save || saveFileInformation}
                                    className={fileClasses.control}
                                    style={{pointerEvents: 'none'}}
                                >
                                    <PhotoCamera />
                                </IconButton>
                            </FormElementFileUploadWrapper>
                            <IconButton
                                color="info"
                                disabled={isLoading.save || saveFileInformation}
                                className={fileClasses.control}
                                onClick={() => {
                                    setEditorProps(defaultEditorProps);
                                    changeHandler({
                                        attribute,
                                        value: null,
                                        displayValue: null,
                                        interacted: true,
                                    });
                                    changeHandler({
                                        attribute: fileReference,
                                        value: null,
                                        displayValue: null,
                                        interacted: true,
                                    });
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </>
                    )}

                </Box>
            </Box>
        </Box>
    );
}

export {FormElementImageContainer, mockables as imageContainerMockables};
