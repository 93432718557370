import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    FirstPageOutlined,
    LastPageOutlined,
    Refresh,
    Restore,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {listTimePeriods} from 'graphql/timeBuddy/TimePeriod/queries';
import {formatDateTimeString} from 'helper/date';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';
import {deleteTimePeriod} from 'graphql/timeBuddy/TimePeriod/mutations';

/** @type {import('components/Form/Listing').ListingSchema} */
const schema = {
    columns: [
        {
            itemConfigurations: [
                {
                    title: 'Abrechnungsperiode',
                    renderItem: (item) => (
                        <Restore
                            data-test={`TimePeriod_${item.open ? 'open' : 'closed'}`}
                            color="primary"
                            style={{
                                maxHeight: '40px', height: '100%', width: '100%',
                            }}
                            sx={{color: item.open ? 'success.main' : 'darkgray'}}
                        />
                    ),
                },
            ],
            boxProps: {style: {width: '80px'}},
        },
        {
            itemConfigurations: [
                {
                    renderItem: (item) => item.startDate && (
                        <>
                            <FirstPageOutlined color="info" />
                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDate, 'dd.MM.yyyy')}</Typography>
                        </>
                    ),
                    title: 'Start',
                }, {
                    renderItem: (item) => item.endDate && (
                        <>
                            <LastPageOutlined color="secondary" />
                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.endDate, 'dd.MM.yyyy')}</Typography>
                        </>
                    ),
                    title: 'Ende',
                },
            ],
            boxProps: {sx: {flex: {xs: 1, md: 3, lg: 1}}},
        },
    ],
    actions: [{
        icon: <Edit />,
        routeId: 'timebuddy_time_period_route',
        routeParams: (item) => ({id: item.id}),
    }],
    routeId: 'timebuddy_time_period_route',
    routeParams: (item) => ({id: item.id}),
};

/**
 * Formular for list Time period listing
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The TimePeriodListForm component
 */
function TimePeriodListFormular() {
    const messageKey = 'TimePeriod_List';
    return (
        <ListData
            loadConfig={{
                query: listTimePeriods,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteTimePeriod,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="timebuddy_time_period_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createTimePeriod')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="timeperiod-filter" messageKey={messageKey}>
                <span style={{width: '100%', marginLeft: '1rem'}}><FilterElementCheckmark label="Aktiv" path="open" /></span>
                <FilterElementDate path="startDate.gte" label="Start von" />
                <FilterElementDate path="startDate.lt" label="Start bis" />
                <FilterElementDate path="endDate.gte" label="Ende von" />
                <FilterElementDate path="endDate.lt" label="Ende bis" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {({deleteItem}) => (
                    <Listing schema={{
                        ...schema,
                        actions: [
                            {
                                icon: <Delete />,
                                safety: true,
                                isVisible: (item) => item.grants?.deletable ?? false,
                                callBack: async (item) => deleteItem({item, messageKey}),
                                buttonProps: {color: 'info'},
                            },
                            ...schema.actions,
                        ],
                    }}
                    />
                )}
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {TimePeriodListFormular};
