// eslint-disable-next-line no-restricted-imports
import {WorkingTimeModelHoursFragment} from '../../beyondBuddy/fragments';

/**
 * listWorkingTimeModels
 * @property {string} tenantId - id of the tenant (required).
 * @property {object} filter - filter.
 * @property {string} nextToken - token to continue a listing (optional).
 * @returns {object} All users.
 */
export const listWorkingTimeModels = /* GraphQL */ `
  query ListWorkingTimeModels (
    $tenantId: ID!
    $nextToken: String
    $filter: WorkingTimeModelFilterInput
    ) {
    listWorkingTimeModels(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: $filter
      ) {
      items {
        id
        name
        revision
        info
        weeklyHours
        weeklyDays
        crossRevId
      }
      nextToken
    }
  }
`;

/**
 * listWorkingTimeModelOptions
 * @property {string} tenantId - id of the tenant (required).
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing (optional).
 * @returns {object} All users.
 */
export const listWorkingTimeModelOptions = /* GraphQL */ `
  query ListWorkingTimeModelOptions (
    $tenantId: ID!
    $nextToken: String
    $filter: String
    ) {
    listWorkingTimeModelOptions: listWorkingTimeModels(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: {
          name: $filter
        }
      ) {
      items {
        id
        revision
        name
        crossRevId
      }
      nextToken
    }
  }
`;

/**
 * getWorkingTimeModel
 * @property {string} id - id of the WorkingTimeModel (required).
 * @returns {object} The requested WorkingTimeModel.
 */
export const getWorkingTimeModel = /* GraphQL */ `
  query GetWorkingTimeModel(
    $id: ID!
    ) {
    getWorkingTimeModel(id: $id) {
      id
      name
      templateId
      template {
        revision
        id
        name
        crossRevId
      }
      info
      revision
      crossRevId
      weeklyHours
      weeklyDays
      vacationClaimWeeks
      dailyHours
      normalWorkingHours {...WorkingTimeModelHours}
      fixedWorkingHours {...WorkingTimeModelHours}
      agreedWorkingHours {...WorkingTimeModelHours}
      workHoursMultiplication {...WorkingTimeModelHours}
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      subGrants {
        assignmentCreatable
        assignmentReadable
      }
    }
  }
  ${WorkingTimeModelHoursFragment}
`;
