/**
 * @satisfies {import("messages/Messages").SnackbarMessage}
 */
const Exceptions = {
    AUTHORIZED_CUSTOMERS_PORTIONS_SUM_TOO_HIGH: {
        message: {
            de: 'Die Summe der Anteile aller Nutzungsberechtigter übersteigt 100%.',
        },
        options: {
            variant: 'error',
        },
    },
    GRAVERECORD_CUSTOMERS_PORTIONS_SUM_TOO_HIGH: {
        message: {
            de: 'Die Summe der Anteile aller Auftraggeber übersteigt 100%.',
        },
        options: {
            variant: 'error',
        },
    },
    GRAVE_CONNECTION_POSITIONS_SINGULARITY: {
        message: {
            de: 'Es kann nur ein linkes und ein rechtes Grab definiert werden.',
        },
        options: {
            variant: 'error',
        },
    },
    GRAVE_UNITPOSITION_MISSING_INFORMATION: {
        message: {
            de: 'Grabpositionen benötigen eine Bezeichnung.',
        },
        options: {
            variant: 'error',
        },
    },
};

export {Exceptions};
