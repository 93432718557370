import _ from 'lodash';
import {useEffect, useState} from 'react';

/**
 * Subscribes to an observable and keeps the state of a extracted value
 * @template T
 * @param {import("rxjs").Observable<T>} observable - observable to subscribe to
 * @param {string|((source:any) => T)} [extractor] - extractor to apply before setting the value
 * @param {T} [start] - starting value
 * @returns {T} value
 */
const useSubscribe = (observable, extractor, start) => {
    const [value, setValue] = useState(start);
    useEffect(() => {
        const subscription = observable?.subscribe((nextValue) => {
            if (extractor) {
                if (_.isString(extractor)) {
                    setValue(_.get(nextValue, extractor));
                } else if (_.isFunction(extractor)) {
                    setValue(extractor(nextValue));
                }
            } else {
                setValue(nextValue);
            }
        });
        return () => subscription?.unsubscribe();
    }, [observable, extractor]);
    return value;
};

export {useSubscribe};
