import React, {useEffect, useState} from 'react';
import {
    Outlet, useLocation, useNavigate,
} from 'react-router-dom';
import {
    AppBar, Box, Divider, Drawer,
    IconButton, Link, SwipeableDrawer, Toolbar, Typography,
} from '@mui/material';
import {
    Apps, Facebook, LinkedIn, Logout,
} from '@mui/icons-material';
import {ModulesMenu} from 'assets/theme/layout/Menu/MainMenu';
import {ApplicationMenu} from 'assets/theme/layout/Menu/ApplicationMenu';
import {ProfileInfo} from 'assets/theme/layout/Menu/ProfileMenu';

import classes from 'assets/theme/layout/mainLayout.module.scss';
import applicationMenuClasses from 'assets/theme/layout/Menu/applicationMenu.module.scss';
import {GridMenuIcon} from '@mui/x-data-grid';
import _ from 'lodash';

import logoLight from 'assets/images/BEYOND_LOGO_V5_TextLogo_darkMode.svg';
import logoDark from 'assets/images/BEYOND_LOGO_V5_TextLogo.svg';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {useRouteInformation} from 'applications/configs';
import {ScrollToTop} from 'assets/theme/components/ScrollToTop/ScrollToTop';
import {RouteLink} from 'components/RouteLink';
import {ColorModeSwitch} from 'assets/theme/components/ColorModeSwitch/ColorModeSwitch';
import {MenuLink} from 'components/MenuLink';

import 'react-image-gallery/styles/css/image-gallery.css';

/**
 * This is the main layout of the theme.
 * @param {object} props - properties passed to the component
 * @param {Array<import('routeinfo').ApplicationMenuInfo>} props.menus - menu information of all applications available
 * @param {import('@mui/material').BoxProps} [props.mainProps] - props of the main content
 * @param {import('@mui/material').PaletteMode} props.mode - color mode
 * @param {React.ReactNode} [props.toolbarChilds] - extra items to be shown in the toolbar
 * @param {(mode:import('@mui/material').PaletteMode)=>void} props.setMode - change the color mode
 * @returns {React.ReactElement} The MainLayout component
 */
function MainLayout({
    menus, mainProps, mode, setMode, toolbarChilds,
}) {
    const [applicationMenuOpen, setApplicationMenuOpen] = useState(false);
    const [mobileMainMenuOpen, setMobileMainMenuOpen] = useState(false);

    const {signOut} = useAuthenticator();
    const navigate = useNavigate();

    // get the current location
    const location = useLocation();
    const {isActive: isMenuActive, currentApplication, currentModule} = useRouteInformation(menus);
    // const currentApplicationMenu = _.find(menus, (m) => m.applicationId === currentMenu.applicationId);

    // the with of the drawer (main- and applicationmenu)
    const drawerWidth = 240;

    const handleMobileMainMenuToggle = () => {
        setMobileMainMenuOpen(!mobileMainMenuOpen);
    };

    // close the drawer in case of a location change
    useEffect(() => {
        setMobileMainMenuOpen(false);
        setApplicationMenuOpen(false);
    }, [location, setMobileMainMenuOpen, setApplicationMenuOpen]);

    useEffect(() => {
        const titleElement = document.querySelector('title');
        if (titleElement) {
            // Defaulting to setting BeyondBuddy if no application is selected, or no applicationName is specified
            titleElement.text = currentApplication?.applicationName ?? 'BeyondBuddy';
        }
    }, [currentApplication?.applicationName]);

    const handleSignOut = async () => {
        try {
            signOut();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error signing out: ', error);
        } finally {
            navigate('/', {replace: true});
        }
    };

    const drawer = (
        <Box height="100%" display="flex" flexDirection="column" style={{overflowX: 'hidden'}}>
            <Box flexGrow="0">
                <Toolbar variant="dense">
                    <MenuLink to="/" data-test="logo_link">
                        <Box component="img" style={{width: '100%', margin: 'auto'}} src={mode === 'dark' ? logoLight : logoDark} alt="BEYOND IT SOLUTIONS" />
                    </MenuLink>
                </Toolbar>
                <Divider />
                <ProfileInfo menus={menus} />
            </Box>
            <Box
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                }}
            >
                <ModulesMenu
                    modules={currentApplication?.modules}
                    isModuleActive={(id) => currentModule?.moduleId === id}
                    isMenuActive={isMenuActive}
                />
                <Box
                    component="footer"
                    className={classes.footerContainer}
                    data-test="footer"
                    marginTop="1rem"
                >
                    <Link href="https://www.facebook.com/beyond.it.solutions.gmbh/" target="_blank" data-test="link_facebook" rel="noreferrer">
                        <Facebook />
                    </Link>
                    <Link href="https://at.linkedin.com/company/beyond-it-solutions-gmbh" target="_blank" data-test="link_linkedin" rel="noreferrer">
                        <LinkedIn />
                    </Link>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box
            className={classes.fadeIn}
            sx={{
                overflow: 'hidden',
                height: '100vH',
                display: 'flex',
            }}
        >
            <AppBar
                data-test="MainLayout_AppBar"
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                    // boxShadow: 0,
                }}
            >
                <Toolbar variant="dense">
                    <Box
                        component="nav"
                        className={classes.headerContainer}
                    >
                        <Box sx={{flexGrow: 1, display: currentApplication ? 'block' : 'none'}}>
                            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                                {currentApplication && (
                                    <RouteLink routeId={currentApplication.menu.routeId}>
                                        <Typography variant="h2" color="white">
                                            {_.chain([currentApplication.applicationName, currentApplication.applicationDescription]).compact().join(' - ').value()}
                                        </Typography>
                                    </RouteLink>
                                )}
                            </Box>
                            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                                {currentApplication && (
                                    <RouteLink routeId={currentApplication.menu.routeId}>
                                        <Typography variant="h2" color="white" style={{fontWeight: 'bold'}}>
                                            {currentApplication?.applicationName}
                                        </Typography>
                                    </RouteLink>
                                )}
                            </Box>
                        </Box>
                        {toolbarChilds}
                        <IconButton
                            color="inherit"
                            aria-label="Hauptmenü öffnen"
                            onClick={handleMobileMainMenuToggle}
                            sx={{display: {sm: 'none'}}}
                            data-test="mainmenu_mobile_button"
                        >
                            <GridMenuIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="Anwendungsmenü öffnen"
                            onClick={() => { setApplicationMenuOpen(true); }}
                            data-test="applicationmenu_button"
                        >
                            <Apps />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box display="flex" width="100%">
                <Box
                    component="nav"
                    sx={{width: {sm: drawerWidth}}}
                >
                    <SwipeableDrawer
                        variant="temporary"
                        anchor="right"
                        open={applicationMenuOpen}
                        onOpen={() => { setApplicationMenuOpen(true); }}
                        onClose={() => { setApplicationMenuOpen(false); }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                            },
                        }}
                        className={classes.drawerContainer}
                        data-test="applicationmenu_drawer"
                    >
                        <>
                            <Toolbar
                                variant="dense"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    padding: '0.7rem 0.5rem 0.7rem 0.5rem',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    className={applicationMenuClasses.menuButtonContainer}
                                    sx={{
                                        '*': {color: 'white', backgroundColor: 'primary.main'},
                                        ':hover *': {color: 'info.main'},

                                    }}
                                >
                                    <MenuLink
                                        to="/"
                                        onClick={() => { handleSignOut(); }}
                                        data-test="logout_button"
                                        style={{
                                            padding: '.5rem',
                                        }}
                                    >
                                        <Logout />
                                        ABMELDEN
                                    </MenuLink>
                                </Box>
                                <ColorModeSwitch data-test="color_mode" checked={mode === 'dark'} onChange={(e) => setMode(e.target.checked ? 'dark' : 'light')} />
                            </Toolbar>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                            }}
                            >
                                <ApplicationMenu menus={menus} />

                            </Box>
                        </>
                    </SwipeableDrawer>
                    <SwipeableDrawer
                        data-test="mainmenu_mobile"
                        variant="temporary"
                        anchor="left"
                        open={mobileMainMenuOpen}
                        onOpen={() => { setMobileMainMenuOpen(true); }}
                        onClose={() => { setMobileMainMenuOpen(false); }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: {xs: 'block', sm: 'none'},
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                            },
                        }}
                        className={classes.drawerContainer}
                    >
                        {drawer}
                    </SwipeableDrawer>
                    <Drawer
                        data-test="mainmenu_desktop"
                        variant="permanent"
                        sx={{
                            display: {xs: 'none', sm: 'block'},
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                height: '100vH',
                                boxShadow: 2,
                            },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    // sx={{width: {xs: '100%', sm: `calc(100% - ${drawerWidth}px)`}}}
                    component="main"
                    className={classes.mainContent}
                    {...mainProps}
                >
                    {/* Needed to push down the main content (example from materialui) */}
                    <Toolbar variant="dense" />
                    <Outlet />
                    <ScrollToTop colorMode={mode} />
                </Box>
            </Box>
        </Box>
    );
}

export {MainLayout};
