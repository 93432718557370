const {FILEEXTENSIONS_DOCUMENT, FILEEXTENSIONS_IMAGE} = require('../constants');
const {FILE_KEY_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        kind: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        message: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type'],
            },
        ],
    },
    backend: {
        kind: [
            {
                actions: ['create'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        message: [
            {
                actions: ['create'],
                configurations: ['required', 'type'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],

    },
    attributes: {
        kind: {
            type: 'String',
            required: true,
            format: /^(ERROR|REQUEST|OTHER)$/,
        },
        message: {
            type: 'String',
            required: true,
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
