import React, {} from 'react';
import {Box} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import workTimeLogImg from 'applications/timebuddy/modules/workingtime/pages/images/AdobeStock_296597100.jpg';
import {WorkingTimeReportsFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeReports/WorkingTimeReportsFormular';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * Shows a form to generate a time-range report for the module workingtime
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeReportsPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Zeit-Bericht erstellen</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Box sx={{display: 'flex', gap: '2rem', marginTop: '1.5rem'}}>
                <Box
                    component="img"
                    src={workTimeLogImg}
                    alt="Zeitbuchung"
                    sx={{
                        maxWidth: '300px',
                        display: {xs: 'none', lg: 'block'},
                        marginBottom: 'auto',
                    }}
                />
                <Box sx={{flexGrow: 1}}>
                    <WorkingTimeReportsFormular />
                </Box>
            </Box>
        </LayoutContainer>
    );
}

export {WorkingTimeReportsPage};
