/* eslint-disable no-restricted-imports */
import {FileInformationFragment} from '../../beyondBuddy/fragments';

/**
 * listWorkingTimeLogs
 * @property {string} tenantId - tenant id.
 * @property {string} nextToken - token to continue a listing
 * @property {object} filter - filter with arguments applicable to workingTimeLogList
 * @returns {object} The working time logs.
 */
export const listWorkingTimeLogs = /* GraphQL */ `
  query ListWorkingTimeLogs(
    $tenantId: ID!
    $nextToken: String
    $filter: WorkingTimeLogListFilterInput
  ) {
    listWorkingTimeLogs(
    tenantId: $tenantId
    nextToken: $nextToken
    filter: $filter
    ) {
      items {
        __typename
        ... on WorkingTimeLogBasis {
          id
          userId
          user {
            contactFirstName
            contactLastName
          }
          startDateTime
          endDateTime
          kind
          comment
          grants {
            updatable
            deletable
          }
        }
        ... on WorkingTimeAbsenceLog {
          status
        }
        ... on WorkingTimeWorkLog {
          id
          draft
          startDateTime
          endDateTime
          comment
          kind
          workplaceId
          workplace {
            id
          name
          }
          templateId
          template{
            modifiable
            checkoutEnforcement
          }
          grants {
            deletable
          }
        }
      }
      nextToken
    }
  }
`;

/**
 * getWorkingTimeLog
 * @property {string} id - working time log id.
 * @returns {object} The working time log
 */
export const getWorkingTimeLog = /* GraphQL */ `
  query GetWorkingTimeLog($id: ID!) {
    getWorkingTimeLog(id: $id) {
      __typename
      ... on WorkingTimeLogBasis {
        id
        startDateTime
        endDateTime
        kind
        comment
        grants {
          updatable
        }
        userId
        user {
          id
          contactFirstName
          contactLastName
        }
      }
      ... on WorkingTimeAbsenceLog {
        status
        approver {
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
        approverId
        attachments {
          ...FileInformation
        }
        subGrants {
          judgable
        }
      }
      ... on WorkingTimeWorkLog {
        draft
        workplaceId
        workplace {
          id
          name
        }
        templateId
        template{
          modifiable
          checkoutEnforcement
        }
      }
    }
  }
  ${FileInformationFragment}
`;
