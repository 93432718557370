/**
 * createTenant
 * @property {string} contactFirstName - firt name of the contact person.
 * @property {string} contactEMailAddress - email of the contact person.
 * @returns {object} The newly created tenant.
 */
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $contactFirstName: String!
    $contactLastName: String
    $companyName: String
    $contactEMailAddress: String!
    ) {
    createTenant(
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      companyName: $companyName 
      contactEMailAddress: $contactEMailAddress
    ) {
      id
      contactFirstName
      contactEMailAddress
      appClientId
      userPoolId
      name
    }
  }
`;

/**
 * createTenant
 * @property {string} contactFirstName - firt name of the contact person.
 * @property {string} contactEMailAddress - email of the contact person.
 * @property {string} name - name of the tenant
 * @returns {object} The newly created tenant.
 */
export const createNamedTenant = /* GraphQL */ `
  mutation CreateNamedTenant(
    $contactFirstName: String!
    $contactEMailAddress: String!
    $name: String!
    ) {
    createTenant(
      contactFirstName: $contactFirstName
      contactEMailAddress: $contactEMailAddress
      name: $name
    ) {
      id
      contactFirstName
      contactEMailAddress
      name
    }
  }
`;

/**
 * updateTenant
 * @property {string} tenantId - tenant id.
 * @property {string} contactFirstName - firt name of the contact person.
 * @property {string} contactLastName - last name of the contact person.
 * @property {string} contactEMailAddress - email of the contact person.
 * @property {string} companyName - name of the company.
 * @property {string} companyRegisterNumber - company register number.
 * @property {string} companyTaxIdentificationId - company tax identification id.
 * @returns {object} The updated tenant.
 */
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $id: ID!
    $contactFirstName: String
    $contactLastName: String
    $contactEMailAddress: String
    $technicalContactEMailAddress: String
    $companyName: String
    $companyRegisterNumber: String
    $companyTaxIdentificationId: String
    $imageKey: String
    $identityProviders: [IdentityProviderInput!]
    $name: String
  ) {
    updateTenant(
      id: $id
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactEMailAddress: $contactEMailAddress
      technicalContactEMailAddress: $technicalContactEMailAddress
      companyName: $companyName
      companyRegisterNumber: $companyRegisterNumber,
      companyTaxIdentificationId: $companyTaxIdentificationId
      imageKey: $imageKey
      identityProviders: $identityProviders
      name: $name
    ) {
      id
      name
      contactFirstName
      contactLastName
      contactEMailAddress
      technicalContactEMailAddress
      companyName
      companyRegisterNumber
      companyTaxIdentificationId
      identityProviders {
        type
        metadataURL
      }
      grants {
        updatable
      }
    }
  }
`;
