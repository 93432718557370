import React from 'react';
import {
    Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import {BusinessOutlined, GroupsOutlined, PersonOutline} from '@mui/icons-material';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';

/**
 * This page shows a quick guide on organizational structures
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideOrganizationPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Die Aufbauorgansation</Typography>
            <hr />
            <Box>
                Die Aufbauorganisation eines Unternehmens beschreibt die Struktur und die Hierarchie innerhalb der Organisation.
                Sie umfasst die Festlegung von Zuständigkeiten, Aufgaben und Verantwortlichkeiten (siehe Berechtigungen) sowie die Schaffung von Entscheidungs- und Kommunikationsstrukturen.
                Die Aufbauorganisation zeigt, wie die verschiedenen Abteilungen, Bereiche und Teams innerhalb des Unternehmens angeordnet sind und wie sie zusammenarbeiten.
                Eine gut durchdachte und klar strukturierte Aufbauorganisation kann dazu beitragen, dass die Ziele des Unternehmens effektiv erreicht werden.
                <br />
                <br />
                Die nachfolgenden Objekte sind teil der Aufbauorganisation
                <List>
                    <ListItem>
                        <ListItemIcon><BusinessOutlined /></ListItemIcon>
                        <ListItemText primary="Mandant" secondary="Das Unternehmen selbst" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><PersonOutline /></ListItemIcon>
                        <ListItemText primary="Benutzer" secondary="Mitarbeiter im Unternehmen" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><GroupsOutlined /></ListItemIcon>
                        <ListItemText primary="Organisationseinheit" secondary="Abteilungen, Bereiche und Teams" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><GroupsOutlined /></ListItemIcon>
                        <ListItemText primary="Gruppe" secondary="Gruppierung von Objekten der Aufbauorganisation" />
                    </ListItem>
                </List>
                <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'})}
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissionChooser', label: 'Auswahl von Objekten im Berechtigungssystem'})}
                </List>
            </Box>
        </>
    );
}
export {GuideOrganizationPage};
