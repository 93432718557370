/* eslint-disable max-len */
import React from 'react';
import {
    Box, Button, Divider, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';
import {UnlockButton} from 'assets/theme/components/UnlockButton/UnlockButton';
import {Delete, OpenInNew} from '@mui/icons-material';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGraveCustomerPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Nutzungsberechtigte</Typography>
            <hr />
            <Box>
                <Typography textAlign="justify">
                Nutzungsberechtigte sind Personen (Kunden), welche das Recht haben, das Grab auf dem Friedhof zu nutzen.
                Dieses Nutzungsrecht umfasst in der Regel das Errichten, Pflegen und Erneuern eines Grabmals sowie das Bestatten von Verstorbenen im betreffenden Grab.
                Zudem obligt dem/der Nutzungsberechtigten die Verantwortung für die Instandhaltung des Grabes und die Einhaltung der Friedhofsordnung.
                Das Nutzungsrecht ist in der Regel zeitlich befristet und kann ggf. nach Ablauf verlängert werden.
                </Typography>
                <br />
                <Typography variant="h2" color="primary">Auswahl der Nutzungsberechtigten</Typography>
                <Divider />
                {'Es können ausschließlich Nutzungsberechtigte (Kunden) ausgewählt werden auf welche ein lesender Zugriff besteht. '}
                <br />
                <Button startIcon={<OpenInNew />}>Nutzungsberechtigte</Button>
                {' öffnet die Auswahlliste der Nutzungsberechtigten. Durch Anwählen werden diese in die Liste für das Grab übernommen.'}
                <br />
                <br />
                <Typography variant="h2" color="primary">Konfiguration</Typography>
                <Divider />
                <Typography textAlign="justify">
                Die Anteile aller Nutzungsberechtigter sollte 100% ergeben. Es wird beim Speichern überprüft und ggf. eine Warnung oder ein Fehler ausgegeben, wenn es in Summe nicht 100% sind.
                </Typography>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{backgroundColor: 'secondary.main'}}>
                                <TableCell>
                                    <Typography fontWeight="bold">Nutzungsberechtigter</Typography>
                                </TableCell>
                                <TableCell><Typography fontWeight="bold">Anteile</Typography></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell>
                                    <Box style={{display: 'flex', gap: '1rem'}}>
                                        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Typography fontWeight="bold" style={{textDecoration: 'underline'}}>Amira Kainz</Typography>
                                            <Typography paddingLeft="1rem" fontSize="0.9rem" style={{textDecoration: 'underline'}}>amira.kainz@beyondbuddy.at</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell><TextField label="Anteile in %" defaultValue={100} type="number" fullWidth /></TableCell>
                                <TableCell>
                                    <UnlockButton onClick={() => {}}>
                                        <Delete />
                                    </UnlockButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Typography variant="h2" color="primary">Synchronisierung</Typography>
                <Divider />
                <Typography textAlign="justify">
                    Die Konfiguration der Nutztungsberechtigten können im Falle eines Familiengrabes auf die anderen Gräber
                    <QuickGuideLink id="peacebuddy_general_grave_authorized_customers_synchronization"> synchronisiert </QuickGuideLink>
                    werden.
                </Typography>
                <br />
                <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'peacebuddy_general_grave_authorized_customers_synchronization', label: 'Nutzungsberechtigte synchronisieren'})}
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'})}
                </List>
            </Box>
        </>
    );
}
export {GuideGraveCustomerPage};
