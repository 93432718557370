/**
 * listWorkingTimeAggregateCorrections
 * @property {string} userId - ID of the user.
 * @property {string} tenantId - ID of the tenant.
 * @property {string} nextToken - next token to continue listing.
 * @property {string} type - type of the aggregate correction to list
 * @returns {object} The requested group.
 */
export const listWorkingTimeAggregateCorrections = /* GraphQL */ `
  query ListWorkingTimeAggregateCorrections (
    $filter: WorkingTimeAggregateCorrectionFilter!
    $tenantId: ID!
    $nextToken: String
  ) {
    listTimeAggregateCorrections(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        time
        value
        type
        comment
      }
    }
  }
`;
