import React, {useEffect, useCallback} from 'react';
import {
    Box, Typography, Button, AccordionDetails, AccordionSummary, Accordion,
} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {useGlobalState} from 'hooks/useGlobalState';
import {useNavigate, useRouteError} from 'react-router-dom';

import {useLogMessage} from 'hooks/useLogMessage';
import errorImageLight from 'applications/pages/images/undraw_page_error_light.svg';
import errorImageDark from 'applications/pages/images/undraw_page_not_found_dark.svg';
import {Messages} from 'messages/Messages';
import {ExpandMore} from '@mui/icons-material';

/**
 * This page gets shown at any error that is not handled by the application.
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function ErrorBoundary() {
    // Router
    const navigate = useNavigate();
    /**
     * @type {any}
     */
    const error = useRouteError();
    // State & Hooks
    const {getGlobal} = useGlobalState();
    const globalUser = getGlobal('user');

    const {logMessage} = useLogMessage();

    const sendFeedback = useCallback(
        () => {
            logMessage(
                'PageCrash',
                {
                    level: 'ERROR',
                    message: error,
                },
                false,
            );
        },
        [logMessage, error],
    );

    const navigateToTheDashboard = () => {
        navigate('/');
    };

    useEffect(() => {
        sendFeedback();
    }, []);

    return (
        <LayoutContainer>
            <Box sx={{
                marginTop: '1.5rem',
                height: 'calc(100% - 128px)',
            }}
            >
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}
                >
                    <img src={globalUser?.darkMode ? errorImageDark : errorImageLight} alt="404" height="350rem" />
                    <Box
                        sx={{
                            margin: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Typography
                            variant="h1"
                            align="center"
                            fontWeight="bold"
                        >
                        Ups, das hätte nicht passieren sollen...
                        </Typography>
                        <Typography color="success.main">{Messages.API_LOG_SUCCESSFUL.message.de}</Typography>
                        <Button
                            variant="contained"
                            onClick={navigateToTheDashboard}
                        >
                                    Zum Dashboard
                        </Button>
                        <Accordion sx={{width: '100vw', maxWidth: '40rem'}}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography fontWeight="bold">Details für Experten</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{
                                overflow: 'scroll',
                            }}
                            >
                                <code style={{

                                    wordWrap: 'break-word',
                                    boxDecorationBreak: 'clone',
                                    padding: '.1rem .3rem .2rem',
                                }}
                                >
                                    <pre>
                                        {error?.stack || error?.message || error}
                                    </pre>

                                </code>
                            </AccordionDetails>
                        </Accordion>

                    </Box>
                </Box>
            </Box>
        </LayoutContainer>
    );
}
export {ErrorBoundary};
