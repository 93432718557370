// eslint-disable-next-line no-restricted-imports
import {PermissionEntitiesFragment} from '../fragments';

/**
 * updateLinks
 * @property {string} entityTypeId - entityTypeId.
 * @property {Array} permissions - new permissions.
 * @returns {object} The requested permission info.
 */
export const updateLinks = /* GraphQL */ `
  mutation UpdateLinks(
    $entityTypeId: String!
    $links: [LinksInput!]!
    $isIncoming: Boolean
    $deleteLinks: Boolean
    $context: String
    ) {
    updateLinks(
      entityTypeId: $entityTypeId
      links: $links
      isIncoming: $isIncoming
      deleteLinks: $deleteLinks
      context: $context
    ) {
      links {
        leftEntityTypeId
        rightEntityTypeId
        permissions
        attributes
      }
      ...PermissionEntities
    }
  }
  ${PermissionEntitiesFragment}
`;
