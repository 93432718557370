import React, {useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {LockOutlined} from '@mui/icons-material';

/**
 * @param {string} tabId the id of the tab
 * @param {object} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (tabId, props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        actionButtonProps={{portalAnchorSelector: `#TimeBuddyPermissionsaction-button-frame-${tabId}-actions`}}
        // entityChooserProps={{showCostsWarning: true}}
        {...props}
    />
);

/**
 * This page shows a create and update form for TimeBuddy object permissions
 * .TimeBuddy.Pages
 * @returns {React.ReactElement} The TimeBuddyPermissionsPage component.
 */
function TimebuddyPermissionsPage() {
    const [currentTab, setCurrentTab] = useState(0);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: 'TimeBuddyPermissions',
            tabs: [{
                id: 'time_period',
                label: 'Abrechnungsperioden',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('time_period', {entityTypeId: 'Objecttype#TimePeriod', context: 'TimeBuddyTimePeriodPermissions'}),
                formWrapperId: 'TimeBuddyTimePeriodPermissions',
            }, {
                id: 'working_time_log',
                label: 'Arbeitszeiten',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('working_time_log', {entityTypeId: 'Objecttype#WorkingTimeLog', context: 'TimeBuddyWorkingTimeLogPermissions'}),
                formWrapperId: 'TimeBuddyWorkingTimeLogPermissions',
            }, {
                id: 'working_time_log_template',
                label: 'Vorlagen',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular(
                    'working_time_log_template',
                    {entityTypeId: 'Objecttype#WorkingTimeLogTemplate', context: 'TimeBuddyWorkingTimeLogTemplatePermissions'},
                ),
                formWrapperId: 'TimeBuddyWorkingTimeLogTemplatePermissions',
            }, {
                id: 'working_time_model',
                label: 'Arbeitszeitmodell',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('working_time_model', {entityTypeId: 'Objecttype#WorkingTimeModel', context: 'TimeBuddyWorkingTimeModelPermissions'}),
                formWrapperId: 'TimeBuddyWorkingTimeModelPermissions',
            }, {
                id: 'working_time_assignment',
                label: 'Arbeitszeitmodell Zuweisungen',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular(
                    'working_time_assignment',
                    {entityTypeId: 'Objecttype#WorkingTimeModelAssignment', context: 'TimeBuddyWorkingTimeModelAssignmentPermissions'},
                ),
                formWrapperId: 'TimeBuddyWorkingTimeModelAssignmentPermissions',
            }, {
                id: 'working_time_schedule',
                label: 'Dienstpläne',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('working_time_schedule', {entityTypeId: 'Objecttype#WorkingTimeSchedule', context: 'TimeBuddyWorkingTimeSchedulePermissions'}),
                formWrapperId: 'TimeBuddyWorkingTimeSchedulePermissions',
            }, {
                id: 'workplace',
                label: 'Orte',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('workplace', {entityTypeId: 'Objecttype#Workplace', context: 'TimeBuddyWorkplacePermissions'}),
                formWrapperId: 'TimeBuddyWorkplacePermissions',
            }, {
                id: 'working_time_aggregate_corrections',
                label: 'Arbeitskonto Korrekturen',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular(
                    'working_time_aggregate_corrections',
                    {entityTypeId: 'Objecttype#WorkingTimeAggregateCorrection', context: 'TimeBuddyWorkingTimeAggregateCorrectionPermissions'},
                ),
                formWrapperId: 'TimeBuddyWorkingTimeAggregateCorrectionPermissions',
            }, {
                id: 'holidays',
                label: 'Feiertage',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('holidays', {entityTypeId: 'Objecttype#WorkingTimeHoliday', context: 'TimeBuddyWorkingTimeHolidayPermissions'}),
                formWrapperId: 'TimeBuddyWorkingTimeHolidayPermissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [currentTab, setCurrentTab, getPermissionFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {TimebuddyPermissionsPage};
