import React, {useContext, useMemo, useCallback} from 'react';
import {Listing} from 'components/Form/Listing';
import {
    CalendarMonth,
    ChurchOutlined,
    Delete,
    Edit,
    Inventory2Outlined,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {formatDateTimeString} from 'helper/date';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {listGraveRecords} from 'graphql/peaceBuddy/GraveRecord/queries';
import {deleteGraveRecord} from 'graphql/peaceBuddy/GraveRecord/mutations';
import {getFullName} from 'helper/name';
import {ItemDataContext} from 'components/Form/ItemData';
import {setGraveRecordId} from 'applications/peacebuddy/settings/forms/grave/GraveRecordFormular';
import _ from 'lodash';

/**
 * Formular for list grave record entries
 * @param {object} props - properties passed to the component
 * @param {{edit: {callBack: (item: any) => void}}} [props.actions] - optional callbacks that can be specified for actions
 * @param {object} [props.listDataRef] - reference to pass to the listData component
 * @param {string} [props.graveId] - id of the grave that the records belong to
 * @param {string} [props.deceasedPersonId] - id of the deceased person that the records belong to
 * @param {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [props.actionButtonProps] - properties for actionButtons
 * @returns {React.ReactElement} The GraveRecordsListFormular component
 */
function GraveRecordsListFormular({
    graveId, deceasedPersonId, actions, listDataRef, actionButtonProps,
}) {
    if (!graveId && !deceasedPersonId) {
        throw new Error('Either graveId or deceasedPersonId must be provided');
    }
    const messageKey = 'GraveRecord_List';

    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {data, updateValue} = useContext(ItemDataContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     * TODO: Negative numbers are not allowed in the input field. This should be fixed.
     */
    const unitPositions = useMemo(() => data?.unitPositions, [data]);

    const removeGraveRecord = useCallback(({id}) => {
        // only gravepage has unitPositions, deceased person page not
        if (unitPositions) {
            const newUnitPositions = [...unitPositions];
            setGraveRecordId(newUnitPositions, id);
            updateValue('unitPositions', newUnitPositions);
        }
    }, [unitPositions, updateValue, setGraveRecordId]);

    const loadConfig = useMemo(() => {
        const filter = graveId ? {graveId} : {deceasedPersonId};
        return {
            query: listGraveRecords,
            mask: {
                tenantId: true, graveId: false, deceasedPersonId: false, filter: undefined,
            },
            variables: {
                direct: {filter},
                global: {tenantId: 'tenantId'},
            },
        };
    }, [graveId, deceasedPersonId, listGraveRecords]);

    const columns = useMemo(() => (graveId ? [
        {
            itemConfigurations: [
                {
                    title: 'Verstorbener',
                    renderItem: (item) => (
                        <Typography fontWeight="bold" noWrap>
                            {getFullName(item.deceasedPerson)}
                        </Typography>
                    ),
                },
                {
                    title: 'Datum der Beisetzung',
                    renderItem: (item) => (
                        <>
                            <CalendarMonth color={item.burryDeadline > new Date().toISOString() ? 'primary' : 'info'} />
                            <Typography noWrap>
                                {`${formatDateTimeString(item.dateOfFuneral, 'dd.MM.yyyy')} - ${formatDateTimeString(item.burryDeadline, 'dd.MM.yyyy')}`}
                            </Typography>
                        </>
                    ),
                    boxProps: {style: {marginLeft: '1rem'}},
                },
            ],
            boxProps: {style: {flexGrow: 1}},
        },
        {
            itemConfigurations: [
                {
                    title: 'Grab',
                    renderItem: (item) => (
                        <>
                            <Inventory2Outlined />
                            <Typography fontWeight="bold" noWrap>
                                { `${item?.grave?.generalNr} ${_.join(_.compact([item?.grave?.division, item?.grave?.subDivision, item?.grave?.nr]), '/')}`}
                            </Typography>
                        </>
                    ),
                },
            ],
            boxProps: {style: {flexGrow: 1}},
        },
    ] : [
        {
            itemConfigurations: [
                {
                    title: 'Friedhof',
                    renderItem: (item) => (
                        <>
                            <ChurchOutlined />
                            <Typography fontWeight="bold" noWrap>
                                { item?.grave?.cemetery
                                && `${item?.grave?.cemetery?.nr} ${item?.grave?.cemetery?.name}${item?.grave?.cemetery?.referenceId ? ` (${item?.grave?.cemetery?.referenceId})` : ''}`}
                                { !item?.grave?.cemetery
                                && 'Keinem Friedhof zugeordnet'}
                            </Typography>
                        </>
                    ),
                },
                {
                    title: 'Datum der Beisetzung',
                    renderItem: (item) => (
                        <>
                            <CalendarMonth color={item.burryDeadline > new Date().toISOString() ? 'primary' : 'info'} />
                            <Typography noWrap>
                                {`${formatDateTimeString(item.dateOfFuneral, 'dd.MM.yyyy')} - ${formatDateTimeString(item.burryDeadline, 'dd.MM.yyyy')}`}
                            </Typography>
                        </>
                    ),
                    boxProps: {style: {marginLeft: '1rem'}},
                },
            ],
            boxProps: {style: {flexGrow: 1}},
        }, {
            itemConfigurations: [
                {
                    title: 'Grab',
                    renderItem: (item) => (
                        <>
                            <Inventory2Outlined />
                            <Typography fontWeight="bold" noWrap>
                                { `${item?.grave?.generalNr} ${_.join(_.compact([item?.grave?.division, item?.grave?.subDivision, item?.grave?.nr]), '/')}`}
                            </Typography>
                        </>
                    ),
                },
            ],
            boxProps: {style: {flexGrow: 1}},
        },
    ]), [graveId]);

    return (
        <ListData
            loadConfig={loadConfig}
            deleteConfig={{
                mutation: deleteGraveRecord,
                mask: {id: true},
            }}
            ref={listDataRef}
        >
            {actionButtonProps && (
                <FormElementActionButton
                    reload
                    {...actionButtonProps}
                >
                    <Refresh />
                </FormElementActionButton>
            )}
            {!deceasedPersonId && (
                <ListFilterProvider id="graverecord-filter" messageKey={messageKey}>
                    <FilterElementCheckmark label="Nur aktive" path="active" />
                </ListFilterProvider>
            )}
            {deceasedPersonId && (
                <ListFilterProvider id="graverecord-filter" messageKey={messageKey} />
            )}
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                        /**
                         * @type {import('components/Form/Listing').ListingSchema}
                         */
                        const listingSchema = {
                            columns,
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    // isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => {
                                        deleteItem({item, messageKey});
                                        // deceased person page does not have unitPositions and therefore mustn't be removed
                                        if (unitPositions) {
                                            removeGraveRecord(item);
                                        }
                                    },
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    isVisible: () => !!actions?.edit?.callBack,
                                    callBack: actions?.edit?.callBack,
                                },
                            ],
                            callBack: actions?.edit?.callBack,
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {GraveRecordsListFormular};
