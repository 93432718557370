import {
    Assignment,
    AssignmentInd,
    AssignmentLate,
    AssignmentReturn,
    AssignmentTurnedIn,
    Delete, Edit, EventAvailable, FirstPageOutlined, HolidayVillage, Houseboat, LastPageOutlined, Refresh, Schedule, Sick,
} from '@mui/icons-material';
import {
    Box, Grid, Tooltip, Typography,
} from '@mui/material';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import {Listing} from 'components/Form/Listing';
import {deleteWorkingTimeAbsenceLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {listWorkingTimeLogs} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {formatDateTimeString} from 'helper/date';
import {useCanAccess} from 'hooks/useCanAccess';
import {useSubscribe} from 'hooks/useSubscribe';
import _ from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {Subject} from 'rxjs';
import {absenceKindDataSchema} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';
import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';

const tz = 'Europe/Vienna';
const moment = extendMoment(momentWithoutRange);

const messageKey = 'ABSENCE';

const kindRouteSegmentPerKind = {
    ABSENCE: 'direct',
    SICKLEAVE: 'sick_leave',
    VACATION: 'vacation',
    COMPENSATION: 'compensation',
    SPECIAL_LEAVE: 'special_leave',
};

/** @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema} */
const statusDataSchema = {
    dataKey: 'id',
    options: [
        {id: 'OPEN', label: 'Offen'},
        {id: 'IN_REVIEW', label: 'Zur Genehmigung'},
        {id: 'APPROVED', label: 'Genehmigt'},
        {id: 'CANCELED', label: 'Storniert'},
        {id: 'REJECTED', label: 'Abgelehnt'},
    ],
    getOptionLabel: (option) => option?.label,
    getOptionValue: (option) => option?.id,
};

/** @type {import("components/Form/ListData").ListLoadConfig} */
const loadConfig = {
    query: listWorkingTimeLogs,
    mask: {filter: false, tenantId: true, userId: false},
    variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
    preProcess: (args) => ({
        ...args,
        filter: {
            ...(args.filter?.userId
                || args.filter?.lineManaged
                || args.filter?.lineManagedRecursive
                ? {} : {userId: args.userId}),
            kinds: ['SICKLEAVE', 'VACATION', 'ABSENCE', 'COMPENSATION', 'SPECIAL_LEAVE'],
            ...args.filter,
        },
    }),
};

/** @type {import('components/Form/ListData').DeleteConfig}  */
const deleteConfig = {
    mutation: deleteWorkingTimeAbsenceLog,
    mask: {id: true},
};

/** @type {import('components/Form/Listing').ListingSchemaColumn[]} */
const schemaColumns = [
    {
        itemConfigurations: [
            {
                title: 'Status',
                renderItem: (item) => {
                    switch (item.status) {
                    case 'IN_REVIEW':
                        return (
                            <Tooltip
                                title={item.kind === 'SICK_LEAVE' ? 'Krankenstand aufgefasst' : 'Auswertung wird entschieden'}
                            >
                                <AssignmentInd />
                            </Tooltip>
                        );
                    case 'APPROVED':
                        return <Tooltip title="Genehmigt"><AssignmentTurnedIn color="success" /></Tooltip>;
                    case 'CANCELED':
                        return <Tooltip title="Storniert"><AssignmentReturn color="warning" /></Tooltip>;
                    case 'REJECTED':
                        return <Tooltip title="Abgelehnt"><AssignmentLate color="error" /></Tooltip>;
                    case 'OPEN':
                    default:
                        return <Tooltip title="Offen"><Assignment color="warning" /></Tooltip>;
                    }
                },
            },
        ],
        boxProps: {style: {width: '25px', marginLeft: '1rem'}},
    }, {
        itemConfigurations: [
            {
                title: 'Vorname',
                renderItem: (item) => <Box marginLeft="0.5rem">{item.user.contactFirstName}</Box>,
            }, {
                title: 'Nachname',
                renderItem: (item) => <Box marginLeft="0.5rem">{item.user.contactLastName}</Box>,
            },
        ],
        boxProps: {style: {width: '80px'}},
    }, {
        itemConfigurations: [
            {
                title: 'Start',
                renderItem: (item) => {
                    const startDateTimeDate = moment(item.startDateTime).tz(tz);
                    const endDateTimeDate = moment(item.endDateTime).tz(tz);
                    const fullDays = startDateTimeDate.isSame(startDateTimeDate.clone().startOf('day'))
&& (!item.endDateTime || endDateTimeDate.isSame(endDateTimeDate.clone().endOf('day')));
                    if (fullDays) {
                        return (
                            <Box display="flex" flexDirection="column">
                                <Box display="flex">
                                    <FirstPageOutlined color="info" />
                                    <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDateTime, 'dd.MM.yyyy')}</Typography>

                                </Box>
                                <Box display="flex">
                                    <LastPageOutlined color="secondary" />
                                    <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.endDateTime, 'dd.MM.yyyy')}</Typography>

                                </Box>
                            </Box>
                        );
                    }
                    return (
                        <Box display="flex" flexDirection="column">
                            <Box display="flex">
                                <FirstPageOutlined color="info" />
                                <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDateTime)}</Typography>

                            </Box>
                            <Box display="flex">
                                <LastPageOutlined color="secondary" />
                                <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.endDateTime)}</Typography>

                            </Box>
                        </Box>
                    );
                },
            },
        ],
        boxProps: {flex: 2, flexBasis: '64px'},
    }, {
        itemConfigurations: [
            {
                title: 'Art',
                renderItem: (item) => {
                    switch (item.kind) {
                    case 'SICKLEAVE':
                        return (
                            <Tooltip
                                title="Krankenstand"
                            >
                                <Sick />
                            </Tooltip>
                        );
                    case 'VACATION':
                        return <Tooltip title="Urlaub"><Houseboat /></Tooltip>;
                    case 'ABSENCE':
                        return <Tooltip title="Abwesend"><Schedule /></Tooltip>;
                    case 'SPECIAL_LEAVE':
                        return <Tooltip title="Sonderurlaub"><HolidayVillage /></Tooltip>;
                    case 'COMPENSATION':
                    default:
                        return <Tooltip title="Zeitausgleich"><EventAvailable /></Tooltip>;
                    }
                },
            },
        ],
        boxProps: {flex: 1, flexBasis: '25px'},
    },
];

/**
 * Formular to display a list of working tim absences
 * @returns {React.ReactElement} the component
 */
function WorkingTimeAbsenceLogListFormular() {
    const relaySubject$ = useMemo(() => new Subject(), []);
    const lineManagedRecursive = useSubscribe(relaySubject$, useCallback((state) => state.lineManagedRecursive, []), false);
    const lineManaged = useSubscribe(relaySubject$, useCallback((state) => state.lineManaged, []), false);
    const kindSelected = useSubscribe(relaySubject$, useCallback((state) => Boolean(state.kind), []), false);
    const statusSelected = useSubscribe(relaySubject$, useCallback((state) => Boolean(state.status), []), false);

    return (
        <ListData loadConfig={loadConfig} deleteConfig={deleteConfig}>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider relaySubject$={relaySubject$} id="absence-simple-filter" messageKey={messageKey} initialFilters={{lineManaged: false}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}><FilterElementAutocomplete path="status" label="Status" dataSchema={statusDataSchema} /></Grid>
                    <Grid item xs={6}><FilterElementAutocomplete path="kind" label="Art" dataSchema={absenceKindDataSchema} /></Grid>
                    {useCanAccess('LineManager') && (
                        <>
                            <Grid item xs={6}>
                                <FilterElementCheckmark
                                    path="lineManaged"
                                    label="Einträge von Mitarbeitern"
                                    disabled={lineManagedRecursive}
                                    checked={lineManagedRecursive}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FilterElementCheckmark
                                    path="lineManagedRecursive"
                                    label="Einträge von Mitarbeitern (ges. Linie)"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <FilterElementText path="comment" label="Kommentar durchsuchen" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {({deleteItem}) => {
                    // Filtering out columns that are all the same according to filters
                    let columns = schemaColumns;
                    if (statusSelected) {
                        columns = _.without(columns, schemaColumns[0]);
                    }
                    if (!lineManaged && !lineManagedRecursive) {
                        columns = _.without(columns, schemaColumns[1]);
                    }
                    if (kindSelected) {
                        columns = _.without(columns, schemaColumns[3]);
                    }

                    /** @type {import('components/Form/Listing').ListingSchema} */
                    const schema = {
                        routeId: 'timebuddy_workingtime_auto_absence_log_route',
                        renderGroupRow: (items, index, renderRow) => (
                            <Box key={index}>
                                {_.map(items, (item, i) => {
                                    const canceled = item.status === 'CANCELED';
                                    return (
                                        <Box
                                            key={i}
                                            // Selector colors in other icons, but not buttons
                                            {...canceled ? {fontStyle: 'italic', sx: {'p,svg:not(button>svg)': {color: 'gray'}}} : {}}
                                        >
                                            {renderRow(item, i)}
                                        </Box>
                                    );
                                })}
                            </Box>
                        ),
                        prepareData: (items) => items.map((item) => [item]),
                        routeParams: ({id, kind}) => ({id, kind: kindRouteSegmentPerKind[kind]}),
                        columns,
                        actions: [
                            {
                                icon: <Delete />,
                                safety: true,
                                isVisible: (item) => item.grants?.deletable ?? false,
                                callBack: async (item) => deleteItem({item, messageKey}),
                                buttonProps: {color: 'info'},
                            },
                            {
                                icon: <Edit />,
                                routeId: 'timebuddy_workingtime_auto_absence_log_route',
                                routeParams: (item) => ({id: item.id, kind: kindRouteSegmentPerKind[item.kind]}),
                            },
                        ],
                    };
                    return <Listing schema={schema} />;
                }}
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkingTimeAbsenceLogListFormular};
