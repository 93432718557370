import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkingTimeModelsListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeModel/WorkingTimeModelListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows list of models
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The WorkingTimeModelListPage component
 */
function WorkingTimeModelsListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Arbeitszeitmodell</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <WorkingTimeModelsListFormular />
            </div>
        </LayoutContainer>
    );
}
export {WorkingTimeModelsListPage};
