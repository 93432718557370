import _ from 'lodash';

/**
 * Takes an address object and returns a formatted string of the address information.
 * @param {import("./helper").AddressData} data the data of the entity to generate the address string from
 * @returns {string} the formatted address text
 */
export const getFullAddressInformation = (data) => {
    let address = data.address ? `${data.address}, ` : '';
    if (data.countryCode) {
        address = _.join([address, `${data.countryCode}`], ', ');
    }
    if (address && data.zip) {
        address += `${data.countryCode ? '-' : ''}${data.zip}`;
    }
    if (data.city) {
        address = _.join([address, `${data.city}`], ', ');
    }
    return address;
};
