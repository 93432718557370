import React, {useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {LockOutlined} from '@mui/icons-material';

/**
 * @param {string} tabId the id of the tab
 * @param {object} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (tabId, props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        actionButtonProps={{portalAnchorSelector: `#DriveBuddyPermissionsaction-button-frame-${tabId}-actions`}}
        // entityChooserProps={{showCostsWarning: true}}
        {...props}
    />
);

/**
 * This page shows a create and update form for DriveBuddy object permissions
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The DriveBuddyPermissionsPage component.
 */
function DrivebuddyPermissionsPage() {
    const [currentTab, setCurrentTab] = useState(0);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: 'DriveBuddyPermissions',
            tabs: [{
                id: 'vehicle',
                label: 'Fahrzeug',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('vehicle', {entityTypeId: 'Objecttype#Vehicle', context: 'DriveBuddyVehiclePermissions'}),
                formWrapperId: 'DriveBuddyVehiclePermissions',
            }, {
                id: 'drivingrecord',
                label: 'Fahrt',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('drivingrecord', {entityTypeId: 'Objecttype#DrivingRecord', context: 'DriveBuddyDrivingRecordPermissions'}),
                formWrapperId: 'DriveBuddyDrivingRecordPermissions',
            }, {
                id: 'vehicleprotocolentry',
                label: 'Protokoll Eintrag',
                icon: <LockOutlined />,
                getChildren: () => getPermissionFormular('vehicleprotocolentry', {entityTypeId: 'Objecttype#VehicleProtocolEntry', context: 'DriveBuddyVehicleProtocolEntryPermissions'}),
                formWrapperId: 'DriveBuddyVehicleProtocolEntryPermissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [currentTab, setCurrentTab, getPermissionFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {DrivebuddyPermissionsPage};
