/* eslint-disable jsdoc/valid-types */
import {
    DriveEta, AddCircleOutline, DashboardOutlined,
    SignpostOutlined, DriveEtaOutlined,
} from '@mui/icons-material';
import {VehiclesListPage} from 'applications/drivebuddy/settings/pages/vehicle/VehiclesListPage';
import {DrivingRecordPage} from 'applications/drivebuddy/modules/logbook/pages/drivingrecord/DrivingRecordPage';
import {DrivingRecordsListPage} from 'applications/drivebuddy/modules/logbook/pages/drivingrecord/DrivingRecordsListPage';
import {VehiclePage} from 'applications/drivebuddy/settings/pages/vehicle/VehiclePage';
import {DriveBuddyDashboard} from 'applications/drivebuddy/pages/DriveBuddyDashboard';
import {DriveBuddyTileDashboard} from 'applications/drivebuddy/pages/DriveBuddyTileDashboard';
import {LogBookDashboard} from 'applications/drivebuddy/modules/logbook/pages/LogBookDashboard';
import {LogBookTileDashboard} from 'applications/drivebuddy/modules/logbook/pages/LogBookTileDashboard';
import {GuideDrivingRecordPage} from 'applications/drivebuddy/pages/quickguides/GuideDrivingRecordPage';
import {GuideVehicleProtocolEntryPage} from 'applications/drivebuddy/pages/quickguides/GuideVehicleProtocolEntryPage';
import {baseLinkSchemaOptions} from 'applications/configs';

/**
 * #### When making changes to the visible menus, the equivalent change should also be made to the menu test
 * @type {import('applications/configuration').ApplicationConfiguration}
 */
const applicationConfiguration = {
    id: 'drivebuddy',
    tileDashboard: <DriveBuddyTileDashboard />,
    accessConditions: ['Logbook'],
    name: 'DriveBuddy',
    order: 2,
    description: 'der digitale Fuhrparkassistent',
    menu: {
        id: 'drivebuddy_menu',
        icon: <DriveEta />,
        label: 'Drive',
        routeId: 'drivebuddy_drive_logbook_dashboard_route',
    },
    route: {
        id: 'drivebuddy_route',
        path: 'drive',
        routes: [
            {
                element: <DriveBuddyDashboard />,
            },
        ],
    },
    modules: [
        {
            id: 'drivebuddy_drive_logbook',
            name: 'Fahrtenbuch',
            tileDashboard: <LogBookTileDashboard />,
            defaultRouteId: 'drivebuddy_drive_logbook_dashboard_route',
            accessConditions: ['Logbook'],
            menus: [
                {
                    id: 'drivebuddy_drive_logbook_dashboard_menu',
                    icon: <DashboardOutlined />,
                    label: 'Dashboard',
                    routeId: 'drivebuddy_drive_logbook_dashboard_route',
                },
                {
                    id: 'drivebuddy_drive_logbook_drivingrecords_menu',
                    icon: <SignpostOutlined />,
                    label: 'Fahrten',
                    routeId: 'drivebuddy_drive_logbook_drivingrecords_route',
                    showConditions: ['createDrivingRecord'],
                    enableConditions: ['readDrivingRecord'],
                    menus: [
                        {
                            id: 'drivebuddy_drive_logbook_drivingrecord_menu',
                            icon: <AddCircleOutline />,
                            label: 'Fahrt protokollieren',
                            routeId: 'drivebuddy_drive_logbook_drivingrecord_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createDrivingRecord'],
                        },
                    ],
                },
            ],
            routes: [
                {
                    id: 'drivebuddy_drive_logbook_route',
                    path: 'logbook',
                    routes: [
                        {
                            id: 'drivebuddy_drive_logbook_dashboard_route',
                            path: 'dashboard',
                            element: <LogBookDashboard />,
                        },
                        {
                            id: 'drivebuddy_drive_logbook_drivingrecords_route',
                            path: 'drivingrecord',
                            accessConditions: ['readDrivingRecord'],
                            routes: [
                                {
                                    element: <DrivingRecordsListPage />,
                                },
                                {
                                    id: 'drivebuddy_drive_logbook_drivingrecord_route',
                                    path: ':id',
                                    element: <DrivingRecordPage />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 'drivebuddy_drive_settings',
            name: 'Einstellungen',
            menus: [
                {
                    id: 'drivebuddy_drive_settings_vehicles_menu',
                    icon: <DriveEtaOutlined />,
                    label: 'Fahrzeuge',
                    routeId: 'drivebuddy_drive_settings_vehicles_route',
                    showConditions: ['createVehicle'],
                    enableConditions: ['readVehicle'],
                    menus: [
                        {
                            id: 'drivebuddy_drive_settings_vehicle_menu',
                            icon: <AddCircleOutline />,
                            label: 'Fahrzeug anlegen',
                            routeId: 'drivebuddy_drive_settings_vehicle_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createVehicle'],
                        },
                    ],
                },

            ],
            routes: [
                {
                    id: 'drivebuddy_drive_settings_route',
                    path: 'settings',
                    routes: [
                        {
                            id: 'drivebuddy_drive_settings_vehicles_route',
                            path: 'vehicle',
                            accessConditions: ['readVehicle'],
                            routes: [
                                {
                                    element: <VehiclesListPage />,
                                },
                                {
                                    id: 'drivebuddy_drive_settings_vehicle_route',
                                    path: ':id',
                                    element: <VehiclePage />,
                                    routes: [
                                        {
                                            id: 'drivebuddy_drive_settings_vehicle_protocolEntry_route',
                                            path: ':protocolEntryId',
                                            element: <VehiclePage />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    guides: [
        {
            id: 'driveBuddy_logbook_drivingRecord',
            title: 'Fahrt',
            content: <GuideDrivingRecordPage />,
        }, {
            id: 'driveBuddy_vehicle_protocolEntry',
            title: 'Fahrzeugprotokoll',
            content: <GuideVehicleProtocolEntryPage />,
        },
    ],
};

/**
 * @typedef {import('./types').PermissionGroupDriveBuddy} PermissionGroupDriveBuddy
 * @typedef {import('applications/beyondbuddy/types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').OperationName} OperationName
 * @typedef {import('./types').UnmanagedObjectDriveBuddy} UnmanagedObjectDriveBuddy
 * @typedef {`${OperationName}${UnmanagedObjectDriveBuddy}`} UnmanagedOperation
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('applications/configuration').ManagedObject} ManagedObject
 */

/** @type {Record<PermissionGroupDriveBuddy, Permission[]>} */
const permissionGroupDefinition = {
    extendedRead: [
        'readDrivingRecord', 'readVehicleProtocolEntry',
    ],
    useVehicleGroup: ['read', 'createDrivingRecord', 'readVehicleProtocolEntry', 'createVehicleProtocolEntry', 'updateVehicleProtocolEntry'],
    administrateVehicleGroup:
        [
            'read', 'update', 'delete',
            'createDrivingRecord', 'readDrivingRecord', 'updateDrivingRecord', 'deleteDrivingRecord',
            'createVehicleProtocolEntry', 'readVehicleProtocolEntry', 'updateVehicleProtocolEntry', 'deleteVehicleProtocolEntry',
            'readPermission', 'updatePermission',
        ],
};

// /** @type {Record<import('./types').ManagedObjectDriveBuddy, string>} */
// const entityLabels = {
//     Vehicle: 'Fahrzeug',
// };

/** @type {import('applications/configuration').ManagedObject[]} */
const managedEntityKeys = ['Vehicle'];

/** @type {Record<UnmanagedOperation|Exclude<PermissionGroupDriveBuddy, PermissionGroupBeyondBuddy>, string>} */
const permissionLabels = {
    administrateVehicleGroup: 'Verwalten',
    useVehicleGroup: 'Verwenden',
    createDrivingRecord: 'Fahrten anlegen',
    createVehicleProtocolEntry: 'Protokolleinträge anlegen',
    deleteDrivingRecord: 'Fahrten löschen',
    deleteVehicleProtocolEntry: 'Protokolleinträge löschen',
    readDrivingRecord: 'Fahrten lesen',
    readVehicleProtocolEntry: 'Protokolleinträge lesen',
    updateDrivingRecord: 'Fahrten bearbeiten',
    updateVehicleProtocolEntry: 'Protokolleinträge bearbeiten',
    extendedRead: 'Erweitertes Lesen', // also defined in other applications like driveBuddy
};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    Vehicle: baseLinkSchemaOptions(isIncoming),
});

export {
    applicationConfiguration,
    permissionGroupDefinition as permissionGroupDefinitionDriveBuddy,
    // entityLabels as entityLabelsDriveBuddy,
    permissionLabels as permissionLabelsDriveBuddy,
    managedEntityKeys as managedEntityKeysDriveBuddy,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsDriveBuddy,
};
