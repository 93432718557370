import {
    Alert, Box, Tab, Tabs,
} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import React, {useEffect, useState} from 'react';
import {WorkingTimeLogListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeWorkLogListFormular';
import {WorkingTimeLogCalendarFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogCalendarFormular';
import {TabPanel} from 'assets/theme/layout/TabPanel';
import {useGlobalState} from 'hooks/useGlobalState';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 *
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeLogListPage() {
    const {setGlobal, getGlobal} = useGlobalState();
    const [currentTab, setCurrentTab] = useState(
        getGlobal('WorkingTimeLog-view-preference') || 0,
    );
    useEffect(() => {
        setGlobal('WorkingTimeLog-view-preference', currentTab, true);
    }, [currentTab]);
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Arbeitszeiten</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Alert severity="info">PRO-TIPP: Zeitgerechtes Buchen spart Dir wertvolle Zeit! Was Du heute kannst besorgen...</Alert>
            <div style={{marginTop: '1rem'}}>
                <Tabs value={currentTab} onChange={(evt, v) => setCurrentTab(v)}>
                    <Tab
                        id="workingtime_log_calendar"
                        aria-controls="workingtime_log_calendar_tab"
                        label="Kalender"
                    />
                    <Tab
                        id="workingtime_log_list"
                        aria-controls="workingtime_log_list_tab"
                        label="Liste"
                    />
                </Tabs>
                <TabPanel
                    id="workingtime_log_calendar_tab"
                    tabId="workingtime_log_calendar"
                    active={currentTab === 0}
                >
                    <WorkingTimeLogCalendarFormular visible={currentTab === 0} />
                </TabPanel>
                <TabPanel
                    id="workingtime_log_list_tab"
                    tabId="workingtime_log_list"
                    active={currentTab === 1}
                >
                    <WorkingTimeLogListFormular visible={currentTab === 1} />
                </TabPanel>

            </div>
        </LayoutContainer>
    );
}

export {WorkingTimeLogListPage};
