/* eslint-disable no-restricted-imports */
import {FileInformationFragment} from '../fragments';

/**
 * getTenant
 * @property {string} id - tenant id.
 * @returns {object} The requested tenant.
 */
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      contactFirstName
      contactLastName
      contactEMailAddress
      technicalContactEMailAddress
      companyName
      companyRegisterNumber
      companyTaxIdentificationId
      imageKey
      image {
        ...FileInformation
      }
      identityProviders {
        type
        metadataURL
      }
      userPoolId
      appClientId
      userPoolDomain
      grants {
        updatable
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * getTenantAuthInfo
 * @property {string} name - short name of the tenant to get info for
 * @returns {object} the requested auth info
 */
export const getTenantAuthInfo = /* GraphQL */ `
  query GetTenantAuthInfo($name: String!) {
    getTenantByName(name: $name) {
      id
      userPoolId
      appClientId
      companyName
      contactEMailAddress
      technicalContactEMailAddress
      userPoolDomain
      identityProviders {
        type
      }
      image {
        ...FileInformation
      }
    }
  }
  ${FileInformationFragment}
`;
