import React from 'react';
import {Box, Typography} from '@mui/material';

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideVehicleProtocolEntryPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Fahrzeugprotokoll</Typography>
            <hr />
            <Box>
                Ein Fahrzeugprotokoll ist eine Art von Logbuch, das verwendet wird, um verschiedene Ereignisse im Zusammenhang mit einem Fahrzeug zu protokollieren.
                Diese Ereignisse können alles umfassen, von Wartungsarbeiten und Reparaturen bis hin zu Unfällen und Tankvorgängen.
                <br />
                <br />
                Durch das Aufzeichnen dieser Ereignisse können Fahrzeughalter und -manager ein genaues Protokoll über die Nutzung des Fahrzeugs führen und wichtige
                Informationen wie Kilometerstand, Kraftstoffverbrauch, Wartungsintervalle und Reparaturhistorie verfolgen. Das Protokoll kann auch zur Vorlage bei
                Versicherungen oder Behörden genutzt werden. Beliebige Anhänge wie Rechnungen, Belege oder Bilder können in das Protokoll hochgeladen werden, um
                eine vollständige Dokumentation zu gewährleisten.
            </Box>
        </>
    );
}
export {GuideVehicleProtocolEntryPage};
