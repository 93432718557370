import React from 'react';
import {Box} from '@mui/material';

import classes from 'components/Form/FormHeadContainer.module.scss';

/**
 * This page shows the form header with two possible areas.
 * The two areas stand side by side on desktop, but over and under on mobile.
 * The first area grows and consumes the available space.
 * The second area is considered for icons.
 * @param {object} props - the properties
 * @param {React.ReactNode} props.children - the two children
 * @returns {React.ReactNode} The TabFormsContainer component.
 */
function FormHeadContainer({children}) {
    const [firstChild, secondChild] = React.Children.toArray(children).slice(0, 2);

    return (
        <Box data-test="form-head-container" className={classes.containerWrap}>
            {firstChild && React.isValidElement(firstChild) && React.cloneElement(firstChild, {className: classes.firstContainer})}
            {secondChild && React.isValidElement(secondChild) && React.cloneElement(secondChild, {className: classes.iconsContainer})}
        </Box>
    );
}
export {FormHeadContainer};
