import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Dashboards} from 'components/Dashboard/Dashboards';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import {
    Alert, Divider, List, Typography,
} from '@mui/material';
import {DashboardContainer} from 'components/Dashboard/DashboardContainer';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';

/**
 * This page shows a dashboard for all applications
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function BeyondBuddyDashboard() {
    const generalGuides = [
        {id: 'beyondbuddy_general_layout', label: 'Allgemeine Informationen zum Layout der Anwendung'},
        {id: 'beyondbuddy_general_dashboard', label: 'Welche Informationen bekommt man auf den Dashboards?'},
    ];
    const organizationGuides = [
        {id: 'beyondbuddy_general_organization', label: 'Abbilden der Unternehmensstruktur in der Aufbauorganisation'},
        {id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'},
        {id: 'beyondbuddy_general_permissionChooser', label: 'Auswahl von Objekten im Berechtigungssystem'},
    ];

    return (
        <LayoutContainer>
            <hr />
            <Alert severity="info">
                <i>&quot;Aller Anfang ist schwer&quot; - </i>
                Das muss nicht sein! Nutze unsere
                <QuickGuideLink id="beyondbuddy_general_layout"> online Hilfen </QuickGuideLink>
                um einen leichteren Einstieg zu erlangen
            </Alert>
            <hr style={{marginBottom: '2rem'}} />

            <Dashboards applicationId="beyondbuddy" />

            <DashboardContainer head={<Typography variant="h2">Hilfe & Dokumentation</Typography>}>
                <List dense style={{width: '100%'}}>
                    {generalGuides.map(createQuickGuideListItem)}
                    <Divider />
                    {organizationGuides.map(createQuickGuideListItem)}
                </List>
            </DashboardContainer>
        </LayoutContainer>
    );
}
export {BeyondBuddyDashboard};
