/**
 * createGroup
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the group.
 * @property {string} managerUserId - the id of user that manages the group.
 * @returns {object} The newly created group.
 */
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $tenantId: ID!
    $name: String!
    ) {
    createGroup(
      tenantId: $tenantId,
      name: $name, 
    ) {
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * updateGroup
 * @property {string} id - ID of the group.
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the group.
 * @returns {object} The updated group.
 */
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $id: ID!
    $name: String
  ) {
    updateGroup(
      id: $id, 
      name: $name,
    ) { 
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

export const updateGroupName = /* GraphQL */ `
  mutation UpdateGroupName(
    $id: ID!
    $name: String
  ) {
    updateGroupName: updateGroup(
      id: $id, 
      name: $name,
    ) { 
      id
      name
    }
  }
`;

/**
 * deleteGroup
 * @property {string} id - ID of the group.
 * @returns {object} The deleted group.
 */
export const deleteGroup = /* GraphQL */ `
 mutation DeleteGroup(
     $id: ID!
   ) {
   deleteGroup(
     id: $id,
     ) {
      id
   }
 }
`;
