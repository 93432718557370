import React from 'react';
import {
    Button, SwipeableDrawer, Toolbar,
} from '@mui/material';
import {Close} from '@mui/icons-material';
import _ from 'lodash';

import {FeedbackPage} from 'applications/beyondbuddy/pages/feedback/FeedbackPage';
import {useGlobalState} from 'hooks/useGlobalState';

/**
 * Feedback drawer - to send feedback
 * @returns {React.ReactElement} - the drawer
 */
function Feedback() {
    const {getGlobal, setGlobal} = useGlobalState();

    const open = getGlobal('showFeedback') ?? false;

    return (
        <SwipeableDrawer
            // No swipe to open, since no specific guide would be selected + there is multiple drawers
            disableSwipeToOpen
            onOpen={_.noop}
            variant="temporary"
            anchor="right"
            open={open}
            onClose={() => setGlobal('showFeedback', false)}
            data-test="Feedback_drawer"
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    boxShadow: 2,
                    paddingX: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    maxWidth: '40rem',
                },

            }}
        >
            <Toolbar variant="dense" style={{width: '100%', justifyContent: 'flex-end'}}>
                <Button data-test="closeFeedback" endIcon={<Close />} onClick={() => setGlobal('showFeedback', false)}>Schließen</Button>
            </Toolbar>

            <FeedbackPage open={open} closeFeedback={() => setGlobal('showFeedback', false)} />
        </SwipeableDrawer>
    );
}

export {Feedback};
