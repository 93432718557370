import React, {useEffect, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';

import {Box, LinearProgress, Typography} from '@mui/material';
import {useGlobalState} from 'hooks/useGlobalState';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listEvacuations} from 'graphql/beyondBuddy/Evacuation/queries';
import {Check, LoopOutlined} from '@mui/icons-material';
import {CancelException} from 'hooks/useCancellablePromise';
import _ from 'lodash';
import {useRouteNavigate} from 'hooks/useRouteNavigation';
import {LoadingButton} from '@mui/lab';
import {createEvacuation} from 'graphql/beyondBuddy/Evacuation/mutations';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * Shows a Typography component that indicates a loading state
 * @param {object} props - props of the component
 * @param {boolean} props.loading - indicates the loading state
 * @param {boolean} [props.disabled] - indicates the disabled state
 * @param {string} props.textLoading - text to be shown when loading is true
 * @param {string} props.textLoaded - text to be shown when loading is false
 * @param {import('@mui/material').TypographyProps} [props.typographyProps] - props for the typography component
 * @param {import('@mui/material').IconProps} [props.iconProps] - props for the icon that is being displayed
 * @returns {React.ReactElement} the LoadingTypography
 */
function LoadingTypography({
    loading, disabled, textLoading, textLoaded, typographyProps, iconProps,
}) {
    const disabledProps = disabled ? {
        color: 'gray',
        fontWeight: 'inherit',
        fontStyle: 'italic',
    } : {};
    return (
        <Typography
            variant="h1"
            display="flex"
            gap={2}
            marginLeft="2rem"
            {...typographyProps}
            {...disabledProps}
        >
            {loading && (
                <LoopOutlined
                    // @ts-ignore
                    color="warning"
                    {...iconProps}
                    {...disabledProps}
                />
            )}
            {!loading && (
                <Check
                    // @ts-ignore
                    color="success"
                    {...iconProps}
                    {...disabledProps}
                />
            )}
            {` ${loading ? textLoading : textLoaded}`}
        </Typography>
    );
}

/**
 * This page helps a user find or start a Evacuation.
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The EvacuationFindPage.
 */
function EvacuationFindPage() {
    // getting the URL parameters
    const {getGlobal} = useGlobalState();
    const {listItems, editItem} = AWSAppSyncProvider();
    const navigate = useRouteNavigate();

    const userId = getGlobal('userId');
    const tenantId = getGlobal('tenantId');

    const [foundEvacuation, setFoundEvacuation] = useState(null);
    const [error, setError] = useState(null);
    const [creatingNew, setCreatingNew] = useState(false);
    const [newEvacuation, setNewEvacuation] = useState(null);

    const progress = foundEvacuation !== null
        ? 100
        : 0;

    const canCreateEvakuation = useCanAccess('createEvacuation');
    // console.log('Prog', progress);

    // looking for a valid evacuation
    useEffect(() => {
        if (canCreateEvakuation) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            listItems(listEvacuations, {
                tenantId,
                filter: {
                    createdAt: {gte: yesterday.toISOString()},
                    completed: false,
                },
            }).then((evacuations) => {
                if (evacuations.length) {
                    // console.log('evacs: ', evacuations);
                    setFoundEvacuation(evacuations[0]);
                } else {
                    setFoundEvacuation(false);
                }
            }).catch((err) => {
                if (err instanceof CancelException) {
                    return;
                }
                setError(err);
            });
        }
        if (canCreateEvakuation === false) {
            setError('Sie haben nicht die erforderliche Berechtigung');
        }
    }, [canCreateEvakuation, userId, listItems, setFoundEvacuation, setError]);

    // Navigating to the evacuation page;
    useEffect(() => {
        const evacuation = foundEvacuation || newEvacuation;
        if (!evacuation) {
            return undefined;
        }
        const timeout = setTimeout(() => {
            navigate('beyondbuddy_settings_evacuation_route', {id: evacuation.id});
        }, 1500);
        return () => clearTimeout(timeout);
    }, [foundEvacuation || newEvacuation]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Evakuierung</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>

            <LinearProgress
                variant={(foundEvacuation !== null || error) ? 'determinate' : 'indeterminate'}
                value={progress}
            />
            <Box display="flex" flexDirection="column" gap={2} marginTop="2rem">
                <LoadingTypography
                    // disabled={}
                    iconProps={{color: canCreateEvakuation === false ? 'error' : 'inherit'}}
                    loading={_.isNull(canCreateEvakuation)}
                    textLoading="Prüfe Berechtigung ..."
                    textLoaded={canCreateEvakuation ? 'Berechtigung geprüft!' : 'Sie haben nicht die erforderliche Berechtigung'}
                />
                <LoadingTypography
                    disabled={(!canCreateEvakuation || !_.isEmpty(error))}
                    loading={foundEvacuation == null}
                    textLoading="Laufende Evakuierung laden ..."
                    textLoaded={foundEvacuation ? 'Evakuierung geladen. Sie werden in kürze weitergeleitet ...' : 'Es wurde keine laufende Evakuierung gefunden.'}
                />
                <LoadingTypography
                    disabled={foundEvacuation == null}
                    loading={!foundEvacuation}
                    textLoading="Ergebnisse analysieren ..."
                    textLoaded={foundEvacuation ? 'Analyse abgeschlossen' : 'Benutzerentscheidung erforderlich'}
                />
                {Boolean(foundEvacuation !== null && !foundEvacuation)
                && (
                    <Box display="flex" gap={2} marginLeft="5rem" width="max-content">
                        <LoadingButton
                            disabled={creatingNew}
                            loading={creatingNew}
                            variant="contained"
                            onClick={async () => {
                                setCreatingNew(true);
                                try {
                                    const result = await editItem(createEvacuation, {tenantId, userId});
                                    // console.log('Result: ', result);
                                    setNewEvacuation(result);
                                    setFoundEvacuation(result);
                                } finally {
                                    setCreatingNew(false);
                                }
                            }}
                        >
                            Neue Evakuierung
                        </LoadingButton>
                        <LoadingButton
                            disabled={creatingNew}
                            variant="contained"
                            onClick={() => navigate('beyondbuddy_settings_evacuations_route', undefined, undefined)}
                        >
                            Historische Evakuierungen
                        </LoadingButton>
                    </Box>
                )}
                <LoadingTypography
                    disabled={!creatingNew && !newEvacuation}
                    loading={creatingNew}
                    textLoading="Evakuierung wird erstellt ..."
                    textLoaded="Evakuierung erstellt. Sie werden in kürze weitergeleitet…"
                />
            </Box>
        </LayoutContainer>
    );
}
export {EvacuationFindPage};
