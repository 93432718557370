import React, {
    useMemo, useState,
} from 'react';
import _ from 'lodash';
import {FormWrapper} from 'components/Form/FormWrapper';
import {
    Alert,
    Box, Grid,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormReset} from 'components/Form/FormReset';
import {createWorkingTimeAggregateCorrection} from 'graphql/timeBuddy/WorkingTimeAggregateCorrection/mutations';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';

const {schema: validatorSchema} = require('beyond-validators/timeBuddy/WorkingTimeAggregateCorrection');

const initialData = {time: new Date().toISOString()};

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').FormularProps & {type: string, userId: string}} props - props passed to the component
 * @returns {React.ReactElement} The WorkingTimeAggregateCorrectionFormular component.
 */
function WorkingTimeAggregateCorrectionFormular({
    onSave, type, userId, ...rest
}) {
    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}, direct: {userId, type}},
        mutation: createWorkingTimeAggregateCorrection,
        mask: {
            tenantId: true,
            comment: false,
            time: true,
            value: true,
            userId: true,
            type: true,
        },
    }), [userId, type]);

    const [clearId, setClearId] = useState(Symbol('id for clearing'));

    return (
        <ItemData saveConfig={saveConfig} initialData={initialData}>
            <Alert severity="info">
                Der Korrekturwert wird dem Kontostand hinzugefügt oder im Falle von negativen
                werten entfernt
            </Alert>
            <FormWrapper
                {...rest}
                isNewItem
                validatorSchema={{
                    schema: validatorSchema,
                    type: 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                    setClearId(Symbol('changed id for clearing'));
                }}
                messageKey="Create_Correction"
                context={`User#${userId}WorkingTimeAggregateCorrection`}
            >
                <FormReset shouldClear id={clearId} />
                <Box style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
                >
                    <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                        <Grid container spacing={2}>
                            <FormElementInfoChips showReadonly />
                            <Grid item xs={12}>
                                <FormElementTextField label={type === 'VACATION' ? 'Wochen' : 'Stunden'} attribute="value" type="float" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementDateTimePicker label="Zeitpunkt" attribute="time" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementTextField label="Kommentar" attribute="comment" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementLoadingButton />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {WorkingTimeAggregateCorrectionFormular};
