/**
 * listWorkingTimeLogTemplates
 * @property {string} tenantId - tenant id
 * @property {string} nextToken - token to continue a listing
 * @property {object} filter - filter with arguments applicable to workingTimeLogList
 * @returns {object} The working time logs.
 */
export const listWorkingTimeLogTemplates = /* GraphQL */ `
  query ListWorkingTimeLogTemplates(
    $tenantId: ID!
    $nextToken: String
    $filter: WorkingTimeLogTemplateListFilterInput
  ) {
    listWorkingTimeLogTemplates(
    tenantId: $tenantId
    nextToken: $nextToken
    filter: $filter
    ) {
      items {
        id
        name
        validityStart
        validityEnd
        modifiable
        checkoutEnforcement
        workplaceId
        kind
        grants {
          deletable
        }
      }
      nextToken
    }
  }
`;

/**
 * getWorkingTimeLog
 * @property {string} id - working time log id.
 * @returns {object} The working time log
 */
export const getWorkingTimeLogTemplate = /* GraphQL */ `
  query GetWorkingTimeLogTemplate($id: ID!) {
    getWorkingTimeLogTemplate(id: $id) {
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      id
      name
      validityStart
      validityEnd
      modifiable
      checkoutEnforcement
      secret

      comment
      workplaceId
      workplace {
        id
        name
      }
      kind
    }
  }
`;
