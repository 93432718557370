import React from 'react';
import {
    Box, List, ListItem, ListItemIcon, ListItemText, TextField, Typography,
} from '@mui/material';
import {FilterAlt} from '@mui/icons-material';

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideGeneralFilterPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Filtern von Ergebnissen</Typography>
            <hr />
            <Box>
                Viele Auflistungen bieten die Möglichkeit, Datensätzen anhand bestimmter Kriterien zu filtern, um nur diejenigen anzuzeigen, die den Kriterien entsprechen.
                Dabei können verschiedene Filteroptionen genutzt werden, um die Informationen nach verschiedenen Kriterien zu filtern.
                <br />
                <br />
                Sobald die Filterkriterien definiert wurden, aktualisiert sich die Liste automatisch und zeigt nur Datensätze an, die den Kriterien entsprechen.
                Auf diese Weise ermöglicht die Filterfunktion eine schnelle und einfache Navigation durch große Datensätze und erleichtert das Auffinden relevanter Informationen.
                <br />
                <br />
                <TextField label="Suche" />
                <br />
                <br />
                Um die Filterfunktion zu nutzen, gibt es oft ein Haupt-Suchfeld (&quot;Suche&quot;), in das Du den Suchbegriff eingeben kannst.
                Dieser Suchbegriff wird anschließend für unterschiedliche Kriterien verwendet.
                <br />
                <List>
                    <ListItem>
                        <ListItemIcon><FilterAlt /></ListItemIcon>
                        <ListItemText
                            primary="Wenn weitere Filter-Optionen verfügbar sind,
                            kannst Du durch Klicken auf das Filter-Symbol zusätzliche
                            Filter Kriterian ausklappen und spezifischere Suchkriterien eingeben."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><FilterAlt color="primary" /></ListItemIcon>
                        <ListItemText
                            primary="Im Fall, dass weitere Filter definiert sind, wird das Filter-Symbol
                            farblich hervorgehoben, um anzuzeigen, dass weitere Kriterien zur Anwendung kommen."
                        />
                    </ListItem>
                </List>
            </Box>
        </>
    );
}
export {GuideGeneralFilterPage};
