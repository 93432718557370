import React, {} from 'react';
import {Alert, Box} from '@mui/material';
import classes from 'components/Dashboard/dashboardTilesContainer.module.scss';
import {useFindRoute} from 'hooks/useFindRoute';
import _ from 'lodash';
/**
 * This page shows a dashboard for all applications
 * .BeyondBuddy.Pages
 * @param {object} props - props of the dashboard tiles
 * @param {{severity: import('@mui/material').AlertColor, message:string}} [props.notification] - notification message
 * @param {{routeId?: string, tile: React.ReactElement}[]} props.elements - tiles to render when authorized
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function DashboardTilesContainer({notification, elements}) {
    const findRoute = useFindRoute();
    const authorizedElements = elements.filter((element) => element && (element.routeId ? findRoute(element.routeId) : true));

    return (
        <Box className={classes.container}>
            {notification?.message && <Alert severity={notification.severity ?? 'info'}>{notification.message}</Alert>}
            <Box className={classes.tiles}>
                {authorizedElements.length && _.map(authorizedElements, (element, index) => (<Box key={index}>{element.tile}</Box>))}
                {!authorizedElements.length && <>Keine Berechtigung für die anzuzeigenden Elemente</>}
            </Box>
        </Box>
    );
}
export {DashboardTilesContainer};
