const {UUID_REGEX, ISO8601_REGEX} = require('../regex');

const required = true;

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        userId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['format', 'required'],
            },
        ],
        modelId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['format', 'required'],
            },
        ],
        startDate: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['format', 'required'],
            },
        ],
    },
    backend: {
        userId: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        modelId: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        startDate: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
    },
    attributes: {
        userId: {
            required,
            format: UUID_REGEX,
        },
        modelId: {
            required,
            format: UUID_REGEX,
        },
        startDate: {
            required,
            format: ISO8601_REGEX,
        },
    },
};

module.exports = {schema};
