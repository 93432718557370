import React, {useContext, useMemo} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Grid,
} from '@mui/material';

import {FormReset} from 'components/Form/FormReset';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {ItemData, ItemDataContext} from 'components/Form/ItemData';
import {createProtocolEntry, updateProtocolEntry} from 'graphql/beyondBuddy/ProtocolEntry/mutations';
import {getProtocolEntry} from 'graphql/beyondBuddy/ProtocolEntry/queries';
import {Refresh} from '@mui/icons-material';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {userDataSchema} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntrySchema';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/ProtocolEntry');

/** @type {Record<keyof(import('applications/beyondbuddy/types').ProtocolEntryEntity), boolean>} */
const mask = {
    id: true,
    tenantId: true,
    clerkUserId: false,
    context: true,
    entityTypeId: true,
    protocolDate: true,
    subject: true,
    notes: false,
    attachmentKeys: false,
    additionalAttributes: false,
};

/**
 * The protocol entry formular for creating and updating a protocol entry
 * @param {import('applications/configuration').FormularProps & {
 *  actionButtonProps: Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>
 *  additionalAttributesNode?: import('react').ReactNode
 *  entityTypeId: string
 *  context?: string
 * }} props - props passed to the component
 * @returns {React.ReactElement} The ProtocolEntryFormular component.
 */
function ProtocolEntryFormular({
    id, onSave, actionButtonProps, additionalAttributesNode, initialData, entityTypeId, context = 'general', ...rest
}) {
    const isNewItem = id === 'create';
    /** @type {{data: import('applications/beyondbuddy/types').ProtocolEntryEntity, updateValue: import('components/Form/form').TItemDataContext['updateValue']}} */
    const {updateValue} = useContext(ItemDataContext);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {
            global: {tenantId: 'tenantId'},
            direct: {entityTypeId, context},
        },
        ...!isNewItem ? {
            mutation: updateProtocolEntry,
            mask: {
                ..._.omit(mask, 'clerkUserId', 'context', 'entityTypeId'),
                subject: false, // subject mustn't be updated
            },
        } : {
            mutation: createProtocolEntry,
            mask: _.omit(mask, 'id'),
        },
    }), [id, entityTypeId, context, createProtocolEntry, updateProtocolEntry, updateValue]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getProtocolEntry,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id, getProtocolEntry]);

    return (
        <ItemData
            {...(!isNewItem) ? {loadConfig} : {}}
            saveConfig={saveConfig}
            initialData={{
                ...initialData,
            }}
        >
            <FormWrapper
                {...rest}
                isNewItem={isNewItem}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (!isNewItem) ? 'update' : 'create',
                }}
                onSaveCallback={onSave}
                messageKey={(!isNewItem) ? 'ProtocolEntry_Update' : 'ProtocolEntry_Create'}
                context={`ProtocolEntry#${id}Base`}
            >
                <FormReset shouldClear={isNewItem} keepInitial />
                {actionButtonProps && (
                    <FormElementActionButton
                        {...actionButtonProps}
                        reload
                        disabled={isNewItem}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                )}
                <Grid container spacing={2} marginTop="1rem">
                    <Grid item xs={12} xl={4}>
                        <FormElementAutocomplete
                            label="Sachbearbeiter"
                            attribute="clerkUserId"
                            optionReference="clerkUser"
                            dataSchema={userDataSchema}
                            routeId="beyondbuddy_settings_general_user_route"
                            readOnly={!isNewItem}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormElementDateTimePicker label="Datum" attribute="protocolDate" readOnly={!isNewItem} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormElementTextField label="Betreff" attribute="subject" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementTextField label="Anmerkungen" attribute="notes" />
                    </Grid>
                    {additionalAttributesNode}
                    <Grid item xs={12}>
                        <FormElementFilesUpload />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormElementLoadingButton label="Speichern" />
                    </Grid>
                </Grid>
            </FormWrapper>
        </ItemData>
    );
}

export {ProtocolEntryFormular};
