/* eslint-disable max-len */
import React from 'react';
import {
    Autocomplete,
    Box, Button, Checkbox, Divider, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {
    ArrowLeft, ArrowRight, Delete, OpenInNew,
} from '@mui/icons-material';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';
import {UnlockButton} from 'assets/theme/components/UnlockButton/UnlockButton';

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGraveConnectionPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Grabverknüpfungen</Typography>
            <hr />
            <Box>
                <Typography textAlign="justify">
                    Die Verknüpfung von Gräbern zu Mehrfachgräbern bezieht sich auf die Zusammenlegung mehrerer Wahlgräber zu einem Familiengrab desselben Friedhof.
                    Dieser Prozess ermöglicht die Verwaltung und Nutzung der Gräber als eine Einheit. Sobald die Gräber verknüpft sind, kann der Friedhof nicht mehr geändert werden.
                </Typography>
                <br />
                <br />
                <Typography variant="h2" color="primary">Auswahl der Nutzungsberechtigten</Typography>
                <Divider />
                {'Es können ausschließlich Gräber ausgewählt werden auf welche ein lesender Zugriff besteht. '}
                <br />
                <Button startIcon={<OpenInNew />}>Gräber</Button>
                {' öffnet die Auswahlliste der möglichen Gräber des selben Friedhofs (oder ohne Friedhof). Durch Anwählen werden diese in die Liste für das Grab übernommen.'}
                <br />
                <br />
                <Typography variant="h2" color="primary">Konfiguration</Typography>
                <Divider />
                <Typography textAlign="justify">
                    {'Die Familiengrab-Verknüpfung, sowie die Position werden auf dem zu verknüpfenden Grab entsprechend ebenfalls hinterlegt. Sobald eine Position angegeben wurde, kann zu diesem Grab mit '}
                    <ArrowLeft />
                    {' '}
                    <ArrowRight />
                    {' navigiert werden. Es ist darauf zu achten, dass es nicht mehrere Gräber in der selben Richtung gibt. Es ist nur der direkte Nachbar mit der Position anzugeben.'}
                </Typography>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{backgroundColor: 'secondary.main'}}>
                                <TableCell>
                                    <Typography fontWeight="bold">Grab</Typography>
                                </TableCell>
                                <TableCell><Typography fontWeight="bold">Familiengrab</Typography></TableCell>
                                <TableCell><Typography fontWeight="bold">Position</Typography></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell>
                                    <Box style={{display: 'flex', gap: '1rem'}}>
                                        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Typography fontWeight="bold" style={{textDecoration: 'underline'}}>292</Typography>
                                            <Typography paddingLeft="1rem" style={{textDecoration: 'underline'}} fontSize="0.9rem">A/31/16</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell padding="checkbox" align="center"><Checkbox checked /></TableCell>
                                <TableCell align="center"><Autocomplete options={['Links']} value="Links" renderInput={(params) => <TextField {...params} label="Position" />} /></TableCell>
                                <TableCell width="3rem">
                                    <UnlockButton onClick={() => {}}>
                                        <Delete />
                                    </UnlockButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'})}
                </List>
            </Box>
        </>
    );
}
export {GuideGraveConnectionPage};
