const {UUID_REGEX} = require('../regex');

const required = true;

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        name: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        info: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        templateId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['format', 'required'],
            },
        ],
        normalWorkingHours: [
            {
                formType: ['create'],
                actions: ['save'],
                configurations: ['required'],
            },
        ],
        weeklyHours: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        weeklyDays: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        vacationClaimWeeks: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        dailyHours: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
    },
    backend: {
        templateId: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        crossRevId: [
            {
                actions: ['create'],
                configurations: ['format'],
            },
        ],
        info: [
            {
                actions: ['create'],
                configurations: ['type'],
            },
        ],
        name: [
            {
                actions: ['create'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        normalWorkingHours: [
            {
                actions: ['create'],
                configurations: ['required'],
            },
        ],
        weeklyHours: [
            {
                actions: ['create'],
                configurations: ['type'],
            },
        ],
        weeklyDays: [
            {
                actions: ['create'],
                configurations: ['type'],
            },
        ],
        vacationClaimWeeks: [
            {
                actions: ['create'],
                configurations: ['type', 'required'],
            },
        ],
        dailyHours: [
            {
                actions: ['create'],
                configurations: ['type', 'required'],
            },
        ],
    },
    attributes: {
        name: {
            type: 'String',
            length: {lte: 50, gte: 3},
            required,
        },
        templateId: {
            format: UUID_REGEX,
            required,
        },
        crossRevId: {
            format: UUID_REGEX,
        },
        info: {
            type: 'String',
        },
        normalWorkingHours: {
            required: true,
        },
        weeklyHours: {
            type: 'Number',
        },
        weeklyDays: {
            type: 'Number',
        },
        vacationClaimWeeks: {
            type: 'Number',
            required: true,
        },
        dailyHours: {
            type: 'Number',
            required: true,
        },
    },
};

module.exports = {schema};
