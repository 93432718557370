import React, {useCallback, useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {UserFormular} from 'applications/beyondbuddy/settings/forms/user/UserFormular';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';

import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {getUser} from 'graphql/beyondBuddy/User/queries';
import {createUser, updateUser} from 'graphql/beyondBuddy/User/mutations';
import {
    BlurOnOutlined, DescriptionOutlined, LockOutlined, ViewTimelineOutlined,
} from '@mui/icons-material';
import _ from 'lodash';
import {useCanAccess} from 'hooks/useCanAccess';
import {WorkingTimeModelAssignmentsListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeModelAssignment/WorkingTimeModelAssignmentListFormular';
import {WorkingTimeTimeAccountReport} from 'applications/timebuddy/modules/workingtime/forms/workingTimeReports/WorkingTimeTimeAccountReport';

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the formular
 */
const getUserFormular = (props) => <UserFormular {...props} />;

/**
 * @param {object} props the properties
 * @returns {React.ReactElement} the formular
 */
const getWorkingTimeAssignmentFormular = (props) => <WorkingTimeModelAssignmentsListFormular {...props} />;

/**
 * @param {object} props the properties
 * @returns {React.ReactElement} the formular
 */
const getWorkingTimeTimeAccountReport = (props) => <WorkingTimeTimeAccountReport {...props} />;

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @param {boolean} isIncomming indicates the permission direction
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props, isIncomming) => {
    const availablePermissions = createPermissionsObject(organizationEntityKeys, ['read', 'extendedRead', 'updateGroup', 'permissionGroup']);
    const defaultPermissions = createPermissionsObject(organizationEntityKeys, ['read']);

    /** @type {Partial<import('components/Form/FormElements/FormElementEntityLinkPermissions').FormElementEntityLinkPermissionsProps>}  */
    const incommingEntityChooserProps = {
        availablePermissions,
        defaultPermissions,
    };
    /** @type {Partial<import('components/Form/FormElements/FormElementEntityLinkPermissions').FormElementEntityLinkPermissionsProps>}  */
    const outgoingEntityChooserProps = {
        availablePermissions: {
            ...availablePermissions,
            Group: ['member_of', ...availablePermissions.Group],
        },
        defaultPermissions: {
            ...defaultPermissions,
            Group: ['member_of', 'read'],
        },
    };

    return (
        <PermissionFormular
            disabled={props.readonly}
            isIncoming={isIncomming}
            entityTypeId={`User#${props.id}`}
            actionButtonProps={{
                portalAnchorSelector: `#UserForm${props.id}action-button-frame-${isIncomming ? '' : 'outgoing_'}permissions-actions`,
            }}
            entityChooserProps={{
                initialEntityType: 'User',
                ...(isIncomming ? incommingEntityChooserProps : outgoingEntityChooserProps),
                permissionDependencies: {
                    User: readUpdateDeletePermissionTemplate,
                    Group: readUpdateDeletePermissionTemplate,
                    OrganizationalUnit: readUpdateDeletePermissionTemplate,
                    Tenant: readUpdateDeletePermissionTemplate,
                },
            }}
            {...props}
        />
    );
};

const mask = {
    id: true,
    cognito: false,
    microsoft: false,
    active: true,
    tenantId: true,
    contactFirstName: false,
    contactLastName: false,
    contactEMailAddress: true,
    tenantAdministrator: false,
    imageKey: false,
    personalNumber: false,
    image: false,
    lineManagerUserId: false,
    organizationalUnitId: false,
    emailNotifications: false,
    entryDate: false,
    exitDate: false,
    colormode: false,
};

/**
 * @returns {React.ReactElement} The UserPage.
 */
function UserPage() {
    // getting the URL parameters
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    const canAccessWorkingTimeModelAssignments = useCanAccess('WorkingTime', 'readWorkingTimeModelAssignment', 'readWorkingTimeModel');
    const canAccessWorkingTimeLog = useCanAccess('WorkingTime', 'readWorkingTimeLog');

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    const loadConfig = useMemo(() => ({
        query: getUser,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...(!isNewItem) ? {
            mutation: updateUser,
            mask,
        } : {
            mutation: createUser,
            mask: _.omit(mask, 'id'),
        },
    }), [isNewItem]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `UserForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: _.compact([{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getUserFormular({id, onSave}),
                formWrapperId: `User#${id}Base`,
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => !isNewItem && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}, true),
                formWrapperId: `User#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            }, {
                id: 'outgoing_permissions',
                label: 'Ausgehende Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => !isNewItem && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}, false),
                formWrapperId: `User#${id}OutgoingPermissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            canAccessWorkingTimeModelAssignments ? {
                id: 'timemodel_history',
                label: 'Arbeitszeitmodell',
                icon: <DescriptionOutlined />,
                enabledCondition: 'workingTimeModelAssignmentReadable',
                getChildren: () => !isNewItem && getWorkingTimeAssignmentFormular({userId: id}),
            } : undefined,
            canAccessWorkingTimeLog ? {
                id: 'time_accounts',
                label: 'Zeitkonten',
                icon: <ViewTimelineOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants, subGrants) => !isNewItem && getWorkingTimeTimeAccountReport(
                    {userId: id, workingTimeAggregateCorrectable: subGrants.workingTimeAggregateCorrectable ?? false},
                ),
                formWrapperId: `User#${id}WorkingTimeAggregateCorrection`,
            } : undefined]),
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab, canAccessWorkingTimeModelAssignments,
            setCurrentTab, onSave, getUserFormular, getPermissionFormular, getWorkingTimeAssignmentFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {UserPage};
