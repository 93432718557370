// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

export const createGrave = /* GraphQL */ `
  mutation CreateGrave(
      $tenantId: ID!
      $cemeteryId: ID
      $generalNr: String!
      $type: String!
      $kind: String!
      $division: String
      $subDivision: String
      $nr: String
      $lastFuneral: AWSDateTime
      $restPeriod: Int!
      $leaseExpirationDate: AWSDateTime
      $honoraryGrave: Boolean
      $free: Boolean
      $choosable: Boolean
      $units: Int!
      $unitPositions: AWSJSON!
      $notes: String
      $imageKey: String
      $attachmentKeys: AWSJSON
    ) {
    createGrave(
      tenantId: $tenantId
      cemeteryId: $cemeteryId
      generalNr: $generalNr
      type: $type
      kind: $kind
      division: $division
      subDivision: $subDivision
      nr: $nr
      lastFuneral: $lastFuneral
      restPeriod: $restPeriod
      leaseExpirationDate: $leaseExpirationDate
      honoraryGrave: $honoraryGrave
      free: $free
      choosable: $choosable
      units: $units
      unitPositions: $unitPositions
      notes: $notes
      imageKey: $imageKey
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      cemeteryId
      generalNr
      type
      kind
      division
      subDivision
      nr
      lastFuneral
      restPeriod
      leaseExpirationDate
      honoraryGrave
      free
      choosable
      units
      unitPositions
      notes
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

export const updateGrave = /* GraphQL */ `
  mutation UpdateGrave(
      $id: ID!
      $cemeteryId: ID
      $generalNr: String!
      $type: String!
      $kind: String!
      $division: String
      $subDivision: String
      $nr: String
      $lastFuneral: AWSDateTime
      $restPeriod: Int!
      $leaseExpirationDate: AWSDateTime
      $honoraryGrave: Boolean
      $free: Boolean
      $choosable: Boolean
      $units: Int!
      $unitPositions: AWSJSON!
      $notes: String
      $imageKey: String
      $attachmentKeys: AWSJSON
    ) {
    updateGrave(
      id: $id
      cemeteryId: $cemeteryId
      generalNr: $generalNr
      type: $type
      kind: $kind
      division: $division
      subDivision: $subDivision
      nr: $nr
      lastFuneral: $lastFuneral
      restPeriod: $restPeriod
      leaseExpirationDate: $leaseExpirationDate
      honoraryGrave: $honoraryGrave
      free: $free
      choosable: $choosable
      units: $units
      unitPositions: $unitPositions
      notes: $notes
      imageKey: $imageKey
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      cemeteryId
      generalNr
      type
      kind
      division
      subDivision
      nr
      lastFuneral
      restPeriod
      leaseExpirationDate
      honoraryGrave
      free
      choosable
      units
      unitPositions
      notes
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

export const deleteGrave = /* GraphQL */ `
 mutation DeleteGrave(
     $id: ID!
   ) {
   deleteGrave(
     id: $id,
     ) {
      id
   }
 }
`;

export const deleteGraves = /* GraphQL */ `
 mutation DeleteGraves(
     $ids: [ID!]
   ) {
   deleteGraves(
     ids: $ids,
     )
 }
`;
