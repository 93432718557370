/* eslint-disable max-len */
import {Delete, Refresh} from '@mui/icons-material';
import {
    Box, TableCell, Typography, useMediaQuery,
    useTheme,
} from '@mui/material';
import {UnlockButton} from 'assets/theme/components/UnlockButton/UnlockButton';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {FormElementEntityLinkAttributes} from 'components/Form/FormElements/FormElementEntityLinkAttributes';
import {postProcessLinks, preProcessLinks} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormReset} from 'components/Form/FormReset';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {ItemData} from 'components/Form/ItemData';
import {updateLinks} from 'graphql/beyondBuddy/Links/mutations';
import {getLinks} from 'graphql/beyondBuddy/Links/queries';
import _ from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

/**
 * @typedef {import('applications/configuration').ManagedObject} ManagedObject
 * @typedef {"Objecttype" | ManagedObject} SelfType
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').EntityBase} EntityBase
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 */

/**
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').Link} Link
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').Links} Links
 * @typedef {import('react').ReactNode} ReactNode
 * @typedef {import('components/Form/form').ChangeHandler} ChangeHandler
 */

/**
 *
 * @param {any} item - the link
 * @param {import('components/Form/FormElements/FormElementEntityLink').Links} currentLinks - the current links of the entity
 * @param {Record<string, any>} attributes - the attribute(s) to set
 * @param {import('components/Form/form').ChangeHandler} changeHandler - function to update the state
 */
const setLink = (item, currentLinks, attributes, changeHandler) => {
    const entityValueIndex = _.findIndex(currentLinks.links, (link) => link.leftEntityTypeId === `${item.type}#${item.id}`);
    // new entity in the list
    if (entityValueIndex === -1) {
        currentLinks.links.push({leftEntityTypeId: `${item.type}#${item.id}`, attributes});
    } else {
        const entityValue = currentLinks.links[entityValueIndex];
        _.merge(entityValue.attributes, attributes);
    }
    // populate changes
    changeHandler({
        attribute: 'links',
        value: {
            ...currentLinks,
            links: Array.from(currentLinks.links),
        },
        interacted: true,
    });
};

/**
 *
 * @param {any} item - the link
 * @param {import('components/Form/FormElements/FormElementEntityLink').Links} currentLinks - the current links of the entity
 * @param {import('components/Form/form').ChangeHandler} changeHandler - function to update the state
 */
const removeLink = (item, currentLinks, changeHandler) => {
    const entityValueIndex = _.findIndex(currentLinks.links, (link) => link.leftEntityTypeId === `${item.type}#${item.id}`);
    if (entityValueIndex !== -1) {
        // populate changes
        changeHandler({
            attribute: 'links',
            value: {
                ...currentLinks,
                [item.type]: _.filter(currentLinks[item.type], (link) => link.id !== item.id),
                links: _.filter(currentLinks.links, (link, index) => index !== entityValueIndex),
            },
            interacted: true,
        });
    }
};

/**
 * This page shows a formular where one can set attributes for an entity
 * @param {object} props - props for the component
 * @param {import('applications/configuration').EntityTypeId} props.entityTypeId - entityTypeId for which attributes are being set
 * @param {string} props.context - the link context to separate from other links
 * @param {Array<{label: string, align?: 'left'|'center'|'right'}>} props.tableHeadLabels - labels to be rendered in the table head on desktops
 * @param {(attributes: Record<string, any>, locked:boolean, changeHandler: (attributes: Record<string, any>)=>void, item?: object)=>ReactNode} props.getLineControls - function to render the specific controls of the link
 * @param {Partial<import('components/Form/FormElements/FormElementEntityLinkAttributes').FormElementEntityLinkAttributesProps>} [props.entityChooserProps] - props for the entity chooser
 * @param {(links: Partial<Link>[], itemDataExtraData?: object)=>Partial<Link>[]} [props.addOpposite] - function to create the opposite links
 * @param {(links: Partial<Link>[])=>boolean} [props.validate] - function to validate the attributes
 * @param {boolean} [props.disabled] - flag that removes the save button, and sets the entity chooser to disabled
 * @param {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [props.actionButtonProps] - properties for actionButtons
 * @param {import('components/Form/form').ItemLoadConfig} [props.extraDataLoadConfig] - extra data loading configuration
 * @param {(attribute: string, value: any, changeHandler: import('components/Form/form').TFormContext['changeHandler'], formData: object) => void} [props.onChangeCallback] - callback on every change of the form
 * @param {React.ReactNode} [props.children] - the additional components, to be rendered within the Form but before the other Elements
 * @returns {import('react').ReactElement} page component
 */
function AttributesFormular({
    entityTypeId, context, tableHeadLabels,
    getLineControls, entityChooserProps, disabled, addOpposite, validate, actionButtonProps, extraDataLoadConfig,
    onChangeCallback, children,
}) {
    const id = entityTypeId.split('#').at(-1);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [currentId, setCurrentId] = useState(id);

    useEffect(() => {
        // for refresh the form on id change
        setCurrentId(id);
    }, [id]);

    const getTableHead = useCallback(() => {
        if (!isDesktop) {
            return null;
        }
        return (
            <>
                {_.map(tableHeadLabels, (headItem, index) => <TableCell key={index} align={headItem.align ?? 'left'}><Typography fontWeight="bold">{headItem.label}</Typography></TableCell>)}
                <TableCell />
            </>
        );
    }, [isDesktop, tableHeadLabels]);

    const getAttributeLine = useCallback(
        /**
         *
         * @param {any} item - the entity link information
         * @param {import('components/Form/FormElements/FormElementEntityLink').Links} currentLinks - the current link information
         * @param {boolean} locked - indicates, that no changes can be made
         * @param {import('components/Form/form').ChangeHandler} changeHandler - the form change handler
         * @returns {import('react').ReactNode} - returns the attribute line
         */
        (item, currentLinks, locked, changeHandler) => {
            const currentLink = _.find(currentLinks.links, (link) => link.leftEntityTypeId === `${item.type}#${item.id}`);
            const lineControls = getLineControls(
                currentLink?.attributes,
                locked,
                (attributes) => setLink(item, currentLinks, attributes, changeHandler),
                item,
            );
            const unlockRemove = (
                <UnlockButton
                    onClick={() => { removeLink(item, currentLinks, changeHandler); }}
                    data-test="FormElementEntityChooser_link_delete_unlockbutton"
                    disabled={locked}
                >
                    <Delete />
                </UnlockButton>
            );
            if (!isDesktop) {
                return (
                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                        {lineControls}
                        {unlockRemove}
                    </Box>
                );
            }
            return (
                <>
                    {lineControls}
                    <TableCell align="center">
                        {unlockRemove}
                    </TableCell>
                </>
            );
        },
        [disabled, isDesktop, getLineControls, removeLink],
    );

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getLinks,
        variables: {
            direct: {
                isIncoming: true, // attributes are set only for incoming links
                entityTypeId, // : `Objecttype#${type}`,
                context,
            },
        },
        postProcess: (data) => postProcessLinks(data, id),
    }), [getLinks, entityTypeId, id, postProcessLinks]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        mutation: updateLinks,
        preProcess: (data) => {
            // validate the data according to the props fn
            if (validate?.(data?.links?.links) === false) {
                return false;
            }
            return preProcessLinks({
                ...data,
                links: {
                    ...data.links,
                    links: addOpposite?.(data?.links?.links, data?.itemDataExtraData) ?? data?.links?.links,
                },
            });
        },
        postProcess: (data) => postProcessLinks(data, id),
        variables: {
            direct: {
                isIncoming: true, // attributes are set only for incoming links
                entityTypeId, // : `Objecttype#${type}`,
                context,
            },
        },
        mask: {
            isIncoming: false,
            entityTypeId: true,
            links: true,
            context: true,
        },
    }), [updateLinks, entityTypeId, id, preProcessLinks, postProcessLinks, addOpposite]);

    // /**
    //  *
    //  * @param {'text'|'icon'|'contained'} variant the type of the button
    //  * @returns {React.ReactElement} the controls for the formular
    //  */
    // const controls = (variant) => (
    //     <Box display="flex" justifyContent="space-between" alignItems="center">
    //         {/* Span to avoid the actionbutton shifting to the right to take the buttons place */}
    //         <span style={{flex: 1}}>
    //             {!disabled && <FormElementLoadingButton variant={variant} />}
    //         </span>
    //     </Box>
    // );

    return (
        <ItemData
            loadConfig={loadConfig}
            extraDataLoadConfig={extraDataLoadConfig}
            saveConfig={saveConfig}
        >
            <FormWrapper
                messageKey="Links"
                context={`${entityTypeId}${context}`}
                hasDataChanged={(data, initial) => _.isEqual(data?.links, initial?.links)}
                onChangeCallback={onChangeCallback}
            >
                <FormReset shouldClear={entityTypeId.endsWith('#create') || id !== currentId} keepInitial />
                {children}
                <Box sx={{
                    // paddingY: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
                >
                    <span style={{marginRight: '1rem'}}>
                        <FormElementActionButton
                            reload
                            context={FormContext}
                            {...actionButtonProps}
                        >
                            <Refresh />
                        </FormElementActionButton>
                    </span>
                    <FormElementEntityLinkAttributes
                        disabled={disabled}
                        attribute="links"
                        getTableHead={getTableHead}
                        getAttributeLine={getAttributeLine}
                        // @ts-ignore
                        selfType={entityTypeId.split('#').at(0)}
                        linkDirection="left"
                        actionButtonProps={actionButtonProps}
                        {...entityChooserProps}
                    />
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {/* Span to avoid the actionbutton shifting to the right to take the buttons place */}
                        <span style={{flex: 1}}>
                            {!disabled && <FormElementLoadingButton variant="contained" />}
                        </span>
                    </Box>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {
    AttributesFormular,
};
