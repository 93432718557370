/* eslint-disable jsdoc/valid-types */
import {
    AddCircleOutline, DashboardOutlined, AccessTimeFilled, MoreTimeOutlined,
    RuleFolderOutlined, HistoryOutlined, AddOutlined, CalendarMonthOutlined, PlaceOutlined,
    QrCodeOutlined, HomeWork, Sick, Houseboat, EventAvailable, Schedule, EditCalendar, DateRange, Forest, HolidayVillage,
} from '@mui/icons-material';
import {WorkingTimeLogListPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLog/WorkingTimeLogListPage';
import {WorkingTimeWorkLogPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLog/WorkingTimeWorkLogPage';
import {WorkingTimeLogAbsencePage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLog/WorkingTimeAbsenceRequestPage';
import {WorkingTimeLogCheckpointPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLog/WorkingTimeLogCheckpointPage';
import {WorkingTimeTileDashboard} from 'applications/timebuddy/modules/workingtime/pages/WorkingTimeTileDashboard';
import {WorkingTimeDashboard} from 'applications/timebuddy/modules/workingtime/pages/WorkingTimeDashboard';
import {WorkingTimeModelsListPage} from 'applications/timebuddy/modules/workingtime/pages/model/WorkingTimeModelListPage';
import {WorkingTimeModelPage} from 'applications/timebuddy/modules/workingtime/pages/model/WorkingTimeModelPage';
import {GuideWorktimeLogPage} from 'applications/timebuddy/pages/quickguides/GuideWorktimeLogPage';
import {TimePeriodListPage} from 'applications/timebuddy/settings/pages/timeperiod/TimePeriodListPage';
import {TimePeriodPage} from 'applications/timebuddy/settings/pages/timeperiod/TimePeriodPage';
import {WorkplacePage} from 'applications/timebuddy/settings/pages/workplace/WorkplacePage';
import {WorkplaceListPage} from 'applications/timebuddy/settings/pages/workplace/WorkplaceListPage';
import {WorkingTimeLogTemplatePage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLogTemplate/WorkingTimeLogTemplatePage';
import {WorkingTimeLogTemplateListPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLogTemplate/WorkingTimeLogTemplateListPage';
import {WorkingTimeReportsPage} from 'applications/timebuddy/modules/workingtime/pages//workingTimeReports/WorkingTimeReportsPage';
import {WorkingTimeAbsenceListPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeLog/WorkingTimeAbsenceListPage';
import {WorkingTimeSchedulePage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeSchedule/WorkingTimeSchedulePage';
import {WorkingTimeScheduleListPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeSchedule/WorkingTimeScheduleListPage';
import {WorkingTimeHolidayListPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeHoliday/WorkingTimeHolidayListPage';
import {WorkingTimeHolidayPage} from 'applications/timebuddy/modules/workingtime/pages/workingTimeHoliday/WorkingTimeHolidayPage';
import {baseLinkSchemaOptions} from 'applications/configs';
/**
 * #### When making changes to the visible menus, the equivalent change should also be made to the menu test
 * @type {import('applications/configs').ApplicationConfiguration}
 */
const applicationConfiguration = {
    id: 'timebuddy',
    tileDashboard: <WorkingTimeTileDashboard />,
    accessConditions: ['TimeBuddy'],
    name: 'TimeBuddy',
    order: 1,
    description: 'ihre intelligente Zeitaufzeichnung',
    menu: {
        id: 'timebuddy_menu',
        icon: <AccessTimeFilled />,
        label: 'Time',
        routeId: 'timebuddy_workingtime_dashboard_route',
    },
    route: {
        id: 'timebuddy_route',
        path: 'time',
        routes: [
            {
                element: <WorkingTimeDashboard />,
            },
        ],
    },
    modules: [
        {
            id: 'timebuddy_workingtime',
            name: 'Arbeitszeit',
            tileDashboard: <WorkingTimeTileDashboard />,
            accessConditions: ['WorkingTime'],
            menus: [
                {
                    id: 'timebuddy_workingtime_dashboard_menu',
                    icon: <DashboardOutlined />,
                    label: 'Dashboard',
                    routeId: 'timebuddy_workingtime_dashboard_route',
                }, {
                    id: 'timebuddy_workingtime_log_menu',
                    icon: <CalendarMonthOutlined />,
                    label: 'Zeitbuchungen',
                    showConditions: ['createWorkingTimeLog'],
                    enableConditions: ['readWorkingTimeLog'],
                    routeId: 'timebuddy_workingtime_work_logs_route',
                    menus: [
                        {
                            id: 'timebuddy_workingtime_log_create_menu',
                            icon: <MoreTimeOutlined />,
                            label: 'Zeit protokollieren',
                            enableConditions: ['createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_work_log_route',
                            routeParams: {id: 'create'},
                        },
                    ],
                }, {
                    id: 'timebuddy_workingtime_absence_logs_menu',
                    icon: <HomeWork />,
                    label: 'Abwesenheiten',
                    routeId: 'timebuddy_workingtime_absence_logs_route',
                    enableConditions: ['readWorkingTimeLog'],
                    showConditions: ['createWorkingTimeLog'],
                    menus: [
                        {
                            id: 'timebuddy_workingtime_sick_leave_log_menu',
                            icon: <Sick />,
                            label: 'Krankenstand melden',
                            enableConditions: ['createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_sick_leave_log_route',
                            routeParams: {id: 'create'},
                        }, {
                            id: 'timebuddy_workingtime_vacation_log_menu',
                            icon: <Houseboat />,
                            label: 'Urlaub anfragen',
                            enableConditions: ['createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_vacation_log_route',
                            routeParams: {id: 'create'},
                        }, {
                            id: 'timebuddy_workingtime_compensation_log_menu',
                            icon: <EventAvailable />,
                            label: 'Zeitausgleich anfragen',
                            enableConditions: ['createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_compensation_log_route',
                            routeParams: {id: 'create'},
                        }, {
                            id: 'timebuddy_workingtime_special_leave_menu',
                            icon: <HolidayVillage />,
                            label: 'Sonderurlaub anfragen',
                            enableConditions: ['createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_special_leave_route',
                            routeParams: {id: 'create'},
                        }, {
                            id: 'timebuddy_workingtime_absence_log_menu',
                            icon: <Schedule />,
                            label: 'Abwesenheit eintragen',
                            enableConditions: ['LineManager', 'createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_absence_log_route',
                            routeParams: {id: 'create'},
                        },
                    ],
                }, {
                    id: 'timebuddy_workingtime_template_menu',
                    icon: <QrCodeOutlined />,
                    label: 'Vorlagen',
                    showConditions: ['createWorkingTimeLog'],
                    enableConditions: ['readWorkingTimeLog'],
                    routeId: 'timebuddy_workingtime_templates_route',
                    menus: [
                        {
                            id: 'timebuddy_workingtime_template_create_menu',
                            icon: <AddOutlined />,
                            label: 'Vorlage erstellen',
                            enableConditions: ['createWorkingTimeLog'],
                            routeId: 'timebuddy_workingtime_template_route',
                            routeParams: {
                                id: 'create',
                            },
                        },
                    ],
                },
                {
                    id: 'timebuddy_workingtime_workplace_menu',
                    icon: <PlaceOutlined />,
                    label: 'Orte',
                    showConditions: ['createWorkplace'],
                    enableConditions: ['readWorkplace'],
                    routeId: 'timebuddy_workplaces_route',
                    menus: [
                        {
                            id: 'timebuddy_workplace_create_menu',
                            icon: <AddOutlined />,
                            label: 'Ort anlegen',
                            enableConditions: ['createWorkplace'],
                            routeId: 'timebuddy_workplace_route',
                            routeParams: {id: 'create'},
                        },
                    ],
                },
                {
                    id: 'timebuddy_workingtime_timemodel_menu',
                    icon: <RuleFolderOutlined />,
                    label: 'Arbeitszeiten Modell',
                    showConditions: ['createWorkingTimeModel'],
                    enableConditions: ['readWorkingTimeModel'],
                    routeId: 'timebuddy_workingtime_models_route',
                    menus: [
                        {
                            id: 'timebuddy_workingtime_timemodel_create_menu',
                            icon: <AddOutlined />,
                            label: 'Arbeitszeit Modell anlegen',
                            enableConditions: ['createWorkingTimeModel'],
                            routeId: 'timebuddy_workingtime_model_route',
                            routeParams: {id: 'create'},
                        },
                    ],
                },
                {
                    id: 'timebuddy_workingtime_reporting_menu',
                    icon: <RuleFolderOutlined />,
                    label: 'Bericht',
                    enableConditions: ['TenantReader'],
                    routeId: 'timebuddy_workingtime_reporting_route',
                },
                {
                    id: 'timebuddy_workingtime_schedules_menu',
                    icon: <DateRange />,
                    label: 'Dienstplan',
                    showConditions: ['createWorkingTimeSchedule'],
                    enableConditions: ['readWorkingTimeSchedule'],
                    routeId: 'timebuddy_workingtime_schedules_route',
                    menus: [
                        {
                            id: 'timebuddy_workingtime_schedule_menu',
                            icon: <EditCalendar />,
                            label: 'Dienstplan erstellen',
                            enableConditions: ['createWorkingTimeSchedule'],
                            routeParams: {id: 'create'},
                            routeId: 'timebuddy_workingtime_schedule_route',
                        },
                    ],
                },
                {
                    id: 'timebuddy_workingtime_holidays_menu',
                    routeId: 'timebuddy_workingtime_holidays_route',
                    label: 'Feiertag',
                    icon: <Forest />,
                    showConditions: ['createWorkingTimeHoliday'],
                    enableConditions: ['readWorkingTimeHoliday'],
                    menus: [
                        {
                            id: 'timebuddy_workingtime_holiday_menu',
                            routeId: 'timebuddy_workingtime_holiday_route',
                            label: 'Feiertag Hinzufügen',
                            enableConditions: ['createWorkingTimeHoliday'],
                            routeParams: {id: 'create'},
                            icon: <AddCircleOutline />,
                        },
                    ],
                },
            ],
            routes: [
                {
                    id: 'timebuddy_workingtime_route',
                    path: 'workingtime',
                    routes: [
                        {
                            id: 'timebuddy_workingtime_dashboard_route',
                            path: 'dashboard',
                            element: <WorkingTimeDashboard />,
                        },
                        {
                            id: 'timebuddy_workingtime_templates_route',
                            path: 'template',
                            routes: [
                                {
                                    element: <WorkingTimeLogTemplateListPage />,
                                },
                                {
                                    id: 'timebuddy_workingtime_template_route',
                                    path: ':id',
                                    element: <WorkingTimeLogTemplatePage />,
                                },
                            ],
                        },
                        {
                            path: 'log',
                            accessConditions: ['readWorkingTimeLog'],
                            routes: [
                                {
                                    id: 'timebuddy_workingtime_work_logs_route',
                                    path: 'work',
                                    routes: [
                                        {
                                            element: <WorkingTimeLogListPage />,
                                        }, {
                                            id: 'timebuddy_workingtime_work_log_route',
                                            path: ':id',
                                            element: <WorkingTimeWorkLogPage />,
                                        },
                                    ],
                                }, {
                                    id: 'timebuddy_workingtime_absence_logs_route',
                                    path: 'absence',
                                    routes: [
                                        {
                                            element: <WorkingTimeAbsenceListPage />,
                                        }, {
                                            id: 'timebuddy_workingtime_absence_log_route',
                                            path: 'direct/:id',
                                            element: <WorkingTimeLogAbsencePage kind="ABSENCE" />,
                                        }, {
                                            id: 'timebuddy_workingtime_sick_leave_log_route',
                                            path: 'sick_leave/:id',
                                            element: <WorkingTimeLogAbsencePage kind="SICKLEAVE" />,
                                        }, {
                                            id: 'timebuddy_workingtime_vacation_log_route',
                                            path: 'vacation/:id',
                                            element: <WorkingTimeLogAbsencePage kind="VACATION" />,
                                        }, {
                                            id: 'timebuddy_workingtime_compensation_log_route',
                                            path: 'compensation/:id',
                                            element: <WorkingTimeLogAbsencePage kind="COMPENSATION" />,
                                        }, {
                                            id: 'timebuddy_workingtime_special_leave_route',
                                            path: 'special_leave/:id',
                                            element: <WorkingTimeLogAbsencePage kind="SPECIAL_LEAVE" />,
                                        }, {
                                            id: 'timebuddy_workingtime_auto_absence_log_route',
                                            path: ':kind/:id',
                                            // intentionally left without an element, to allow directing to the other paths with
                                            // their respective segments
                                        },
                                    ],
                                }, {
                                    id: 'timebuddy_workingtime_log_checkpoint_route',
                                    path: 'checkpoint/:templateid/:secret',
                                    routes: [
                                        {
                                            element: <WorkingTimeLogCheckpointPage />,
                                        },
                                    ],
                                },
                            ],
                        }, {
                            id: 'timebuddy_workingtime_models_route',
                            path: 'model',
                            routes: [
                                {
                                    element: <WorkingTimeModelsListPage />,
                                }, {
                                    id: 'timebuddy_workingtime_model_route',
                                    path: ':id',
                                    element: <WorkingTimeModelPage />,
                                },
                            ],
                        }, {
                            id: 'timebuddy_workingtime_schedules_route',
                            path: 'schedule',
                            routes: [
                                {
                                    element: <WorkingTimeScheduleListPage />,
                                }, {
                                    id: 'timebuddy_workingtime_schedule_duplicate_route',
                                    path: ':id/duplicate',
                                    element: <WorkingTimeSchedulePage duplicate />,
                                }, {
                                    id: 'timebuddy_workingtime_schedule_route',
                                    path: ':id',
                                    element: <WorkingTimeSchedulePage />,
                                },
                            ],
                        }, {
                            id: 'timebuddy_workplaces_route',
                            path: 'place',
                            routes: [
                                {
                                    element: <WorkplaceListPage />,
                                }, {
                                    id: 'timebuddy_workplace_route',
                                    path: ':id',
                                    element: <WorkplacePage />,
                                },
                            ],
                        }, {
                            id: 'timebuddy_workingtime_reporting_route',
                            path: 'reporting',
                            routes: [
                                {
                                    element: <WorkingTimeReportsPage />,
                                },
                            ],
                        }, {
                            id: 'timebuddy_workingtime_holidays_route',
                            path: 'holiday',
                            routes: [
                                {
                                    element: <WorkingTimeHolidayListPage />,
                                }, {
                                    id: 'timebuddy_workingtime_holiday_route',
                                    path: ':id',
                                    element: <WorkingTimeHolidayPage />,
                                },
                            ],
                        },
                    ],
                },
            ],
        }, {
            id: 'timebuddy_settings',
            name: 'Einstellungen',
            accessConditions: ['WorkingTime'],
            menus: [
                {
                    id: 'timebuddy_time_period_menu',
                    icon: <HistoryOutlined />,
                    label: 'Abrechnungsperiode',
                    routeId: 'timebuddy_time_periods_route',
                    enableConditions: ['readTimePeriod'],
                    showConditions: ['createTimePeriod'],
                    menus: [
                        {
                            id: 'timebuddy_time_period_create_menu',
                            icon: <AddOutlined />,
                            label: 'Periode erstellen',
                            routeId: 'timebuddy_time_period_route',
                            enableConditions: ['createTimePeriod'],
                            routeParams: {id: 'create'},
                        },
                    ],
                },
            ],
            routes: [
                {
                    id: 'timebuddy_settings_route',
                    path: 'settings',
                    routes: [
                        {
                            id: 'timebuddy_time_periods_route',
                            path: 'time-period',
                            accessConditions: ['WorkingTime'],
                            routes: [
                                {
                                    element: <TimePeriodListPage />,
                                },
                                {
                                    id: 'timebuddy_time_period_route',
                                    path: ':id',
                                    element: <TimePeriodPage />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    guides: [
        {
            id: 'timebuddy_workingtime_workingtimelog',
            title: 'Arbeitszeit',
            content: <GuideWorktimeLogPage />,
        },
    ],
};

/**
 * @typedef {import('./types').PermissionGroupTimeBuddy} PermissionGroupTimeBuddy
 * @typedef {import('applications/beyondbuddy/types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').OperationName} OperationName
 * @typedef {import('./types').UnmanagedObjectTimeBuddy} UnmanagedObjectTimeBuddy
 * @typedef {`${OperationName}${UnmanagedObjectTimeBuddy}`} UnmanagedOperation
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('applications/configuration').ManagedObject} ManagedObject
 */

/** @type {Record<PermissionGroupTimeBuddy, Permission[]>} */
const permissionGroupDefinition = {
    extendedRead: [
        'readWorkingTimeLog',
    ],
    createUpdateWorkingTimeLog: ['createWorkingTimeLog', 'updateWorkingTimeLog'],
};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    TimePeriod: baseLinkSchemaOptions(isIncoming),
    WorkingTimeModel: baseLinkSchemaOptions(isIncoming),
    WorkingTimeLogTemplate: baseLinkSchemaOptions(isIncoming),
    Workplace: baseLinkSchemaOptions(isIncoming),
});

// /** @type {Record<import('./types').ManagedObjectTimeBuddy, string>} */
// const entityLabels = {
//     TimePeriod: 'Abrechnungsperiode',
//     WorkingTimeLogTemplate: 'Arbeitszeitvorlage',
//     WorkingTimeModel: 'Arbeitszeitmodell',
//     Workplace: 'Arbeitsort',
//     WorkingTimeHoliday: 'Feiertag',
// };

/** @type {import('applications/configuration').ManagedObject[]} */
const managedEntityKeys = ['TimePeriod', 'WorkingTimeLogTemplate', 'WorkingTimeModel', 'Workplace'];

/** @type {Record<UnmanagedOperation|Exclude<PermissionGroupTimeBuddy, PermissionGroupBeyondBuddy>, string>} */
const permissionLabels = {
    createWorkingTimeLog: 'Zeit erfassen',
    readWorkingTimeLog: 'Zeitbuchung lesen',
    updateWorkingTimeLog: 'Zeitbuchung bearbeiten',
    deleteWorkingTimeLog: 'Zeitbuchung löschen',
    createWorkingTimeModelAssignment: 'Zeitmodell zuweisen',
    readWorkingTimeModelAssignment: 'Zeitmodellzuweisungen lesen',
    updateWorkingTimeModelAssignment: 'Zeitmodellzuweisung bearbeiten',
    deleteWorkingTimeModelAssignment: 'Zeitmodellzuweisung entfernen',
    createWorkingTimeSchedule: 'Dienstplan erstellen',
    deleteWorkingTimeSchedule: 'Dienstplan löschen',
    readWorkingTimeSchedule: 'Dienstplan lesen',
    updateWorkingTimeSchedule: 'Dienstplan bearbeiten',
    createWorkingTimeAggregateCorrection: 'Zeitkonto korrigieren',
    deleteWorkingTimeAggregateCorrection: 'Zeitkonto Korrektur löschen',
    readWorkingTimeAggregateCorrection: 'Zeitkonto Korrekturen einsehen',
    updateWorkingTimeAggregateCorrection: 'Zeitkonto Korrekturen bearbeiten',
    createUpdateWorkingTimeLog: 'Zeit buchen',
    extendedRead: 'Erweitertes Lesen', // also defined in other applications like driveBuddy
};

export {
    applicationConfiguration,
    permissionGroupDefinition as permissionGroupDefinitionTimeBuddy,
    // entityLabels as entityLabelsTimeBuddy,
    permissionLabels as permissionLabelsTimeBuddy,
    managedEntityKeys as managedEntityKeysTimeBuddy,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsTimeBuddy,
};
