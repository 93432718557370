/**
 * createFeedback
 * @property {string} creatorUserId - the id of user that provides feedback
 * @property {string} location - location, that is possibly relevant for the feedback
 * @property {string} kind - kind of feedback
 * @property {string} message - the actual feedback
 * @property {Array<string>} attachmentKeys - s3 keys of the attachments of the feedback
 * @returns {object} - newly created feedback
 */
export const createFeedback = /* GraphQL */ `
    mutation CreateFeedback(
        $anonymous: Boolean,
        $location: String,
        $kind: FeedbackKind,
        $message: String!,
        $attachmentKeys: AWSJSON
    ) {
        createFeedback(
            anonymous: $anonymous
            location: $location
            kind: $kind
            message: $message            
            attachmentKeys: $attachmentKeys
        ) {
            id
        }
    }
`;
