import React, {
    useCallback, useEffect, useState,
} from 'react';
import {
    Tile, TileBadge, TileIconBackground, TileLabel, TileRouteButton,
} from 'assets/theme/components/Tile/Tile';
import {
    AddCircleOutline, CarCrashOutlined, DirectionsCar, SignpostOutlined,
} from '@mui/icons-material';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listDrivingRecords} from 'graphql/driveBuddy/DrivingRecord/queries';
import {useVariables} from 'hooks/useVariables';
import {CancelException} from 'hooks/useCancellablePromise';
import {useMessage} from 'hooks/useMessage';
import {Exceptions} from 'messages/Exceptions';
import {DashboardTilesContainer} from 'components/Dashboard/DashboardTilesContainer';
import {useCanAccess} from 'hooks/useCanAccess';

/**
 * This page shows a dashboard for all applications
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function LogBookTileDashboard() {
    const {listItems} = AWSAppSyncProvider();
    const [items, setItems] = useState([]);
    const {getVariables} = useVariables();
    const {enqueueMessage} = useMessage();

    const canReadDrivingRecords = useCanAccess('readDrivingRecord');

    const load = useCallback((variables) => {
        listItems(listDrivingRecords, variables)
            .then(((newItems) => setItems(newItems ?? [])))
            .catch((error) => {
                if (!(error instanceof CancelException)) {
                // eslint-disable-next-line no-console
                    console.error('Encountered error', error);
                    enqueueMessage('open-dr-tile', Exceptions.API_LOAD_ERROR);
                }
            });
    }, [listItems, enqueueMessage, setItems]);

    useEffect(() => {
        if (canReadDrivingRecords) {
            const variables = getVariables({
                direct: {filter: {draft: true, onlyOwn: true}},
                global: {tenantId: 'tenantId', userId: 'userId'},
            });
            load(variables);
        }
    }, [load, getVariables, canReadDrivingRecords]);

    const newDrivingRecord = (
        <Tile data-test="Tile_Drive">
            <TileLabel label="Fahrt" />
            <TileRouteButton routeId="drivebuddy_drive_logbook_drivingrecord_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<AddCircleOutline color="primary" />} />
        </Tile>
    );
    const allDrivingRecords = canReadDrivingRecords && (
        <Tile data-test="Tile_DriveList">
            <TileLabel label="Fahrten" />
            <TileRouteButton routeId="drivebuddy_drive_logbook_drivingrecords_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<SignpostOutlined color="primary" />} />
        </Tile>
    );
    const openDrivingRecordsTile = canReadDrivingRecords && (
        <Tile id="open-driving-records">
            {(items.length === 1)
            && (
                <>
                    <TileLabel label="Entwurf" />
                    <TileRouteButton
                        routeId="drivebuddy_drive_logbook_drivingrecord_route"
                        routeParams={{id: items[0]?.id}}
                    />
                </>
            )}
            {(items.length > 1)
            && (
                <>
                    <TileLabel label="Entwürfe" />
                    <TileRouteButton
                        routeId="drivebuddy_drive_logbook_drivingrecords_route"
                        routeState={{initialFilters: {draft: true}}}
                    />
                </>
            )}
            <TileBadge badgeContent={items.length} />
            <TileIconBackground icon={<CarCrashOutlined color="primary" />} />
        </Tile>
    );
    const driveBuddyVehiclesTile = (
        <Tile data-test="Tile_VehicleList">
            <TileLabel label="Fahrzeuge" />
            <TileRouteButton routeId="drivebuddy_drive_settings_vehicles_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<DirectionsCar color="primary" />} />
        </Tile>
    );
    const driveBuddyVehicleCreateTile = (
        <Tile data-test="Tile_Vehicle">
            <TileLabel label="Fahrzeug" />
            <TileRouteButton routeId="drivebuddy_drive_settings_vehicle_route" routeParams={{id: 'create'}} />
            <TileIconBackground icon={<AddCircleOutline color="primary" />} />
        </Tile>
    );

    const elements = [
        useCanAccess('createDrivingRecord') && {
            routeId: 'drivebuddy_drive_logbook_drivingrecord_route',
            tile: newDrivingRecord,
        },
        {
            routeId: 'drivebuddy_drive_logbook_drivingrecords_route',
            tile: allDrivingRecords,
        },
        useCanAccess('createVehicle') && {
            routeId: 'drivebuddy_drive_settings_vehicle_route',
            tile: driveBuddyVehicleCreateTile,
        }, {
            routeId: 'drivebuddy_drive_settings_vehicles_route',
            tile: driveBuddyVehiclesTile,
        },
    ];
    /** @type {{severity: import('@mui/material').AlertColor, message:string}} */
    const notification = {severity: 'success', message: 'Alles im grünen Bereich! Es liegen keine Notifizierungen vor.'};
    if (items.length) {
        notification.severity = 'warning';
        if (items.length === 1) {
            notification.message = 'Du hast eine Fahrt im Entwurfs-Status!';
            elements.push({
                routeId: 'drivebuddy_drive_logbook_drivingrecord_route',
                tile: openDrivingRecordsTile,
            });
        } else {
            notification.message = 'Du hast mehrere Fahrten im Entwurfs-Status!';
            elements.push({
                routeId: 'drivebuddy_drive_logbook_drivingrecords_route',
                tile: openDrivingRecordsTile,
            });
        }
    }

    return (
        <DashboardTilesContainer
            notification={notification}
            elements={elements}
        />
    );
}
export {LogBookTileDashboard};
