import React from 'react';
import {
    Box, Checkbox, Divider, FormControlLabel, List, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import {createQuickGuideListItem} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuidePermissionChooserPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Effektive Berechtigungen</Typography>
            <hr />
                Die gesetzten Berechtigungen sind tabellarisch dargestellt und können einfach durch setzen der entsprechenden Checkbox hinzugefügt oder entfernt werden.
            <br />
            <br />
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{backgroundColor: 'secondary.main'}}>
                            <TableCell>
                                <Typography fontWeight="bold">Benutzer</Typography>
                            </TableCell>
                            <TableCell align="center"><Typography fontWeight="bold">Lesen</Typography></TableCell>
                            <TableCell align="center"><Typography fontWeight="bold">Schreiben</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell>
                                <Box style={{display: 'flex', gap: '1rem'}}>
                                    <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <Typography fontWeight="bold">Amira Kainz</Typography>
                                        <Typography paddingLeft="1rem" fontSize="0.9rem">amira.kainz@beyondbuddy.at</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell padding="checkbox" align="center"><Checkbox checked /></TableCell>
                            <TableCell padding="checkbox" align="center"><Checkbox checked={false} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Typography variant="h2" color="primary">Experten-Modus</Typography>
            <Divider />
            <Box>
                <FormControlLabel
                    style={{width: 'calc(100% - 1rem)'}}
                    label={<Typography noWrap>Expertenmodus</Typography>}
                    control={(<Switch checked />)}
                />
            </Box>
            Die Berechtigungen sind gegebenenfalls gruppiert um eine erhöhte Komplexität zu reduzieren. Im Fall, dass spezielle Berechtigungs-Lösungen nötig sind,
            kann der Experten-Modus aktiviert werden (wo verfügbar). Dadurch werden die verfügbaren Berechtigungen einzeln angezeigt.
            <br />
            <br />
            <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
            <Divider />
            <List dense>
                {createQuickGuideListItem({id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'})}
                {createQuickGuideListItem({id: 'beyondbuddy_general_organization', label: 'Abbilden der Unternehmensstruktur in der Aufbauorganisation'})}
            </List>
        </>
    );
}
export {GuidePermissionChooserPage};
