/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        label: [{
            formType: ['create', 'update'],
            actions: ['save', 'blur'],
            configurations: ['required', 'type'],
        }],
        startDateTime: [{
            formType: ['create', 'update'],
            actions: ['save', 'blur'],
            configurations: ['required', 'type'],
        }],
        endDateTime: [{
            formType: ['create', 'update'],
            actions: ['save', 'blur'],
            configurations: ['required', 'type'],
        }],
    },
    backend: {
        startDateTime: [{
            actions: ['create', 'update'],
            configurations: ['required', 'type'],
        }],
        endDateTime: [{
            actions: ['create', 'update'],
            configurations: ['required', 'type'],
        }],
    },
    attributes: {
        startDateTime: {
            type: 'DateTime',
            required: true,
        },
        endDateTime: {
            type: 'DateTime',
            required: true,
        },
        label: {
            type: 'String',
            required: true,
        },
    },
};

module.exports = {schema};
