import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {deleteCemetery} from 'graphql/peaceBuddy/Cemetery/mutations';
import {listCemeteries} from 'graphql/peaceBuddy/Cemetery/queries';

import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {getFullAddressInformation} from 'helper/address';

/**
 * Formular for list cemeteries
 * @returns {React.ReactElement} The CemeteryListFormular component
 */
function CemeteryListFormular() {
    const messageKey = 'Cemetery_List';
    return (
        <ListData
            loadConfig={{
                query: listCemeteries,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteCemetery,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_cemetery_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createCemetery')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="Cemetery-unit-filter" messageKey={messageKey}>
                <FilterElementText label="Suche" path="search" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Nr.',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold">{item.nr}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '50px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Referenz',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold">{item.referenceId}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap textOverflow="ellipsis">{item.name}</Typography>,
                                        },
                                    ],
                                    boxProps: {style: {width: '230px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Adresse',
                                            renderItem: (item) => <Typography noWrap textOverflow="ellipsis">{getFullAddressInformation(item)}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'peacebuddy_settings_cemetery_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'peacebuddy_settings_cemetery_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {CemeteryListFormular};
