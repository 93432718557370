import React, {lazy, Suspense} from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import {AppContext} from 'context/AppContext';
import {TenantCreatePage} from 'applications/beyondbuddy/settings/pages/tenant/TenantCreatePage';
import {Authenticator} from '@aws-amplify/ui-react';
import {MessageProvider} from 'assets/theme/layout/MessageProvider/MessageProvider';
import {SplashScreenTemplateProvider} from 'assets/theme/index';
import {Amplify} from 'aws-amplify';
import {awsConfigAPIKEY} from 'aws-exports';
import {QuickGuideProvider} from 'components/QuickGuide/QuickGuideContext';

const AuthRouter = lazy(() => import('AuthRouter'));

const authConfig = JSON.parse(localStorage.getItem('authConfig'));
Amplify.configure(authConfig ?? awsConfigAPIKEY);

const router = createBrowserRouter([
    {
        path: '/register',
        element: <SplashScreenTemplateProvider />,
        children: [
            {
                index: true,
                path: '*',
                element: <TenantCreatePage />,
            },
        ],
    },
    {
        path: '/*',
        element: <Suspense fallback={<div>Loading...</div>}><AuthRouter /></Suspense>,
    },
]);

/**
 *
 * @namespace Frontend
 * @returns {React.ReactElement} The main application
 */
function App() {
    return (
        <Authenticator.Provider>
            <MessageProvider>
                <QuickGuideProvider>
                    <AppContext>
                        <RouterProvider router={router} />
                    </AppContext>
                </QuickGuideProvider>
            </MessageProvider>
        </Authenticator.Provider>
    );
}

export {App};
