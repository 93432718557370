// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

export const listGraves = /* GraphQL */ `
  query ListGraves (
    $tenantId: ID!
    $filter: GravesListFilterInput
    $nextToken: String
    ) {
      listGraves(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: $filter
    ) {
      items {
        id
        tenantId
        generalNr
        type
        kind
        division
        subDivision
        nr
        lastFuneral
        leaseExpirationDate
        honoraryGrave
        free
        choosable
        units
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

export const listGravesLink = /* GraphQL */ `
  query ListGraves (
    $tenantId: ID!
    $filter: GravesListFilterInput
    ) {
      listGraves(
        tenantId: $tenantId
        filter: $filter
    ) {
      items {
        id
        generalNr
        kind
        division
        subDivision
        nr
        free
        choosable
        units
      }
    }
  }
`;

export const getGrave = /* GraphQL */ `
  query GetGrave(
      $id: ID!
    ) {
      getGrave(
        id: $id
    ) {
      id
      tenantId
      generalNr
      cemeteryId
      cemetery{
        id
        nr
        name
        referenceId
      }
      type
      kind
      division
      subDivision
      nr
      lastFuneral
      restPeriod
      leaseExpirationDate
      honoraryGrave
      free
      choosable
      units
      unitPositions
      notes
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
      subGrants {
        graveRecordReadable
        graveRecordCreatable
        protocolEntryReadable
        protocolEntryCreatable
      }
      connections {
        grave {
          id
        }
        attributes
      }
    }
  }
  ${FileInformationFragment}
`;
