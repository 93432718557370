import React, {useEffect, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Alert, Box} from '@mui/material';
import {TimePeriodListFormular} from 'applications/timebuddy/settings/forms/timeperiod/TimePeriodListFormular';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listTimePeriods} from 'graphql/timeBuddy/TimePeriod/queries';
import {CancelException} from 'hooks/useCancellablePromise';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of TimePeriods
 * .TimeBuddy.Pages
 * @returns {React.ReactElement} The TimePeriodListPage component.
 */
function TimePeriodListPage() {
    const {listItems} = AWSAppSyncProvider();
    const [openPeriods, setOpenPeriods] = useState(null);

    useEffect(() => {
        listItems(listTimePeriods, {filter: {open: true}})
            .then(async (periods) => {
                setOpenPeriods(periods);
            }).catch((error) => {
                if (!(error instanceof CancelException)) {
                    // eslint-disable-next-line no-console
                    console.error('Encountered error', error);
                }
            });
    }, [listTimePeriods, listItems, setOpenPeriods]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Abrechnungsperioden</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            {(openPeriods && !openPeriods?.length) && <Alert severity="warning">Es ist keine Abrechnungsperiode aktiv - es können aktuell nur Entwürfe gebucht werden!</Alert>}
            <div style={{marginTop: '1rem'}}>
                <TimePeriodListFormular />
            </div>
        </LayoutContainer>
    );
}
export {TimePeriodListPage};
