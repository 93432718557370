import React from 'react';
import {
    Tile, TileIconBackground, TileLabel, TileRouteButton,
} from 'assets/theme/components/Tile/Tile';
import {
    BusinessOutlined, GroupsOutlined, PersonOutline,
} from '@mui/icons-material';
import {DashboardTilesContainer} from 'components/Dashboard/DashboardTilesContainer';

/**
 * This page shows a dashboard for all applications
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function GeneralSettingsTileDashboard() {
    const allUsers = (
        <Tile>
            <TileLabel label="Benutzer" />
            <TileRouteButton routeId="beyondbuddy_settings_general_users_route" />
            <TileIconBackground icon={<PersonOutline color="primary" />} />
        </Tile>
    );
    const allGroups = (
        <Tile>
            <TileLabel label="Gruppen" />
            <TileRouteButton routeId="beyondbuddy_settings_general_groups_route" />
            <TileIconBackground icon={<GroupsOutlined color="primary" />} />
        </Tile>
    );
    const allOrganisationalUnits = (
        <Tile>
            <TileLabel label="Org.-Einheiten" abbreviation="OUs" />
            <TileRouteButton routeId="beyondbuddy_settings_general_organizationalunits_route" />
            <TileIconBackground icon={<GroupsOutlined color="primary" />} />
        </Tile>
    );
    const tenant = (
        <Tile>
            <TileLabel label="Mandant" />
            <TileRouteButton routeId="beyondbuddy_settings_general_tenant_route" />
            <TileIconBackground icon={<BusinessOutlined color="primary" />} />
        </Tile>
    );

    const elements = [
        {
            routeId: 'beyondbuddy_settings_general_tenant_route',
            tile: tenant,
        },
        {
            routeId: 'beyondbuddy_settings_general_organizationalunits_route',
            tile: allOrganisationalUnits,
        },
        {
            routeId: 'beyondbuddy_settings_general_users_route',
            tile: allUsers,
        },
        {
            routeId: 'beyondbuddy_settings_general_groups_route',
            tile: allGroups,
        },
    ];
    /** @type {{severity: import('@mui/material').AlertColor, message:string}} */
    const notification = {severity: 'success', message: 'Alles im grünen Bereich! Es liegen keine Notifizierungen vor.'};

    return (
        <DashboardTilesContainer
            notification={notification}
            elements={elements}
        />
    );
}
export {GeneralSettingsTileDashboard};
