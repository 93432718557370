import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {deleteOrganizationalUnit} from 'graphql/beyondBuddy/OrganizationalUnit/mutations';
import {listOrganizationalUnits} from 'graphql/beyondBuddy/OrganizationalUnit/queries';

import groupImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_77507676.jpg';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';

/**
 * Formular for list organizational units
 * @returns {React.ReactElement} The OrganizationalUnitsListFormular component
 */
function OrganizationalUnitsListFormular() {
    const messageKey = 'OrganizationalUnit_List';
    return (
        <ListData
            loadConfig={{
                query: listOrganizationalUnits,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteOrganizationalUnit,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="beyondbuddy_settings_general_organizationalunit_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createOrganizationalUnit')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="organizational-unit-filter" messageKey={messageKey}>
                <FilterElementText label="Name" path="name" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Organisationseinheit',
                                            renderItem: (item) => (<img style={{maxHeight: '40px'}} src={item.image?.url ?? groupImg} alt="organizationalUnit" />),
                                        },
                                    ],
                                    boxProps: {
                                        style: {width: '80px'},
                                    },
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold">{item.name}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'beyondbuddy_settings_general_organizationalunit_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'beyondbuddy_settings_general_organizationalunit_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {OrganizationalUnitsListFormular};
