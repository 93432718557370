import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import {Grid, Typography} from '@mui/material';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {listWorkingTimeLogTemplates} from 'graphql/timeBuddy/WorkingTimeLogTemplate/queries';

/** @type {import('components/Form/Listing').ListingSchema} */
const listingSchema = {
    columns: [
        {
            itemConfigurations: [
                {
                    title: 'Name',
                    renderItem: (item) => (
                        <Typography noWrap fontWeight="bold" fontStyle={item.active && 'italic'}>
                            {item.name}
                            {item.revision > 0 && <Typography component="span" color="GrayText">{` #${item.revision}`}</Typography>}
                        </Typography>
                    ),
                },
            ],
            boxProps: {sx: {flex: {xs: 4, lg: 2}}},
        },
    ],
    actions: [
        {
            icon: <Edit />,
            routeId: 'timebuddy_workingtime_template_route',
            routeParams: (item) => ({id: item.id}),
        },
    ],
    routeId: 'timebuddy_workingtime_template_route',
    routeParams: (item) => ({id: item.id}),
};

/**
 * Formular for list working time models
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The component to render
 */
function WorkingTimeLogTemplateListFormular() {
    const messageKey = 'WorkingTimeModel_List';
    return (
        <ListData
            loadConfig={{
                query: listWorkingTimeLogTemplates,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
        >
            <FormElementActionButton
                routeId="timebuddy_workingtime_template_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createWorkingTimeLogTemplate')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="model-filter" messageKey={messageKey}>
                <FilterElementText label="Suche" path="search" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}><FilterElementText label="Name" path="name" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementText label="Info Text" path="info" /></Grid>
                </Grid>
            </ListFilterProvider>
            <Listing schema={listingSchema} />
        </ListData>
    );
}

export {WorkingTimeLogTemplateListFormular};
