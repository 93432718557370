import {Box, Checkbox} from '@mui/material';
import {ListFilterContext} from 'components/Form/ListFilterProvider';
import {useSubscribe} from 'hooks/useSubscribe';
import _ from 'lodash';
import React, {
    useCallback, useContext, useEffect,
} from 'react';

/**
 * ## Filter Element Checkmark
 * Creates a filter that is set as a boolean. It only set's the filter value to true
 * when toggled, and to undefined if false, to not force filtering in case one want's to turn
 * a filter off again
 * @param {object} props - props given to the component
 * @param {string} props.path - path to filter value to set
 * @param {string} props.label - label to be shown next to a checkbox
 * @param {boolean} [props.disabled] - indicates the element to be disabled
 * @param {boolean} [props.checked] - flag that makes the path permanently true / always checked
 * @returns {React.ReactElement} - element to be rendered
 */
function FilterElementCheckmark({
    path, label, disabled, checked,
}) {
    const {updateFilter, filterValues$} = useContext(ListFilterContext);
    const selected = useSubscribe(filterValues$, (values) => _.get(values, path), false);

    const onClick = useCallback(() => {
        updateFilter(path, !selected || undefined);
    }, [path, selected, updateFilter]);

    const checkedFunction = useCallback(() => {
        if (_.isFunction(checked)) {
            const selectedPreProcessed = checked(selected);
            if (selectedPreProcessed !== selected) {
                updateFilter(path, selectedPreProcessed);
            }
            return selectedPreProcessed;
        }
        return selected ?? false;
    }, [selected, updateFilter]);

    useEffect(() => {
        if (checked !== undefined) {
            updateFilter(path, checked);
        }
    }, [checked, updateFilter]);

    return (
        <Box>
            <label
                htmlFor={`FilterElement_FilterElementCheckmark_${path}`}
                style={{cursor: 'pointer'}}
                data-test={`FilterElement_FilterElementCheckmark_${path}_label`}
            >
                {label}

            </label>
            <Checkbox
                id={`FilterElementCheckmark_${path}`}
                data-test={`FilterElement_FilterElementCheckmark_${path}`}
                checked={checkedFunction() ?? selected}
                onClick={onClick}
                disabled={disabled}
            />
        </Box>
    );
}

export {FilterElementCheckmark};
