import React, {
    useMemo, useState, useContext, useEffect,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {
    Alert,
    Autocomplete,
    Box, Button, Grid, TextField, useTheme, useMediaQuery,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormReset} from 'components/Form/FormReset';
import {
    AddCircleOutline, Delete, Refresh,
} from '@mui/icons-material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {uuid} from 'short-uuid';
import timegrid from '@fullcalendar/timegrid';
import interaction from '@fullcalendar/interaction';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {workPlaceDataSchema} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';
import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {formatDateTimeString} from 'helper/date';
import {createWorkingTimeSchedule, updateWorkingTimeSchedule} from 'graphql/timeBuddy/WorkingTimeSchedule/mutations';
import {getWorkingTimeSchedule} from 'graphql/timeBuddy/WorkingTimeSchedule/queries';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementTestHelper} from 'components/Form/FormElements/FormElementTestHelper';
import {postProcessSchedule, preProcessSchedule} from 'applications/timebuddy/modules/workingtime/forms/workingTimeSchedule/processing';
import {BeyondCalendar} from 'assets/theme/components/Calendar/BeyondCalendar';

const {schema: validatorSchema} = require('beyond-validators/timeBuddy/WorkingTimeSchedule');

const dateZero = new Date(0, 0, 1, 0, 0, 0, 0);

const lineManagerDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

/** @type {(events: import('@fullcalendar/core').EventInput[], value: Record<string, any>)=> string[]} */
const getDuplicates = (events, value) => {
    const dps = [];
    const orderedEvents = events.sort((a, b) => {
        if (a.start === b.start) {
            return 0;
        }
        return (a.start < b.start) ? -1 : 1;
    });
    const overlapGroups = orderedEvents.map((event, index) => orderedEvents
        .slice(index)
        .filter((other) => event.end > other.start));
    overlapGroups.filter((group) => group.length).forEach((group) => {
        const duplicateIds = _.chain(group)
            .map((event) => event.id)
            .flatMap((index) => value?.[index]?.participantIds ?? [])
            .groupBy()
            .map((duplicates, id) => [id, duplicates.length])
            .filter(([, length]) => typeof length === 'number' && length > 1)
            .map(([id]) => id)
            .value();
        dps.push(...duplicateIds);
    });
    return _.uniq(dps);
};

/** @type {import('components/Form/form').ItemSaveConfig} */
const createConfig = {
    mutation: createWorkingTimeSchedule,
    variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
    mask: {
        tenantId: true,
        userId: true,
        shifts: true,
        range: true,
        startDateTime: true,
        endDateTime: true,
    },
    postProcess: postProcessSchedule,
    preProcess: preProcessSchedule,
};
/** @type {import('components/Form/form').ItemSaveConfig} */
const updateConfig = {
    mutation: updateWorkingTimeSchedule,
    mask: {
        id: true,
        shifts: true,
        range: true,
        startDateTime: true,
        endDateTime: true,
    },
    postProcess: postProcessSchedule,
    preProcess: preProcessSchedule,
};

/**
 * Creates a reusable section depicting working hours over the week
 * @param {object} props - props for the working hours section
 * @param {string} props.attribute - key under which the working hour data will be saved
 * @returns {React.ReactElement} component to display
 */
function WorkingHoursSection({
    attribute,
}) {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedParticipantId, setSelectedParticipantId] = useState(null);

    const {changeHandler, get, isReadonly} = useContext(FormContext);
    const {value} = get(attribute);
    const events = useMemo(() => {
        /** @type {import('@fullcalendar/core').EventInput[]} */
        const es = [];
        if (value) {
            Object.values(value).forEach((event) => {
                const start = new Date(event.from);
                const end = new Date(event.until);
                let color = 'blue';
                if (selectedParticipantId && event.participantIds instanceof Array && event.participantIds.includes(selectedParticipantId)) {
                    color = 'orange';
                }
                if (selectedEvent && selectedEvent.id === event.id) {
                    color = 'red';
                }
                es.push({
                    color,
                    start,
                    end,
                    editable: !isReadonly,
                    extendedProps: event,
                    id: event.id ?? uuid(),
                });
            });
        }
        return es;
    }, [value, isReadonly, selectedEvent, selectedParticipantId]);

    /** @type {({id: string, contactFirstName: string, contactLastName?: string})[]} */
    const participants = useMemo(() => {
        const ps = [];
        events.forEach((event) => {
            const eventParticipants = get(`${attribute}.${event.id}.participantIds`);
            if (eventParticipants.value?.length && eventParticipants.displayValue?.length) {
                ps.push(...eventParticipants.value.map((id, personIndex) => ({
                    id,
                    ...eventParticipants.displayValue[personIndex],
                })));
            } else {
                ps.push(...(event.extendedProps?.participants || []));
            }
        });
        return _.sortBy(_.uniqBy(ps, 'id'), 'id');
    }, [events, get]);

    /** @type {(string)[]} */
    const duplicateParticipants = useMemo(() => getDuplicates(events, value), [events, value]);

    useEffect(() => {
        if (duplicateParticipants.length) {
            changeHandler({
                attribute,
                value,
                interacted: true,
                error: {type: 'blur', value: 'Found duplicates'},
            });
        }
    }, [duplicateParticipants]);

    // TODO when backend exists. Check online for person reuse
    // const currentParticipantsHash = useMemo(() => participants.map(({id}) => id).toString(), [participants]);
    // const presenceCheckResults = useRef(Object());

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <FormElementTestHelper attribute="shifts" />
            <Grid item xs={12}>
                <Box
                    sx={{
                        '.fc': {
                            userSelect: 'none',
                        },
                    }}
                >
                    <BeyondCalendar
                        calendarOptions={{
                            views: {
                                dynamicTimeGrid: {
                                    type: 'timeGrid',
                                },
                            },
                            plugins: [timegrid, interaction],
                            eventMaxStack: 3,
                            dayHeaderFormat: {weekday: 'short'},
                            aspectRatio: 2,
                            initialView: 'timeGridWeek',
                            locale: 'de-at',
                            firstDay: 1,
                            allDaySlot: false,
                            events,
                            eventDidMount: ({el, event}) => el.setAttribute('id', event.id),
                            eventClick: ({event, jsEvent}) => {
                                setSelectedEvent(event);
                                jsEvent.preventDefault();
                            },
                            eventsSet: (evt) => {
                                const nonBackgroundEvents = evt.filter((event) => event.display !== 'background');

                                const slots = Object.fromEntries(nonBackgroundEvents.map((e) => [e.id, {
                                    ...(e.extendedProps ?? {}),
                                    from: e.start.toISOString(),
                                    until: e.end.toISOString(),
                                }]));
                                // Only comparing to existing values, hence omitBy isNil
                                if (!_.isEqual(value, slots)) {
                                // Timeout to prevent double render
                                    setTimeout(() => {
                                        changeHandler({
                                            attribute,
                                            value: slots,
                                            interacted: true,
                                        });
                                    }, 1);
                                }
                            },
                            select: (evt) => {
                                const {start, end} = evt;
                                const id = uuid();
                                Object.assign(evt, {id});
                                changeHandler({
                                    attribute: `${attribute}.${id}`,
                                    value: {
                                        id,
                                        from: start.toISOString(),
                                        until: end.toISOString(),
                                        participantIds: [],
                                    },
                                    displayValue: null,
                                    interacted: true,
                                });
                                setSelectedEvent(evt);
                            },
                            selectable: !isReadonly,
                            // selectMirror: true,
                            headerToolbar: false,
                            initialDate: dateZero,
                        }}
                    />
                </Box>
            </Grid>
            {Boolean(selectedEvent) && (
                <>
                    <Grid item xs={12} style={{maxWidth: isMD ? 'calc(100vw - 240px)' : '100vw'}}>
                        <FormElementAutocomplete
                            label="Personen"
                            attribute={`${attribute}.${selectedEvent?.id}.participantIds`}
                            dataSchema={lineManagerDataSchema}
                            optionReference={`${attribute}.${selectedEvent?.id}.participants`}
                            multiple
                        />
                    </Grid>
                    <FormElementContainer attribute="*" conditionalRender={({value: v}) => v && (!v.id || v.grants.updatable)}>
                        <Grid item xs={6} sm={4} display="grid">
                            <Button
                                data-test="DeleteButton"
                                onClick={() => {
                                    changeHandler({
                                        attribute,
                                        value: _.omit(value, selectedEvent.id),
                                    });
                                    setSelectedEvent(null);
                                }}
                            >
                                <Delete />
Löschen

                            </Button>
                        </Grid>
                    </FormElementContainer>
                    <Grid item xs={12}>
                        <FormElementAutocomplete
                            label="Arbeitsort"
                            attribute={`${attribute}.${selectedEvent?.id}.placeId`}
                            dataSchema={workPlaceDataSchema}
                            optionReference={`places.${value?.[selectedEvent?.id]?.placeId}`}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {Boolean(duplicateParticipants.length) && (
                            <Alert severity="warning">
                            Die folgenden Personen kommen in überlappenden Schichten vor
                            </Alert>
                        )}
                        {duplicateParticipants.map(((participant) => {
                            const person = participants.find((p) => p.id === participant);
                            return _.compact([person?.contactFirstName, person?.contactLastName]).join(' ');
                        }))}
                    </Grid>
                </>
            ) }
            <Grid item xs={12}>
                <Autocomplete
                    style={{maxWidth: isMD ? 'calc(100vw - 240px)' : '100vw'}}
                    options={participants}
                    getOptionLabel={(person) => _.compact([person?.contactFirstName, person?.contactLastName]).join(' ')}
                    renderInput={(props) => <TextField label="Benutzer hervorheben" {...props} />}
                    onChange={(_event, option) => {
                        setSelectedParticipantId(option?.id);
                    }}
                />
            </Grid>
        </>
    );
}

const selectionDateStart = new Date('2023-05-30T22:00:00.000Z');
selectionDateStart.setHours(0, 0, 0, 0);
selectionDateStart.setDate(selectionDateStart.getDate() - selectionDateStart.getDay() + 3);
selectionDateStart.setMonth(selectionDateStart.getMonth() - 1);
const now = new Date();
const nowIso = now.toISOString();
// Increase by 2038
const weeks = _.times(1000, (nr) => {
    const startDateTime = selectionDateStart.toISOString();
    selectionDateStart.setDate(selectionDateStart.getDate() + 7);
    const endDateTime = selectionDateStart.toISOString();
    return {
        startDateTime,
        endDateTime,
        nr,
    };
});
{ // Shifting past weeks to the end of the list
    let week;
    // eslint-disable-next-line no-cond-assign
    while ((week = weeks.shift()).endDateTime < nowIso) {
        weeks.push(week);
    }
    weeks.push(week);
}

/**
 * The WorkingTimeScheduleFormular formular for creating and updating a WorkingTimeScheduleFormular
 * .TimeBuddy.Forms
 * @param {import('applications/configuration').FormularProps & {duplicate?: boolean}} props - props passed to the component
 * @returns {React.ReactElement} The WorkingTimeScheduleFormular component.
 */
function WorkingTimeScheduleFormular({
    id, onSave, duplicate, ...rest
}) {
    const canCreate = useCanAccess('createWorkingTimeSchedule');
    const isCreate = id === 'create';
    const saveConfig = (isCreate || duplicate) && canCreate
        ? createConfig
        : updateConfig;

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getWorkingTimeSchedule,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: postProcessSchedule,
    }), [id]);

    return (
        <ItemData {...(!isCreate) ? {loadConfig} : {}} saveConfig={saveConfig}>
            <FormWrapper
                {...rest}
                isNewItem={isCreate}
                validatorSchema={{
                    schema: validatorSchema,
                    type: 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={`WorkingTimeSchedule_${isCreate ? 'Create' : 'Update'}`}
                context={`WorkingTimeSchedule_${id}`}
            >
                <FormReset shouldClear={isCreate} />
                <Box style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
                >
                    <FormElementAutocomplete
                        label="Woche"
                        attribute="range"
                        usePopular={isCreate || duplicate}
                        dataSchema={{
                            dataKey: 'nr',
                            options: weeks,
                            getOptionLabel: (opt) => {
                                const endDateTime = new Date(opt.endDateTime);
                                endDateTime.setDate(endDateTime.getDate() - 1);
                                return `${formatDateTimeString(opt.startDateTime, 'dd.MM.yy')} - ${formatDateTimeString(endDateTime.toISOString(), 'dd.MM.yy')}`;
                            },
                            getOptionValue: _.identity,
                        }}
                    />
                    <FormElementActionButton
                        routeId="timebuddy_workingtime_schedule_route"
                        routeParams={{id: 'create'}}
                        portalAnchorSelector="#action-button-frame"
                        disabled={isCreate || !canCreate}
                        context={FormContext}
                    >
                        <AddCircleOutline />
                    </FormElementActionButton>
                    <FormElementActionButton
                        reload
                        portalAnchorSelector="#action-button-frame"
                        disabled={isCreate}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                    <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                        <Grid container spacing={2}>
                            <WorkingHoursSection attribute="shifts" />
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <FormElementLoadingButton />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </FormWrapper>
        </ItemData>
    );
}

export {WorkingTimeScheduleFormular};
