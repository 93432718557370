// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

/**
 * Query to list vehicles of a specific tenant
 * @property {string} tenantId - id of the tenant, vehicles should be listed for
 * @property {object} filter - filter input to get specific vehicles
 * @property {string} nextToken - token to continue a listing
 * @returns {object} - vehicles of the tenant
 */
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
      $tenantId: ID!
      $nextToken: String
      $filter: VehicleListFilterInput
    ) {
    listVehicles(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: $filter
      ) {
      items {
        id
        name
        manufacturer
        model
        vin
        licensenumber
        image{
          url
        }
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

/**
 * Query to get a specific vehicle entity instance
 * @property {string} id - id of the vehicle entity instance
 * @returns {object} - vehicle
 */
export const getVehicle = /* GraphQL */ `
  query GetVehicle(
      $id: ID!
    ) {
    getVehicle(
      id: $id
    ) {
      id
      name
      manufacturer
      model
      vin
      licensenumber
      imageKey
      image {
        ...FileInformation
      }
      grants {
        updatable
        deletable
        permissionsReadable
        permissionsUpdatable
      }
      subGrants {
        vehicleProtocolEntryReadable
        vehicleProtocolEntryCreatable
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * Query to get a recommended list of vehicles
 * @property {string} tenantId - id of the tenant, vehicles should be listed for
 * @property {string} userId - id of the user, recommendations should be listed for
 * @property {object} filter - filter input to get specific vehicles in the recommendation
 * @property {string} nextToken - token to continue a listing
 * @returns {object} - recommended vehicles of the tenant
 */
export const listVehicleRecommendations = /* GraphQL */ `
  query ListVehicleRecommendations(
    $tenantId: ID!
    $userId: ID!
    $filter: String
    $nextToken: String
  ) {
    listVehicleRecommendations(
      tenantId: $tenantId
      userId: $userId
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        userId
        occurrence
        rank
      }
      nextToken
    }
  }
`;

/**
 * listVehicleRecommendationsWithVehicle
 * @property {string} tenantId - tenant id.
 * @property {string} userId - user id.
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All vehicle recommendations with the corresponding vehicle.
 */
export const listVehicleRecommendationsWithVehicle = /* GraphQL */ `
  query ListVehicleRecommendations(
    $tenantId: ID!
    $userId: ID!
    $filter: String
    $nextToken: String
  ) {
    listVehicleRecommendations(
      tenantId: $tenantId
      userId: $userId
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        userId
        occurrence
        rank
        vehicle {
          id
          name
        }
      }
      nextToken
    }
  }
`;

/**
 * Query to list the protocol entries of a vehicle entity instance
 * @property {string} vehicleId - id of the vehicle, protocol entries should be shown for
 * @property {object} filter - filter input to get specific protocol entries
 * @property {string} nextToken - token to continue a listing
 * @returns {object} - protocol entries of the vehicle
 */
export const listVehicleProtocolEntries = /* GraphQL */ `
  query ListVehicleProtocolEntries(
      $vehicleId: ID!
      $nextToken: String
      $filter: VehicleProtocolEntryListFilterInput
    ) {
      listVehicleProtocolEntries(
        vehicleId: $vehicleId
        nextToken: $nextToken
        filter: $filter
      ) {
      items {
        id
        userId
        user{
          id
          contactFirstName
          contactLastName
        }
        vehicleId
        vehicle{
          id
          name,
          model
          manufacturer
          licensenumber
        }
        vehicleKM
        eventDateTime
        cause
        description
        attachments{
          key
          filename
          description
          url
        }
      }
      nextToken
    }
  }
`;

/**
 * Query to get a specific protocol entry instance
 * @property {string} id - id of the protocol entry instance
 * @returns {object} - protocol entry
 */
export const getVehicleProtocolEntry = /* GraphQL */ `
  query GetVehicleProtocolEntry(
      $id: ID!
    ) {
      getVehicleProtocolEntry(
      id: $id
    ) {
      id
      userId
      vehicleId
      vehicleKM
      eventDateTime
      cause
      description
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
      }
    }
  }
`;
