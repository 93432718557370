/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const genderDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'MALE',
        de: 'Mann',
    }, {
        value: 'FEMALE',
        de: 'Frau',
    }, {
        value: 'DIVERSE',
        de: 'Divers',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};
