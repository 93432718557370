export const createGraveRecord = /* GraphQL */ `
  mutation CreateGraveRecord(
      $tenantId: ID!
      $clerkUserId: ID!
      $graveId: ID!
      $deceasedPersonId: ID!
      $customers: [GraveRecordCustomerInput]
      $dateOfFuneral: AWSDateTime!
      $burryDeadline: AWSDateTime!
      $graveUnitId: String!
      $notes: String
      $attachmentKeys: AWSJSON
    ) {
    createGraveRecord(
      tenantId: $tenantId
      clerkUserId: $clerkUserId
      graveId: $graveId
      deceasedPersonId: $deceasedPersonId
      customers: $customers
      dateOfFuneral: $dateOfFuneral
      burryDeadline: $burryDeadline
      graveUnitId: $graveUnitId
      notes: $notes
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      clerkUserId
      clerkUser {
        id
        contactFirstName
        contactLastName
      }
      graveId
      grave {
        generalNr
        division
        subDivision
        nr
      }
      deceasedPersonId
      deceasedPerson {
        id
        gender
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        dateOfBirth
        dateOfDeath
      }
      customers {
        customerId
        portion
        customer {
          id
          companyName
          titlePrefixed
          firstName
          lastName
          titlePostfixed
        }
      }
      dateOfFuneral
      burryDeadline
      graveUnitId
      notes
      attachments{
        key
        filename
        description
        url
      }
    }
  }
`;

export const updateGraveRecord = /* GraphQL */ `
  mutation UpdateGraveRecord(
      $id: ID!
      $tenantId: ID!
      $clerkUserId: ID!
      $customers: [GraveRecordCustomerInput]
      $dateOfFuneral: AWSDateTime!
      $burryDeadline: AWSDateTime!
      $graveUnitId: String!
      $notes: String
      $attachmentKeys: AWSJSON
    ) {
    updateGraveRecord(
      id: $id
      tenantId: $tenantId
      clerkUserId: $clerkUserId
      customers: $customers
      dateOfFuneral: $dateOfFuneral
      burryDeadline: $burryDeadline
      graveUnitId: $graveUnitId
      notes: $notes
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      clerkUserId
      clerkUser {
        id
        contactFirstName
        contactLastName
      }
      graveId
      grave {
        generalNr
        division
        subDivision
        nr
      }
      deceasedPersonId
      deceasedPerson {
        id
        gender
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        dateOfBirth
        dateOfDeath
      }
      customers {
        customerId
        portion
        customer {
          id
          companyName
          titlePrefixed
          firstName
          lastName
          titlePostfixed
          eMailAddress
        }
      }
      dateOfFuneral
      burryDeadline
      graveUnitId
      notes
      attachments{
        key
        filename
        description
        url
      }
    }
  }
`;

export const deleteGraveRecord = /* GraphQL */ `
 mutation DeleteGraveRecord(
     $id: ID!
   ) {
   deleteGraveRecord(
     id: $id,
     ) {
      id
   }
 }
`;

export const deleteGraveRecords = /* GraphQL */ `
 mutation DeleteGraveRecords(
     $ids: [ID!]
   ) {
   deleteGraveRecords(
     ids: $ids,
     )
 }
`;
