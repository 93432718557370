import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {AsyncListDataProvider} from 'helper/bb-asynclistdata-provider';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {dataSchemas} from 'components/Form/FormElements/FormElementEntityChooserSchema';
import {
    Autocomplete, Avatar, Button, List, ListItem,
    ListItemAvatar, ListItemButton, ListItemText, Skeleton,
    TextField, useMediaQuery, useTheme,
} from '@mui/material';
import _ from 'lodash';
import {useIsMounted} from 'hooks/useIsMounted';
import {
    Business,
    Filter, Person,
} from '@mui/icons-material';

// time distance between the last user interaction and the loading of items
// value is in ms
const interactionTimeoutForSuggestions = 1000;

/**
 * @typedef  {import('applications/configuration').ManagedObject} ManagedObject
 * @typedef  {import('./formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 */

/**
 * Variables that can be switched out for testing
 */
const mockables = {
    AWSAppSyncProvider,
};

const avatarDefault = {
    User: <Person />,
    OrganizationalUnit: <Business />,
};

/**
 * @typedef EntityChooserProps
 * @property {(entityType: string, item:any)=>void} selectEntity - function to handle an entity click
 * @property {(items:any[])=>any[]} [unusedEntitiesFilter] - function to handle an entity click
 * @property {ManagedObject[]} availableEntities - the entity types to show
 * @property {boolean} [active] - indicates, that the form element should load data
 * @property {string} [dataTest] - attribute to load/write data from/to
 *  {import('@mui/material').UseAutocompleteProps["value"]} [value] - The value of the autocomplete
 *  {import('@mui/material').UseAutocompleteProps["options"]} [options] - Array of options.
 * @property {ManagedObject} [initialEntityType] - entityType to be selected initially
 * @property {Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} [schemaOptions] - options for the dataschemas
 * @property {boolean} [disabled] - flag to disable the entity chooser. Viewing and browsing is still possible
 */

/**
 * ## FormElementEntityChooser
 *
 * Renders a chooser to filter and select entities
 *
 * If items that have been selected in a previous session are supposed to be shown
 * the `permittedObjects` field should be set in the above `ItemData`
 * @param {EntityChooserProps} props - props of the component
 * @returns {React.ReactElement} - rendered FormElementEntityChooser
 */
function FormElementEntityChooser({
    selectEntity,
    unusedEntitiesFilter,
    schemaOptions,
    availableEntities,
    active,
    initialEntityType,
    dataTest,
    disabled,
}) {
    const [filterText, setFilterText] = useState(''); // the current filter value
    const [isLoadingOptions, setIsLoadingOptions] = useState(true); // indicates wether the drawer loads entities
    const [entityType, setEntityType] = useState(initialEntityType ?? availableEntities[0]); // the entity type to be loaded

    const isMounted = useIsMounted(); // indicates, wether the form is still mounted or not
    const filterRef = useRef();

    const theme = useTheme();
    const isPortraitMobile = useMediaQuery(theme.breakpoints.down('sm'));

    /** @type {import('./formElements').FormElementEntityChooserDataSchema} */
    const dataSchema = useMemo(() => dataSchemas[entityType]?.(schemaOptions?.[entityType]), [dataSchemas, schemaOptions, entityType]);
    if (entityType && !dataSchema) {
        throw new Error(`No data schema for entity type ${entityType}! Available schemas are ${_.join(_.keys(dataSchemas), ', ')}`);
    }
    const entityLabels = useMemo(() => _.mapValues(dataSchemas, (schema, eType) => schema(schemaOptions?.[eType]).label), [dataSchemas, schemaOptions, entityType]);
    const getFilterMap = useMemo(() => dataSchema?.getFilterMap, [dataSchema]);

    const provider = mockables.AWSAppSyncProvider();

    const dataSchemasMemo = useMemo(() => _.mapValues(dataSchemas, (schema, type) => (_.isFunction(schema) ? schema(schemaOptions?.[type]) : schema)), [dataSchemas, schemaOptions]);
    const tenantProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Tenant, false);
    const userProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.User, false);
    const groupProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Group, false);
    const organizationalUnitProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.OrganizationalUnit, false);
    const customerProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Customer, false);

    const vehicleProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Vehicle, false);

    const timePeriodProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.TimePeriod, false);
    const workingTimeModelProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.WorkingTimeModel, false);
    const workingTimeLogTemplateProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.WorkingTimeLogTemplate, false);
    const workplaceProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Workplace, false);

    const cemeteryProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Cemetery, false);
    const graveProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.Grave, false);
    const deceasedPersonProvider = AsyncListDataProvider(provider.call, dataSchemasMemo.DeceasedPerson, false);

    const providers = useMemo(() => ({
        Tenant: tenantProvider,
        User: userProvider,
        Group: groupProvider,
        OrganizationalUnit: organizationalUnitProvider,
        Customer: customerProvider,

        Vehicle: vehicleProvider,

        TimePeriod: timePeriodProvider,
        WorkingTimeModel: workingTimeModelProvider,
        WorkingTimeLogTemplate: workingTimeLogTemplateProvider,
        Workplace: workplaceProvider,

        Cemetery: cemeteryProvider,
        Grave: graveProvider,
        DeceasedPerson: deceasedPersonProvider,
    }), [
        tenantProvider, userProvider, groupProvider, organizationalUnitProvider,
        vehicleProvider,
        timePeriodProvider, workingTimeModelProvider, workingTimeLogTemplateProvider, workplaceProvider,
        cemeteryProvider, graveProvider,
    ]);

    /**
     * Loads options for the autocomplete
     * @param {string} [filter] - value to send to the api for indicating a filter value
     * @param {boolean} [more] - indicates to load  more items with the same filter
     * @returns {boolean} true if the call set a load operation in motion
     */
    const loadEntities = useMemo(() => _.debounce(({loadMoreItems, isFilterNew, hasMoreItems}, filter, more = false) => {
        // async data loader is not ready yet
        if (!_.isFunction(isFilterNew)) {
            return false;
        }
        if (!filter && !hasMoreItems) {
            return false;
        }
        if (filter && (!more && !isFilterNew({filter}))) {
            return false;
        }

        setIsLoadingOptions(true);
        const loadPromise = loadMoreItems(() => {}, {filter});
        if (loadPromise) {
            loadPromise?.finally(() => {
                if (isMounted()) {
                    setIsLoadingOptions(false); // disable the loading indicator
                }
            });
            return true;
        }
        setIsLoadingOptions(false);
        return false;
    }, interactionTimeoutForSuggestions), [isMounted, setIsLoadingOptions]);

    const unusedEntities = useMemo(() => {
        const items = providers?.[entityType]?.items;
        return unusedEntitiesFilter?.(items) ?? items;
    }, [unusedEntitiesFilter, entityType, providers]);

    useEffect(() => {
        if (active && !!getFilterMap) {
            loadEntities(providers[entityType], getFilterMap(filterText));
        }
    }, [active, entityType, filterText, loadEntities, providers, getFilterMap]);
    return (
        <>
            {availableEntities.length === 1 && <h2>{dataSchema.label}</h2>}
            {availableEntities.length >= 2
            && (
                <Autocomplete
                    data-test={`FormElementEntityChooser_${dataTest}_entityType`}
                    disabled={availableEntities.length <= 1}
                    value={entityType}
                    options={availableEntities}
                    renderInput={(params) => (<TextField {...params} />)}
                    onChange={(event, newEntityType) => {
                        setEntityType(newEntityType ?? initialEntityType);
                        setFilterText('');
                        // @ts-ignore
                        filterRef.current?.focus();
                    }}
                    getOptionLabel={(option) => entityLabels[option]}
                />
            )}
            <TextField
                label="Filter"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                data-test={`FormElementEntityChooser_${dataTest}_filter`}
                autoFocus
                inputRef={filterRef}
            />
            {(isLoadingOptions && entityType) && (
                <List sx={{width: '100%', overflow: 'scroll'}} dense data-test={`FormElementEntityChooser_${dataTest}_list`}>
                    {_.map(Array(10).fill(''), (i, index) => {
                        let skeletonHeight = '3rem';
                        if (dataSchema.getSecondaryValue) {
                            skeletonHeight = '3.5rem';
                        }
                        return (
                            <ListItem
                                key={index}
                                disableGutters
                                disablePadding
                                divider
                            >
                                <ListItemText>
                                    <Skeleton height={skeletonHeight} variant="rounded" animation="pulse" data-test={`FormElementEntityChooser_${dataTest}_skeleton`} />
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
            ) }
            {(!isLoadingOptions && entityType) && (
                <List sx={{width: '100%', overflow: 'scroll'}} dense>
                    {
                        _.map(unusedEntities, (item) => (
                            <ListItem
                                key={item.id}
                                disableGutters
                                disablePadding
                                divider
                                data-test={`FormElementEntityChooser_${dataTest}_listItem`}
                            >
                                <ListItemButton
                                    disabled={disabled}
                                    onClick={() => selectEntity(entityType, item)}
                                >
                                    {(!isPortraitMobile && _.has(item, 'image')) && (
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={item.id}
                                                src={item.image?.url}
                                                data-test={`FormElementEntityChooser_${dataTest}_list_avatar`}
                                            >
                                                {_.get(avatarDefault, entityType)}

                                            </Avatar>
                                        </ListItemAvatar>
                                    )}
                                    <ListItemText
                                        primary={dataSchema.getPrimaryValue(item)}
                                        primaryTypographyProps={{
                                            style: {
                                                fontSize: '1.2rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                            },
                                        }}
                                        secondary={dataSchema.getSecondaryValue ? dataSchema.getSecondaryValue(item) : undefined}
                                        secondaryTypographyProps={{style: {overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                    <ListItem
                        key="last"
                        disableGutters
                        disablePadding
                        divider
                    >
                        <ListItemButton onClick={() => {
                            // @ts-ignore
                            filterRef.current?.focus();
                        }}
                        >
                            <Button startIcon={<Filter />}>Für mehr Ergebnisse entsprechend filtern</Button>
                        </ListItemButton>
                    </ListItem>
                </List>
            )}
        </>
    );
}

export {
    FormElementEntityChooser,
    mockables,
};
