import React, {useCallback, useMemo} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {
    Grid, IconButton,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {createDrivingRecord, updateDrivingRecord} from 'graphql/driveBuddy/DrivingRecord/mutations';
import {getDrivingRecord} from 'graphql/driveBuddy/DrivingRecord/queries';
import {
    endAddressRecommendationsDataSchema,
    kindDataSchema,
    reasonRecommendationsDataSchema,
    startAddressRecommendationDataSchema,
    userSchema,
    vehicleRecommendationsDataSchema,
} from 'applications/drivebuddy/modules/logbook/forms/drivingrecord/DrivingRecordSchema';
import {schema as validatorSchema} from 'beyond-validators/driveBuddy/DrivingRecord';
import {RouteLink} from 'components/RouteLink';
import {
    AccessTime, AddCircleOutline, OpenInBrowserOutlined, Refresh,
} from '@mui/icons-material';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';
import {useGlobalState} from 'hooks/useGlobalState';
import {LoadingButton} from '@mui/lab';

/**
 * @type {import("helper/recommendation-manager").RecommendationConfig}
 */
const recommendationConfig = {
    variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
    mask: {
        tenantId: true,
        userId: true,
        kind: false,
        reason: false,
        drivingRecordVehicleId: false,
        endAddress: false,
        startAddress: false,
        startDateTime: false,
        endKM: false,
        startKM: false,
    },
    valueDependencies: {
        drivingRecordVehicleId: [],
        startDateTime: [],
        startAddress: ['drivingRecordVehicleId'],
        endAddress: ['startAddress', 'drivingRecordVehicleId'],
        reason: ['startAddress', 'endAddress', 'drivingRecordVehicleId'],
        startKM: ['drivingRecordVehicleId'],
        kind: ['startAddress', 'endAddress'],
        endKM: ['startAddress', 'endAddress', 'startKM'],
        vehicle: ['drivingRecordVehicleId'],
    },
    optionDependencies: {
        reasonRecommendations: ['startAddress', 'endAddress', 'drivingRecordVehicleId'],
        startAddressRecommendations: ['drivingRecordVehicleId'],
        endAddressRecommendations: ['startAddress', 'drivingRecordVehicleId'],
        vehicleRecommendations: [],
    },
    attributeMapping: {
        reasonRecommendations: 'reason',
        startAddressRecommendations: 'startAddress',
        endAddressRecommendations: 'endAddress',
        vehicleRecommendations: 'drivingRecordVehicleId',
    },
    postProcess: (result) => ({
        ...result,
        vehicleRecommendations: result?.vehicleRecommendations?.map(
            (r) => ({...r, ...r.vehicle}),
        ),
    }),
    querySpec: {
        queryName: 'getDrivingRecordRecommendation',
        queryLabel: 'GetDrivingRecordRecommendation',
        possibleAttributes: {
            startAddress: true,
            endAddress: true,
            reason: true,
            startDateTime: true,
            startKM: true,
            endKM: true,
            kind: true,
            drivingRecordVehicleId: true,
            vehicle: {
                name: true,
                id: true,
            },
            reasonRecommendations: {
                rank: true,
                reason: true,
            },
            startAddressRecommendations: {
                rank: true,
                startAddress: true,
            },
            endAddressRecommendations: {
                rank: true,
                endAddress: true,
            },
            vehicleRecommendations: {
                vehicle: {
                    name: true,
                    id: true,
                },
                rank: true,
            },
        },
        possibleInputVariables: {
            tenantId: 'ID!',
            userId: 'ID!',
            kind: 'DrivingRecordKind',
            reason: 'String',
            drivingRecordVehicleId: 'ID',
            endAddress: 'String',
            startAddress: 'String',
            startDateTime: 'AWSDateTime',
            endKM: 'Int',
            startKM: 'Int',
        },
    },
};

/**
 * The DrivingRecord formular for creating and updating a driving record
 * @param {import('applications/configuration').FormularProps} props - props passed to the component
 * @returns {React.ReactElement} The DrivingRecordFormular component
 */
function DrivingRecordFormular({id, onSave, ...rest}) {
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');
    const canCreateWorkingTimeLogs = useCanAccess('TimeBuddy', 'createWorkingTimeLog');
    const isLineManager = useCanAccess('LineManager');

    const postProcess = useCallback((item) => {
        const ownEntry = item.drivingRecordUserId === userId;
        return {
            ...item,
            ownEntry,
        };
    }, [userId]);
    const createType = id === 'create';
    const saveConfig = useMemo(() => ({
        ...(!createType) ? {
            mutation: updateDrivingRecord,
            variables: {global: {tenantId: 'tenantId'}},
            mask: {
                id: true,
                draft: false,
                startDateTime: false,
                endDateTime: false,
                startAddress: false,
                endAddress: false,
                startKM: false,
                endKM: false,
                reason: false,
                kind: false,
                drivingRecordUserId: true,
                drivingRecordVehicleId: false,
            },
        } : {
            mutation: createDrivingRecord,
            variables: {global: {tenantId: 'tenantId'}},
            mask: {
                tenantId: true,
                draft: false,
                drivingRecordUserId: false,
                drivingRecordVehicleId: false,
                startDateTime: false,
                endDateTime: false,
                startAddress: false,
                endAddress: false,
                endKM: false,
                reason: false,
                kind: false,
                startKM: false,
            },
            preProcess: (item) => ({
                ...item,
                drivingRecordUserId: item.ownEntry ?? true
                    ? userId
                    : item.drivingRecordUserId,
            }),
            postProcess,
        },
    }), [id, userId, postProcess]);

    const loadConfig = useMemo(() => ({
        query: getDrivingRecord,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess,
    }), [id, postProcess]);

    const canCreateDrivingRecords = useCanAccess('createDrivingRecord');

    return (
        <ItemData {...(!createType) ? {loadConfig} : {}} saveConfig={saveConfig}>
            <FormWrapper
                {...rest}
                isNewItem={createType}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (!createType) ? 'update' : 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                recommendationConfig={
                    createType
                        ? recommendationConfig
                        : undefined
                }
                messageKey={(!createType) ? 'DrivingRecord_Update' : 'DrivingRecord_Create'}
                context={`DrivingRecord${id}`}
            >
                <FormReset shouldClear={createType} />
                <FormElementActionButton
                    routeId="drivebuddy_drive_logbook_drivingrecord_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector="#action-button-frame"
                    disabled={createType || !canCreateDrivingRecords}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector="#action-button-frame"
                    disabled={createType}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Grid container spacing={2}>
                    <FormElementInfoChips showDraft showReadonly />
                    { isLineManager && (
                        <>
                            <Grid item xs={6} sm={12}>
                                <FormElementSwitch
                                    attribute="ownEntry"
                                    label="Eigener Eintrag"
                                    defaultTrue
                                    disabled={!createType}
                                />
                            </Grid>
                            <Grid item xs={6} sm={12} display="flex">
                                <FormElementContainer attribute="ownEntry" conditionalRender={({value}) => !value}>
                                    <FormElementAutocomplete
                                        attribute="drivingRecordUserId"
                                        label="Benutzer"
                                        readOnly={!createType}
                                        dataSchema={userSchema}
                                        optionReference="user"
                                        optionsFilter={useCallback((option) => option.id !== userId, [userId])}
                                    />
                                    <FormElementContainer
                                        attribute="drivingRecordUserId"
                                        propsMapping={(props) => ({
                                            routeParams: {id: props?.value},
                                            disabled: _.isNil(props?.value),
                                        })}
                                    >
                                        <RouteLink routeId="beyondbuddy_settings_general_user_route">
                                            <IconButton><OpenInBrowserOutlined /></IconButton>
                                        </RouteLink>
                                    </FormElementContainer>
                                </FormElementContainer>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} display="flex">
                        <FormElementAutocomplete
                            label="Fahrzeug"
                            attribute="drivingRecordVehicleId"
                            optionReference="vehicle"
                            dataSchema={vehicleRecommendationsDataSchema}
                        />
                        <FormElementContainer
                            attribute="drivingRecordVehicleId"
                            propsMapping={(props) => ({
                                routeParams: {id: props?.value},
                                disabled: _.isNil(props?.value),
                            })}
                        >
                            <RouteLink routeId="drivebuddy_drive_settings_vehicle_route">
                                <IconButton><OpenInBrowserOutlined /></IconButton>
                            </RouteLink>
                        </FormElementContainer>
                    </Grid>
                    <Grid item xs={6} sm={12} md={6} xl={3}>
                        <FormElementDateTimePicker
                            label="Datum Start"
                            attribute="startDateTime"
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} md={6} xl={3}>
                        <FormElementDateTimePicker
                            label="Datum End"
                            attribute="endDateTime"
                        />
                    </Grid>
                    <Grid item xs={3} sm={6} md={3}>
                        <FormElementTextField
                            label="Start KM"
                            attribute="startKM"
                            type="int"
                        />
                    </Grid>
                    <Grid item xs={3} sm={6} md={3}>
                        <FormElementTextField
                            label="End KM"
                            attribute="endKM"
                            type="int"
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} md={6} xl={3}>
                        <FormElementAutocomplete
                            attribute="kind"
                            label="Art der Fahrt"
                            dataSchema={kindDataSchema}
                        />
                    </Grid>
                    <Grid item xs={12} xl={9}>
                        <FormElementAutocomplete
                            attribute="reason"
                            label="Grund"
                            dataSchema={reasonRecommendationsDataSchema}
                            freeSolo
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementAutocomplete
                            label="Start"
                            attribute="startAddress"
                            dataSchema={startAddressRecommendationDataSchema}
                            freeSolo
                            noFilter
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormElementAutocomplete
                            label="Ziel"
                            attribute="endAddress"
                            dataSchema={endAddressRecommendationsDataSchema}
                            freeSolo
                            noFilter
                        />
                    </Grid>
                    <Grid item md={3} sm={6}>
                        <FormElementLoadingButton label="Speichern" saveVariables={{draft: false}} />
                    </Grid>
                    <Grid item md={3} sm={6}>
                        <FormElementLoadingButton variant="text" label="Entwurf" draft />
                    </Grid>
                    { canCreateWorkingTimeLogs && (
                        // TODO only show for complete record
                        <FormElementContainer attribute="*" conditionalRender={({value}) => value.id && !value.draft}>
                            <Grid item md={6} sm={12}>
                                <FormElementContainer
                                    attribute="*"
                                    propsMapping={({value}) => ({
                                        state: {
                                            initialData: {
                                                ..._.pick(value, 'startDateTime', 'endDateTime'),
                                                kind: 'TRAVEL_ACTIVE',
                                                userId: value.drivingRecordUserId ?? userId,
                                                comment: `Fahrt ${value.startAddress} nach ${value.endAddress}`,
                                            },
                                        },
                                    })}
                                >
                                    <RouteLink routeId="timebuddy_workingtime_work_log_route" routeParams={{id: 'create'}}>
                                        <LoadingButton
                                            variant="text"
                                            startIcon={<AccessTime />}
                                        >
Arbeitszeiteintrag erstellen

                                        </LoadingButton>
                                    </RouteLink>
                                </FormElementContainer>
                            </Grid>
                        </FormElementContainer>
                    )}
                </Grid>
            </FormWrapper>
        </ItemData>
    );
}

export {DrivingRecordFormular};
