import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {UsersListFormular} from 'applications/beyondbuddy/settings/forms/user/UsersListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows list of users
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The UserListPage component
 */
function UsersListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Benutzer</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <UsersListFormular />
            </div>
        </LayoutContainer>
    );
}
export {UsersListPage};
