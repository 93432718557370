import {Box} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import React, {useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {WorkingTimeLogAbsenceFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeAbsenceLogFormular';
import {useRouteNavigate} from 'hooks/useRouteNavigation';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/** @typedef {"SICKLEAVE" |"VACATION" |"ABSENCE" |"COMPENSATION" | "SPECIAL_LEAVE"} AbsenceKind */

/** @type {Record<AbsenceKind, {create: string, view: string, routeId: string}>} */
const kindData = {
    ABSENCE: {create: 'Neue Abwesenheit eintragen', view: 'Abwesenheit', routeId: 'timebuddy_workingtime_absence_log_route'},
    COMPENSATION: {create: 'Neuen Zeitausgleich beantragen', view: 'Zeitausgleich', routeId: 'timebuddy_workingtime_compensation_log_route'},
    SICKLEAVE: {create: 'Krankenstand melden', view: 'Krankenstand', routeId: 'timebuddy_workingtime_sick_leave_log_route'},
    VACATION: {create: 'Neuen Urlaub beantragen', view: 'Urlaub', routeId: 'timebuddy_workingtime_vacation_log_route'},
    SPECIAL_LEAVE: {create: 'Neuen Sonderurlaub beantragen', view: 'Sonderurlaub', routeId: 'timebuddy_workingtime_special_leave_route'},
};

/**
 * ## WorkingTime log page
 * Displays information about a single `WorkingTimeLog`
 * @param {object} params - params for the component
 * @param {AbsenceKind} [params.kind] - kind of absence being displayed
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeLogAbsencePage({kind}) {
    // getting the URL parameters
    const {id} = useParams();
    const routeNavigate = useRouteNavigate();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id && result?.kind) {
            const {routeId} = kindData[result.kind];
            routeNavigate(routeId, {id: result.id});
        }
    }, [id, routeNavigate]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>{id === 'create' ? kindData[kind].create : kindData[kind].view}</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Box sx={{display: 'flex', gap: '2rem', marginTop: '1.5rem'}}>
                <Box sx={{flexGrow: 1}}>
                    <WorkingTimeLogAbsenceFormular initialData={useMemo(() => ({kind}), [kind])} id={id} onSave={onSave} />
                </Box>
            </Box>
        </LayoutContainer>
    );
}

export {WorkingTimeLogAbsencePage};
