/**
 * createLog
 * @property {string} stream - Name of the stream where the log should be created.
 * @property {string} message - Log message that should be created.
 * @returns {{level: string}} The level of the newly created log.
 */
export const createLog = /* GraphQL */ `
    mutation CreateLog(
        $stream: String!
        $message: AWSJSON
    ) {
        createLog(
            stream: $stream
            message: $message
        ) {
            stream
            message
        }
    }
`;
