import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {ItemData} from 'components/Form/ItemData';
import {createWorkingTimeModelAssignment} from 'graphql/timeBuddy/WorkingTimeModelAssignment/mutations';
import {schema as validatorSchema} from 'beyond-validators/timeBuddy/WorkingTimeModelAssignment';
import React, {useMemo} from 'react';
import _ from 'lodash';
import {Box, Grid} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {Refresh} from '@mui/icons-material';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {listWorkingTimeModelOptions} from 'graphql/timeBuddy/WorkingTimeModel/queries';
import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormReset} from 'components/Form/FormReset';

const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

const modelSchema = {
    query: listWorkingTimeModelOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => (option?.revision ? `${option?.name} #${option.revision}` : option?.name),
    getOptionValue: (option) => option?.id,
};

/**
 * The WorkingTimeModelAssignment formular for creating and updating a WorkingTimeModelAssignments
 * .TimeBuddy.Forms
 * @param {object} props - props passed to the component
 * @param {string} props.id - id of the assignment, or the keyword "create"
 * @param {(param: any) => void} [props.onSave] - onSave callback, that is invoked with results or errors after a response arrives
 * @param {React.ReactNode} [props.controls] - optional control elements
 * @param {string} [props.modelId] - modelId that only needs to be supplied when creating a new assignment
 * @returns {React.ReactElement} The WorkingTimeModelFormular component.
 */
function WorkingTimeModelAssignmentFormular({
    id, modelId, onSave, controls, ...rest
}) {
    const isNewItem = id === 'create';
    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        mask: {
            modelId: true,
            userId: true,
            startDate: true,
        },
        postProcess: (item) => ({grants: {updatable: false}, ...item}),
        mutation: createWorkingTimeModelAssignment,
        variables: {direct: {modelId}},
    }), [modelId]);

    return (
        <ItemData initialData={useMemo(() => ({modelId}), [modelId])} {...!isNewItem ? {} : {saveConfig}}>
            <FormWrapper
                {...rest}
                isNewItem={isNewItem}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (id !== 'create') ? 'update' : 'create',
                }}
                onSaveCallback={onSave}
                messageKey={!isNewItem ? 'WorkingTimeModelAssignment_View' : 'WorkingTimeModelAssignment_Create'}
                context={`WorkingTimeModelAssignment${id}`}
            >
                <FormReset shouldClear={id === 'create'} />
                {controls ? (
                    <Box display="flex" marginBottom="1rem" marginRight="1rem" justifyContent="space-between">
                        {controls}
                        {' '}
                        <FormElementActionButton
                            reload
                            disabled={isNewItem}
                            context={FormContext}
                        >
                            <Refresh />
                        </FormElementActionButton>
                    </Box>
                ) : (
                    <FormElementActionButton
                        reload
                        portalAnchorSelector="#action-button-frame"
                        disabled={isNewItem}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                )}

                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12} sm={6}>
                            <FormElementDatePicker label="Datum" attribute="startDate" />
                        </Grid>
                        <Grid item xs={12} sm={modelId ? 6 : 3}>
                            <FormElementAutocomplete attribute="userId" label="Benutzer" dataSchema={userDataSchema} />
                        </Grid>
                        {!modelId && (
                            <Grid item xs={12} sm={3}>
                                <FormElementAutocomplete attribute="modelId" label="Model" dataSchema={modelSchema} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormElementLoadingButton />
                        </Grid>
                    </Grid>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {WorkingTimeModelAssignmentFormular};
