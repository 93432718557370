import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {listWorkplaceOptions} from 'graphql/timeBuddy/Workplace/queries';
import _ from 'lodash';

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const kindDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'NORMAL',
        de: 'Normal',
    }, {
        value: 'TRAVEL_ACTIVE',
        de: 'Reisezeit (aktiv)',
    }, {
        value: 'TRAVEL_PASSIVE',
        de: 'Reisezeit (passiv)',
    }, {
        value: 'BREAK',
        de: 'Pause',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const absenceKindDataSchema = {
    dataKey: 'id',
    options: [
        {id: 'SICKLEAVE', label: 'Krankenstand'},
        {id: 'VACATION', label: 'Urlaub'},
        {id: 'ABSENCE', label: 'Abwesenheit ohne Zeitausgleich'},
        {id: 'COMPENSATION', label: 'Ausgleich'},
        {id: 'SPECIAL_LEAVE', label: 'Sonderurlaub'},
    ],
    getOptionLabel: (option) => option?.label,
    getOptionValue: (option) => option?.id,
};

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const workPlaceDataSchema = {
    query: listWorkplaceOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id', // needed for merging
    getOptionLabel: (option) => option?.name,
    getOptionValue: (option) => option?.id,
};

/** @type {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} */
export const userSchema = {
    query: listUserOptions,
    queryVariablesMask: {filter: false, lineManagerUserId: true, tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}, direct: {createWorkingTimeLog: true}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

/** @type {Record<import('../../pages/workingTimeLog/WorkingTimeAbsenceRequestPage').AbsenceKind, string>} */
export const idByKind = {
    ABSENCE: 'timebuddy_workingtime_absence_log_route',
    COMPENSATION: 'timebuddy_workingtime_compensation_log_route',
    SICKLEAVE: 'timebuddy_workingtime_sick_leave_log_route',
    VACATION: 'timebuddy_workingtime_vacation_log_route',
    SPECIAL_LEAVE: 'timebuddy_workingtime_special_leave_route',
};

export const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}, direct: {readWorkingTimeLog: true}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};
