/**
 * getWorkingTimeHoliday
 * @property {string} id - ID of the requested group.
 * @property {string} tenantId - ID of the tenant.
 * @returns {object} The requested group.
 */
export const getWorkingTimeHoliday = /* GraphQL */ `
  query GetWorkingTimeHoliday(
      $id: ID!
    ) {
    getWorkingTimeHoliday(
      id: $id
    ) {
      id
      label
      startDateTime
      endDateTime
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * listWorkingTimeHolidays
 * @property {string} tenantId - id of the tenant
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All groups in the given tenant.
 */
export const listWorkingTimeHolidays = /* GraphQL */ `
 query ListWorkingTimeHolidays (
      $tenantId: ID!
      $nextToken: String
      $filter: WorkingTimeHolidayListFilter
   ) {
   listWorkingTimeHolidays(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
     items {
       id
       label
       startDateTime
       endDateTime
       grants {
        deletable
        permissionsUpdatable
       }
     }
     nextToken
   }
 }
`;
