import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {EvacuationPointsListFormular} from 'applications/beyondbuddy/settings/forms/evacuationPoint/EvacuationPointsListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of EvacuationPoints.
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The EvacuationPointsListPage component
 */
function EvacuationPointListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Evakuierungspunkt</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <EvacuationPointsListFormular />
            </div>
        </LayoutContainer>
    );
}
export {EvacuationPointListPage};
