import React, {useMemo, useState} from 'react';
import {FormWrapper} from 'components/Form/FormWrapper';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {Alert, Grid} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {createWorkingTimeReport} from 'graphql/timeBuddy/WorkingTimeReports/mutations';
import {LoadingButton} from '@mui/lab';
import {CopyAllOutlined, DownloadOutlined} from '@mui/icons-material';
import {schema as validatorSchema} from 'beyond-validators/timeBuddy/WorkingTimeReports';
import {downloadURL} from 'helper/download';
import {formatDateTimeString} from 'helper/date';
import {useClipboard} from 'hooks/useClipboard';

const currentDate = new Date();
const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
lastDayOfMonth.setHours(23, 59);

const fileName = `${formatDateTimeString(currentDate.toDateString(), 'yyMM')}_TimeBuddy_WorkingTimeReport.xlsx`;

/** @type {import('components/Form/form').ItemSaveConfig} */
const generateConfig = {
    mutation: createWorkingTimeReport,
    variables: {
        global: {tenantId: 'tenantId', organizationalStructureItemId: 'tenantId'},
        direct: {fileName},
    },
    mask: {
        tenantId: true,
        organizationalStructureItemId: true,
        startDateTime: true,
        endDateTime: true,
        fileName: false,
    },
};

/**
 * The WorkingTimeReportsFormular for generating a worktime report
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The WorkingTimeReportsFormular component
 */
function WorkingTimeReportsFormular() {
    const initialData = useMemo(() => ({
        startDateTime: firstDayOfMonth.toISOString(),
        endDateTime: lastDayOfMonth.toISOString(),
    }), [new Date().getMonth()]);
    const [reportInformation, setReportInformation] = useState(null);
    const {copyToClipboard} = useClipboard();
    return (
        <ItemData
            saveConfig={generateConfig}
            initialData={initialData}
        >
            <FormWrapper
                messageKey="WorkingTimeReports"
                validatorSchema={{
                    schema: validatorSchema,
                    type: 'create',
                }}
                onSaveCallback={(result) => {
                    setReportInformation(result);
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormElementDateTimePicker
                            label="Datum VON"
                            attribute="startDateTime"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormElementDateTimePicker
                            label="Datum BIS"
                            attribute="endDateTime"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormElementLoadingButton label="Generieren" />
                    </Grid>
                    <Grid item display="flex" gap={2}>
                        <LoadingButton
                            variant="contained"
                            color="info"
                            startIcon={<DownloadOutlined />}
                            disabled={!reportInformation?.sheetDownloadURL}
                            onClick={() => {
                                if (reportInformation?.sheetDownloadURL) {
                                    downloadURL(reportInformation?.sheetDownloadURL, fileName);
                                }
                            }}
                        >
                            Download
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            color="info"
                            disabled={!reportInformation?.sheetDownloadURL}
                            onClick={() => copyToClipboard(reportInformation?.sheetDownloadURL, true)}
                            startIcon={<CopyAllOutlined />}
                        >
                            Link
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        {(reportInformation?.expiresIn)
                        && (
                            <Alert severity="info">
                                {`Der Report kann bis zum ${formatDateTimeString(reportInformation?.expiresIn)} heruntergeladen werden`}
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </FormWrapper>
        </ItemData>
    );
}

export {WorkingTimeReportsFormular};
