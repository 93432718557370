// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

export const listCemeteries = /* GraphQL */ `
  query ListCemeteries (
    $tenantId: ID!
    $filter: CemeteriesListFilterInput
    $nextToken: String
    ) {
    listCemeteries(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        tenantId
        nr
        referenceId
        name
        address
        zip
        city
        countryCode
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

export const listCemeteriesOptions = /* GraphQL */ `
  query ListCemeteriesOptions (
    $tenantId: ID!
    $filter: String
    $nextToken: String
    ) {
    listCemeteries(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: {
          search: $filter,
        }
    ) {
      items {
        id
        nr
        referenceId
        name
      }
      nextToken
    }
  }
`;

export const getCemetery = /* GraphQL */ `
  query GetCemetery(
      $id: ID!
    ) {
    getCemetery(
      id: $id
    ) {
      id
      tenantId
      nr
      referenceId
      name
      address
      zip
      city
      countryCode
      eMailAddress
      phoneNumber
      restPeriodUrnGrave
      restPeriodCoffinGrave
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;
