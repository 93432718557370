import React from 'react';
import {
    Button, Box, Fade, Modal, Typography,
} from '@mui/material';
import {ErrorOutline} from '@mui/icons-material';
import {useGlobalState} from 'hooks/useGlobalState';

import classes from 'components/applicationModal.module.scss';
import _ from 'lodash';

/**
 * The Modal that is displayed when the user tries to leave the page and there is unsaved data
 * @returns {React.ReactElement} - the modal
 */
function ApplicationModal() {
    const {getGlobal, setGlobal} = useGlobalState();
    const {open, onContinue, onCancel} = getGlobal('applicationModal') ?? {};

    const closeDialog = () => {
        setGlobal('applicationModal', {
            open: false,
        });
    };

    const onContinueWrapper = () => {
        if (_.isFunction(onContinue)) {
            onContinue();
        }
        closeDialog();
    };

    const onCancelWrapper = () => {
        if (_.isFunction(onCancel)) {
            onCancel();
        }
        closeDialog();
    };

    return (
        <Modal
            open={open ?? false}
            className={classes.modal}
            data-test="ApplicationModal"
            onClose={onCancelWrapper}
        >
            <Fade in={open}>
                <Box className={classes.box} sx={{bgcolor: 'background.paper'}}>
                    <ErrorOutline color="primary" style={{fontSize: '5rem'}} />
                    <Typography variant="h2" sx={{my: 2}}>
                        Du verlierst möglicherweise ungespeicherte Daten.
                    </Typography>
                    <Box style={{margin: '0 auto'}}>
                        <Button
                            onClick={onContinueWrapper}
                            variant="contained"
                            sx={{mr: '.5rem', width: '10rem'}}
                        >
                        Weiter
                        </Button>
                        <Button
                            onClick={onCancelWrapper}
                            variant="outlined"
                            sx={{ml: '.5rem', width: '10rem'}}
                        >
                        Abbrechen
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export {ApplicationModal};
