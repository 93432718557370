import React, {
    useCallback, useMemo, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {DeceasedPersonFormular} from 'applications/peacebuddy/settings/forms/deceasedPerson/DeceasedPersonFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {GraveRecordsListFormular} from 'applications/peacebuddy/settings/forms/grave/GraveRecordsListFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {ArticleOutlined, BlurOnOutlined, LockOutlined} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';

import {createDeceasedPerson, updateDeceasedPerson} from 'graphql/peaceBuddy/DeceasedPerson/mutations';
import {getDeceasedPerson} from 'graphql/peaceBuddy/DeceasedPerson/queries';
import {isValidGUID} from 'helper/guid';
import {NotFound} from 'applications/pages/NotFound';
import {useFindRoute} from 'hooks/useFindRoute';

const mask = {
    id: true,
    tenantId: true,
    referenceId: false,
    gender: true,
    titlePrefixed: false,
    firstName: true,
    lastName: true,
    titlePostfixed: false,
    nationalityCountryCode: true,
    dateOfBirth: true,
    placeOfBirth: false,
    placeOfBirthCountryCode: false,
    dateOfDeath: true,
    stillbirth: false,
    cremation: false,
    ashCapsuleNr: false,
    burried: false,
    attachmentKeys: false,
};

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the deceasedPerson formular
 */
const getDeceasedPersonFormular = (props) => <DeceasedPersonFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`DeceasedPerson#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#DeceasedPersonForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup', 'administrateDeceasedPersonGroup']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['administrateDeceasedPersonGroup']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a deceasedPerson.
 * @returns {React.ReactElement} The DeceasedPersonPage component.
 */
function DeceasedPersonPage() {
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const findRoute = useFindRoute();
    const [currentTab, setCurrentTab] = useState(0);

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...(id !== 'create') ? {
            mutation: updateDeceasedPerson,
            mask,
        } : {
            mutation: createDeceasedPerson,
            mask: _.omit(mask, 'id'),
        },
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getDeceasedPerson,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({
            ...data,
            attachmentKeys:
                'attachments' in data && data.attachments instanceof Array
                    ? JSON.stringify(data.attachments.map((item) => item.key))
                    : '[]',
        }),
    }), [id]);

    const getGraveRecordEntries = useCallback(() => (
        <GraveRecordsListFormular
            deceasedPersonId={id}
            actions={{
                edit: {
                    callBack: (item) => navigate(`/${generatePath(
                        findRoute('peacebuddy_settings_grave_sub_route').path,
                        {id: item.graveId, subId: item.id},
                    )}#graveRecord`),
                },
            }}
            actionButtonProps={{portalAnchorSelector: `#DeceasedPersonForm${id}action-button-frame-graveRecord-actions`}}
        />
    ), [id, navigate, generatePath, findRoute]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `DeceasedPersonForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
                initialData: {nationalityCountryCode: 'AT', placeOfBirthCountryCode: 'AT'},
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getDeceasedPersonFormular({id, onSave}),
                formWrapperId: `DeceasedPerson#${id}Base`,
                quickGuideId: 'peacebuddy_general_deceasedPerson',
            }, {
                id: 'graveRecord',
                label: 'Beisetzungen',
                icon: <ArticleOutlined />,
                enabledCondition: 'graveRecordReadable',
                getChildren: () => id !== 'create' && getGraveRecordEntries(),
                quickGuideId: 'peacebuddy_general_graveRecord',
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `DeceasedPerson#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab,
            getDeceasedPersonFormular, getPermissionFormular, setCurrentTab, onSave]);

    // check if id is a guid and return notfound it is not
    if (!isValidGUID(id)) {
        return <NotFound />;
    }

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {DeceasedPersonPage};
