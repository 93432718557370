import React, {useState} from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Circle,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Grid, Typography} from '@mui/material';
import {deleteGrave} from 'graphql/peaceBuddy/Grave/mutations';
import {listGraves} from 'graphql/peaceBuddy/Grave/queries';

import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import _ from 'lodash';
import {formatDateTimeString} from 'helper/date';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {cemeteriesDataSchema, coffinGraveKindDataSchema, graveTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';

/**
 * Formular for list graves
 * @returns {React.ReactElement} The GraveListFormular component
 */
function GraveListFormular() {
    const messageKey = 'Grave_List';
    const [graveKindDataSchema, setGraveKindDataSchema] = useState(coffinGraveKindDataSchema);
    return (
        <ListData
            loadConfig={{
                query: listGraves,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteGrave,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_grave_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createGrave')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider
                id="Grave-unit-filter"
                messageKey={messageKey}
                onChangeCallback={(attribute, value) => {
                    if (attribute === 'type') {
                        const typeSchemaOption = _.find(graveTypeDataSchema.options, (option) => option.value === value);
                        setGraveKindDataSchema(typeSchemaOption?.formDataSchema ?? coffinGraveKindDataSchema);
                    }
                }}
            >
                <FilterElementText label="Grabkartennr. / Obj.Nr." path="generalNr" />
                <Grid container spacing={1}>
                    <Grid item xs={12}><FilterElementAutocomplete label="Friedhof" path="cemeteryId" dataSchema={cemeteriesDataSchema} /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete label="Art" path="type" dataSchema={graveTypeDataSchema} /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete label="Form" path="kind" dataSchema={graveKindDataSchema} /></Grid>
                    <Grid item xs={12}><FilterElementCheckmark label="Frei" path="free" /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Frei',
                                            renderItem: (item) => (<Circle color={item.free ? 'success' : 'info'} />),
                                        },
                                    ],
                                    boxProps: {style: {width: '30px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Nr.',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold">{item.generalNr}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        style: {width: '150px'},
                                    },
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Ort',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold">{_.join(_.compact([item.division, item.subDivision, item.nr]), '/')}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '150px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Letztes Begräbnis',
                                            renderItem: (item) => <Typography noWrap textOverflow="ellipsis">{formatDateTimeString(item.lastFuneral, 'dd.MM.yyyy')}</Typography>,
                                        },
                                    ],
                                    boxProps: {style: {width: '230px'}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'peacebuddy_settings_grave_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'peacebuddy_settings_grave_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {GraveListFormular};
