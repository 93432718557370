import React, {
    useMemo, useState,
    useCallback,
} from 'react';
// import classes from 'assets/theme/components/ActivationOverlay/activationOverlay.module.scss';
import {Box, Button, Tooltip} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import _ from 'lodash';

/**
 * This button is locked until you press and hold the button
 * @param {object} props - props that were passed to this component.
 * @param {React.ReactNode} props.children - all form elements to be rendered and handled
 * @param {Function} props.onClick - actual click function
 * @param {boolean} [props.bypass] - no unlock needed
 * @param {boolean} [props.disabled] - disables the unlock button
 * @returns {React.ReactElement} - unlockable button
 */
function UnlockButton({
    children, onClick, bypass, disabled, ...rest
}) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [isLocked, setIsLocked] = useState(!bypass);
    const [isLockedVisible, setIsLockedVisible] = useState(isLocked);

    const debounceLockedState = useMemo(() => _.debounce(setIsLockedVisible, 500), [setIsLockedVisible]);

    const handleStartAction = () => {
        debounceLockedState(false);
    };

    const handleStopAction = useCallback((e) => {
        if (isLocked) {
            debounceLockedState.cancel();
            if (!isLockedVisible) {
                setIsLocked(false);
                setTimeout(() => {
                    setIsLocked(true);
                    setIsLockedVisible(true);
                }, 5000);
            }
        } else if (e?.type === 'mouseup') {
            onClick();
        }
    }, [isLocked, isLockedVisible, debounceLockedState, onClick, setIsLocked, setIsLockedVisible]);

    const handleClick = useCallback(() => {
        if (isLocked) {
            setShowTooltip(true);
            setTimeout(() => {
                setShowTooltip(false);
            }, 1500);
        }
    }, [isLocked, setShowTooltip]);

    return (
        <Tooltip open={showTooltip} title="Halten zum Entsperren">
            <Button
                {...rest}
                disabled={disabled}
                onTouchStart={handleStartAction}
                onTouchEnd={handleStopAction}
                onTouchCancel={handleStopAction}
                onMouseDown={handleStartAction}
                onMouseUp={handleStopAction}
                onMouseLeave={handleStopAction}
                onClick={handleClick}
            >
                <Box
                    sx={{display: isLockedVisible ? 'flex' : 'none'}}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        padding: '0.4rem',
                        color: 'gray',
                        opacity: '0.8',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <LockOutlined style={{fontSize: '0.7rem'}} />
                </Box>
                <Box
                    style={{display: 'flex'}}
                    sx={{color: isLocked ? 'gray' : 'inherit'}}
                >
                    {children}
                </Box>
            </Button>
        </Tooltip>
    );
}

export {UnlockButton};
