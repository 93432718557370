import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {listEndAddressRecommendations, listReasonRecommendations, listStartAddressRecommendations} from 'graphql/driveBuddy/DrivingRecord/queries';
import {listVehicleRecommendationsWithVehicle} from 'graphql/driveBuddy/Vehicle/queries';
import _ from 'lodash';

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const startAddressRecommendationDataSchema = {
    query: listStartAddressRecommendations,
    queryVariablesMask: {
        tenantId: true, userId: true, filter: false, vehicleId: false,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', userId: 'userId'},
        sibling: {vehicleId: 'drivingRecordVehicleId'},
    },
    dataKey: 'startAddress', // needed for merging
    getOptionLabel: (option) => option?.startAddress,
    getOptionValue: (option) => option?.startAddress,
    convertOption: (data) => ({startAddress: data, rank: 0}),
};
/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const endAddressRecommendationsDataSchema = {
    query: listEndAddressRecommendations,
    queryVariablesMask: {
        tenantId: true, userId: true, filter: false, startAddress: false, vehicleId: false,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', userId: 'userId'},
        sibling: {vehicleId: 'drivingRecordVehicleId', startAddress: 'startAddress'},
    },
    dataKey: 'endAddress', // needed for merging
    getOptionLabel: (option) => option?.endAddress,
    getOptionValue: (option) => option?.endAddress,
    convertOption: (data) => ({endAddress: data, rank: 0}),
};

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const vehicleRecommendationsDataSchema = {
    query: listVehicleRecommendationsWithVehicle,
    queryVariablesMask: {tenantId: true, userId: true, filter: false},
    queryVariables: {global: {tenantId: 'tenantId', userId: 'userId'}},
    dataKey: 'id', // needed for merging
    // removes the wrapping vehicle-object and flattens the results
    resultPreprocess: ({items, nextToken}) => ({
        items: _.map(items, (result) => _.merge(result?.vehicle, _.pick(result, ['rank', 'name', 'model']))),
        nextToken,
    }),
    getOptionLabel: (option) => `${option?.name ?? option?.id}`,
    getOptionValue: (option) => option?.id,
    convertOption: (data) => ({
        id: data?.id, name: data?.name, rank: 0, model: data?.model,
    }),
};
/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const reasonRecommendationsDataSchema = {
    query: listReasonRecommendations,
    queryVariablesMask: {
        tenantId: true, userId: true, filter: false, startAddress: false, vehicleId: false, endAddress: false,
    },
    queryVariables: {
        global: {tenantId: 'tenantId', userId: 'userId'},
        sibling: {vehicleId: 'drivingRecordVehicleId', startAddress: 'startAddress', endAddress: 'endAddress'},
    },
    dataKey: 'reason', // needed for merging
    getOptionLabel: (option) => option?.reason,
    getOptionValue: (option) => option?.reason,
    convertOption: (data) => ({reason: data, rank: 0}),
};

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const kindDataSchema = {
    dataKey: 'value',
    options: [{
        value: 'BUSINESS',
        de: 'Geschäftlich',
    }, {
        value: 'PRIVATE',
        de: 'Privat',
    }],
    getOptionLabel: (option) => option.de,
    getOptionValue: (option) => option.value,
};

/** @type {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} */
export const userSchema = {
    query: listUserOptions,
    queryVariablesMask: {filter: false, tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}, direct: {createDrivingRecord: true}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};
