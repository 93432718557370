import {useCallback} from 'react';
import {Messages} from 'messages/Messages';
import {useMessage} from 'hooks/useMessage';

/**
 * The return value of the useClipboard hook.
 * @typedef {object} UseClipboardFunction
 * @property {function(string, boolean): void} copyToClipboard - function to copy the text into the clipboard
/**
 * A hook to the variables from the global state.
 * @module useClipboard
 * @returns {UseClipboardFunction} - global state functions.
 */

const useClipboard = () => {
    const {enqueueMessage} = useMessage();

    // eslint-disable-next-line function-paren-newline
    const copyToClipboard = useCallback(
        /**
         * Sends a message to cloudwatch.
         * @param {string} text - text to be copied to the clipboard
         * @param {boolean} [showMessage] - indicates wether to show a message when copied
         */
        (text, showMessage = false) => {
            // only available in secure context
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text);
                if (showMessage) {
                    enqueueMessage('copy-to-clipboard', Messages.COPYTOCLIPBOARD_SUCCESSFUL);
                }
            } else {
                // eslint-disable-next-line no-console
                console.log(text);
            }
        }, [enqueueMessage]);

    return {
        copyToClipboard,
    };
};
export {useClipboard};
