import {useContext, useMemo} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import {Skeleton} from '@mui/material';
import _ from 'lodash';
import {DateTimePicker} from '@mui/x-date-pickers';
import moment from 'moment';

/**
 * A React component that is used to display a DateTimePicker.
 * @param {import('./formElements').FormElementDateTimePickerProps} props - props for the FormElement
 * @returns {object} - The DateTimePicker Component
 */
function FormElementDateTimePicker({
    attribute, minTimeAttribute, minDateAttribute, readOnly, ...rest
}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, get, onBlurHandler, isReadonly,
    } = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const elementData = useMemo(() => {
        const d = get(attribute);
        if (!d.value) {
            return d;
        }
        const v = moment(d?.value);
        // in case of date/time pickers, the displayValue must be set in case the value is set (edit)
        if (v && !d?.displayValue) {
            d.displayValue = v;
        }

        return {
            ...d,
            value: v.toISOString(),
        };
    }, [attribute, get]);

    const minTime = useMemo(() => (minTimeAttribute ? moment(minTimeAttribute) : null), [minTimeAttribute]);
    const minDate = useMemo(() => (minDateAttribute ? moment(minDateAttribute) : null), [minDateAttribute]);

    /**
     * Displays a loading animation if the context is still loading.
     */
    if (isLoading?.load) {
        return <Skeleton variant="rectangular" animation="wave" width="100%" height="53.13px" />;
    }

    return (
        <DateTimePicker
            minTime={minTime}
            minDate={minDate}
            disableIgnoringDatePartForTimeValidation
            // desktopModeMediaQuery="@media (min-width: 768px)"
            desktopModeMediaQuery={window.Cypress ? '@media (min-width: 268px)' : '@media (min-width: 768px)'}
            value={elementData?.displayValue ?? null}
            readOnly={isReadonly || readOnly}
            disabled={isReadonly || readOnly}
            slotProps={{
                // The actions will be the same between desktop and mobile
                actionBar: {
                    actions: ['today', 'clear', 'accept'],
                },

                textField: {
                    id: attribute,
                    // @ts-ignore
                    'data-test': `FormElementDateTimePicker_${attribute}`,
                    helperText: elementData?.error?.value,
                    error: !!elementData?.error?.value,
                    onBlur: () => onBlurHandler({
                        attribute,
                        value: elementData?.value,
                        displayValue: elementData?.displayValue,
                        error: elementData?.error,
                    }),
                },
            }}
            onAccept={(v) => {
                if (_.isNull(v)) {
                    onBlurHandler({
                        attribute,
                        value: null,
                        displayValue: null,
                        interacted: true,
                    });
                }
            }}
            onChange={(date) => {
                if (date === null) {
                    onBlurHandler({
                        attribute,
                        value: null,
                        displayValue: null,
                        interacted: true,
                    });
                    return;
                }
                if (date.isValid() && !_.isNaN(date.milliseconds())) {
                    onBlurHandler({
                        attribute,
                        value: date.toISOString(),
                        displayValue: date,
                        interacted: true,
                    });
                    return;
                }
                onBlurHandler({
                    attribute,
                    value: undefined,
                    displayValue: date,
                    interacted: true,
                });
            }}
            {...rest}
        />
    );
}

export {FormElementDateTimePicker};
