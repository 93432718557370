/**
 * @typedef {import("notistack").OptionsObject} OptionsObject
 * @typedef {{
 *  message: Record<string, string>;
 *  options?: OptionsObject;
 * }} message
 * @typedef {Record<string, message>} SnackbarMessage
 */

/**
 * .Messages
 * @satisfies {SnackbarMessage}
 */
const Messages = {
    API_SEND_SUCCESSFUL: {
        message: {
            de: 'Die Nachricht wurde erfolgreich versendet.',
        },
        options: {
            variant: 'success',
            // persist: true,
        },
    },
    API_SAVE_SUCCESSFUL: {
        message: {
            de: 'Der Eintrag wurde erfolgreich gespeichert.',
        },
        options: {
            variant: 'success',
            // persist: true,
        },
    },
    API_SYNC_SUCCESSFUL: {
        message: {
            de: 'Die Synchronisierung wurde erfolgreich durchgeführt.',
        },
        options: {
            variant: 'success',
            // persist: true,
        },
    },
    API_DELETE_SUCCESSFUL: {
        message: {
            de: 'Der Eintrag wurde erfolgreich gelöscht.',
        },
        options: {
            variant: 'success',
        },
    },
    API_LOG_SUCCESSFUL: {
        message: {
            de: 'Die Angelegenheit wurde weitergeleitet.',
        },
        options: {
            variant: 'success',
        },
    },
    DOWNLOAD_SUCCESSFUL: {
        message: {
            de: 'Erfolgreich heruntergeladen',
        },
        options: {
            variant: 'success',
        },
    },
    COPYTOCLIPBOARD_SUCCESSFUL: {
        message: {
            de: 'Erfolgreich in die Zwischenablage kopiert',
        },
        options: {
            variant: 'success',
        },
    },
    PERMISSION_REFRESH_INFO: {
        message: {
            de: 'Wir aktualisieren Ihre Berechtigungen - dies kann einige Sekunden in Anspruch nehmen.',
        },
        options: {
            variant: 'info',
            autoHideDuration: 1000 * 5,
        },
    },
};

export {Messages};
