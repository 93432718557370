import React from 'react';
import {
    Box,
} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a dashboard for the PeaceBuddy module
 * .PeaceBuddy.Pages
 * @returns {React.ReactElement} The PeaceBuddy DashBoard component
 */
function PeaceBuddyDashboard() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Dashboard</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                PeaceBuddy
            </div>
        </LayoutContainer>
    );
}
export {PeaceBuddyDashboard};
