import {useMemo} from 'react';
import {
    switchMap, startWith, NEVER, throttleTime, timer, Subject, mergeWith, retry,
} from 'rxjs';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listNotifications} from 'graphql/beyondBuddy/Notification/queries';
import {useGlobalState} from 'hooks/useGlobalState';
import {useSubscribe} from 'hooks/useSubscribe';
import {pageVisible$} from 'helper/visibility';

const notificationCheckInterval = 1000 * 60 * 10; // Every 10 min
const minimalNotificationRefreshDuration = 1000 * 60 * 3; // 3 min
const reloadSubject = new Subject();

/**
 * Observable that emits once after passing notification interval,
 * and after the page has not been visible, in case that it has passed the minimal notification refresh duration
 */
const notificationRefresh$ = pageVisible$.pipe(
    switchMap((visible) => (!visible
        ? NEVER
        : timer(0, notificationCheckInterval))),
    throttleTime(minimalNotificationRefreshDuration),
    mergeWith(reloadSubject),
);

export const reloadNotificationLists = () => {
    reloadSubject.next(true);
};

/**
 * Loads and keeps track of notifications
 * @returns {({subject: string, id: string, viewed: boolean})[]} notification list
 */
export const useNotificationList = () => {
    const {listItems} = AWSAppSyncProvider();
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');
    const tenantId = getGlobal('tenantId');
    const notificationList$ = useMemo(() => notificationRefresh$.pipe(
        switchMap(async () => {
            if (userId && tenantId) {
                return listItems(listNotifications, {userId, tenantId});
            }
            return [];
        }),
        retry({
            count: 10,
            delay: (_error, retryCount) => timer((1.5 ** retryCount) * 1000), // Exponential backoff
            resetOnSuccess: true,
        }),
        startWith([]),
    ), [listItems, userId, tenantId]);
    return useSubscribe(notificationList$, undefined, []);
};
