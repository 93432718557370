import {
    useCallback, useContext,
} from 'react';
// PACKAGES
import _ from 'lodash';
// CONTEXT
import {Context} from 'context/AppContext';

/**
 * The return value of the useGlobalState hook.
 * @typedef {object} GlobalStateFunctions
 * @property {function(string): object} getGlobal - type for the getGlobal function.
 * @property {function(string, any, boolean=): object} setGlobal - type of the setGlobal function.
 * @property {function(string): void} deleteGlobal - type of the deleteGlobal function.
 * @property {function(): void} deleteGlobalAll - type of the deleteGlobalAll function.
 * @property {(context?: string) => Array<string>|boolean} getFormHasChangesState - type of the getFormHasChangesState function.
 */

/**
 * A hook to get/set the global state.
 * @module useGlobalState
 * @property {function(string): {object}} getGlobal - function to get a global state value.
 * @property {Function} setGlobal - function to set a global state value.
 * @property {Function} deleteGlobal - function to delete a global state value.
 * @property {Function} deleteGlobalAll - function to delete all global state values.
 * @property {Function} getFormHasChangesState - function to get the state of a form change
 * @returns {GlobalStateFunctions} The global state functions.
 * @example
 *  const {getGlobal, setGlobal, deleteGlobal, deleteGlobalAll, getFormHasChangesState} = useGlobalState();
 */
const useGlobalState = () => {
    const ctx = useContext(Context);
    if (_.isNil(ctx)) {
        throw new Error('Global Context is missing. You need to add the Context to your App!');
    }

    // The AppContext.
    const {data, setData} = ctx;

    /**
     * Set a value in the global state.
     * @function setGlobal
     * @memberof module:useGlobalState
     * @param {string} key - key of the value.
     * @param {any} value - value to set.
     * @param {boolean} [persistent] - if the value should be stored in the local storage.
     * @returns {void}
     * @type {(key: string, value: any, persistent: boolean) => void}
     * @example
     * const {setGlobal} = useGlobalState();
     * setGlobal('myKey', 'myValue');
     */
    const setGlobal = useCallback((
        key,
        value,
        persistent,
    ) => {
        if (persistent) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            setData((
                /** @type {object} */
                curr,
            ) => {
                if (_.isEqual(curr[key], value)) {
                    return curr;
                }
                return {
                    ...curr,
                    [key]: value,
                };
            });
        }
    }, [setData]);

    /**
     * Get a value from the global state.
     * @function getGlobal
     * @memberof module:useGlobalState
     * @param {string} key - key of the value.
     * @returns {void}
     * @example
     * const {getGlobal} = useGlobalState();
     * const value = getGlobal('key');
     */
    const getGlobal = useCallback(
        // console.log(`requesting ${key} from the state`);
        (
            /** @type {string} - key of the value */
            key,
        ) => {
            const stateValue = _.get(data, key);
            if (_.isNil(stateValue)) {
                const localStorageValue = localStorage.getItem(key);
                if (!_.isNil(localStorageValue)) {
                    return JSON.parse(localStorageValue);
                }
                return undefined;
            }
            return stateValue;
        },
        [data],
    );

    /**
     * Delete a value from the global state.
     * @function deleteGlobal
     * @memberof module:hooks/useGlobalState
     * @param {string} key - key of the value.
     * @returns {void}
     * @example
     * const {deleteGlobal} = useGlobalState();
     * deleteGlobal('myKey');
     */
    const deleteGlobal = useCallback((
        /** @type {string} */
        key,
    ) => {
        // delete the current variable
        setData((curr) => (key in curr
            ? _.omitBy(curr, (v, k) => k === key)
            : curr));
        localStorage.removeItem(key);
    }, [setData]);

    /**
     * Delete all values from the global state.
     * @function deleteGlobalAll
     * @memberof module:useGlobalState
     * @returns {void}
     * @example
     * const {deleteGlobalAll} = useGlobalState();
     * deleteGlobalAll();
     */
    const deleteGlobalAll = useCallback(() => {
        _.forEach(data, (v, k) => deleteGlobal(k));
    }, [deleteGlobal, data]);

    /**
     * Checks if a form has changes
     * When a context is provided, it checks if the form with that context has changes
     * @function getFormHasChangesState
     * @memberof module:useGlobalState
     * @returns {Array<string>|boolean}
     */
    const getFormHasChangesState = useCallback((context) => {
        /** @type {Record<string, Array<string>>} */
        const formHasUnsavedChanges = getGlobal('formHasUnsavedChanges');
        if (!formHasUnsavedChanges) {
            return false;
        }
        const item = context && _.find(formHasUnsavedChanges, (k, e) => _.includes(e, context));

        return context
            ? item ?? false
            : true;
    }, [getGlobal]);

    return {
        getGlobal,
        setGlobal,
        deleteGlobal,
        deleteGlobalAll,
        getFormHasChangesState,
    };
};
export {useGlobalState};
