/* eslint-disable jsdoc/valid-types */
import React, {
    useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Button, Grid, LinearProgress, Tab, Tabs,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {createWorkingTimeHoliday, updateWorkingTimeHoliday} from 'graphql/timeBuddy/WorkingTimeHoliday/mutations';
import {getWorkingTimeHoliday, listWorkingTimeHolidays} from 'graphql/timeBuddy/WorkingTimeHoliday/queries';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {AddCircleOutline, Refresh} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementDateTimePicker} from 'components/Form/FormElements/FormElementDateTimePicker';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {TabPanel, useTabState} from 'assets/theme/layout/TabPanel';

import deAt2023 from 'applications/timebuddy/modules/workingtime/forms/workingTimeHoliday/de-At2023.json';
import deAt2024 from 'applications/timebuddy/modules/workingtime/forms/workingTimeHoliday/de-At2024.json';
import deAt2025 from 'applications/timebuddy/modules/workingtime/forms/workingTimeHoliday/de-At2025.json';
import deAt2026 from 'applications/timebuddy/modules/workingtime/forms/workingTimeHoliday/de-At2026.json';
import deAt2027 from 'applications/timebuddy/modules/workingtime/forms/workingTimeHoliday/de-At2027.json';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {
    Observable, defer, from, map, mergeMap, retry, startWith,
} from 'rxjs';
import {useMessage} from 'hooks/useMessage';
import {useLogChanged} from 'hooks/useLogChanged';
import {useGlobalState} from 'hooks/useGlobalState';
import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';
import {FormElementSwitch} from 'components/Form/FormElements/FormElementSwitch';

const tz = 'Europe/Vienna';
// @ts-ignore
const moment = extendMoment(momentWithoutRange);

const {schema: validatorSchema} = require('beyond-validators/timeBuddy/WorkingTimeHoliday');

const preProcess = (data) => {
    if (!data.fullDays) {
        return data;
    }
    const processed = {...data};
    if (data.startDateTime) {
        processed.startDateTime = moment(data.startDateTime).tz(tz).startOf('day').toISOString();
    }
    if (data.endDateTime) {
        processed.endDateTime = moment(data.endDateTime).tz(tz).endOf('day').toISOString();
    }

    return (processed);
};

const postProcess = (result) => {
    const startDateTimeDate = moment(result.startDateTime).tz(tz);
    const endDateTimeDate = moment(result.endDateTime).tz(tz);
    const fullDays = startDateTimeDate.isSame(startDateTimeDate.clone().startOf('day'))
&& (!result.endDateTime || endDateTimeDate.isSame(endDateTimeDate.clone().endOf('day')));
    return {
        ...result,
        fullDays,
    };
};

/**
 * Keeps the start and end of the form wrapper above constantly in sync
 * @returns {React.ReactElement} sync component
 */
// function StartEndSync() {
//     const {changeHandler, get} = useContext(FormContext);
//     const startDateTime = get('startDateTime').value;

//     useEffect(() => {
//         changeHandler({
//             attribute: 'endDateTime',
//             value: startDateTime,
//         });
//     }, [changeHandler, startDateTime]);

//     // eslint-disable-next-line react/jsx-no-useless-fragment
//     return <></>;
// }

const datasets = {
    deAt2023,
    deAt2024,
    deAt2025,
    deAt2026,
    deAt2027,
};
/**
 * @typedef {keyof typeof datasets} DatasetKey
 * @typedef {(typeof datasets)[DatasetKey]} DataSet
 */
/** @type {Record<keyof typeof datasets, number | boolean>} */
const startProgress = _.mapValues(datasets, () => false);

/**
 * Imports a dataset and reports on the progress in the form of an observable with percentages
 * @param {DataSet} dataset - dataset to process
 * @param {(query: string, variables: any) => Promise<any>} apiCall - api call function
 * @returns {Observable<number>} progress
 */
const importDataset$ = (dataset, apiCall) => from(dataset).pipe(
    mergeMap(({label, startDateTime}) => defer(() => {
        const start = moment(new Date(startDateTime));
        const end = start.clone().endOf('day');
        // if (!endDateTime) {
        start.startOf('day');
        // }
        return apiCall(createWorkingTimeHoliday, {
            label,
            startDateTime: start.toISOString(),
            endDateTime: end.toISOString(),
        });
    }).pipe(
        retry(3),
    ), 3),
    startWith(0),
    map((_value, index) => {
        const total = dataset.length;
        return 100 - 100 * (total - index - 1);
    }),
);

/**
 * Section with holiday dataset import buttons
 * @returns {React.ReactElement} import section
 */
function ImportHolidays() {
    const [progressState, setProgressState] = useState(startProgress);
    const {getGlobal} = useGlobalState();
    const tenantId = getGlobal('tenantId');

    useLogChanged({
        progressState,
        startProgress,
    });

    const {editItem, listItems} = AWSAppSyncProvider();
    const {enqueueMessage} = useMessage();

    /** @type {(key: keyof datasets) => void} */
    const loadDataset = (key) => {
        importDataset$(datasets[key], editItem).subscribe({
            complete: () => setProgressState((v) => ({...v, [key]: 100})),
            next: (num) => setProgressState((v) => ({...v, [key]: num})),
            error: () => enqueueMessage('HOLIDAY_IMPORT', 'Die Feiertage konnten nicht importiert werden', {
                key,
                operation: 'Holiday import',
            }),
        });
    };

    useEffect(() => {
        _.forEach(datasets, (data, key) => {
            // get the last data point
            const dataPoint = data.at(-1);
            const start = moment(new Date(dataPoint.startDateTime)).startOf('day');
            listItems(listWorkingTimeHolidays, {
                tenantId,
                filter: {
                    // label: data.at(-1).label,
                    startDateTime: {gte: start.toISOString()},
                    // endDateTime: {lte: dateEnd.toISOString()},
                },
            }).then((results) => {
                if (results.some((item) => {
                    const itemStart = moment(item.startDateTime).startOf('day');
                    return item.label === dataPoint.label && itemStart.isSame(start);
                })) {
                    setProgressState((v) => ({...v, [key]: 100}));
                }
            });
        });
        // Checking if the last days of each dataset are entered
    }, [listItems, tenantId]);

    return (
        <Grid container gap="1rem">
            <Grid item xs={4}>
                <Button
                    disabled={Boolean(progressState.deAt2023)}
                    variant="contained"
                    onClick={() => loadDataset('deAt2023')}
                >
                        Österreichische Feiertage 2023
                </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" flexDirection="column">
                <LinearProgress variant="determinate" value={Number(progressState.deAt2023)} />
            </Grid>
            <Grid item xs={4}>
                <Button
                    disabled={Boolean(progressState.deAt2024)}
                    variant="contained"
                    onClick={() => loadDataset('deAt2024')}
                >
                        Österreichische Feiertage 2024
                </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" flexDirection="column">
                <LinearProgress variant={progressState.deAt2024 === 0 ? 'indeterminate' : 'determinate'} value={Number(progressState.deAt2024)} />
            </Grid>
            <Grid item xs={4}>
                <Button
                    disabled={Boolean(progressState.deAt2025)}
                    variant="contained"
                    onClick={() => loadDataset('deAt2025')}
                >
                        Österreichische Feiertage 2025
                </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" flexDirection="column">
                <LinearProgress variant={progressState.deAt2025 === 0 ? 'indeterminate' : 'determinate'} value={Number(progressState.deAt2025)} />
            </Grid>
            <Grid item xs={4}>
                <Button
                    disabled={Boolean(progressState.deAt2026)}
                    variant="contained"
                    onClick={() => loadDataset('deAt2026')}
                >
                        Österreichische Feiertage 2024
                </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" flexDirection="column">
                <LinearProgress variant={progressState.deAt2026 === 0 ? 'indeterminate' : 'determinate'} value={Number(progressState.deAt2026)} />
            </Grid>
            <Grid item xs={4}>
                <Button
                    disabled={Boolean(progressState.deAt2027)}
                    variant="contained"
                    onClick={() => loadDataset('deAt2027')}
                >
                        Österreichische Feiertage 2024
                </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" flexDirection="column">
                <LinearProgress variant={progressState.deAt2027 === 0 ? 'indeterminate' : 'determinate'} value={Number(progressState.deAt2027)} />
            </Grid>
        </Grid>
    );
}

/**
 * Tabs for holidays
 * @param {object} params - params
 * @param {string} params.id - id of the current holiday
 * @returns {React.ReactElement} tabs
 */
function HolidayTaps({id}) {
    const tabState = useTabState({
        imports: id === 'create',
    });

    return (
        <>
            <hr style={{margin: '1rem 0 1rem 0'}} />
            <Tabs
                value={tabState.selectedTab}
                onChange={(_other, next) => tabState.selectTab(next)}
                aria-label="Tabs for holidays"
            >
                <Tab label="Importieren" {...tabState.tabArgs.imports} />
            </Tabs>
            <TabPanel {...tabState.tabPanelArgs.imports}>
                <ImportHolidays />
            </TabPanel>
        </>
    );
}

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').FormularProps} props - props passed to the component
 * @returns {React.ReactElement} The WorkingTimeHolidayFormular component.
 */
function WorkingTimeHolidayFormular({id, onSave, ...rest}) {
    const canCreateWorkingTimeHolidays = useCanAccess('createWorkingTimeHoliday');
    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        postProcess,
        preProcess,
        ...(id !== 'create') ? {
            mutation: updateWorkingTimeHoliday,
            mask: {
                id: true,
                label: true,
                startDateTime: true,
                endDateTime: true,
            },
        } : {
            mutation: createWorkingTimeHoliday,
            mask: {
                label: true,
                startDateTime: true,
                endDateTime: true,
            },
        },
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        postProcess,
        query: getWorkingTimeHoliday,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    return (
        <ItemData {...(id !== 'create') ? {loadConfig} : {}} saveConfig={saveConfig} initialData={{fullDays: true}}>
            <FormWrapper
                {...rest}
                isNewItem={id === 'create'}
                validatorSchema={{
                    schema: validatorSchema,
                    type: (id !== 'create') ? 'update' : 'create',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={(id !== 'create') ? 'WorkingTimeHoliday_Update' : 'WorkingTimeHoliday_Create'}
                context={`WorkingTimeHoliday${id}`}
            >
                <FormReset shouldClear={id === 'create'} />
                <Box style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
                >

                    <FormElementActionButton
                        routeId="timebuddy_workingtime_holiday_route"
                        routeParams={{id: 'create'}}
                        portalAnchorSelector="#action-button-frame"
                        disabled={id === 'create' || !canCreateWorkingTimeHolidays}
                        context={FormContext}
                    >
                        <AddCircleOutline />
                    </FormElementActionButton>
                    <FormElementActionButton
                        reload
                        portalAnchorSelector="#action-button-frame"
                        disabled={id === 'create'}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                    <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                        <Grid container spacing={2}>
                            <FormElementInfoChips showReadonly />
                            <Grid item xs={12}>
                                <FormElementTextField label="Name" attribute="label" />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <FormElementSwitch label="Ganztag" attribute="fullDays" />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={5}>
                                <FormElementContainer attribute="fullDays" conditionalRender={({value}) => value}>
                                    <FormElementDatePicker
                                        label="Datum Start"
                                        attribute="startDateTime"
                                    />
                                </FormElementContainer>
                                <FormElementContainer attribute="fullDays" conditionalRender={({value}) => !value}>
                                    <FormElementDateTimePicker
                                        label="Datum Start"
                                        attribute="startDateTime"
                                        minutesStep={15}
                                    />
                                </FormElementContainer>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={5}>
                                <FormElementContainer attribute="fullDays" conditionalRender={({value}) => value}>
                                    <FormElementDatePicker
                                        label="Datum End"
                                        attribute="endDateTime"
                                        minDateAttribute="startDateTime"
                                    />
                                </FormElementContainer>
                                <FormElementContainer attribute="fullDays" conditionalRender={({value}) => !value}>
                                    <FormElementDateTimePicker
                                        label="Datum End"
                                        attribute="endDateTime"
                                        minTimeAttribute="startDateTime"
                                        minutesStep={15}
                                    />
                                </FormElementContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <FormElementLoadingButton />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </FormWrapper>
            <HolidayTaps id={id} />
        </ItemData>
    );
}

export {WorkingTimeHolidayFormular};
