// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

/**
 * creareWorkingTimeLog
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} userId - id of the user for which to create the log.
 * @property {string} comment - comment on the work log
 * @property {boolean} draft - flag that makes the log a draft
 * @property {string} kind - kind of the worktime log
 * @property {string} workplaceId - id of the place
 * @property {string} templateId - id of the log template
 * @returns {object} - the created WorkingTimeLog
 */
export const createWorkingTimeWorkLog = /* GraphQL */ `
  mutation CreateWorkingTimeWorkLog(
    $draft: Boolean
    $userId: ID!
    $startDateTime: AWSDateTime
    $endDateTime: AWSDateTime
    $comment: String
    $kind: WorkingTimeLogKind
    $workplaceId: ID
    $templateId: ID
    $secret: String
  ) {
    createWorkingTimeWorkLog(
      userId: $userId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      draft: $draft
      comment: $comment
      kind: $kind
      workplaceId: $workplaceId
      templateId: $templateId
      secret: $secret
    ) {
      id
      draft
      startDateTime
      endDateTime
      kind
      templateId
      workplaceId
      workplace{
        id
        name
      }
      grants {
        updatable
      }
      userId
      user {
        id
        contactFirstName
        contactLastName
      }
    }
  }
`;

/**
 * createWorkingTimeAbsenceLog
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} userId - id of the user for which to create the log.
 * @property {string} comment - comment on the work log
 * @property {string[]} attachmentKeys - keys to files that are attached to the log
 * @property {string} kind - kind of the worktime log
 * @returns {object} - the created WorkingTimeLog
 */
export const createWorkingTimeAbsenceLog = /* GraphQL */ `
  mutation CreateWorkingTimeAbsenceLog(
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime
    $comment: String
    $attachmentKeys: AWSJSON
    $kind: WorkingTimeLogKind!
    $userId: ID!
  ) {
    createWorkingTimeAbsenceLog(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      comment: $comment
      attachmentKeys: $attachmentKeys
      kind: $kind
      userId: $userId
    ) {
      id
      status
      userId,
      kind,
      attachments {
        ... FileInformation
      }
      startDateTime
      endDateTime
      comment
      approver {
        contactFirstName
        contactLastName
        contactEMailAddress
        id
      }
      approverId
      grants {
        updatable
      }
      subGrants {
        judgable
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * updateWorkingTimeLog
 * @property {string} id - id.
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} comment - comment on the work log
 * @property {boolean} draft - flag that makes the log a draft
 * @property {string} kind - kind of the worktime log
 * @property {string} workplaceId - id of the place
 * @property {string} templateId - id of the log template
 * @returns {object} - the updated WorkingTimeLog
 */
export const updateWorkingTimeWorkLog = /* GraphQL */ `
  mutation UpdateWorkingTimeWorkLog(
    $id: ID!
    $startDateTime: AWSDateTime
    $endDateTime: AWSDateTime
    $kind: WorkingTimeLogKind
    $draft: Boolean
    $comment: String
    $workplaceId: ID
    $secret: String
  ) {
    updateWorkingTimeWorkLog(
      id: $id
      comment: $comment
      kind: $kind
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      draft: $draft
      workplaceId: $workplaceId
      secret: $secret
    ) {
      id  
      comment
      draft
      kind
      startDateTime
      endDateTime
      workplaceId
      workplace{
        id
        name
      }
      grants {
        updatable
      }
    }
  }
`;

/**
 * updateWorkingTimeAbsenceLog
 * @property {string} id - id.
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @property {string} comment - comment on the work log
 * @property {string[]} attachmentKeys - keys of files that are attached to the log
 * @returns {object} - the updated WorkingTimeLog
 */
export const updateWorkingTimeAbsenceLog = /* GraphQL */ `
  mutation UpdateWorkingTimeAbsenceLog(
    $id: ID!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime
    $comment: String
    $attachmentKeys: AWSJSON
    $status: WorkingTimeLogAbsenceStatus!
  ) {
    updateWorkingTimeAbsenceLog(
      id: $id
      status: $status
      comment: $comment
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      attachmentKeys: $attachmentKeys 
    ) {
      id  
      comment
      kind
      status
      startDateTime
      endDateTime
      approver {
        contactFirstName
        contactLastName
        contactEMailAddress
        id
      }
      approverId
      attachments {
        ... FileInformation
      }
      grants {
        updatable
      }
      subGrants {
          judgable
        }
    }
  }
  ${FileInformationFragment}
`;

/**
 * updateWorkingTimeAbsenceLogStatus
 * @property {string} id - id.
 * @property {string} status - new status of the time log
 * @returns {object} - the updated WorkingTimeLog
 */
export const updateWorkingTimeAbsenceLogStatus = /* GraphQL */ `
  mutation UpdateWorkingTimeAbsenceLog(
    $id: ID!
    $status: WorkingTimeLogAbsenceStatus!
  ) {
    updateWorkingTimeAbsenceLogStatus: updateWorkingTimeAbsenceLog(id: $id, status: $status) {
      id
      status
    }
  }
`;

/**
 * UpdateWorkingTimeAbsenceLogComment
 * @property {string} id - id.
 * @property {string} status - new status of the time log
 * @returns {object} - the updated WorkingTimeLog
 */
export const updateWorkingTimeAbsenceLogComment = /* GraphQL */ `
  mutation UpdateWorkingTimeAbsenceLogComment(
    $id: ID!
    $comment: String!
  ) {
    updateWorkingTimeAbsenceLogComment: updateWorkingTimeAbsenceLog(id: $id, comment: $comment) {
      comment
      id
    }
  }
`;

/**
 * completeWorkingTimeLog
 *
 * Closes the working log and set's the end time to the specified time.
 * May fail if other fields in the work log are missing (kind)
 * @property {string} id - id.
 * @property {string} endDateTime - end date time.
 * @returns {object} The updated WorkingTimeLog.
 */
export const completeWorkingTimeWorkLog = /* GraphQL */ `
  mutation CompleteWorkingTimeWorkLog(
    $id: ID!
    $endDateTime: AWSDateTime
    $draft: Boolean = false
  ) {
    updateWorkingTimeWorkLog(
      id: $id
      endDateTime: $endDateTime
      draft: $draft
    ) {
      id  
      comment
      draft
      kind
      startDateTime
      endDateTime
      grants {
        updatable
      }
    }
  }
`;

/**
 * deleteWorkingTimeLog
 * @property {string} id - WorkingTimeLog id.
 * @returns {object} The deleted WorkingTimeLog.
 */
export const deleteWorkingTimeWorkLog = /* GraphQL */ `
  mutation DeleteWorkingTimeWorkLog(
    $id: ID!
    ) {
    deleteWorkingTimeWorkLog(
      id: $id
    ) {
      id
    }
  }
`;

/**
 * deleteWorkingTimeAbsenceLog
 * @property {string} id - WorkingTimeLog id.
 * @returns {object} The deleted WorkingTimeLog.
 */
export const deleteWorkingTimeAbsenceLog = /* GraphQL */ `
  mutation DeleteWorkingTimeAbsenceLog(
    $id: ID!
  ) {
    deleteWorkingTimeAbsenceLog(id: $id) {
      id
    }
  }
`;
