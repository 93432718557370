import {Alert, Box} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import React, {useCallback, useEffect, useState} from 'react';
import {WorkingTimeLogFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeWorkLogFormular';
import {
    generatePath, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import workTimeLogImg from 'applications/timebuddy/modules/workingtime/pages/images/AdobeStock_296597100.jpg';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listTimePeriods} from 'graphql/timeBuddy/TimePeriod/queries';
import {CancelException} from 'hooks/useCancellablePromise';
import {formatDateTimeString} from 'helper/date';
import {useCanAccess} from 'hooks/useCanAccess';
import _ from 'lodash';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * ## WorkingTime log page
 * Displays information about a single `WorkingTimeLog`
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeWorkLogPage() {
    // getting the URL parameters
    const {id} = useParams();
    const navigate = useNavigate();
    const {listItems} = AWSAppSyncProvider();
    const [openPeriods, setOpenPeriods] = useState(null);
    const canReadWorkingTimeModel = useCanAccess('readWorkingTimeModel');
    const location = useLocation();

    /**
     * @type {import('routeinfo').RoutePathInfo}
     */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result.id})}`);
        }
    }, [id, route, navigate]);

    useEffect(() => {
        listItems(listTimePeriods, {filter: {open: true}})
            .then(async (periods) => {
                setOpenPeriods(periods);
            }).catch((error) => {
                if (!(error instanceof CancelException)) {
                    // eslint-disable-next-line no-console
                    console.error('Encountered error', error);
                }
            });
    }, [listTimePeriods, listItems, setOpenPeriods]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>{id === 'create' ? 'Neue Zeitbuchung' : 'Zeitbuchung bearbeiten'}</h2></Box>
                <Box>
                    <span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}}>
                        <QuickGuideLink id="timebuddy_workingtime_workingtimelog" isActionButton />
                    </span>
                </Box>
            </FormHeadContainer>
            {(canReadWorkingTimeModel && !openPeriods) && <Alert severity="info">Aktive Abrechnungsperioden werden geladen ...</Alert>}
            {(canReadWorkingTimeModel && openPeriods && !!openPeriods?.length) && (
                <Alert severity="info">
                    Zeitbuchungen können nur innerhalb der aktiven Periode(n) gebucht werden! (
                    {_.join(_.map(openPeriods, (period) => `${formatDateTimeString(period.startDate, 'dd.MM.yyy')} - ${formatDateTimeString(period.endDate, 'dd.MM.yyy')}`), ',')}
                    )
                </Alert>
            )}
            {(canReadWorkingTimeModel && openPeriods && !openPeriods?.length)
            && <Alert severity="warning">Es ist keine Abrechnungsperiode aktiv - es können aktuell nur Entwürfe gespeichert werden!</Alert>}
            <Box sx={{display: 'flex', gap: '2rem', marginTop: '1.5rem'}}>
                <Box
                    component="img"
                    src={workTimeLogImg}
                    alt="Zeitbuchung"
                    sx={{
                        maxWidth: '300px',
                        display: {xs: 'none', lg: 'block'},
                        marginBottom: 'auto',
                    }}
                />
                <Box sx={{flexGrow: 1}}>
                    <WorkingTimeLogFormular id={id} onSave={onSave} initialData={location.state?.initialData} />
                </Box>
            </Box>
        </LayoutContainer>
    );
}

export {WorkingTimeWorkLogPage};
