/**
 * listWorkingTimeModels
 * @property {string} tenantId - id of the tenant (required).
 * @property {object} filter - filter.
 * @property {string} nextToken - token to continue a listing (optional).
 * @returns {object} All users.
 */
export const listWorkingTimeModelAssignmentsByUser = /* GraphQL */ `
  query ListWorkingTimeModelAssignmentsByUser (
    $tenantId: ID!
    $userId: ID!
    $nextToken: String
    $startDate: DateConditionInput
  ) {
    listWorkingTimeModelAssignments(
      tenantId: $tenantId
      filter: {
        userId: $userId
        startDate: $startDate
      }
      nextToken: $nextToken
      ) {
      items {
        id
        modelId
        model {
           name
           revision
        }
        grants {
          deletable
        }
        startDate
        
      }
      nextToken
    }
  }
`;

/**
 * listWorkingTimeModels
 * @property {string} tenantId - id of the tenant (required).
 * @property {object} filter - filter.
 * @property {string} nextToken - token to continue a listing (optional).
 * @returns {object} All users.
 */
export const listWorkingTimeModelAssignmentsByModel = /* GraphQL */ `
  query ListWorkingTimeModelAssignmentsByModel (
    $tenantId: ID!
    $modelId: ID!
    $nextToken: String
    $startDate: DateConditionInput
  ) {
    listWorkingTimeModelAssignments(
      tenantId: $tenantId
      filter: {
        modelId: $modelId
        startDate: $startDate
      }
      nextToken: $nextToken
      ) {
      items {
        id
        userId
        grants {
          deletable
        }
        user {
          active
          contactFirstName
          contactLastName
          contactEMailAddress
          image {
            url
            options {
              image {
                position {
                  x
                  y
                  width
                  height
                }
              }
            }
          }
        }
        modelId
        startDate
        
      }
      nextToken
    }
  }
`;

/**
 * getWorkingTimeModelAssignment
 * @property {string} id - id of the WorkingTimeModelAssignment
 * @returns {object} The requested WorkingTimeModelAssignment.
 */
export const getWorkingTimeModelAssignment = /* GraphQL */ `
  query GetWorkingTimeModelAssignment(
    $id: ID!
    ) {
    getWorkingTimeModelAssignment(id: $id) {
      id
      userId
      user {
        contactFirstName
        contactLastName
      }
      modelId
      model {
        name
        revision
        crossRevId
      }
      startDate
    }
  }
`;
