/**
 * getWorkingTimeAggregate
 * @property {string} userId - id of the user (required)
 * @property {object} type - type of aggregate to load
 * @property {string} time - time for aggregate
 * @returns {object} All users.
 */
export const getWorkingTimeAggregate = /* GraphQL */ `
  query GetWorkingTimeAggregate (
    $userId: ID!
    $type: WorkingTimeAggregateType!
    $time: AWSDateTime
  ) {
    getTimeAggregate(
      userId: $userId
      time: $time
      type: $type
    ) {
        id
        value
        time
        tenantId
    }
  }
`;
