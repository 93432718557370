// eslint-disable-next-line no-restricted-imports
import {PermissionEntitiesFragment} from '../fragments';

/**
 * getLinks
 * @property {string} entityTypeId - entityTypeId.
 * @returns {object} The requested permission info.
 */
export const getLinks = /* GraphQL */ `
  query GetLinks (
    $entityTypeId: String!
    $isIncoming: Boolean
    $context: String
    ) {
    getLinks (
      entityTypeId: $entityTypeId, 
      isIncoming: $isIncoming,
      context: $context
    ) {
      links {
        leftEntityTypeId
        rightEntityTypeId
        permissions 
        attributes
      }
      ...PermissionEntities
    }
  }
  ${PermissionEntitiesFragment}
`;
