// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../fragments';

/**
 * Mutation to create information for a specific file
 * @property {string} key  - key of the file
 * @property {string} [filename] - filename of the file
 * @property {string} [description] - description of the file
 * @property {import('helper/helper').FileInformationOptions} [options] - options for the file
 * @returns {import('helper/helper').FileInformation} The newly created file information
 */
export const createFileInformation = /* GraphQL */ `
  mutation CreateFileInformation(
    $key: ID!
    $filename: String
    $description: String
    $options: FileInformationOptionsInput
  ) {
  createFileInformation(
    key: $key,
    filename: $filename,
    description: $description,
    options: $options
  ) {
    ...FileInformation
    }
  }
  ${FileInformationFragment}
`;

/**
 * Mutation to update the information for a specific file
 * @property {string} key  - key of the file
 * @property {string} [filename] - filename of the file
 * @property {string} [description] - description of the file
 * @property {import('helper/helper').FileInformationOptions} [options] - options for the file
 * @returns {import('helper/helper').FileInformation} The updated file information
 */
export const updateFileInformation = /* GraphQL */ `
  mutation UpdateFileInformation(
    $key: ID!
    $filename: String
    $description: String
    $options: FileInformationOptionsInput
  ) {
  updateFileInformation(
    key: $key,
    filename: $filename,
    description: $description,
    options: $options
  ) {
    ...FileInformation
    }
  }
  ${FileInformationFragment}
`;

/**
 * Delete the file information for a specific file
 * @property {string} key  - key of the file
 * @returns {import('helper/helper').FileInformation} The deleted file information
 */
export const deleteFileInformation = /* GraphQL */ `
 mutation DeleteFileInformation(
     $key: ID!
   ) {
   deleteFileInformation(
     key: $key,
     ) {
      key
   }
 }
`;
