import React from 'react';
import {
    Box, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import {
    LockClock, PlayCircleOutline, StopCircleOutlined, TableBar, TravelExplore,
} from '@mui/icons-material';
import {
    Tile, TileButton, TileIconBackground, TileLabel,
} from 'assets/theme/components/Tile/Tile';

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideWorktimeLogPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Arbeitszeit</Typography>
            <hr />
            <hr />
            <Box>
                Der TimeBuddy unterstützt bei der Protokollierung der Arbeitszeiten.
                Durch hilfreiche Funktionen soll eine zeitnahe Buchung ermöglicht werden um nachträgliche Buchungen zu vermeiden.
                <br />
                <br />
                Die nachfolgenden Symbole werden für die entsprechenden Zeit-Arten verwendet
                <List>
                    <ListItem>
                        <ListItemIcon><LockClock /></ListItemIcon>
                        <ListItemText primary="Normal" secondary="Das Arbeiten im Büro oder am definierten Arbeitsort" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><TableBar /></ListItemIcon>
                        <ListItemText primary="HomeOffice" secondary="Das Arbeiten von zu Hause" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><TravelExplore /></ListItemIcon>
                        <ListItemText primary="Dienstreise" secondary="z.B. die Fahrt zu und von einem Kunden" />
                    </ListItem>
                </List>
                <br />
                Ein Arbeitszeit-Eintrag kann nur in einer aktiven Periode gemacht werden.
                Dies ist notwendig, um einerseits die gesetzlichen Auflagen der Revisionssicherheit zu erfüllen, als auch andererseits eine abgeschlossene Buchhaltung zu ermöglichen.
                <br />
                <br />
                Um das Buchen so einfach wie möglich zu gestalten, gibt es am Dashboard eine Schnellstartfunktion welche eine Zeitbuchung startet oder stoppt.
                <br />
                <br />
                <Box display="flex" justifyContent="space-around">
                    <Tile>
                        <TileLabel label="Starten" />
                        <TileButton onClick={() => {}} />
                        <TileIconBackground icon={<PlayCircleOutline color="primary" />} />
                    </Tile>
                    <Tile>
                        <TileLabel label="Stoppen" />
                        <TileButton onClick={() => {}} />
                        <TileIconBackground icon={<StopCircleOutlined color="primary" />} />
                    </Tile>
                </Box>
                {/* <Typography variant="h2" color="primary">Weiterführende Kurzanleitungen</Typography>
                <Divider />
                <List dense>
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissions', label: 'Vergabe von Berechtigungen an Objekte der Aufbauorganisation'})}
                    {createQuickGuideListItem({id: 'beyondbuddy_general_permissionChooser', label: 'Auswahl von Objekten im Berechtigungssystem'})}
                </List> */}
            </Box>
        </>
    );
}
export {GuideWorktimeLogPage};
