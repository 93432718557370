import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {DrivingRecordsListFormular} from 'applications/drivebuddy/modules/logbook/forms/drivingrecord/DrivingRecordsListFormular';
import {useLocation} from 'react-router-dom';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of drivingrecords
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The DrivingRecordsPage component.
 */
function DrivingRecordsListPage() {
    const location = useLocation();
    const {initialFilters} = location.state ?? {};
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Fahrten</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <DrivingRecordsListFormular initialFilters={initialFilters} />
            </div>
        </LayoutContainer>
    );
}
export {DrivingRecordsListPage};
