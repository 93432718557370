import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {GraveListFormular} from 'applications/peacebuddy/settings/forms/grave/GraveListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of graves
 * @returns {React.ReactElement} The GraveListPage component
 */
function GraveListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Gräber</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <GraveListFormular />
            </div>
        </LayoutContainer>
    );
}
export {GraveListPage};
