import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import _ from 'lodash';

export const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}, direct: {readWorkingTimeLog: true}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};
