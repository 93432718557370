const {UUID_REGEX, ISO8601_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        drivingRecordVehicleId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format', 'required', 'format'],
            },
        ],
        startDateTime: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        endDateTime: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        startAddress: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type'],
            },
        ],
        endAddress: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type'],
            },
        ],
        startKM: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type'],
            },
        ],
        kind: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        endKM: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type'],
            },
        ],
        reason: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
    },
    backend: {
        drivingRecordVehicleId: [
            {
                actions: ['create', 'update'],
                configurations: ['format', 'required', 'format'],
            },
        ],
        startDateTime: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        endDateTime: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        startAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        endAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        startKM: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        kind: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        endKM: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        reason: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
    },
    attributes: {
        drivingRecordVehicleId: {
            required: true,
            format: UUID_REGEX,
        },
        startDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        endDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        startAddress: {
            type: 'String',
            required: true,
        },
        endAddress: {
            type: 'String',
            required: true,
        },
        startKM: {
            type: 'Number',
            required: true,
        },
        kind: {
            type: 'String',
            required: true,
            format: /^(PRIVATE|BUSINESS)$/,
        },
        endKM: {
            type: 'Number',
            required: true,
        },
        reason: {
            type: 'String',
        },
    },
};

module.exports = {schema};
