// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment, WorkingTimeModelHoursFragment} from '../fragments';

/**
 * listUsers
 * @property {string} tenantId - id of the tenant (required).
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing (optional).
 * @returns {object} All users.
 */
export const listUsers = /* GraphQL */ `
  query ListUsers (
    $tenantId: ID!
    $nextToken: String
    $filter: UserListFilterInput
    ) {
    listUsers(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: $filter
      ) {
      items {
        id
        active
        contactFirstName
        contactLastName
        contactEMailAddress
        notVerified
        organizationalUnit{
          name
        }
        tenantAdministrator
        lineManager{
          contactFirstName
          contactLastName
        }
        grants {
          permissionsUpdatable
          deletable
        }
        image{
          ...FileInformation
        }
      }
      nextToken
    }
  }
  ${FileInformationFragment}
`;

/**
 * getUser
 * @property {string} id - id of the user (required).
 * @returns {object} The requested user.
 */
export const getUser = /* GraphQL */ `
  query GetUser(
    $id: ID!
    ) {
    getUser(id: $id) {
      id
      active
      cognito
      microsoft
      tenantId
      contactFirstName
      contactLastName
      contactEMailAddress
      tenantAdministrator
      imageKey
      notVerified
      personalNumber
      emailNotifications
      image {
          ...FileInformation
      }
      grants {
        updatable
        deletable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
      subGrants {
        workingTimeModelAssignmentReadable
        workingTimeAggregateCorrectable
        workingTimeLogsReadable
      }
      lineManagerUserId
      lineManager {
        id
        contactFirstName
        contactLastName
      }
      entryDate
      exitDate
      organizationalUnitId
      organizationalUnit {
        id
        name
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * getUserProfile
 * @property {string} id - id of the user (required).
 * @returns {object} The requested user.
 */
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile {
    getOwnUser {
      id
      tenantId
      notVerified
      contactFirstName
      contactLastName
      contactEMailAddress
      tenantAdministrator
      isLineManager
      entryDate
      exitDate
      accessGrants {
        special {
          tenantAdministrator
          lineManager
          tenantReader
        }
        applications
        modules
        objectAccess
      }
      image {
        ...FileInformation
      }
      darkMode
      workingTimeModel {
        normalWorkingHours { ...WorkingTimeModelHours }
        agreedWorkingHours { ...WorkingTimeModelHours }
        fixedWorkingHours { ...WorkingTimeModelHours }
        workHoursMultiplication {...WorkingTimeModelHours}
        weeklyHours
        weeklyDays
        dailyHours
      }
    }
  }
  ${FileInformationFragment}
  ${WorkingTimeModelHoursFragment}
`;

/**
 * getUserWithWorkingTimeModel
 * @property {string} id - id of the user (required).
 * @returns {object} The requested user working time model.
 */
export const getUserWithWorkingTimeModel = /* GraphQL */ `
  query GetUserWithWorkingTimeModel(
    $id: ID!
    ) {
    getUser(id: $id) {
      entryDate
      exitDate
      workingTimeModel {
        vacationClaimWeeks
        dailyHours
        weeklyDays
        vacationClaimWeeks
        weeklyHours
        normalWorkingHours { ...WorkingTimeModelHours }
        agreedWorkingHours { ...WorkingTimeModelHours }
        fixedWorkingHours { ...WorkingTimeModelHours }
      }
    }
  }
  ${WorkingTimeModelHoursFragment}
`;

/**
 * listUsers
 * (used for the user dropdown)
 * @property {string} tenantId - id of the tenant (required).
 * @property {string} nextToken - token to continue a listing (optional).
 * @returns {object} All users.
 */
export const listUsersContactNames = /* GraphQL */ `
 query ListUsersContactNames (
    $tenantId: ID!
    $nextToken: String
  ) {
   listUsers(
      tenantId: $tenantId,
      nextToken: $nextToken) 
    {
     items {
       id
       contactFirstName
       contactLastName
     }
     nextToken
   }
 }
`;

/**
 * listUsers
 * @property {string} tenantId - id of the tenant (required).
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing (optional).
 * @property {string} lineManagerUserId - userid to restrict users to those the person is responsible for
 * @returns {object} All users.
 */
export const listUserOptions = /* GraphQL */ `
 query ListUserOptions (
   $tenantId: ID!
   $nextToken: String
   $filter: String
   $lineManagerUserId: String
   $createDrivingRecord: Boolean
   $createWorkingTimeLog: Boolean 
   $readWorkingTimeLog: Boolean
   $readDrivingRecord: Boolean
   ) {
   listUsers(
       tenantId: $tenantId,
       nextToken: $nextToken
       filter: {
          search: $filter,
          active: true,
          recursiveLineManagerUserId: $lineManagerUserId 
          createDrivingRecord: $createDrivingRecord
          createWorkingTimeLog: $createWorkingTimeLog
          readWorkingTimeLog: $readWorkingTimeLog
          readDrivingRecord: $readDrivingRecord
        }
     ) {
     items {
       id
       tenantId
       contactFirstName
       contactLastName
       contactEMailAddress
     }
     nextToken
   }
 }
`;
