const {UUID_REGEX, FILE_KEY_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        name: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        parentOrganizationalUnitId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format'],
            },
        ],
        imageKey: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format', 'fileExtensions'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['format', 'required'],
            },
        ],
        parentOrganizationalUnitId: [
            {
                actions: ['create', 'update'],
                configurations: ['format'],
            },
        ],
        name: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        imageKey: [
            {
                actions: ['create', 'update'],
                configurations: ['format', 'fileExtensions'],
            },
        ],
    },
    attributes: {
        id: {
            format: UUID_REGEX,
            required: true,
        },
        parentOrganizationalUnitId: {
            format: UUID_REGEX,
        },
        name: {
            type: 'String',
            length: {lte: 50},
            required: true,
        },
        imageKey: {
            format: FILE_KEY_REGEX,
            fileExtensions: ['jpg', 'jpeg', 'png'],
        },
    },
};

module.exports = {schema};
