/**
 * listNotifications
 * @property {string} tenantId - source tenant id.
 * @property {string} userId - user id.
 * @property {string} [nextToken] - next token for more results.
 * @returns {object} notifications of the given user.
 */
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $tenantId: ID!
    $userId: ID!
    $nextToken: String
  ) {
    listNotifications(
      tenantId: $tenantId
      filter: {userId: $userId}
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        level
        viewed
      }
      nextToken
    }
  }
`;

/**
 * getNotification
 * @property {string} id - source id.
 * @returns {object} All notification.
 */
export const getNotification = /* GraphQL */ `
  query GetNotification(
    $id: ID!
  ) {
    getNotification(id: $id) {
      id
      viewed
      subject
      message
      level
      referenceId
    }
  }
`;
