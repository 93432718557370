import * as React from 'react';

/**
 * Hook to check if the component is mounted.
 * @module useIsMounted
 * @returns {Function} A function that returns a boolean.
 * @example
 * const isMounted = useIsMounted()
 * if (isMounted()) {
 *    // do something
 * }
 */
const useIsMounted = () => {
    const mounted = React.useRef(false);
    const isMounted = React.useCallback(() => mounted.current, []);
    React.useEffect(() => {
        mounted.current = true;
        return () => { mounted.current = false; };
    }, []);
    return isMounted;
};

export {useIsMounted};
