import _ from 'lodash';
import {CancelException} from 'hooks/useCancellablePromise';
import {Exceptions} from 'messages/Exceptions';
import {maskVariables} from 'helper/mask-variables';
import {buildQuery} from 'helper/query-builder';

/**
 * @typedef RecommendationConfig
 * @property {import('helper/query-builder').QuerySpec} querySpec - parameters that allow for a query to be derived
 * @property {Record<string, boolean>} mask - mask to be applied to present variables
 * @property {boolean} [skipInvalid] - flag that skips requests that are invalid as specified via inputs specified by the mask but missing
 * @property {{direct?:object, global?: Record<string,string>}} [variables] - variables to be injected into the request
 * @property {(loadResult: object) => object} [postProcess] - post processing function.
 * Should bring the data into a format Record<attribute,{value?:any, options?: any[]}>
 * if it is not already
 * @property {(values: object) => object} [preProcess] - pre processing function.
 * Should bring data into the format expected by the query, if it is not already
 * @property {Record<string, string[]>} [valueDependencies] - dependency mapping from fields to set to their dependencies
 * @property {Record<string, string[]>} [optionDependencies] - dependency mapping from attributes who's options to set, to their dependencies
 * @property {Record<string, string>} [attributeMapping] - mapping between values/options requested, and attributes present in the real form.
 * Important for options that are set on the same values as other attributes
 */

/**
 * @typedef RecommendationLoadParams
 * @property {Function} getVariables - `getVariables` function obtained with the `useVariables` hook
 * @property {(message: *) => void} enqueueMessage - enqueues messages in case of load failures
 * @property {Function} changeHandler - change handler of the form context to update values and options
 */

/**
 * ## Recommendation Manager
 *
 * Handles actions and holds state regarding the initialization of values with smart recommendations,
 * and the setting of options for autocomplete fields
 *
 * Should be marked as disabled once a new one is created, to ensure potential pending requests don't take effect
 *
 */
class RecommendationManager {
    referenceData = Object();

    currentData = Object();

    disabled = false;

    /**
     * Recommendation manager constructor
     * @param {Function} getItem - api call function go get an item from the api
     * @param {RecommendationConfig} schedule - schedule that decides what dependencies should lead to what updates
     */
    constructor(getItem, schedule) {
        this.getItem = getItem;
        this.schedule = schedule;
        this.loadRecommendations = _.debounce(this.loadRecommendations.bind(this), 1000);
        this.queryCallback = buildQuery(schedule.querySpec);
    }

    /**
     * Checks if dependencies have changed, and if that is the case, queues a update for these exact changes
     * @param {RecommendationLoadParams} loadParams - functions required for loading
     */
    checkChangesAndUpdate(loadParams) {
        const values = this.updatableValues(this.schedule.valueDependencies);
        const options = this.updatableValues(this.schedule.optionDependencies);
        if (values.length || options.length) {
            this.loadRecommendations(loadParams, values, options);
        } else {
            // @ts-ignore .cancel not found because of reassignment
            this.loadRecommendations.cancel();
        }
    }

    /**
     * Executes a load operation for recommendations. This method is overwritten with a
     * debounced version of it, meaning it follows the interface of a `_.debounce`'d function,
     * holding the `cancel` and `flush` methods.
     * @param {RecommendationLoadParams} loadParams - functions required for loading
     * @param {string[]} [valueKeys] - keys of values that should be set as part of this call
     * @param {string[]} [optionKeys] - keys of options that should be set as part of this call
     * @returns {Promise<boolean>} - indicator if the recommendations were loaded
     */
    async loadRecommendations(
        loadParams,
        valueKeys,
        optionKeys,
    ) {
        if (this.disabled) {
            return false;
        }
        [valueKeys, optionKeys].forEach((keys) => {
            const dependencies = keys === valueKeys
                ? this.schedule.valueDependencies
                : this.schedule.optionDependencies;
            keys.forEach((attribute) => {
                dependencies[attribute].forEach((dependency) => {
                    const value = _.get(this.currentData, [dependency, 'value']);
                    _.set(this.referenceData, dependency, value);
                });
            });
        });
        try {
            const inputsData = _.chain(this.currentData)
                // .pickBy({interacted: true}) // in case of errors -> this was changed to also take initialValues
                .mapValues('value')
                .omit(valueKeys)
                .value();
            const {variables, missingObligatoryVariables} = maskVariables({
                ...this.schedule.preProcess?.(inputsData) ?? inputsData,
                ...loadParams.getVariables(this.schedule.variables),
            }, this.schedule.mask);
            if (!_.isEmpty(missingObligatoryVariables)) {
                if (!this.schedule.skipInvalid) {
                    loadParams.enqueueMessage(Exceptions.MISSING_ATTRIBUTES_ERROR);
                }
                return false;
            }
            const presentKeys = _.chain(variables)
                .omitBy(_.isUndefined)
                .keys()
                .value();
            const requestedAttributes = [
                ...valueKeys,
                ...optionKeys,
            ];
            const query = this.queryCallback(requestedAttributes, presentKeys);
            let result = await this.getItem(query, variables);
            if (this.disabled) {
                return false;
            }
            result = (this.schedule.postProcess ?? _.identity)(result);
            valueKeys.forEach((attribute) => {
                const interacted = _.get(this.currentData, [attribute, 'interacted']);
                const value = _.get(result, attribute);
                if (!interacted) {
                    _.set(this.referenceData, attribute, value);
                    const alternateAttr = _.get(this.schedule.attributeMapping, attribute, attribute);
                    loadParams.changeHandler({attribute: alternateAttr, value});
                }
            });
            optionKeys.forEach((attribute) => {
                const interacted = _.get(this.currentData, [attribute, 'interacted']);
                const options = _.get(result, attribute);
                if (!interacted) {
                    const alternateAttr = _.get(this.schedule.attributeMapping, attribute, attribute);
                    loadParams.changeHandler({attribute: alternateAttr, options});
                }
            });
            return true;
        } catch (err) {
            if (!(err instanceof CancelException)) {
                loadParams.enqueueMessage(Exceptions.API_LOAD_ERROR);
                // eslint-disable-next-line no-console
                console.error(err);
            }
            return false;
        }
    }

    /**
     * Checks if an attribute and it's dependencies need to be updated
     * @param {string} attribute - attribute to check for changes in
     * @param {string[]} dependencies - dependency array of the attribute
     * @returns {boolean} true if at least one dependency has changed, and the
     * attribute is still eligible for an update
     */
    attributeCanUpdate(attribute, dependencies) {
        const interacted = _.get(this.currentData, [attribute, 'interacted']);
        return (
            // user has not interacted with the field
            !interacted
            // some dependency has changed
            && dependencies.some((dependency) => !_.isEqual(
                _.get(this.currentData, [dependency, 'value']),
                _.get(this.referenceData, dependency),
            ))
        );
    }

    /**
     * computes values that should be updated
     * @param {Record<string, string[]>} dependencySet - mapping from attribute to dependencies
     * @returns {string[]} value keys
     */
    updatableValues(dependencySet) {
        const attributes = _.keys(dependencySet)
            .filter((attribute) => this.attributeCanUpdate(attribute, dependencySet[attribute]));
        return [...attributes, ...this.cascadedUpdatableValues(attributes, dependencySet ?? {})];
    }

    /**
     * Computes a list of attributes that may change after their dependency attributes receive an update.
     * These can then be requested additionally
     * @param {string[]} toBeChangedKeys - list of keys that will change
     * @param {Record<string,string[]>} dependencySet - set of dependencies for the values at hand (valueDependencies or optionDependencies)
     * @returns {string[]} list of other attributes that will change as part of the above changes
     */
    cascadedUpdatableValues(toBeChangedKeys, dependencySet) {
        const keys = _.keys(this.schedule.valueDependencies)
            .filter((attribute) => {
                const dependencyChanges = dependencySet[attribute]?.some?.((dependency) => toBeChangedKeys.includes(dependency));
                const interacted = _.get(this.currentData, [attribute, 'interacted']);
                return dependencyChanges && !interacted;
            });
        if (keys.length > 0) {
            return [...keys, ...this.cascadedUpdatableValues(keys, dependencySet)];
        }
        return [];
    }
}

export {RecommendationManager};
