// eslint-disable-next-line no-restricted-imports
import {WorkingTimeModelHoursFragment} from '../../beyondBuddy/fragments';

/**
 * createWorkingTimeModel
 * @returns {object} The newly created WorkingTimeModel.
 */
export const createWorkingTimeModel = /* GraphQL */ `
  mutation CreateWorkingTimeModel(
    $name: String!
    $templateId: ID
    $weeklyHours: Float
    $weeklyDays: Float
    $vacationClaimWeeks: Float!
    $dailyHours: Float!
    $normalWorkingHours: WorkingTimeModelTimeTableInput!
    $agreedWorkingHours: WorkingTimeModelTimeTableInput
    $fixedWorkingHours: WorkingTimeModelTimeTableInput
    $workHoursMultiplication: WorkingTimeModelTimeTableInput
    $info: String
    ) {
    createWorkingTimeModel(
        name: $name
        info: $info
        weeklyHours: $weeklyHours
        weeklyDays: $weeklyDays
        vacationClaimWeeks: $vacationClaimWeeks
        dailyHours: $dailyHours
        agreedWorkingHours: $agreedWorkingHours
        fixedWorkingHours: $fixedWorkingHours
        normalWorkingHours: $normalWorkingHours
        workHoursMultiplication: $workHoursMultiplication
        templateId: $templateId
    ) {
      id
      name
      templateId
      crossRevId
      template {
        revision
        id
        name
        crossRevId
      }
      weeklyHours
      weeklyDays
      vacationClaimWeeks
      dailyHours
      revision
      vacationClaimWeeks
      normalWorkingHours {...WorkingTimeModelHours}
      fixedWorkingHours {...WorkingTimeModelHours}
      agreedWorkingHours {...WorkingTimeModelHours}
      workHoursMultiplication {...WorkingTimeModelHours}
      info
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      subGrants {
        assignmentCreatable
        assignmentReadable
      }
    }
  }
  ${WorkingTimeModelHoursFragment}
`;

/**
 * createWorkingTimeModel
 * @returns {object} The newly created revision.
 */
export const createWorkingTimeModelRevision = /* GraphQL */ `
  mutation CreateWorkingTimeModel(
    $name: String!
    $templateId: ID
    $crossRevId: ID!
    $revision: Int!
    $vacationClaimWeeks: Float!
    $dailyHours: Float!
    $normalWorkingHours: WorkingTimeModelTimeTableInput!
    $agreedWorkingHours: WorkingTimeModelTimeTableInput
    $fixedWorkingHours: WorkingTimeModelTimeTableInput
    $workHoursMultiplication: WorkingTimeModelTimeTableInput
    $info: String
    ) {
      createWorkingTimeModelRevision: createWorkingTimeModel(
        name: $name
        info: $info
        crossRevId: $crossRevId
        revision: $revision
        vacationClaimWeeks: $vacationClaimWeeks
        dailyHours: $dailyHours
        agreedWorkingHours: $agreedWorkingHours
        fixedWorkingHours: $fixedWorkingHours
        normalWorkingHours: $normalWorkingHours
        workHoursMultiplication: $workHoursMultiplication
        templateId: $templateId
    ) {
      id
      name
      crossRevId
      templateId
      template {
        revision
        id
        name
        crossRevId
      }
      normalWorkingHours {...WorkingTimeModelHours}
      fixedWorkingHours {...WorkingTimeModelHours}
      agreedWorkingHours {...WorkingTimeModelHours}
      workHoursMultiplication {...WorkingTimeModelHours}
      info
      weeklyDays
      weeklyHours
      vacationClaimWeeks
      dailyHours
      revision
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      subGrants {
        assignmentCreatable
        assignmentReadable
      }
    }
  }
  ${WorkingTimeModelHoursFragment}
`;
