import React from 'react';
import {Box} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkplaceListFormular} from 'applications/timebuddy/settings/forms/workplace/WorkplaceListFormular';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of WorkPlaces
 * .TimeBuddy.Pages
 * @returns {React.ReactElement} - the WorkplaceListPage component
 */
function WorkplaceListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Orte</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <WorkplaceListFormular />
            </div>
        </LayoutContainer>
    );
}
export {WorkplaceListPage};
