/**
 * getTimePeriod
 * @property {string} id - ID of the requested group.
 * @property {string} tenantId - ID of the tenant.
 * @returns {object} The requested group.
 */
export const getTimePeriod = /* GraphQL */ `
  query GetTimePeriod(
      $id: ID!
    ) {
    getTimePeriod(
      id: $id
    ) {
      id
      startDate
      endDate
      open
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * listTimePeriod
 * @property {string} tenantId - id of the tenant
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} a list of time periods
 */
export const listTimePeriods = /* GraphQL */ `
  query ListTimePeriods (
       $nextToken: String
       $filter: TimePeriodListFilterInput
    ) {
    listTimePeriods (
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        startDate
        endDate
        open
        grants {
          deletable
        }
      }
      nextToken
    }
  }
`;
