import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Box} from '@mui/material';
import {NotificationsListFormular} from 'applications/beyondbuddy/settings/forms/notification/NotificationListFormular';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of groups.
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The GroupsListPage component
 */
function NotificationListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Benachrichtigungen</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <NotificationsListFormular />
            </div>
        </LayoutContainer>
    );
}
export {NotificationListPage};
