import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    ContentCopy,
    Delete,
    Edit,
    FirstPageOutlined,
    LastPageOutlined,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import _ from 'lodash';

import {Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {listWorkingTimeSchedules} from 'graphql/timeBuddy/WorkingTimeSchedule/queries';
import {deleteWorkingTimeSchedule} from 'graphql/timeBuddy/WorkingTimeSchedule/mutations';
import {formatDateTimeString} from 'helper/date';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {listUserOptions} from 'graphql/beyondBuddy/User/queries';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';

const userDataSchema = {
    query: listUserOptions,
    queryVariablesMask: {tenantId: true},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id',
    getOptionLabel: (option) => _.compact([option?.contactFirstName, option?.contactLastName]).join(' '),
    getOptionValue: (option) => option?.id,
};

/**
 * Formular for list groups listing
 * @returns {React.ReactElement} The GroupsListFormular component
 */
function WorkingTimeScheduleListFormular() {
    const messageKey = 'WorkingTimeSchedule_List';
    const canDelete = useCanAccess('deleteWorkingTimeSchedule');
    const canCreate = useCanAccess('createWorkingTimeSchedule');
    return (
        <ListData
            loadConfig={{
                query: listWorkingTimeSchedules,
                variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
                mask: {tenantId: true, filter: false, userId: false},
                preProcess: (variables) => ({
                    ...variables,
                    filter: variables.filter ?? {
                        participantId: variables.userId,
                    },
                }),
            }}
            deleteConfig={{
                mutation: deleteWorkingTimeSchedule,
                mask: {
                    id: true,
                },
            }}
        >
            <FormElementActionButton
                routeId="timebuddy_workingtime_schedule_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createWorkingTimeSchedule')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="schedule-filter" messageKey={messageKey}>
                <>
                    <FilterElementDate path="startDateTime.gte" label="Start nach" />
                    <FilterElementDate path="startDateTime.lte" label="Start bevor" />
                </>
                <FilterElementAutocomplete label="Zuständiger" path="userId" dataSchema={userDataSchema} />
                <FilterElementAutocomplete label="Teilnehmer" path="participantId" dataSchema={userDataSchema} />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Start',
                                            renderItem: (item) => (
                                                <>
                                                    <FirstPageOutlined color="info" />
                                                    <Typography>{`${formatDateTimeString(item.startDateTime, 'dd.MM.yyyy')}`}</Typography>
                                                </>
                                            ),
                                        }, {
                                            title: 'End',
                                            renderItem: (item) => (
                                                <>
                                                    <LastPageOutlined color="secondary" />
                                                    <Typography>{`${formatDateTimeString(item.endDateTime, 'dd.MM.yyyy')}`}</Typography>
                                                </>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '100px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Zuständiger',
                                            renderItem: (item) => (
                                                <Typography
                                                    noWrap
                                                    fontWeight="bold"
                                                >
                                                    {_.compact([item.user?.contactFirstName, item.user?.contactLastName]).join(' ')}
                                                </Typography>
                                            ),
                                        }, {
                                            title: 'Zuständiger EMail',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold">{item.user?.contactEMailAddress}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 1, md: 3, lg: 1}}},
                                }, {
                                    itemConfigurations: [
                                        {
                                            title: 'Schichten Anzahl',
                                            renderItem: (item) => (
                                                <Typography noWrap>
                                                    {item.shifts?.length ? `${item.shifts?.length} Schicht${item.shifts?.length > 1 ? 'en' : ''}` : false}
                                                </Typography>
                                            ),
                                        }, {
                                            title: 'Teilnehmer Anzahl',
                                            renderItem: (item) => <Typography noWrap>{item.participantIds?.length ? `${item.participantIds?.length} Teilnehmer` : false}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 1, md: 3, lg: 1}}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => canDelete && item?.grants?.deletable,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                }, {
                                    icon: <ContentCopy />,
                                    buttonProps: {title: 'Duplizieren'},
                                    routeParams: (item) => ({id: item.id}),
                                    routeId: 'timebuddy_workingtime_schedule_duplicate_route',
                                    isVisible: () => canCreate,
                                }, {
                                    icon: <Edit />,
                                    routeId: 'timebuddy_workingtime_schedule_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'timebuddy_workingtime_schedule_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkingTimeScheduleListFormular};
