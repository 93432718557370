/**
 * createReport for worktime
 * @property {string} organizationalStructureItemId - id of an item of the organizational structure (tenant, ou, group, user)
 * @property {string} tenantId - id of the tenant, the report is generated for *
 * @property {string} startDateTime - start date time.
 * @property {string} endDateTime - end date time.
 * @returns {object} - the info for the generated report
 */
export const createWorkingTimeReport = /* GraphQL */ `
  mutation CreateWorkingTimeReport(
    $tenantId: ID!,
    $organizationalStructureItemId: ID!,
    $startDateTime: AWSDateTime!,
    $endDateTime: AWSDateTime!,
    $fileName: String
  ) {
    createReport(
      tenantId: $tenantId,
      organizationalStructureItemId: $organizationalStructureItemId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      including: {workingTimeLog: true}
      fileName: $fileName
    ) {
      sheetDownloadURL
      expiresIn
    }
  }
`;
