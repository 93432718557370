import React, {
    useCallback,
    useEffect, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';

import {
    useParams,
    useSearchParams,
} from 'react-router-dom';
import {
    Box,
    Alert, Collapse, LinearProgress, Typography,
} from '@mui/material';

import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import _ from 'lodash';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMessage} from 'hooks/useMessage';
import {Messages} from 'messages/Messages';
import {LoadingButton} from '@mui/lab';
import {useLogMessage} from 'hooks/useLogMessage';
import {Check, LoopOutlined} from '@mui/icons-material';
import {listEvacuations} from 'graphql/beyondBuddy/Evacuation/queries';
import {addUserToEvacuation} from 'graphql/beyondBuddy/Evacuation/mutations';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * Shows a Typography component that indicates a loading state
 * @param {object} props - props of the component
 * @param {boolean} props.loading - indicates the loading state
 * @param {boolean} [props.disabled] - indicates the disabled state
 * @param {string} props.textLoading - text to be shown when loading is true
 * @param {string} props.textLoaded - text to be shown when loading is false
 * @param {import('@mui/material').TypographyProps} [props.typographyProps] - props for the typography component
 * @param {import('@mui/material').IconProps} [props.iconProps] - props for the icon that is being displayed
 * @returns {React.ReactElement} the LoadingTypography
 */
function LoadingTypography({
    loading, disabled, textLoading, textLoaded, typographyProps, iconProps,
}) {
    const disabledProps = disabled ? {
        color: 'gray',
        fontWeight: 'inherit',
        fontStyle: 'italic',
    } : {};
    return (
        <Typography
            variant="h1"
            display="flex"
            gap={2}
            marginLeft="2rem"
            {...typographyProps}
            {...disabledProps}
        >
            {loading && (
                <LoopOutlined
                    // @ts-ignore
                    color="warning"
                    {...iconProps}
                    {...disabledProps}
                />
            )}
            {!loading && (
                <Check
                    // @ts-ignore
                    color="success"
                    {...iconProps}
                    {...disabledProps}
                />
            )}
            {` ${loading ? textLoading : textLoaded}`}
        </Typography>
    );
}

/**
 * - Loads the recent/most relevant evacuation
 * - enters the user into the evacuation
 * - omits lists of components that correspond to error messages, retry messages, and success messages
 */

/**
 * This page shows a create or edit form for a EvacuationPoint.
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The EvacuationPointPage.
 */
function EvacuationCheckpointPage() {
    // getting the URL parameters
    const {id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const {getGlobal} = useGlobalState();
    const {enqueueMessage} = useMessage();
    const {logMessage, isSending} = useLogMessage();
    const {listItems, editItem} = AWSAppSyncProvider();

    const tenantId = getGlobal('tenantId');
    const userId = getGlobal('userId');

    const [feedbackSent, setFeedbackSent] = useState(false);
    const [evacuationId, setEvacuationId] = useState(null);
    const [isSaved, setIsSaved] = useState(null);
    const [error, setError] = useState(null);
    const [isCanceled, setIsCanceled] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const progress = 0 || (evacuationId && 50
        && isSaved && 100);

    // preventing refresh based update
    useEffect(() => {
        try {
            const curr = (new Date()).getTime();
            const call = searchParams.get('call');
            if (call) {
                // call is older than a minute
                if ((curr - Number(call)) > 60 * 1000) {
                    // setIsCanceled(true);
                }
            } else {
                setSearchParams({call: `${curr}`});
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
    }, [searchParams, setIsCanceled, setSearchParams]);

    const sendFeedback = async () => {
        logMessage(
            'PageError',
            {
                level: 'WARN',
                message: JSON.stringify({
                    error,
                }),
            },
            false,
        );
        enqueueMessage('Feedback_Message', Messages.API_LOG_SUCCESSFUL);
        setFeedbackSent(true);
    };

    // const onSave = useCallback((result) => {
    //     if (result?.id) {
    //         setTimeout(() => navigate(`/${generatePath(evacuationRoute.path, {id: result.id})}`, {replace: true}), 2000);
    //     }
    // }, [evacuationRoute, navigate]);

    // load the evacuation
    useEffect(() => {
        if (id) {
            const oneDayAgo = new Date();
            oneDayAgo.setDate(oneDayAgo.getDate() - 1);
            listItems(listEvacuations, {
                tenantId,
                filter: {
                    completed: false,
                    createdAt: {gte: oneDayAgo.toISOString()},
                },
            }).then((result) => {
                // sort order is createdAt desc - so it will take the newest evacuation
                const foundEvacuation = result instanceof Array && result[0];
                // console.log(foundEvacuation);
                if (!foundEvacuation) {
                    // console.log('Did not find evacuation');
                    setErrorMessage('Keine Evakuierung wurde gefunden. Versuche es später erneut');
                    setIsCanceled(true);
                    return;
                }
                // console.log(foundEvacuation, foundEvacuation.presentUserIds.includes(userId));
                // if (foundEvacuation.presentUserIds.includes(userId)) {
                //     setErrorMessage('Der Eintrag wurde bereits gemacht');
                //     return;
                // }
                setEvacuationId(foundEvacuation.id);
            }).catch((err) => {
                // console.log(err);
                setError(err);
                setIsCanceled(true);
            });
        }
    }, [id, listItems, tenantId, setIsCanceled, setError, userId, setErrorMessage, setEvacuationId]);

    // update the evacuation
    const save = useCallback(() => {
        if (!evacuationId || isSaved || errorMessage) {
            return;
        }
        editItem(addUserToEvacuation, {
            id: evacuationId,
            newEntry: {
                evacuationPointId: id,
                userId,
            },
        }).then(() => {
            // console.log(result);
            // onSave(result);
            setIsSaved(true);
            enqueueMessage('workingtimelogcheckpointpage', Messages.API_SAVE_SUCCESSFUL);
        }).catch((err) => {
            // console.log(err);
            setIsCanceled(true);
            setError(err);
        });
    }, [editItem, evacuationId, isSaved, setIsSaved, setIsCanceled, evacuationId, id, userId, enqueueMessage]);

    useEffect(() => {
        if (!isSaved && !errorMessage && !error && !isCanceled) {
            save();
        }
    }, [save, isSaved, errorMessage, error, isCanceled]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Evakuierungspunkt erreicht</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <LinearProgress
                variant={(isSaved || error) ? 'determinate' : 'indeterminate'}
                value={progress}
            />

            {isCanceled && (<Alert severity="error">Es wurde ein Unstimmigkeit festgestellt und die Anfrage abgebrochen!</Alert>)}
            {errorMessage && (<Alert severity="error">{errorMessage}</Alert>)}

            {/* <Box display="flex" alignItems="center" gap={2} marginTop="2rem">
                <Box component="img" alt="QR" src={qrCodeDemo} height="100px" />
                <Typography variant="h1">
                    Evakuierung registrieren
                </Typography>
            </Box> */}

            <Box display="flex" flexDirection="column" gap={2} marginTop="2rem">
                <Alert severity="success">
                    <ul>
                        <li><Typography fontWeight="bold">Ruhe bewahren</Typography></li>
                        <li><Typography fontWeight="bold">Sicherheit beachten</Typography></li>
                        <li><Typography fontWeight="bold">Anderen Evakuierungsteilnehmern helfen</Typography></li>
                        <li><Typography fontWeight="bold">Anweisungen abwarten</Typography></li>
                    </ul>
                </Alert>
                <Alert severity="warning">
                    <ul>
                        <li><Typography fontWeight="bold">Nicht eigenmächtig den Treffpunkt verlassen</Typography></li>
                        <li><Typography fontWeight="bold">Nicht ohne Erlaubnis in das evakuierte Gebiet zurückkehren</Typography></li>
                    </ul>
                </Alert>
            </Box>

            <Box display="flex" flexDirection="column" gap={2} marginTop="2rem">
                <LoadingTypography
                    disabled={!_.isEmpty(error) || isCanceled}
                    loading={!evacuationId}
                    textLoading="Laufende Evakuierung laden ..."
                    textLoaded="Laufende Evakuierung geladen"
                />
                <LoadingTypography
                    disabled={!_.isEmpty(error) || isCanceled}
                    loading={!isSaved}
                    textLoading="Sie werden registriert ..."
                    textLoaded="Sie wurden als erfolgreich evakuiert registriert!"
                    typographyProps={{
                        // eslint-disable-next-line no-nested-ternary
                        bgcolor: isSaved
                            ? 'success.main' : 'inherit',
                        color: isSaved ? 'white' : 'warning.main',
                        fontWeight: 'bold',
                    }}
                />
            </Box>

            {Boolean(errorMessage) && (
                <>
                    <Collapse
                        in={!feedbackSent}
                    >
                        <LoadingButton
                            variant="contained"
                            loading={isSending}
                            onClick={sendFeedback}
                            data-test="LoadingButton_SendFeedback"
                            style={{marginTop: '2rem'}}
                        >
                        Benachrichtigung senden
                        </LoadingButton>
                    </Collapse>
                    <Typography
                        style={{marginTop: '2rem'}}
                    >
                        Wende Dich an den Systemverantwortlichen um das Problem zu analysieren.
                        <br />
                        Mögliche Gründe sind eine fehlende Berechtigung oder ein fehlerhafter QR-Code.
                        <br />
                        <i>{`Referenz: Evakuierungspunkt ${id}`}</i>
                    </Typography>
                </>
            ) }
        </LayoutContainer>
    );
}
export {EvacuationCheckpointPage};
