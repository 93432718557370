/**
 * createWorkingTimeHoliday
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the Holiday.
 * @property {string} managerUserId - the id of user that manages the Holiday.
 * @returns {object} The newly created Holiday.
 */
export const createWorkingTimeHoliday = /* GraphQL */ `
  mutation CreateWorkingTimeHoliday(
    $label: String!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    ) {
    createWorkingTimeHoliday(
      label: $label 
      startDateTime: $startDateTime
      endDateTime: $endDateTime  
    ) {
      id
      label
      startDateTime
      endDateTime
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * updateWorkingTimeHoliday
 * @property {string} id - ID of the Holiday.
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the Holiday.
 * @returns {object} The updated Holiday.
 */
export const updateWorkingTimeHoliday = /* GraphQL */ `
  mutation UpdateWorkingTimeHoliday(
    $id: ID!
    $label: String!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
  ) {
    updateWorkingTimeHoliday(
      id: $id
      label: $label
      startDateTime: $startDateTime
      endDateTime: $endDateTime
    ) { 
      id
      label
      startDateTime
      endDateTime
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * deleteWorkingTimeHoliday
 * @property {string} id - ID of the Holiday.
 * @returns {object} The deleted Holiday.
 */
export const deleteWorkingTimeHoliday = /* GraphQL */ `
 mutation DeleteWorkingTimeHoliday(
     $id: ID!
   ) {
   deleteWorkingTimeHoliday(
     id: $id,
     ) {
      id
   }
 }
`;
