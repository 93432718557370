import {
    Skeleton, Checkbox, FormControlLabel, FormHelperText, FormControl,
} from '@mui/material';
import React, {
    useCallback, useContext, useMemo,
} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';

/**
 * ## FormElementCheckbox
 *
 * Renders a toggle button, loads values from FormWrapper, and writes into it
 * @param {object} props - props of the component
 * @param {string} props.attribute - attribute to load/write data from/to
 * @param {React.ReactElement | string} props.label - label to be shown instead of child content if none is given.
 * @param {boolean} [props.disabled] - if the checkbox should be disabled
 * @returns {React.ReactElement} - element to be rendered
 */
function FormElementCheckbox({
    attribute, label, disabled = false, ...rest
}) {
    const {
        get, onBlurHandler, isLoading, isReadonly,
    } = useContext(FormContext);

    const elementData = useMemo(() => get(attribute), [attribute, get]);
    const onChange = useCallback(() => {
        // changeHandler({
        //     attribute,
        //     value: !elementData.value,
        //     interacted: true,
        // });
        onBlurHandler({
            attribute,
            value: !elementData.value,
            interacted: true,
        });
    }, [onBlurHandler, elementData.value, attribute, get]);

    if (isLoading.load) {
        return <Skeleton variant="rectangular" animation="wave" height="3rem" />;
    }

    return (
        <FormControl error={!_.isUndefined(elementData?.error)}>
            <FormControlLabel
                label={label}
                aria-readonly={isReadonly}
                control={(
                    <Checkbox
                        data-test={`FormElementCheckbox_${attribute}`}
                        checked={elementData.value ?? false} // false is default value
                        onChange={onChange}
                        disabled={disabled || isReadonly}
                        readOnly={isReadonly}
                        {...rest}
                    />
                )}
            />
            <FormHelperText>{elementData?.error?.value}</FormHelperText>
        </FormControl>
    );
}

export {FormElementCheckbox};
