const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    UUID_REGEX,
    FILE_KEY_REGEX,
    ISO8601_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema<import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/app/peaceBuddy/GraveRecord').GraveRecordEntity>}
 */
const schema = {
    frontend: {
        deceasedPersonId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        graveId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        clerkUserId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        dateOfFuneral: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        burryDeadline: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        graveUnitId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        deceasedPersonId: [
            {
                actions: ['create'],
                configurations: ['required', 'format'],
            },
        ],
        graveId: [
            {
                actions: ['create'],
                configurations: ['required', 'format'],
            },
        ],
        clerkUserId: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'format'],
            },
        ],
        dateOfFuneral: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        burryDeadline: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        graveUnitId: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'format'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },

    attributes: {
        id: {
            required: true,
            format: UUID_REGEX,
        },
        deceasedPersonId: {
            required: true,
            format: UUID_REGEX,
        },
        graveId: {
            required: true,
            format: UUID_REGEX,
        },
        clerkUserId: {
            required: true,
            format: UUID_REGEX,
        },
        graveUnitId: {
            required: true,
            format: UUID_REGEX,
        },
        dateOfFuneral: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        burryDeadline: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
