import {useCallback} from 'react';
// PACKAGES
import {useSnackbar} from 'notistack';
import {v4 as uuidv4} from 'uuid';
import {IconButton} from '@mui/material';
import {useLogMessage} from 'hooks/useLogMessage';
import {Close, ReportProblem} from '@mui/icons-material';
import {Messages} from 'messages/Messages';

/**
 * The hook to show/queue a snackbar.
 * @module useMessage
 * @returns {{enqueueMessage: (messageKey: string, message: import('messages/Messages').message, metaData: object)=> void}} function to enqueue a message
 * @example
 * const {enqueueMessage} = useMessage();
 * enqueueMessage(snackBarKey, Exceptions.MISSING_ATTRIBUTES_ERROR);
 */
const useMessage = () => {
    const {logMessage} = useLogMessage();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const enqueueMessage = useCallback(
        /**
         * The function to show/queue a snackbar.
         * @function enqueueMessage
         * @param {string} messageKey - snackbar key.
         * @param {import('messages/Messages').message} message - message of the snackbar.
         * @param {object} metaData - meta data to send with the error.
         * @returns {void}
         * @example
         * enqueueMessage(snackBarKey, Exceptions.MISSING_ATTRIBUTES_ERROR);
         */
        (messageKey, message, metaData) => {
            if (!message) {
                throw new Error('Snackbar: No message was provided');
            }
            // if (!messageKey) {
            //     throw new Error('Snackbar: No messageKey was provided');
            // }
            const mKey = messageKey ?? 'defaultMsgKey';

            enqueueSnackbar(<span id={mKey ?? 'GENERAL'}>{message?.message.de}</span>, {
                autoHideDuration: metaData ? 10000 : 2000,
                ...message.options,
                // add a unique id to the snackbar to prevent duplicate messages errors
                key: `${mKey}####${uuidv4()}`,
                action: (key) => (
                    <>
                        { metaData && (
                            <IconButton
                                size="large"
                                ria-label="Report Problem"
                                color="inherit"
                                data-test={`${mKey}_reportProblem`}
                                onClick={() => {
                                    logMessage(
                                        'PageError',
                                        {
                                            level: 'WARN',
                                            message: message?.message.de,
                                            metaData,

                                        },
                                        false,
                                    );
                                    enqueueMessage('Feedback_Message', Messages.API_LOG_SUCCESSFUL);
                                    closeSnackbar(key);
                                }}
                            >
                                <ReportProblem />
                            </IconButton>
                        )}
                        <IconButton
                            size="large"
                            ria-label="Close"
                            color="inherit"
                            data-test={`${mKey}_close`}
                            onClick={() => closeSnackbar(key)}
                        >
                            <Close />
                        </IconButton>
                    </>
                ),
            });
        },
        [enqueueSnackbar],

    );

    return {
        enqueueMessage,
    };
};
export {useMessage};
