import {useCallback, useMemo} from 'react';
import _ from 'lodash';
import {
    applicationConfiguration as PeaceBuddySchema,
    defaultLinkSchemaOptionsPeaceBuddy,
    managedEntityKeysPeaceBuddy,
    permissionGroupDefinitionPeaceBuddy,
    permissionLabelsPeaceBuddy,
} from 'applications/peacebuddy/config';
import {
    applicationConfiguration as TimeBuddySchema,
    defaultLinkSchemaOptionsTimeBuddy,
    managedEntityKeysTimeBuddy,
    permissionGroupDefinitionTimeBuddy,
    permissionLabelsTimeBuddy,
} from 'applications/timebuddy/config';
import {
    applicationConfiguration as DriveBuddySchema,
    defaultLinkSchemaOptionsDriveBuddy,
    managedEntityKeysDriveBuddy,
    permissionGroupDefinitionDriveBuddy,
    permissionLabelsDriveBuddy,
} from 'applications/drivebuddy/config';
import {
    applicationConfiguration as BeyondBuddySchema,
    defaultLinkSchemaOptionsBeyondBuddy,
    managedEntityKeysBeyondBuddy,
    permissionGroupDefinitionBeyondBuddy,
    permissionLabelsBeyondBuddy,
} from 'applications/beyondbuddy/config';
import {matchPath, useLocation} from 'react-router-dom';
import {useGlobalState} from 'hooks/useGlobalState';

/**
 * @typedef {import('./configuration').ApplicationConfiguration} ApplicationConfiguration
 */

/** @type {ApplicationConfiguration[]} */
const applicationConfigurations = [
    DriveBuddySchema,
    TimeBuddySchema,
    PeaceBuddySchema,
    BeyondBuddySchema,
];

const guideConfigurations = Object.fromEntries(
    applicationConfigurations
        .flatMap(({guides}) => guides)
        .map((guide) => [guide.id, guide]),
);

/**
 *
 * @param {Array<import('routeinfo').ApplicationMenuInfo>} menus - information of all applications
 * @returns {{
 *  isActive: (id: string)=>boolean,
 *  currentRoute: import('routeinfo').RoutePathInfo,
 *  currentApplication: import('routeinfo').ApplicationMenuInfo,
 *  currentModule: import('routeinfo').ModuleMenuInfo
 * }} - information about the current router location
 */
function useRouteInformation(menus) {
    const location = useLocation();
    const {getGlobal} = useGlobalState();
    /**
     * @type {Array<import('routeinfo').RoutePathInfo>}
     */
    const routes = getGlobal('routes');

    /**
     * @type {import('routeinfo').RoutePathInfo}
     */
    const currentRoute = useMemo(() => _.find(routes, (r) => !!matchPath(`/${r.path}`, location.pathname)), [routes, location.pathname]);

    /**
     * @type {import('routeinfo').ApplicationMenuInfo}
     */
    const currentApplication = useMemo(() => _.find(menus, (m) => m.applicationId === currentRoute?.applicationId), [currentRoute, menus]);

    /**
     * @type {import('routeinfo').ModuleMenuInfo}
     */
    const currentModule = useMemo(() => _.find(currentApplication?.modules, (mod) => mod.moduleId === currentRoute?.moduleId), [currentRoute, currentApplication]);

    /**
     * checks if the menu path is part of the current location path
     * @param {import('routeinfo').MenuInfo} menu
     * @returns {boolean}
     */
    const checkMenu = useCallback((menu) => {
        if (!menu.path) {
            throw new Error(`Could not find path in menu: ${menu?.id}`);
        }
        const regex = new RegExp(`^/${menu.path.replace(/\/:\w+/g, '/[^/]+')}/?`, 'i');
        // if (regex.test(location.pathname)) { console.log(menu.path.replace(/\/:\w+/g, '/[^/]+')); }
        // if (regex.test(location.pathname)) { console.log(menu.path); }
        return regex.test(location.pathname);
        // console.log(menu.basePath, menu.path, location.pathname, !!matchPath(`/${menu.path}`, location.pathname));
        // return !!matchPath(`/${menu.path}`, location.pathname)
        // return menu.route === currentRoute?.id;
    }, [location.pathname]);

    /**
     * @param {import('routeinfo').MenuInfo} menu
     * @returns {Array<import('routes').MenuInfo>}
     */
    const extractMatchingMenus = useCallback((menu) => [
        ...checkMenu(menu) ? [menu] : [],
        ..._.flatMap(menu.menus, extractMatchingMenus),
    ], [checkMenu]);

    const matchingMenus = useMemo(
        () => _.flatMap(menus, ({
            applicationId, applicationName, menu, modules,
        }) => [
            ...checkMenu(menu)
                ? [{
                    applicationId,
                    applicationName,
                    menuId: menu.id,
                }] : [],
            ..._.flatMap(modules, ({menus: moduleMenus, moduleId, moduleName}) => _.chain(moduleMenus)
                .flatMap(extractMatchingMenus)
                .map((subMenu) => ({
                    applicationId,
                    applicationName,
                    moduleId,
                    moduleName,
                    menuId: subMenu.id,
                }))
                .value()),
        ]),
        [menus, location.pathname, extractMatchingMenus],
    );

    const isActive = useCallback((id) => !!_.find(matchingMenus, (m) => m.menuId === id), [matchingMenus]);

    return {
        currentRoute,
        currentApplication,
        currentModule,
        isActive,
    };
}

/**
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').PermissionGroup} PermissionGroup
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('./configuration').ManagedObject} ManagedObject
 */

/** @type {Record<Permission|PermissionGroup, string>} */
const permissionLabels = _.merge(
    permissionLabelsBeyondBuddy,
    permissionLabelsTimeBuddy,
    permissionLabelsDriveBuddy,
    permissionLabelsPeaceBuddy,
);

const defaultPermissionGroupDefinitions = _.merge(
    permissionGroupDefinitionBeyondBuddy,
    permissionGroupDefinitionDriveBuddy,
    permissionGroupDefinitionTimeBuddy,
    permissionGroupDefinitionPeaceBuddy,
);

/** @type {(isIncoming: boolean)=>FormElementEntityChooserDataSchemaOptions} */
const baseLinkSchemaOptions = (isIncoming) => ({
    getFilterMap: (search) => ({search, ...(!isIncoming ? {permittableOnly: true} : {})}),
    // explicit false check because new options are always active, and the field is not loaded
    isDisabled: (item) => (!isIncoming && !item?.grants?.permissionsUpdatable),
});

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    ...defaultLinkSchemaOptionsBeyondBuddy(isIncoming),
    ...defaultLinkSchemaOptionsDriveBuddy(isIncoming),
    ...defaultLinkSchemaOptionsTimeBuddy(isIncoming),
    ...defaultLinkSchemaOptionsPeaceBuddy(isIncoming),
});

/** @type {import('./configuration').ManagedObject[]} */
// @ts-ignore
const managedEntityKeys = _.concat(
    managedEntityKeysBeyondBuddy,
    managedEntityKeysDriveBuddy,
    managedEntityKeysTimeBuddy,
    managedEntityKeysPeaceBuddy,
);

/**
 *
 * @param {import('./configuration').ManagedObject[]} entities - the entities to be used
 * @param {Array<Permission|PermissionGroup>} permissions - the permissions to set
 * @returns {Partial<Record<import('./configuration').ManagedObject, Array<Permission|PermissionGroup>>>} - the permissions object
 */
function createPermissionsObject(entities, permissions) {
    return _.zipObject(entities, _.fill(Array(entities.length), permissions));
}

export {
    applicationConfigurations,
    guideConfigurations,
    // entityLabels,
    permissionLabels,
    defaultPermissionGroupDefinitions,
    managedEntityKeys,
    useRouteInformation,
    createPermissionsObject,
    baseLinkSchemaOptions,
    defaultLinkSchemaOptions,
};
