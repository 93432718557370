import {useGlobalState} from 'hooks/useGlobalState';
import _ from 'lodash';

/**
 *
 * @returns {(id:string)=>import('routeinfo').RoutePathInfo} the target route
 */
function useFindRoute() {
    const {getGlobal} = useGlobalState();
    /**
     * @type {Array<import('routeinfo').RoutePathInfo>}
     */
    const routes = getGlobal('routes');
    return (id) => _.find(routes, (r) => r.id === id);
}

export {useFindRoute};
