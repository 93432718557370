import React from 'react';
import {Box} from '@mui/material';

import classes from 'components/Dashboard/dashboardContainer.module.scss';

/**
 * This page shows a dashboard for all applications
 * @param {object} props - props of the dashboard container
 * @param {React.ReactElement} [props.head] - element to be rendered in the header
 * @param {React.ReactNode} [props.children] - element to be rendered in the body
 * @param {import('@mui/system').BoxProps} [props.boxProps] - props of the container
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function DashboardContainer({head, children, boxProps}) {
    return (
        <Box className={classes.container} {...boxProps}>
            {head && <Box className={classes.head}>{head}</Box>}
            {children && <Box className={classes.body}>{children}</Box>}
        </Box>
    );
}

export {DashboardContainer};
