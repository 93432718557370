import React from 'react';
import {Box, Typography} from '@mui/material';
import beyondBuddyLogo from 'assets/images/BEYONDBUDDY_TextLogo_V1.png';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Dashboards} from 'components/Dashboard/Dashboards';
import {DashboardContainer} from 'components/Dashboard/DashboardContainer';
import {DashboardTilesContainer} from 'components/Dashboard/DashboardTilesContainer';
import {
    Tile, TileButton, TileImageBackground, TileRouteButton,
} from 'assets/theme/components/Tile/Tile';
import feedbackImg from 'applications/pages/images/AdobeStock_63317365.jpg';
// import beyondLogo from 'applications/pages/images/TileBackgroundBEYONDWebsite.jpg';
import settings from 'applications/pages/images/TileBackgroundSettings.jpg';
import explore from 'applications/pages/images/AdobeStock_423708920.jpg';
import {useGlobalState} from 'hooks/useGlobalState';

/**
 * This page shows a dashboard for all applications
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The OrganizationalUnitListPage component
 */
function ApplicationDashboard() {
    const {setGlobal} = useGlobalState();

    const feedbackTile = (
        <Tile>
            {/* <TileLabel label="Fahrtenbuch" style={{color: 'black', fontWeight: 'bold'}} /> */}
            <TileButton onClick={() => setGlobal('showFeedback', true)} />
            <TileImageBackground src={feedbackImg} alt="feedback" />
            {/* <TileIconBackground icon={<EditRoad color="primary" />} /> */}
        </Tile>
    );
    const beyondbuddyTile = (
        <Tile>
            {/* <TileLabel label="Mehr über BeyondBuddy" /> */}
            <TileButton onClick={() => window.open('https://www.beyondbuddy.at')} />
            <TileImageBackground src={explore} alt="beyondbuddy online" />
            {/* <TileIconBackground icon={<ReadMore color="primary" />} /> */}
        </Tile>
    );

    // const beyonditsolutionsTile = (
    //     <Tile>
    //         {/* <TileLabel label="Fahrtenbuch" style={{color: 'black', fontWeight: 'bold'}} /> */}
    //         <TileButton onClick={() => window.open('https://www.beyond-it-solutions.at')} />
    //         <TileImageBackground src={beyondLogo} alt="beyond it solutions online" />
    //         {/* <TileIconBackground icon={<EditRoad color="primary" />} /> */}
    //     </Tile>
    // );

    const settingsTile = (
        <Tile>
            {/* <TileLabel label="Fahrtenbuch" style={{color: 'black', fontWeight: 'bold'}} /> */}
            <TileRouteButton routeId="beyondbuddy_settings_general_dashboard_route" />
            <TileImageBackground src={settings} alt="Einstellungen" />
            {/* <TileIconBackground icon={<EditRoad color="primary" />} /> */}
        </Tile>
    );

    return (
        <LayoutContainer>
            <Box
                component="img"
                src={beyondBuddyLogo}
                style={{display: 'block', margin: '2rem auto 2rem auto', maxHeight: '2rem'}}
            />
            <Dashboards />

            <DashboardContainer head={<Typography variant="h2">Weiterführende Informationen und Feedback</Typography>}>
                <DashboardTilesContainer elements={[
                    {tile: feedbackTile},
                    {routeId: 'beyondbuddy_settings_general_dashboard_route', tile: settingsTile},
                    {tile: beyondbuddyTile},
                    // {tile: beyonditsolutionsTile},
                ]}
                />

            </DashboardContainer>
        </LayoutContainer>
    );
}
export {ApplicationDashboard};
