/**
 * listCustomers
 * @property {string} tenantId - id of the tenant
 * @property {object} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All customers.
 */
export const listCustomers = /* GraphQL */ `
  query ListCustomers (
    $tenantId: ID!
    $filter: CustomersListFilterInput
    $nextToken: String
    ) {
    listCustomers(
      tenantId: $tenantId
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        id
        referenceId
        nr
        companyName
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        gender
        eMailAddress
        phoneNumber
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

/**
 * getCustomer
 * @property {string} id - customer id.
 * @property {string} tenantId - tenant id.
 * @returns {object} The requested customer.
 */
export const getCustomer = /* GraphQL */ `
  query GetCustomer(
      $id: ID!
    ) {
    getCustomer(
      id: $id
    ) {
      id
      allowedApplications
      referenceId
      nr
      companyName
      vat
      companyRegisterNumber
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      gender
      toHandsTitlePrefixed
      toHandsFirstName
      toHandsLastName
      toHandsTitlePostfixed
      toHandsGender
      address
      zip
      city
      countryCode
      deliveryAddress
      deliveryZip
      deliveryCity
      deliveryCountryCode
      deliveryEMailAddress
      eMailAddress
      phoneNumber
      phoneNumber2
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;
