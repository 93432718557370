/* eslint-disable no-shadow */
/* eslint-disable jsdoc/require-returns-type */
import React, {useMemo, useState} from 'react';
import {getWorkingTimeAggregate} from 'graphql/timeBuddy/WorkingTimeAggregate/queries';
import {
    Alert, Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Typography,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import {FormElementText} from 'components/Form/FormElements/FormElementText';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    HouseboatOutlined, MoreTimeOutlined, Refresh, RemoveOutlined,
} from '@mui/icons-material';
import _ from 'lodash';
import {schema} from 'beyond-validators/timeBuddy/WorkingTimeAggregateCorrection';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {getUserWithWorkingTimeModel} from 'graphql/beyondBuddy/User/queries';
import {defer, map, share} from 'rxjs';
import {useSubscribe} from 'hooks/useSubscribe';
import {TabPanel} from 'assets/theme/layout/TabPanel';
import {WorkingTimeAggregateCorrectionFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeAggregateCorrection/WorkingTimeAggregateCorrectionFormular';
import {WorkingTimeAggregateCorrectionListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeAggregateCorrection/WorkingTimeAggregateCorrectionListFormular';
import {useCanAccess} from 'hooks/useCanAccess';
import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';
import {formatDateTimeString} from 'helper/date';

const tz = 'Europe/Vienna';
const moment = extendMoment(momentWithoutRange);

const loadingSymbol = Symbol('Symbol indicating that a variable is still loading');

const getEndOfDate = () => new Date(moment().tz(tz).endOf('day').valueOf());

const getVacationContoTimeText = (weeks, model) => {
    if (!model) {
        return `${Math.fround(weeks)} Wochen. Der genaue Stand kann nicht dargestellt werden, da kein Arbeitszeitmodell geladen ist`;
    }

    let daysHundred = model.weeklyDays * ((weeks * 100) % 100);
    let weekAmount = Math.floor(weeks < 0
        // +1 since the partial negative week is accounted for in days
        ? weeks + 1
        : weeks);

    if (Number(model.weeklyDays) <= Number((Math.abs(daysHundred) / 100).toPrecision(2))) {
        weekAmount += 1;
        daysHundred = Math.max(0, daysHundred - (model.weeklyDays * 100));
    }
    const weekText = Math.abs(weekAmount - 1) < 0.0001
        ? 'eine Woche'
        : `${Math.abs(weekAmount)} Wochen`;

    let dayText = '';
    const days = (Math.abs(daysHundred) / 100).toPrecision(2).replace(/\.0$/, '');

    let preSign = (weeks > 0 ? '' : '- ');
    // nearly one day (100 is one day)
    if (Math.abs(daysHundred) > 80) {
        dayText = Math.abs(daysHundred) < (daysHundred ? 100 : 0)
            ? 'ein Tag'
            : `${days} Tage`;
    } else if (daysHundred > 13) {
        dayText = 'weniger als ein Tag';
        preSign = '';
    } else if (daysHundred < 13) {
        dayText = 'weniger als ein Tag im Minus';
        preSign = '';
    }

    // console.log(daysHundred, weeks);
    return preSign + _.compact([weekAmount && weekText, daysHundred && dayText]).join(' und ') || `aktuell kein Urlaubsanspruch (${days} Tage)`;
};

/**
 * @param {string} userId - id of the user
 * @param {string?} exitDate - date of users exit
 * @param {import('../../../../../../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeModelEntity} workingTimeModel - model to calculate days with
 * @returns {import('components/Form/form').ItemLoadConfig} config
 */
const useVacationLoadConfig = (userId, exitDate, workingTimeModel) => useMemo(() => {
    const exit = moment(exitDate).tz(tz);
    const end = moment(getEndOfDate()).tz(tz);

    const time = exit.isBefore(end) ? exit.toISOString() : end.toISOString();

    return {
        query: getWorkingTimeAggregate,
        mask: {type: true, userId: true, time: true},
        variables: {
            direct: {
                type: 'VACATION',
                userId,
                time,
            },
        },
        postProcess: (obj) => ({
            ...obj,
            // Setting value to undefined, so it can be used for corrections
            value: undefined,
            originalValue: JSON.parse(obj.value),
            amountText: getVacationContoTimeText(JSON.parse(obj.value), workingTimeModel),
        }),
    };
}, [userId, workingTimeModel]);

/**
 * @param {string} userId - id of the user
 * @returns {import('components/Form/form').ItemLoadConfig} config
 */
const useWorkLoadConfig = (userId) => useMemo(() => ({
    query: getWorkingTimeAggregate,
    mask: {type: true, userId: true, time: true},
    variables: {
        direct: {
            type: 'WORK',
            userId,
            time: getEndOfDate(),
        },
    },
    postProcess: (result) => ({
        ...result,
        value: undefined,
        originalValue: JSON.parse(result.value),
        shortenedValues: _.mapValues(JSON.parse(result.value), (value) => _.round(value, 2)),
    }),
}), [userId]);

/** @type {import('../../../../../../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeAggregateCorrectionType[]} */
const tabTypes = ['VACATION', 'MISSING', 'OVER', 'EXTRA'];
const names = ['Urlaubswochen', 'Fehlstunden', 'Überstunden', 'Mehrstunden'];

/**
 * Renders tabs to make and see correction
 * @param {object} params - params
 * @param {string} params.userId - id of user to show tabs for
 * @returns {React.ReactElement} tabs
 */
function CorrectionTabs({userId}) {
    // Cannot use tabState, because tabs in tabs are not supported
    const [currentTab, setCurrentTab] = useState(false);
    const canReadAggregateCorrections = useCanAccess('readWorkingTimeAggregateCorrection');
    const canWriteAggregateCorrections = useCanAccess('readWorkingTimeAggregateCorrection');

    if (!canReadAggregateCorrections && !canWriteAggregateCorrections) {
        return <Box display="none" />;
    }

    return (
        <>
            <Tabs
                value={currentTab}
                // variant="scrollable"
                onChange={(_info, v) => setCurrentTab(v)}
                aria-label="correction tabs"
            >
                {tabTypes.map((type, i) => (
                    <Tab
                        key={type}
                        id={`${type}_tab`}
                        aria-controls={`${type}_panel`}
                        label={names[i]}
                    />
                ))}
            </Tabs>
            {tabTypes.map((type, i) => (
                <TabPanel key={type} id={`${type}_panel`} active={typeof currentTab === 'number' && currentTab === i} tabId={`${type}_tab`}>
                    {canReadAggregateCorrections && (
                        <>
                            <Typography variant="h2">Korrektur Erstellen</Typography>
                            <WorkingTimeAggregateCorrectionFormular {...{type, userId}} />
                        </>
                    )}
                    {canReadAggregateCorrections
                        && canWriteAggregateCorrections
                        && <hr style={{margin: '1rem 0 1rem 0'}} />}
                    {canWriteAggregateCorrections && (
                        <>
                            <Typography variant="h2" display="flex" alignItems="center" justifyContent="space-between">
Historische Korrekturen
                                <span id={`${type}-correction-reload-button-frame`} />
                            </Typography>
                            <WorkingTimeAggregateCorrectionListFormular {...{type, userId}} />
                        </>
                    )}
                </TabPanel>
            ))}
        </>
    );
}

/**
 * The WorkingTimeTimeAccountReport for viewing the recent changes and state of the specified users vacation account
 * @param {object} params - params for the display
 * @param {string} params.userId - id of the user for who to display events and stats
 * @param {boolean} params.workingTimeAggregateCorrectable - flag that indicates that the specified user may make aggregate corrections for the user specified in userId
 * @returns {React.ReactElement} The WorkingTimeTimeAccountReport component
 */
function WorkingTimeTimeAccountReport({
    userId, workingTimeAggregateCorrectable,
}) {
    const {getItem} = AWSAppSyncProvider();
    const {
        entryDate$,
        exitDate$,
        workingTimeModel$,
    } = useMemo(() => {
        const userModelAndEntry$ = defer(() => getItem(getUserWithWorkingTimeModel, {id: userId})).pipe(
            share(),
        );
        const workingTimeModel$ = userModelAndEntry$.pipe(map((result) => result.workingTimeModel));
        const entryDate$ = userModelAndEntry$.pipe(map((result) => result.entryDate));
        const exitDate$ = userModelAndEntry$.pipe(map((result) => result.exitDate));
        return {
            workingTimeModel$,
            entryDate$,
            exitDate$,
        };
    }, [userId, getItem]);

    const workingTimeModel = useSubscribe(workingTimeModel$, undefined, loadingSymbol);
    const entryDate = useSubscribe(entryDate$, undefined, loadingSymbol);
    const exitDate = useSubscribe(exitDate$, undefined, loadingSymbol);

    // console.log('entryDate', entryDate);
    // console.log('exitDate', exitDate);

    const loadVacationConfig = useVacationLoadConfig(userId, exitDate !== loadingSymbol ? exitDate : null, workingTimeModel);
    const loadWorkConfig = useWorkLoadConfig(userId);

    if (entryDate === loadingSymbol || exitDate === loadingSymbol || workingTimeModel === loadingSymbol) {
        return <Box display="grid" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
    }

    if (!entryDate || !workingTimeModel) {
        return (
            <Alert severity="warning">
            Wenn kein
                {!entryDate ? ' Eintrittsdatum ' : ' Arbeitsmodell '}
vermerkt ist können Berechnungen für den Urlaubsanspruch nicht stattfinden
            </Alert>
        );
    }

    const secondaryRefreshAction = (
        <FormElementActionButton
            context={FormContext}
            reload
        >
            <Refresh />
        </FormElementActionButton>
    );

    return (
        <Box>
            <Typography variant="h2">{`Zeitkonten (Stand ${formatDateTimeString(moment(new Date()).endOf('day').toISOString())})`}</Typography>
            <hr />
            <ItemData
                loadConfig={entryDate !== loadingSymbol && workingTimeModel !== loadingSymbol ? loadVacationConfig : undefined}
            >
                <FormWrapper
                    validatorSchema={{
                        type: 'create',
                        schema,
                    }}
                >
                    <List dense>
                        <ListItem secondaryAction={secondaryRefreshAction}>
                            <ListItemAvatar><Avatar><HouseboatOutlined /></Avatar></ListItemAvatar>
                            <ListItemText
                                primary="Urlaubskonto"
                                secondary={<FormElementText attribute="amountText" />}
                            />
                        </ListItem>
                    </List>
                </FormWrapper>
            </ItemData>
            <ItemData
                loadConfig={entryDate !== loadingSymbol && workingTimeModel !== loadingSymbol ? loadWorkConfig : undefined}
            >
                <FormWrapper
                    validatorSchema={{
                        type: 'create',
                        schema,
                    }}
                >
                    <List dense>
                        <ListItem secondaryAction={secondaryRefreshAction}>
                            <ListItemAvatar><Avatar><RemoveOutlined /></Avatar></ListItemAvatar>
                            <ListItemText
                                primary="Fehlstunden"
                                secondary={(
                                    <>
                                        <FormElementText attribute="shortenedValues.missing" />
                                        <span> Stunden</span>
                                    </>
                                )}
                            />
                        </ListItem>
                        <ListItem secondaryAction={secondaryRefreshAction}>
                            <ListItemAvatar><Avatar><MoreTimeOutlined /></Avatar></ListItemAvatar>
                            <ListItemText
                                primary="Mehrstunden"
                                secondary={(
                                    <>
                                        <FormElementText attribute="shortenedValues.extra" />
                                        <span> Stunden</span>
                                    </>
                                )}
                            />
                        </ListItem>
                        <ListItem secondaryAction={secondaryRefreshAction}>
                            <ListItemAvatar><Avatar><MoreTimeOutlined /></Avatar></ListItemAvatar>
                            <ListItemText
                                primary="Überstunden"
                                secondary={(
                                    <>
                                        <FormElementText attribute="shortenedValues.over" />
                                        <span> Stunden</span>
                                    </>
                                )}
                            />
                        </ListItem>
                    </List>
                </FormWrapper>
            </ItemData>
            <Typography variant="h2" marginTop="2rem">Korrekturen</Typography>
            <hr />
            {workingTimeAggregateCorrectable && <CorrectionTabs userId={userId} />}
        </Box>
    );
}

export {WorkingTimeTimeAccountReport};
