// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../fragments';

/**
 * listOrganizationalUnits
 * @property {string} tenantId - id of the tenant
 * @property {object} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All organizationalunits.
 */
export const listOrganizationalUnits = /* GraphQL */ `
  query ListOrganizationalUnits (
    $tenantId: ID!
    $filter: OrganizationalUnitListFilterInput
    $nextToken: String
    ) {
    listOrganizationalUnits(
      tenantId: $tenantId
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        id
        name
        image{
          url
        }
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

/**
 * autocompleteListOrganizationalUnits
 * @property {string} tenantId - id of the tenant
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All organizationalunits.
 */
export const autocompleteListOrganizationalUnits = /* GraphQL */ `
 query ListOrganizationalUnits (
     $tenantId: ID!
     $filter: String
   ) {
   listOrganizationalUnits(
      tenantId: $tenantId
      filter: {
        name: $filter
      }
    ) {
     items {
       id
       name
     }
   }
 }
`;

/**
 * getOrganizationalUnit
 * @property {string} id - organizationalunit id.
 * @property {string} tenantId - tenant id.
 * @returns {object} The requested organizationalunit.
 */
export const getOrganizationalUnit = /* GraphQL */ `
  query GetOrganizationalUnit(
      $id: ID!
    ) {
    getOrganizationalUnit(
      id: $id
    ) {
      id
      name
      imageKey
      image {
        ...FileInformation
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      parentOrganizationalUnitId
      parentOrganizationalUnit {
        id
        name
      }
    }
  }
  ${FileInformationFragment}
`;
