import {useCallback} from 'react';
// PACKAGES
import _ from 'lodash';
// HOOKS
import {useGlobalState} from 'hooks/useGlobalState';

/**
 * The return value of the useVariables hook.
 * @typedef {object} UseVariablesFunction
 * @property {function(object): object} getVariables - function to get the variables.
 * @param {object} props - props that were passed to this component.
 * @example
 * const {getVariables} = useVariables();
 * const variables = getVariables(variables);
 */

/**
 * A hook to the variables from the global state.
 * @module useVariables
 * @returns {UseVariablesFunction} - global state functions.
 * @example
 * const {getVariables} = useVariables();
 * const variables = getVariables(variables);
 */

const useVariables = () => {
    // getting the global state function for retrieving a state value
    const {getGlobal} = useGlobalState();

    // filter variables with no value
    const pick = (
        /** @type {object} - picked variables */
        o,
    ) => _.pickBy(o, (v) => v);

    return {
        /**
         * @typedef containerVariable The container variable.
         * @property {object} global - global variables from the global state.
         * @property {object} direct - direct variables (e.g. from the query).
         */
        getVariables: useCallback((
            /** @type {containerVariable} The variables */
            containerVariable,
        ) => {
            if (!containerVariable || !_.isObject(containerVariable) || _.isEmpty(containerVariable)) {
                return {};
            }
            return {
                ...pick(_.mapValues(
                    containerVariable?.global,
                    (g) => getGlobal(g),
                ) ?? {}), // load globals
                ...pick(
                    containerVariable?.direct,
                ),
            };
        }, [getGlobal]),
    };
};
export {useVariables};
