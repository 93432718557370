/* eslint-disable prefer-promise-reject-errors */
import {
    useRef, useEffect, useCallback,
} from 'react';
// PACKAGES
import _ from 'lodash';

/**
 * ## Cancel Exception
 *
 * Indicates that a cancellation occurred
 */
class CancelException extends Error {
    constructor(message = 'Cancelled', options = {}) {
        super(message, options);
        this.name = 'CancelException';
    }
}

/**
 * This function takes a promise and a function to get executed when cancelation gets processed
 * @param {Promise} promise - promise that should get wrapped
 * @param {Function} [cancelFunction] - optional cancel function that gets executed when cancelation gets processed
 * @param {object} [finalFunction] - optional final function that gets executed when the promise is resolved
 * @returns {{promise: Promise, cancel: ()=>void}} The wrapped promise
 */
function makeCancelable(promise, cancelFunction, finalFunction) {
    let isCanceled = false;
    // create a new promise and wrap the passed promise inside
    const wrappedPromise = new Promise((resolve, reject) => {
        // when the isCanceled boolean turns to true, the promise will be canceled
        promise
            .then((val) => (isCanceled ? reject(new CancelException('Canceled as a Cancellable Promise')) : resolve(val)), reject)
            .catch((error) => (isCanceled ? reject(new CancelException('Canceled as a Cancellable Promise')) : reject(error)))
            .finally(finalFunction);
    });
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
            if (_.isFunction(cancelFunction)) {
                // console.log('cancel promise');
                // process the passed cancelation function
                cancelFunction(promise);
            }
        },
    };
}

/**
 * This hook can be used to wrap promises, so they get canceled in case of a page change
 * @returns {{cancellablePromise: (p: Promise, cancelFunction: (p: Promise)=>any)=>Promise<any>}} The wrapped promises
 */
function useCancellablePromise() {
    // think of useRef as member variables inside a hook
    // you cannot define promises here as an array because
    // they will get initialized at every render refresh
    const promises = useRef([]);
    // useEffect initializes the promises array
    // and cleans up by calling cancel on every stored
    // promise.
    // Empty array as input to useEffect ensures that the hook is
    // called once during mount and the cancel() function called
    // once during unmount
    useEffect(() => {
        promises.current = promises.current || [];
        return () => {
            promises.current.forEach((p) => p.cancel());
            promises.current = [];
        };
    }, [promises]);

    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise
    // eslint-disable-next-line function-paren-newline
    const cancellablePromise = useCallback(
        /**
         *
         * @param {Promise} p the promise to wrap
         * @param {(p: Promise)=>any} cancelFunction the function to cancel
         * @returns {Promise<any>} the wrapped promise
         */
        (p, cancelFunction) => {
            const cPromise = makeCancelable(p, cancelFunction, () => _.pull(promises.current, p));
            promises.current.push(cPromise);
            return cPromise.promise;
        }, [promises, makeCancelable]);
    return {cancellablePromise};
}

export {useCancellablePromise, makeCancelable, CancelException};
