import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Dashboards} from 'components/Dashboard/Dashboards';

/**
 * This page shows a dashboard for working time
 * .TimeBuddy.Pages
 * @returns {React.ReactElement} The component to render
 */
function WorkingTimeDashboard() {
    // const {setGlobal} = useGlobalState();

    // const generalGuides = [
    //     {id: 'driveBuddy_logbook_drivingRecord', label: 'Fahrten KI gestützt erfassen'},
    //     {id: 'driveBuddy_vehicle_protocolEntry', label: 'Protokolleinträge für Fahrzeuge erfassen'},
    // ];

    return (
        <LayoutContainer>
            <Dashboards applicationId="timebuddy" />
            {/* <DashboardContainer head={<Typography variant="h2">Hilfe & Dokumentation</Typography>}>
                <List dense style={{width: '100%'}}>
                    {generalGuides.map(createQuickGuideListItem)}
                </List>
            </DashboardContainer> */}
        </LayoutContainer>
    );
}
export {WorkingTimeDashboard};
