import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import {listVehicles} from 'graphql/driveBuddy/Vehicle/queries';
import {deleteVehicle} from 'graphql/driveBuddy/Vehicle/mutations';

import carImg from 'applications/drivebuddy/settings/pages/images/AdobeStock_453925539.jpg';
import {Grid, Typography} from '@mui/material';
import _ from 'lodash';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';

/**
 * Formular for list vehicles
 * .DriveBuddy.Forms
 * @returns {React.ReactElement} The VehiclesListFormular component
 */
function VehiclesListFormular() {
    const messageKey = 'Vehicle_List';
    return (
        <ListData
            loadConfig={{
                query: listVehicles,
                mask: {tenantId: true, filter: undefined},
                variables: {global: {tenantId: 'tenantId'}},
            }}
            deleteConfig={{
                mutation: deleteVehicle,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="drivebuddy_drive_settings_vehicle_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createVehicle')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="vehicle-filter" messageKey={messageKey}>
                <FilterElementText label="Suche" path="search" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}><FilterElementText label="Name" path="name" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementText label="Hersteller" path="manufacturer" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementText label="Kennzeichen" path="licensenumber" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementText label="Model" path="model" /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Fahrzeug',
                                            renderItem: (item) => (<img style={{maxHeight: '40px'}} src={item.image?.url ?? carImg} alt="vehicle" />),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold">{item.name}</Typography>,
                                        },
                                        {
                                            title: 'Hersteller & Model',
                                            renderItem: (item) => <Typography noWrap>{_.join([item.manufacteur, item.model], ' ')}</Typography>,
                                            boxProps: {style: {marginLeft: '1rem'}},
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 2, lg: 1}}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Kennzeichen',
                                            renderItem: (item) => <Typography noWrap>{item.licensenumber}</Typography>,
                                        },
                                        {
                                            title: 'Fahrgestellnummer',
                                            renderItem: (item) => <Typography noWrap>{item.vin}</Typography>,
                                        },
                                    ],
                                    boxProps: {
                                        sx: {
                                            display: {xs: 'none', md: 'inherit'},
                                            flex: 1,
                                        },
                                    },
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'drivebuddy_drive_settings_vehicle_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'drivebuddy_drive_settings_vehicle_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {VehiclesListFormular};
