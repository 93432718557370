// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

/**
 * Mutation to create a vehicle entity instance
 * @property {string} tenantId - id of the tenant, the record should be created in
 * @property {string} name - unique name of the vehicle
 * @property {string} manufacturer - manufacturer of the vehicle
 * @property {string} model - model of the vehicle
 * @property {string} vin - vehicle identification number
 * @property {string} licensenumber - licence plate identification
 * @property {string} imageKey - key of the image
 * @returns {object} - the created vehicle instance
 */
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $tenantId: ID!,
    $name: String!,
    $manufacturer: String,
    $model: String,
    $vin: String,
    $licensenumber: String,
    $imageKey: String,
  ) {
    createVehicle(
      tenantId: $tenantId
      name: $name
      manufacturer: $manufacturer
      model: $model
      vin: $vin
      licensenumber: $licensenumber
      imageKey: $imageKey
    ) {
      id
      name
      manufacturer
      model
      vin
      licensenumber
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      subGrants {
        vehicleProtocolEntryReadable
        vehicleProtocolEntryCreatable
      }
      image {
        ...FileInformation
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * Mutation to update a vehicle entity instance
 * @property {string} id - id of the vehicle instance
 * @property {string} tenantId - id of the tenant, the record should be created in
 * @property {string} name - unique name of the vehicle
 * @property {string} manufacturer - manufacturer of the vehicle
 * @property {string} model - model of the vehicle
 * @property {string} vin - vehicle identification number
 * @property {string} licensenumber - licence plate identification
 * @property {string} imageKey - key of the image
 * @returns {object} - the updated vehicle instance
 */
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $id: ID!,
    $name: String,
    $manufacturer: String,
    $model: String,
    $vin: String,
    $licensenumber: String,
    $imageKey: String,
  ) {
    updateVehicle(
      id: $id,
      name: $name,
      manufacturer: $manufacturer,
      model: $model,
      vin: $vin,
      licensenumber: $licensenumber,
      imageKey: $imageKey,
    ) {
      id
      name
      manufacturer
      model
      vin
      licensenumber
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
      subGrants {
        vehicleProtocolEntryReadable
        vehicleProtocolEntryCreatable
      }
      image {
        ...FileInformation
      }
    }
  }
  ${FileInformationFragment}
`;

/**
 * Mutation to update a vehicle entity instance
 * @property {string} id - id of the vehicle instance
 * @property {string} name - new unique name of the vehicle
 * @returns {object} - the updated vehicle name and id
 */
export const updateVehicleName = /* GraphQL */ `
  mutation UpdateVehicleName(
    $id: ID!,
    $name: String!,
  ) {
    updateVehicle(id: $id, name: $name) {
      id name 
    }
  }
`;

/**
 * Mutation to delete a vehicle entity instance
 * @property {string} id - id of the vehicle instance
 * @returns {object} - the deleted vehicle instance
 */
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
      $id: ID!
    ) {
    deleteVehicle(
      id: $id
      ) {
      id
    }
  }
`;

/**
 * Mutation to create a protocol entry entity instance
 * @property {string} vehicleId - id of the vehicle, the protocol entry is referencing to
 * @property {number} vehicleKM - correct odometer value
 * @property {string} eventDateTime - date and time of the event
 * @property {string} cause - cause, why the protocol entry was made
 * @property {string} description - text to describe the event
 * @property {Array<string>} attachmentKeys - s3 keys of the attachments of the event
 * @returns {object} - the updated protocol entry entity instance
 */
export const createVehicleProtocolEntry = /* GraphQL */ `
  mutation CreateVehicleProtocolEntry(
    $userId: ID!,
    $vehicleId: ID!, 
    $vehicleKM: Int, 
    $eventDateTime: AWSDateTime, 
    $cause: VehicleProtocolCause, 
    $description: String, 
    $attachmentKeys: AWSJSON
  ) {
    createVehicleProtocolEntry(
      userId: $userId,
      vehicleId: $vehicleId,
      vehicleKM: $vehicleKM,
      eventDateTime: $eventDateTime,
      cause: $cause,
      description: $description,
      attachmentKeys: $attachmentKeys
    ) {
      id
      vehicleId,
      vehicleKM,
      eventDateTime,
      cause,
      description,
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
      }
    }
  }
`;

/**
 * Mutation to update a protocol entry entity instance
 * @property {string} id - id of the protocol entry instance
 * @property {string} vehicleId - id of the vehicle, the protocol entry is referencing to
 * @property {number} vehicleKM - correct odometer value
 * @property {string} eventDateTime - date and time of the event
 * @property {string} cause - cause, why the protocol entry was made
 * @property {string} description - text to describe the event
 * @property {Array<string>} attachmentKeys - s3 keys of the attachments of the event
 * @returns {object} - the updated protocol entry entity instance
 */
export const updateVehicleProtocolEntry = /* GraphQL */ `
  mutation UpdateVehicleProtocolEntry(
    $id: ID!,
    $vehicleId: ID, 
    $vehicleKM: Int, 
    $eventDateTime: AWSDateTime, 
    $cause: VehicleProtocolCause, 
    $description: String, 
    $attachmentKeys: AWSJSON
  ) {
    updateVehicleProtocolEntry(
      id: $id,
      vehicleId: $vehicleId,
      vehicleKM: $vehicleKM,
      eventDateTime: $eventDateTime,
      cause: $cause,
      description: $description,
      attachmentKeys: $attachmentKeys
    ) {
      id
      vehicleId,
      vehicleKM,
      eventDateTime,
      cause,
      description,
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
      }
    }
  }
`;

/**
 * Mutation to delete a protocol entry of a vehicle entity instance
 * @property {string} id - id of the protocol entry instance
 * @returns {object} - the deleted protocol entry instance
 */
export const deleteVehicleProtocolEntry = /* GraphQL */ `
  mutation DeleteVehicleProtocolEntry(
      $id: ID!
    ) {
    deleteVehicleProtocolEntry(
      id: $id
      ) {
      id
    }
  }
`;
