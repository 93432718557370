const {UUID_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {},
    backend: {
        completedAt: [{
            actions: ['create', 'update'],
            configurations: ['type'],
        }],
    },
    attributes: {
        createdByUserId: {
            type: 'String',
            format: UUID_REGEX,
            required: true,
        },
        completedAt: {
            type: 'DateTime',
        },
        completedByUserId: {
            type: 'String',
            format: UUID_REGEX,
        },
        presentEntries: {
            required: true,
            type: 'Array',
        },
    },
};

module.exports = {schema};
