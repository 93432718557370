/* eslint-disable jsdoc/valid-types */
import {
    Settings, AddCircleOutline, DashboardOutlined,
    BusinessOutlined, GroupsOutlined, PersonOutline, Edit, BookOutlined, StorageOutlined, CrisisAlert, EmergencyShareOutlined,
    PeopleOutline,
} from '@mui/icons-material';
import {UsersListPage} from 'applications/beyondbuddy/settings/pages/user/UsersListPage';
import {UserPage} from 'applications/beyondbuddy/settings/pages/user/UserPage';
import {TenantPage} from 'applications/beyondbuddy/settings/pages/tenant/TenantPage';
import {OrganizationalUnitPage} from 'applications/beyondbuddy/settings/pages/organizationalUnit/OrganizationalUnitPage';
import {OrganizationalUnitListPage} from 'applications/beyondbuddy/settings/pages/organizationalUnit/OrganizationalUnitListPage';
import {GroupPage} from 'applications/beyondbuddy/settings/pages/group/GroupPage';
import {GroupListPage} from 'applications/beyondbuddy/settings/pages/group/GroupsListPage';
import {CustomerPage} from 'applications/beyondbuddy/settings/pages/customer/CustomerPage';
import {CustomerListPage} from 'applications/beyondbuddy/settings/pages/customer/CustomerListPage';
import {ProfilePage} from 'applications/beyondbuddy/settings/pages/user/ProfilePage';
import {DrivebuddyPermissionsPage} from 'applications/drivebuddy/settings/pages/permissions/DrivebuddyPermissionsPage';
import {BeyondBuddyPermissionsPage} from 'applications/beyondbuddy/settings/pages/permissions/BeyondBuddyPermissionsPage';
import {BeyondBuddyDashboard} from 'applications/pages/BeyondBuddyDashboard';
import {GeneralSettingsTileDashboard} from 'applications/pages/GeneralSettingsTileDashboard';
import {ModulesPage} from 'applications/beyondbuddy/settings/pages/modules/ModulesPage';
import {GuideGeneralLayoutPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralLayoutPage';
import {GuideDashboardsPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuideDashboardsPage';
import {GuideOrganizationPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuideOrganizationPage';
import {GuidePermissionChooserPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuidePermissionChooserPage';
import {GuidePermissionsPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuidePermissionsPage';
import {GuideGeneralPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralPage';
import {GuideGeneralFilterPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuideGeneralFilterPage';
import {TimebuddyPermissionsPage} from 'applications/timebuddy/settings/pages/permissions/TimeBuddyPermissionsPage';
import {NotificationPage} from 'applications/beyondbuddy/settings/pages/notification/NotificationPage';
import {NotificationListPage} from 'applications/beyondbuddy/settings/pages/notification/NotificationListPage';
import {EvacuationPointListPage} from 'applications/beyondbuddy/settings/pages/evacuationPoint/EvacuationPointListPage';
import {EvacuationPointPage} from 'applications/beyondbuddy/settings/pages/evacuationPoint/EvacuationPointPage';
import {EvacuationCheckpointPage} from 'applications/beyondbuddy/settings/pages/evacuation/EvacuationCheckpointPage';
import {EvacuationPage} from 'applications/beyondbuddy/settings/pages/evacuation/EvacuationPage';
import {EvacuationListPage} from 'applications/beyondbuddy/settings/pages/evacuation/EvacuationListPage';
import {EvacuationFindPage} from 'applications/beyondbuddy/settings/pages/evacuation/EvacuationFindPage';
import {GuideUserActivityPage} from 'applications/beyondbuddy/settings/pages/quickguides/GuideUserActivityPage';
import {PeacebuddyPermissionsPage} from 'applications/peacebuddy/settings/pages/permissions/PeacebuddyPermissionsPage';
import {baseLinkSchemaOptions} from 'applications/configs';

/**
 * #### When making changes to the visible menus, the equivalent change should also be made to the menu test
 * @type {import('applications/configuration').ApplicationConfiguration}
 */
const applicationConfiguration = {
    id: 'beyondbuddy',
    name: 'BeyondBuddy',
    order: 99,
    menu: {
        id: 'beyondbuddy_menu',
        icon: <Settings />,
        label: 'Einstellungen',
        routeId: 'beyondbuddy_settings_general_dashboard_route',
    },
    route: {
        id: 'beyondbuddy_route',
        path: 'settings',
        routes: [
            {
                element: <>beyondbuddy_route</>,
            },
        ],
    },
    modules: [
        {
            id: 'beyondbuddy_settings_general',
            tileDashboard: <GeneralSettingsTileDashboard />,
            name: 'BeyondBuddy',
            menus: [
                {
                    id: 'beyondbuddy_settings_general_dashboard_menu',
                    icon: <DashboardOutlined />,
                    label: 'Dashboard',
                    routeId: 'beyondbuddy_settings_general_dashboard_route',
                },
                {
                    id: 'beyondbuddy_settings_general_users_menu',
                    icon: <PersonOutline />,
                    label: 'Benutzer',
                    routeId: 'beyondbuddy_settings_general_users_route',
                    showConditions: ['createUser'],
                    enableConditions: ['readUser'],
                    menus: [
                        {
                            id: 'beyondbuddy_settings_general_user_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Benutzer anlegen',
                            routeId: 'beyondbuddy_settings_general_user_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createUser'],
                        },
                        {
                            id: 'beyondbuddy_settings_general_user_profile_menu',
                            icon: <Edit />,
                            label: 'Eigenes Profil',
                            routeId: 'beyondbuddy_settings_general_user_profile_route',
                            enableConditions: ['readUser'],
                        },
                    ],
                }, {
                    id: 'beyondbuddy_settings_general_groups_menu',
                    icon: <GroupsOutlined />,
                    label: 'Gruppe',
                    routeId: 'beyondbuddy_settings_general_groups_route',
                    showConditions: ['createGroup'],
                    enableConditions: ['readGroup'],
                    menus: [
                        {
                            id: 'beyondbuddy_settings_general_group_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Gruppe anlegen',
                            routeId: 'beyondbuddy_settings_general_group_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createGroup'],
                        },
                    ],
                }, {
                    id: 'beyondbuddy_settings_general_organizationalunits_menu',
                    icon: <GroupsOutlined />,
                    label: 'Organisationseinheit',
                    routeId: 'beyondbuddy_settings_general_organizationalunits_route',
                    showConditions: ['createOrganizationalUnit'],
                    enableConditions: ['readOrganizationalUnit'],
                    menus: [
                        {
                            id: 'beyondbuddy_settings_general_organizationalunit_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Einheit anlegen',
                            routeId: 'beyondbuddy_settings_general_organizationalunit_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createOrganizationalUnit'],
                        },
                    ],
                },
                {
                    id: 'beyondbuddy_settings_general_customers_menu',
                    icon: <PeopleOutline />,
                    label: 'Kunden',
                    routeId: 'beyondbuddy_settings_general_customers_route',
                    showConditions: ['createCustomer'],
                    enableConditions: ['readCustomer'],
                    menus: [
                        {
                            id: 'beyondbuddy_settings_general_customer_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Kunde anlegen',
                            routeId: 'beyondbuddy_settings_general_customer_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createCustomer'],
                        },
                    ],
                },
                {
                    id: 'beyondbuddy_settings_general_tenant_menu',
                    icon: <BusinessOutlined />,
                    label: 'Mandant',
                    routeId: 'beyondbuddy_settings_general_tenant_route',
                },
                {
                    id: 'beyondbuddy_settings_permissions',
                    icon: <BookOutlined />,
                    label: 'Berechtigungen',
                    enableConditions: ['TenantAdmin'],
                    routeId: 'beyondbuddy_settings_permissions_route',
                },
                {
                    id: 'beyondbuddy_settings_modules',
                    icon: <StorageOutlined />,
                    label: 'Modulfreigabe',
                    enableConditions: ['TenantAdmin'],
                    routeId: 'beyondbuddy_settings_modules_route',
                }, {
                    id: 'beyondbuddy_settings_evacuation_point_menu',
                    icon: <CrisisAlert />,
                    label: 'Evakuierungspunkte',
                    routeId: 'beyondbuddy_settings_evacuation_points_route',
                    showConditions: ['createEvacuationPoint'],
                    // enableConditions: ['readEvacuationPoint'],
                    menus: [
                        {
                            id: 'beyondbuddy_settings_evacuation_point_create_menu',
                            icon: <AddCircleOutline />,
                            label: 'Evakuierungspunkt anlegen',
                            routeId: 'beyondbuddy_settings_evacuation_point_route',
                            routeParams: {
                                id: 'create',
                            },
                            enableConditions: ['createEvacuationPoint'],
                        },
                    ],
                },
                {
                    id: 'beyondbuddy_settings_evacuations_menu',
                    icon: <EmergencyShareOutlined />,
                    label: 'Evakuierungen',
                    routeId: 'beyondbuddy_settings_evacuations_route',
                    showConditions: ['createEvacuationPoint'],
                    enableConditions: ['readEvacuationPoint'],
                },
            ],
            routes: [
                {
                    id: 'beyondbuddy_settings_general_route',
                    path: 'general',
                    routes: [
                        {
                            id: 'beyondbuddy_settings_general_dashboard_route',
                            path: 'dashboard',
                            element: <BeyondBuddyDashboard />,
                        },
                        {
                            id: 'beyondbuddy_settings_general_users_route',
                            path: 'user',
                            accessConditions: ['readUser'],
                            routes: [
                                {
                                    element: <UsersListPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_general_user_profile_route',
                                    path: 'profile',
                                    element: <ProfilePage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_general_user_route',
                                    path: ':id',
                                    element: <UserPage />,
                                },
                            ],
                        },
                        {
                            id: 'beyondbuddy_settings_general_organizationalunits_route',
                            path: 'organizationalunit',
                            accessConditions: ['readOrganizationalUnit'],
                            routes: [
                                {
                                    element: <OrganizationalUnitListPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_general_organizationalunit_route',
                                    path: ':id',
                                    element: <OrganizationalUnitPage />,
                                },
                            ],
                        },
                        {
                            id: 'beyondbuddy_settings_general_groups_route',
                            path: 'group',
                            accessConditions: ['readGroup'],
                            routes: [
                                {
                                    element: <GroupListPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_general_group_route',
                                    path: ':id',
                                    element: <GroupPage />,
                                },
                            ],
                        },
                        {
                            id: 'beyondbuddy_settings_general_customers_route',
                            path: 'customer',
                            accessConditions: ['readCustomer'],
                            routes: [
                                {
                                    element: <CustomerListPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_general_customer_route',
                                    path: ':id',
                                    element: <CustomerPage />,
                                },
                            ],
                        },
                        {
                            id: 'beyondbuddy_settings_evacuations_route',
                            path: 'evacuation',
                            routes: [
                                {
                                    element: <EvacuationListPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_evacuation_route',
                                    path: ':id',
                                    element: <EvacuationPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_evacuation_route',
                                    path: 'find',
                                    element: <EvacuationFindPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_evacuation_points_route',
                                    path: 'point',
                                    element: <EvacuationPointListPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_evacuation_point_route',
                                    path: 'point/:id',
                                    element: <EvacuationPointPage />,
                                },
                                {
                                    id: 'beyondbuddy_settings_evacuation_point_checkpoint_route',
                                    path: 'checkpoint/:id',
                                    element: <EvacuationCheckpointPage />,
                                },
                            ],
                        },
                        {
                            id: 'beyondbuddy_settings_general_tenant_route',
                            path: 'tenant',
                            routes: [
                                {
                                    element: <TenantPage />,
                                },
                            ],
                        },
                        {
                            id: 'beyondbuddy_settings_modules_route',
                            path: 'modules',
                            accessConditions: ['TenantAdmin'],
                            element: <ModulesPage />,
                        },
                    ],
                }, {
                    id: 'beyondbuddy_settings_permissions_route',
                    path: 'permissions/beyondbuddy',
                    element: <BeyondBuddyPermissionsPage />,
                }, {
                    id: 'beyondbuddy_notification_list_route',
                    path: 'notification',
                    routes: [
                        {element: <NotificationListPage />},
                        {
                            id: 'beyondbuddy_notification_route',
                            path: ':id',
                            element: <NotificationPage />,
                        },
                    ],
                },
            ],
        },
        {
            id: 'drivebuddy_settings_permissions',
            name: 'DriveBuddy',
            accessConditions: ['TenantAdmin', 'DriveBuddy'],
            menus: [
                {
                    id: 'drivebuddy_settings_permissions',
                    icon: <BookOutlined />,
                    label: 'Berechtigungen',
                    routeId: 'drivebuddy_settings_permissions_route',
                },
            ],
            routes: [
                {
                    id: 'drivebuddy_settings_permissions_route',
                    path: 'permissions/drivebuddy',
                    element: <DrivebuddyPermissionsPage />,
                },
            ],
        },
        {
            id: 'timebuddy_settings_permissions',
            name: 'TimeBuddy',
            accessConditions: ['TenantAdmin', 'TimeBuddy'],
            menus: [
                {
                    id: 'timebuddy_settings_permissions',
                    icon: <BookOutlined />,
                    label: 'Berechtigungen',
                    routeId: 'timebuddy_settings_permissions_route',
                },
            ],
            routes: [
                {
                    id: 'timebuddy_settings_permissions_route',
                    path: 'permissions/timebuddy',
                    element: <TimebuddyPermissionsPage />,
                },
            ],
        },
        {
            id: 'peacebuddy_settings_permissions',
            name: 'PeaceBuddy',
            accessConditions: ['TenantAdmin', 'PeaceBuddy'],
            menus: [
                {
                    id: 'peacebuddy_settings_permissions',
                    icon: <BookOutlined />,
                    label: 'Berechtigungen',
                    routeId: 'peacebuddy_settings_permissions_route',
                },
            ],
            routes: [
                {
                    id: 'peacebuddy_settings_permissions_route',
                    path: 'permissions/peacebuddy',
                    element: <PeacebuddyPermissionsPage />,
                },
            ],
        },
    ],
    guides: [
        {
            id: 'beyondbuddy_general_guides',
            title: 'Online-Hilfe',
            content: <GuideGeneralPage />,
        }, {
            id: 'beyondbuddy_general_layout',
            title: 'Allgemeines Layout',
            content: <GuideGeneralLayoutPage />,
        }, {
            id: 'beyondbuddy_general_filter',
            title: 'Filtern von Ergebnissen',
            content: <GuideGeneralFilterPage />,
        }, {
            id: 'beyondbuddy_general_dashboard',
            title: 'Dashboards',
            content: <GuideDashboardsPage />,
        }, {
            id: 'beyondbuddy_general_permissions',
            title: 'Berechtigungen',
            content: <GuidePermissionsPage />,
        }, {
            id: 'beyondbuddy_general_permissionChooser',
            title: 'Berechtigung vergeben',
            content: <GuidePermissionChooserPage />,
        }, {
            id: 'beyondbuddy_general_organization',
            title: 'Aufbauorganisation',
            content: <GuideOrganizationPage />,
        }, {
            id: 'beyondbuddy_general_user_active',
            title: 'Aktive / Inaktive Benutzer',
            content: <GuideUserActivityPage />,
        },
    ],
};

/**
 * @typedef {import('./types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('applications/configuration').OperationName} OperationName
 * @typedef {import('./types').UnmanagedObjectBeyondBuddy} UnmanagedObjectBeyondBuddy
 * @typedef {`${OperationName}${UnmanagedObjectBeyondBuddy}`} UnmanagedOperation
 * @typedef {OperationName | UnmanagedOperation | 'member_of' | "access" | 'readPermission' | 'updatePermission' | 'readAttributes' | 'updateAttributes'} Permission
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('applications/configuration').ManagedObject} ManagedObject
 */

/** @type {Record<PermissionGroupBeyondBuddy, Permission[]>} */
const permissionGroupDefinition = {
    updateGroup: ['update', 'delete'],
    permissionGroup: ['readPermission', 'updatePermission'],
    attributeGroup: ['readAttributes', 'updateAttributes'],
};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    User: {
        getFilterMap: (search) => ({search, active: true, ...(!isIncoming ? {permittableOnly: true} : {})}),
        // explicit false check because new options are always active, and the field is not loaded
        isDisabled: (item) => (item?.active === false || (!isIncoming && !item?.grants?.permissionsUpdatable)),
    },
    Group: baseLinkSchemaOptions(isIncoming),
    OrganizationalUnit: baseLinkSchemaOptions(isIncoming),
    Customer: baseLinkSchemaOptions(isIncoming),
});

// /** @type {Record<import('./types').ManagedObjectBeyondBuddy, string>} */
// const entityLabels = {
//     Tenant: 'Mandant',
//     User: 'Benutzer',
//     Group: 'Gruppen',
//     OrganizationalUnit: 'Organisationseinheiten',
//     Customer: 'Kunde',
//     EvacuationPoint: 'Evakuierungspunkt',
// };

/** @type {import('applications/configuration').ManagedObject[]} */
// @ts-ignore
const managedEntityKeys = ['Tenant', 'User', 'Group', 'OrganizationalUnit', 'Customer'];

/** @type {import('applications/configuration').ManagedObject[]} */
const organizationEntityKeys = ['Tenant', 'User', 'Group', 'OrganizationalUnit'];

/** @type {Record<Permission|PermissionGroupBeyondBuddy, string>} */
const permissionLabels = {
    access: 'Zugriff',
    create: 'Anlegen',
    read: 'Lesen',
    update: 'Bearbeiten',
    updateGroup: 'Schreiben',
    delete: 'Löschen',
    member_of: 'Mitglied',
    permissionGroup: 'Berechtigen',
    readPermission: 'Berechtigungen lesen',
    updatePermission: 'Berechtigungen schreiben',
    attributeGroup: 'Verknüpfungen',
    readAttributes: 'Verknüpfungen lesen',
    updateAttributes: 'Verknüpfungen schreiben',
    createNotification: 'Benachrichtigung erstellen',
    deleteNotification: 'Benachrichtigung löschen',
    updateNotification: 'Benachrichtigung bearbeiten',
    readNotification: 'Benachrichtigung lesen',
    createEvacuation: 'Evakuierung starten',
    deleteEvacuation: 'Evakuierung löschen',
    readEvacuation: 'Evakuierung einsehen',
    updateEvacuation: 'Evakuierung bearbeiten',
    readProtocolEntry: 'Protokolleintrag lesen',
    createProtocolEntry: 'Protokolleintrag erstellen',
    updateProtocolEntry: 'Protokolleintrag bearbeiten',
    deleteProtocolEntry: 'Protokolleintrag löschen',
};

export {
    applicationConfiguration,
    permissionGroupDefinition as permissionGroupDefinitionBeyondBuddy,
    // entityLabels as entityLabelsBeyondBuddy,
    permissionLabels as permissionLabelsBeyondBuddy,
    managedEntityKeys as managedEntityKeysBeyondBuddy,
    organizationEntityKeys,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsBeyondBuddy,
};
