import React from 'react';
import {VehiclesListFormular} from 'applications/drivebuddy/settings/forms/vehicle/VehiclesListFormular';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of vehicles
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The VehiclesPage component.
 */
function VehiclesListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Fahrzeuge</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <VehiclesListFormular />
            </div>
        </LayoutContainer>
    );
}
export {VehiclesListPage};
