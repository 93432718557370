import {
    Alert, Box, Collapse, LinearProgress, Typography,
} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import React, {
    useCallback, useEffect, useState,
    useMemo,
} from 'react';
import {
    generatePath, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listTimePeriods} from 'graphql/timeBuddy/TimePeriod/queries';
import {useCanAccess} from 'hooks/useCanAccess';
import _ from 'lodash';
import {listWorkingTimeLogs} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {useGlobalState} from 'hooks/useGlobalState';
import {getWorkingTimeLogTemplate} from 'graphql/timeBuddy/WorkingTimeLogTemplate/queries';
import {createWorkingTimeWorkLog, updateWorkingTimeWorkLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {Exceptions} from 'messages/Exceptions';
import {useMessage} from 'hooks/useMessage';
import {Messages} from 'messages/Messages';
import {useFindRoute} from 'hooks/useFindRoute';
import {LoadingButton} from '@mui/lab';
import {useLogMessage} from 'hooks/useLogMessage';
import qrCodeDemo from 'applications/timebuddy/modules/workingtime/pages/images/qr_code.png';
import {Check, LoopOutlined} from '@mui/icons-material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * Shows a Typography component that indicates a loading state
 * @param {object} props - props of the component
 * @param {boolean} props.loading - indicates the loading state
 * @param {boolean} [props.disabled] - indicates the disabled state
 * @param {string} props.textLoading - text to be shown when loading is true
 * @param {string} props.textLoaded - text to be shown when loading is false
 * @param {import('@mui/material').TypographyProps} [props.typographyProps] - props for the typography component
 * @param {import('@mui/material').IconProps} [props.iconProps] - props for the icon that is being displayed
 * @returns {React.ReactElement} the LoadingTypography
 */
function LoadingTypography({
    loading, disabled, textLoading, textLoaded, typographyProps, iconProps,
}) {
    const disabledProps = disabled ? {
        color: 'gray',
        fontWeight: 'inherit',
        fontStyle: 'italic',
    } : {};
    return (
        <Typography
            variant="h1"
            display="flex"
            gap={2}
            marginLeft="2rem"
            {...typographyProps}
            {...disabledProps}
        >
            {loading && (
                <LoopOutlined
                    // @ts-ignore
                    color="warning"
                    {...iconProps}
                    {...disabledProps}
                />
            )}
            {!loading && (
                <Check
                    // @ts-ignore
                    color="success"
                    {...iconProps}
                    {...disabledProps}
                />
            )}
            {` ${loading ? textLoading : textLoaded}`}
        </Typography>
    );
}

/**
 * ## WorkingTime log page
 * Displays information about a single `WorkingTimeLog`
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeLogCheckpointPage() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const findRoute = useFindRoute();
    const {getGlobal} = useGlobalState();
    const {enqueueMessage} = useMessage();
    const {logMessage, isSending} = useLogMessage();
    const {templateid: templateId, secret} = useParams();

    const canReadWorkingTimeModel = useCanAccess('readWorkingTimeModel');

    const [openPeriods, setOpenPeriods] = useState(null);
    const [shouldSave, setShouldSave] = useState(true);
    const [isClosingDrafts, setClosingDrafts] = useState(false);
    const [oldWorkingTimeLog, setOldWorkingTimeLog] = useState(null);
    const [newWorkingTimeLog, setNewWorkingTimeLog] = useState(null);
    const [workingTimeLogSaved, setWorkingTimeLogSaved] = useState(false);
    const [progress, setProgress] = useState(0);

    const [error, setError] = useState(Object);
    const [isCanceled, setIsCanceled] = useState(false);
    const [feedbackSent, setFeedbackSent] = useState(false);

    const {getItem, listItems, editItem} = AWSAppSyncProvider();

    const [workingTimeLogTemplate, setWorkingTimeLogTemplate] = useState(null);
    const workingtimeLogRoute = findRoute('timebuddy_workingtime_work_log_route');

    const tenantId = getGlobal('tenantId');
    const userId = getGlobal('userId');

    useEffect(() => {
        try {
            const curr = (new Date()).getTime();
            const call = searchParams.get('call');
            if (call) {
                // call is older than a minute
                if ((curr - Number(call)) > 60 * 1000) {
                    setIsCanceled(true);
                }
            } else {
                setSearchParams({call: `${curr}`});
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
    }, [secret, searchParams, setIsCanceled, setSearchParams]);

    const isDraft = useMemo(() => oldWorkingTimeLog?.draft, [oldWorkingTimeLog]);
    // console.log(isDraft, oldWorkingTimeLog);

    const sendFeedback = async () => {
        logMessage(
            'PageError',
            {
                level: 'WARN',
                message: JSON.stringify({
                    workingTimeLogTemplate,
                    workingTimeLog: newWorkingTimeLog,
                    openPeriods,
                    error,
                }),
            },
            false,
        );
        enqueueMessage('Feedback_Message', Messages.API_LOG_SUCCESSFUL);
        setFeedbackSent(true);
    };

    const onSave = useCallback((result) => {
        if (result?.id) {
            setTimeout(() => navigate(`/${generatePath(workingtimeLogRoute.path, {id: result.id})}`, {replace: true}), 2000);
        }
    }, [workingtimeLogRoute, navigate]);

    useEffect(() => {
        if (!_.isEmpty(error)) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }, [error]);

    // load the template
    useEffect(() => {
        if (templateId) {
            getItem(getWorkingTimeLogTemplate, {
                id: templateId,
            }).then(setWorkingTimeLogTemplate)
                .catch(setError);
        }
    }, [templateId, getWorkingTimeLogTemplate, getItem, setWorkingTimeLogTemplate]);

    // load the open logs
    useEffect(() => {
        if (userId && templateId) {
            // list all log drafts that were created with the template
            listItems(listWorkingTimeLogs, {
                tenantId,
                filter: {
                    userId,
                    draft: true,
                    templateId,
                },
            }).then((result) => {
                const log = _.orderBy(result, 'startDateTime', 'desc').filter((l) => !l.endDateTime)[0];
                // if a draft was found
                if (log?.id) {
                    setOldWorkingTimeLog(log);
                    const startDateTime = Date.parse(log.startDateTime);
                    const now = (new Date()).getTime();
                    if ((now - startDateTime) > (30 * 60 * 1000)) { // 30 min
                        setShouldSave(false);
                    }
                    if ((now - startDateTime) < (10 * 1000)) { // 10 sec
                        setIsCanceled(true);
                    }
                } else {
                    setOldWorkingTimeLog({});
                }
            }).catch(setError);
        }
    }, [userId, templateId, listWorkingTimeLogs, listItems, setOldWorkingTimeLog, setShouldSave]);

    // get the open periods
    useEffect(() => {
        // list all periods that are open
        listItems(listTimePeriods, {
            filter: {open: true},
        }).then(async (periods) => {
            setOpenPeriods(periods);
        });
    }, [listTimePeriods, listItems, setOpenPeriods]);

    /**
     * load all open logs (corresponding to the template id)
     * and close them
     */
    const closeAllDraftLogs = useCallback(() => {
        setClosingDrafts(true);
        // list all log drafts that were created with the template
        listItems(listWorkingTimeLogs, {
            tenantId,
            filter: {
                userId,
                draft: true,
                templateId,
            },
        }).then((result) => {
            const logs = result.filter((l) => !l.endDateTime);
            logs.forEach((l) => {
                editItem(updateWorkingTimeWorkLog, {
                    ...l,
                    endDateTime: new Date().toISOString(),
                    draft: _.isEmpty(openPeriods),
                    secret,
                }).catch(setError);
            });
            // all drafts are closed -> so set the log to empty
            setOldWorkingTimeLog({});
            // new check-in possible
            setShouldSave(true);
        }).catch(setError).finally(() => setClosingDrafts(false));
    }, [templateId, secret, tenantId, userId, listWorkingTimeLogs, openPeriods, listItems, setOldWorkingTimeLog, setShouldSave]);

    const save = useMemo(() => _.debounce(() => {
        if (!workingTimeLogSaved && _.isEmpty(error)) {
            try {
                if (!oldWorkingTimeLog?.id) {
                    // console.log(workingTimeLogTemplate);
                    // create
                    editItem(createWorkingTimeWorkLog, {
                        ..._.pick(workingTimeLogTemplate, 'kind', 'workplaceId', 'comment'),
                        startDateTime: new Date().toISOString(),
                        draft: true,
                        userId,
                        templateId,
                        secret,
                    }).then((item) => {
                        // console.log(item);
                        setNewWorkingTimeLog(item);
                        enqueueMessage('workingtimelogcheckpointpage', Messages.API_SAVE_SUCCESSFUL);
                        setWorkingTimeLogSaved(true);
                        onSave(item);
                    }).catch(setError);
                } else {
                // update
                    editItem(updateWorkingTimeWorkLog, {
                        ...oldWorkingTimeLog,
                        endDateTime: new Date().toISOString(),
                        draft: _.isEmpty(openPeriods),
                        secret,
                    }).then((item) => {
                        // console.log(item);
                        setNewWorkingTimeLog(item);
                        enqueueMessage('workingtimelogcheckpointpage', Messages.API_SAVE_SUCCESSFUL);
                        setWorkingTimeLogSaved(true);
                        onSave(item);
                    }).catch(setError);
                }
            } catch (err) {
                enqueueMessage('workingtimelogcheckpointpage', Exceptions.API_SAVE_ERROR, {err});
            }
        }
    }, 250), [
        enqueueMessage, onSave, editItem, setNewWorkingTimeLog, setWorkingTimeLogSaved,
        workingTimeLogTemplate, error, oldWorkingTimeLog, newWorkingTimeLog, openPeriods, templateId, secret, userId,
    ]);

    const errorMessage = useMemo(() => _.first(error?.errors)?.message, [error]);
    const templateNotFount = useMemo(() => _.startsWith(_.first(error?.errors)?.message, 'Could not find WorkingTimeLogTemplate'), [error]);
    const secretNotCorrect = useMemo(() => _.first(error?.errors)?.message === 'Error: The secret is not correct', [error]);
    const templateNotValidYet = useMemo(() => _.first(error?.errors)?.message === 'Error: Template is not valid yet', [error]);
    const templateNotValidAnymore = useMemo(() => _.first(error?.errors)?.message === 'Error: Template is not valid anymore', [error]);

    useEffect(() => {
        if (_.isEmpty(error)
        && !isCanceled && shouldSave
        && userId && !workingTimeLogSaved
        && openPeriods && oldWorkingTimeLog
        && workingTimeLogTemplate) {
            save();
        }
        if (isCanceled || !_.isEmpty(error)) {
            setProgress(0);
        } else if (workingTimeLogSaved) {
            setProgress(100);
        } else {
            let p = 0;
            if (workingTimeLogTemplate) p += 25;
            if (oldWorkingTimeLog) p += 25;
            if (openPeriods) p += 25;
            setProgress(p);
        }
    }, [isCanceled, error, shouldSave, userId, workingTimeLogSaved, save, oldWorkingTimeLog, workingTimeLogTemplate, openPeriods]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>{!newWorkingTimeLog?.endDateTime ? 'Check-In' : 'Check-Out'}</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <LinearProgress
                variant={(workingTimeLogSaved || error) ? 'determinate' : 'indeterminate'}
                value={progress}
            />
            {(canReadWorkingTimeModel && openPeriods && !openPeriods?.length)
            && <Alert severity="warning">Es ist keine Abrechnungsperiode aktiv - es können aktuell nur Entwürfe gespeichert werden!</Alert>}
            {/* {(workingTimeLogSaved && newWorkingTimeLog.id && !newWorkingTimeLog?.endDateTime) && <Alert severity="success">Dein Check-In war erfolgreich</Alert>} */}
            {/* {(workingTimeLogSaved && newWorkingTimeLog.id && newWorkingTimeLog?.endDateTime) && <Alert severity="success">Dein Check-Out war erfolgreich</Alert>} */}
            {/* {(!isCanceled && !workingTimeLogSaved && _.isEmpty(error)) && <Alert severity="info">Verarbeite Deine Zeitbuchung</Alert>} */}

            {templateNotFount && (<Alert severity="error">Der QR Code kann nicht verarbeitet werden!</Alert>)}
            {secretNotCorrect && (<Alert severity="error">Der QR Code konnte nicht verifiziert werden!</Alert>)}
            {templateNotValidYet && (<Alert severity="warning">Der QR Code ist noch nicht gültig!</Alert>)}
            {templateNotValidAnymore && (<Alert severity="error">Der QR Code ist nicht mehr gültig!</Alert>)}
            {isCanceled && (<Alert severity="error">Es wurde ein Unstimmigkeit festgestellt und die Anfrage abgebrochen!</Alert>)}

            {!(secretNotCorrect || templateNotValidYet || templateNotValidAnymore || templateNotFount)
            && errorMessage && (<Alert severity="error">{errorMessage}</Alert>)}

            <Box display="flex" alignItems="center" gap={2} marginTop="2rem">
                <Box component="img" alt="QR" src={qrCodeDemo} height="100px" />
                <Typography variant="h1">
                        QR-Code Checkpoint
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} marginTop="2rem">
                <LoadingTypography
                    disabled={!_.isEmpty(error) || isCanceled}
                    loading={!workingTimeLogTemplate}
                    textLoading="Vorlage laden ..."
                    textLoaded={`Vorlage "${workingTimeLogTemplate?.name}" geladen`}
                />
                <LoadingTypography
                    disabled={!_.isEmpty(error) || isCanceled}
                    loading={!openPeriods}
                    textLoading="Offene Zeitperioden laden ..."
                    textLoaded={_.isEmpty(openPeriods) ? 'Es wurde keine offene Periode gefunden' : 'Es wurden offene Perioden gefunden'}
                />
                <LoadingTypography
                    disabled={!_.isEmpty(error) || isCanceled}
                    loading={!oldWorkingTimeLog}
                    textLoading="Letzten Check-In laden ..."
                    textLoaded={isDraft ? 'Es wurde ein Check-In gefunden' : 'Es wurde kein Check-In gefunden'}
                />
                <LoadingTypography
                    disabled={(shouldSave && !isDraft) || !_.isEmpty(error) || isCanceled}
                    loading={!oldWorkingTimeLog}
                    textLoading="Check-In analysieren ..."
                    textLoaded={shouldSave ? 'Check-In analysiert' : 'Benutzerentscheidung erforderlich'}
                    typographyProps={{color: shouldSave ? 'inherit' : 'warning.main', fontWeight: shouldSave ? 'inherit' : 'bold'}}
                    // @ts-ignore
                    iconProps={!shouldSave ? {color: 'warning.main'} : undefined}
                />
                {(!shouldSave && !isCanceled && _.isEmpty(error))
                && (
                    <Box display="flex" gap={2} marginLeft="5rem" width="max-content">
                        <LoadingButton disabled={isClosingDrafts} loading={isClosingDrafts} variant="contained" onClick={() => closeAllDraftLogs()}>Check - IN</LoadingButton>
                        <LoadingButton disabled={isClosingDrafts} variant="contained" onClick={() => setShouldSave(true)}>Check - OUT</LoadingButton>
                    </Box>
                )}
                <LoadingTypography
                    disabled={!shouldSave || !_.isEmpty(error)}
                    loading={!workingTimeLogSaved}
                    textLoading={isCanceled ? 'Abgebrochen!' : 'Speichern ...'}
                    textLoaded={newWorkingTimeLog?.endDateTime ? 'Check-OUT erfolgreich' : 'Check-IN erfolgreich'}
                    typographyProps={{
                        // eslint-disable-next-line no-nested-ternary
                        bgcolor: workingTimeLogSaved
                            ? (newWorkingTimeLog?.endDateTime
                                ? 'success.main' : 'warning.main') : 'inherit',
                        color: workingTimeLogSaved ? 'white' : 'warning.main',
                        fontWeight: 'bold',
                    }}
                />
            </Box>

            {(secretNotCorrect || templateNotValidYet || templateNotValidAnymore || templateNotFount || errorMessage) && (
                <>
                    <Collapse
                        in={!feedbackSent}
                    >
                        <LoadingButton
                            variant="contained"
                            loading={isSending}
                            onClick={sendFeedback}
                            data-test="LoadingButton_SendFeedback"
                            style={{marginTop: '2rem'}}
                        >
                        Benachrichtigung senden
                        </LoadingButton>
                    </Collapse>
                    <Typography
                        style={{marginTop: '2rem'}}
                    >
                        Wende Dich an den Systemverantwortlichen um das Problem zu analysieren.
                        <br />
                        Mögliche Gründe sind eine fehlende Berechtigung oder ein fehlerhafter QR-Code.
                        <br />
                        <i>{`Referenz: Vorlage ${templateId}`}</i>
                    </Typography>
                </>
            ) }

        </LayoutContainer>
    );
}

export {WorkingTimeLogCheckpointPage};
