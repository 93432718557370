// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../../beyondBuddy/fragments';

export const createCemetery = /* GraphQL */ `
  mutation CreateCemetery(
      $tenantId: ID!
      $nr: String!
      $referenceId: String
      $name: String!
      $address: String
      $zip: Int
      $city: String
      $countryCode: String
      $eMailAddress: String
      $phoneNumber: String
      $restPeriodUrnGrave: Int
      $restPeriodCoffinGrave: Int
      $imageKey: String
      $attachmentKeys: AWSJSON
    ) {
    createCemetery(
      tenantId: $tenantId
      nr: $nr
      referenceId: $referenceId
      name: $name
      address: $address
      zip: $zip
      city: $city
      countryCode: $countryCode
      eMailAddress: $eMailAddress
      phoneNumber: $phoneNumber
      restPeriodUrnGrave: $restPeriodUrnGrave
      restPeriodCoffinGrave: $restPeriodCoffinGrave
      imageKey: $imageKey
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      nr
      referenceId
      name
      address
      zip
      city
      countryCode
      eMailAddress
      phoneNumber
      restPeriodUrnGrave
      restPeriodCoffinGrave
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

export const updateCemetery = /* GraphQL */ `
  mutation UpdateCemetery(
      $id: ID!
      $nr: String!
      $referenceId: String
      $name: String!
      $address: String
      $zip: Int
      $city: String
      $countryCode: String
      $eMailAddress: String
      $phoneNumber: String
      $restPeriodUrnGrave: Int
      $restPeriodCoffinGrave: Int
      $imageKey: String
      $attachmentKeys: AWSJSON
    ) {
    updateCemetery(
      id: $id
      nr: $nr
      referenceId: $referenceId
      name: $name
      address: $address
      zip: $zip
      city: $city
      countryCode: $countryCode
      eMailAddress: $eMailAddress
      phoneNumber: $phoneNumber
      restPeriodUrnGrave: $restPeriodUrnGrave
      restPeriodCoffinGrave: $restPeriodCoffinGrave
      imageKey: $imageKey
      attachmentKeys: $attachmentKeys
    ) {
      id
      tenantId
      nr
      referenceId
      name
      address
      zip
      city
      countryCode
      eMailAddress
      phoneNumber
      restPeriodUrnGrave
      restPeriodCoffinGrave
      imageKey
      image {
        ...FileInformation
      }
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
  ${FileInformationFragment}
`;

export const deleteCemetery = /* GraphQL */ `
 mutation DeleteCemetery(
     $id: ID!
   ) {
   deleteCemetery(
     id: $id,
     ) {
      id
   }
 }
`;

export const deleteCemeteries = /* GraphQL */ `
 mutation DeleteCemeteries(
     $ids: [ID!]
   ) {
    deleteCemeteries(
     ids: $ids,
     )
 }
`;
