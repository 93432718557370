const {UUID_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        userId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format'],
            },
        ],
    },
    backend: {},
    attributes: {
        userId: {
            format: UUID_REGEX,
        },
    },
};

module.exports = {schema};
