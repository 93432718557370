import _ from 'lodash';
/**
 * Mask variables
 *
 * Function that checks if required variables are present, and hides variables
 * that should not be sent
 * @param {Record<string, any>} variables - variables to check
 * @param {Record<string, boolean>} mask - masking config to check with. True values are seen as required,
 * while false values are seen as allowed. Everything else will be filtered
 * @returns {{variables: Record<string, any>, missingObligatoryVariables: string[]}} - variables, and potential missing keys
 */
const maskVariables = (variables, mask) => {
    if (!mask) {
        return {variables, missingObligatoryVariables: []};
    }

    // remove everything that shouldn't be sent
    const output = _.pickBy(
        variables,
        (_vValue, vKey) => _.hasIn(mask, vKey),
    );

    // check obligatory
    const missingObligatoryVariables = _.flatMap(mask, (mValue, mKey) => {
        if (mValue) {
            const oValue = _.get(output, mKey); // TODO: check change in behavior: dot chaining now allowed
            return _.isNil(oValue)
                ? [mKey]
                : [];
        }
        return [];
    });
    return {variables: output, missingObligatoryVariables};
};

export {maskVariables};
