import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import {ListFilterContext} from 'components/Form/ListFilterProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';

/**
 * ## Range Filter Element
 *
 * Enables filtering by ranges of numbers dates
 * @param {object} props props of the component
 * @param {string} [props.id] id of the FilterElementDate
 * @param {string} props.label label to display above form fields
 * @param {string} props.path path to put the range conditions in the filter
 * @param {(value: any) => void} [props.onAccept] - function to be called on date choose
 * @returns {React.ReactElement} element to be rendered
 */
function FilterElementDate({
    id,
    path,
    label,
    onAccept,
    ...rest
}) {
    const {updateFilter, filterValues$} = useContext(ListFilterContext);
    const [dateValue, setDateValue] = useState(null);

    useEffect(() => {
        const subscription = filterValues$?.subscribe((values) => {
            const value = _.get(values, path);
            if (!value) {
                setDateValue(null);
                return;
            }
            /** @type {import('moment').Moment} */
            const valueM = moment(value);
            if (valueM.isValid() && !_.isNaN(valueM.milliseconds())) {
                setDateValue(valueM);
            } else {
                setDateValue(null);
            }
        });
        return () => subscription?.unsubscribe();
    }, [filterValues$, setDateValue, path]);

    // add extra debounce for date pick
    const changeFilter = _.debounce(updateFilter, 2000);

    // eslint-disable-next-line function-paren-newline
    const onChange = useCallback(
        /**  @param {moment.Moment | null} date the date from the picker */
        (date) => {
            if (date === null) {
                changeFilter(path, undefined, true);
                return;
            }
            if (date.isValid() && !_.isNaN(date.milliseconds())) {
                changeFilter(path, date.toISOString(), true);
                return;
            }
            changeFilter(path, undefined, true);
        }, [path, changeFilter]);

    return (
        <DesktopDatePicker
            // desktopModeMediaQuery="@media (min-width: 768px)"
            label={label}
            value={dateValue ?? null}
            onChange={onChange}
            onAccept={onAccept}
            slotProps={{
                // The actions will be the same between desktop and mobile
                actionBar: {
                    actions: ['today', 'clear', 'accept'],
                },
                textField: {
                    id,
                    // @ts-ignore
                    'data-test': `FilterElement_FilterElementDate_${path}`,
                },
            }}
            {...rest}
        />
    );
}

export {FilterElementDate};
