import React, {useCallback} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkingTimeScheduleFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeSchedule/WorkingTimeScheduleFormular';

import {
    generatePath, useNavigate, useParams,
} from 'react-router-dom';
import {Box} from '@mui/material';
import {useFindRoute} from 'hooks/useFindRoute';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a create or edit form for a schedule.
 * .DriveBuddy.Pages
 * @param {object} params - params for the page
 * @param {boolean} [params.duplicate] - indicator that even with an id, the create action should be invoked in the form
 * @returns {React.ReactElement} The SchedulePage.
 */
function WorkingTimeSchedulePage({duplicate = false}) {
    // getting the URL parameters
    const {id} = useParams();
    const navigate = useNavigate();
    const findRoute = useFindRoute();

    const onSave = useCallback((result) => {
        const route = findRoute('timebuddy_workingtime_schedule_route');
        if (route.path && (id === 'create' || duplicate) && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, findRoute, navigate]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>{id === 'create' || duplicate ? 'Neuer Dienstplan' : 'Dienstplan'}</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Box sx={{marginTop: '1.5rem'}}>
                <WorkingTimeScheduleFormular id={id} onSave={onSave} duplicate={duplicate} />
            </Box>
        </LayoutContainer>
    );
}
export {WorkingTimeSchedulePage};
