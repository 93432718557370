const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {ISO8601_REGEX, UUID_REGEX, FILE_KEY_REGEX} = require('../regex');

/**
 * @type {Record<string, import('../types').ValidationSchemaAttribute>}
 */
const attributes = {
    userId: {
        required: true,
        format: UUID_REGEX,
    },
    workplaceId: {
        format: UUID_REGEX,
    },
    startDateTime: {
        type: 'DateTime',
        format: ISO8601_REGEX,
        required: true,
    },
    endDateTime: {
        type: 'DateTime',
        format: ISO8601_REGEX,
        required: true,
    },
    kind: {
        type: 'String',
        required: true,
    },
    comment: {
        type: 'String',
    },
    attachmentKeys: {
        type: 'Array',
        format: FILE_KEY_REGEX,
        fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
    },
};

/** @type {Record<string, import('beyond-validators').ValidationSchemaFrontendEntry[]>} */
const commonFrontend = {
    startDateTime: [
        {
            formType: ['create', 'update'],
            actions: ['blur', 'save'],
            configurations: ['required', 'type', 'format'],
        },
    ],
    endDateTime: [
        {
            formType: ['create', 'update'],
            actions: ['blur', 'save'],
            configurations: ['required', 'type', 'format'],
        },
    ],
    kind: [
        {
            formType: ['create', 'update'],
            actions: ['blur', 'save'],
            configurations: ['required', 'type'],
        },
    ],
    comment: [
        {
            formType: ['create', 'update'],
            actions: ['blur', 'save'],
            configurations: ['type'],
        },
    ],
};

/** @type {Record<string, import('beyond-validators').ValidationSchemaBackendEntry[]>} */
const commonBackend = {
    startDateTime: [
        {
            actions: ['create', 'update'],
            configurations: ['type', 'format', 'required'],
        },
    ],
    endDateTime: [
        {
            actions: ['create', 'update'],
            configurations: ['type', 'format', 'required'],
        },
    ],
    kind: [
        {
            actions: ['create', 'update'],
            configurations: ['required', 'type'],
        },
    ],
    userId: [
        {
            actions: ['create'],
            configurations: ['format', 'required'],
        },
    ],
    comment: [
        {
            actions: ['create', 'update'],
            configurations: ['type'],
        },
    ],
};

/** @type {Record<import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeLogAbsenceKind, import('../types').ValidationSchema>} */
const absenceSchemas = {
    ABSENCE: {
        frontend: {
            ...commonFrontend,
            endDateTime: [
                {
                    formType: ['create'],
                    actions: ['blur', 'save'],
                    configurations: ['type', 'format'],
                }, {
                    formType: ['update'],
                    actions: ['blur', 'save'],
                    configurations: ['type', 'format', 'required'],
                },
            ],
        },
        backend: commonBackend,
        attributes,
    },
    COMPENSATION: {
        frontend: commonFrontend,
        backend: commonBackend,
        attributes,
    },
    SICKLEAVE: {
        frontend: {
            ...commonFrontend,
            endDateTime: [
                {
                    formType: ['create', 'update'],
                    actions: ['blur', 'save'],
                    configurations: ['type', 'format'],
                },
            ],
        },
        backend: {
            ...commonBackend,
            attachmentKeys: [
                {
                    configurations: ['type', 'format', 'fileExtensions'],
                    actions: ['create', 'update'],
                },
            ],
            endDateTime: [
                {
                    actions: ['create', 'update'],
                    configurations: ['type', 'format'],
                },
            ],
        },
        attributes,
    },
    SPECIAL_LEAVE: {
        frontend: commonFrontend,
        backend: commonBackend,
        attributes,
    },
    VACATION: {
        frontend: commonFrontend,
        backend: commonBackend,
        attributes,
    },
};

module.exports = {absenceSchemas};
