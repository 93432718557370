const {ISO8601_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        startDateTime: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        endDateTime: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
    },
    backend: {
        startDateTime: [
            {
                actions: ['create'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        endDateTime: [
            {
                actions: ['create'],
                configurations: ['type', 'format', 'required'],
            },
        ],
    },
    attributes: {
        startDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        endDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
    },
};

module.exports = {schema};
