/**
 * getEvacuationPoint
 * @property {string} id - ID of the requested group.
 * @property {string} tenantId - ID of the tenant.
 * @returns {object} The requested group.
 */
export const getEvacuationPoint = /* GraphQL */ `
  query GetEvacuationPoint(
      $id: ID!
    ) {
    getEvacuationPoint(
      id: $id
    ) {
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * listEvacuationPoints
 * @property {string} tenantId - id of the tenant
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All EvacuationPoints in the given input.
 */
export const listEvacuationPoints = /* GraphQL */ `
 query ListEvacuationPoints (
      $tenantId: ID!
      $nextToken: String
      $filter: EvacuationPointListFilterInput
   ) {
   listEvacuationPoints(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
     items {
       id
       name
       grants {
        deletable
        permissionsUpdatable
       }
     }
     nextToken
   }
 }
`;
