import {Box} from '@mui/material';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import React from 'react';
import {WorkingTimeAbsenceLogListFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeAbsenceLogListFormular';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * Lists absences by oneself, or others in case the viewer is a line manager
 * @returns {React.ReactElement} element to be rendered
 */
function WorkingTimeAbsenceListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Abwesenheiten</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <WorkingTimeAbsenceLogListFormular />
            </div>
        </LayoutContainer>
    );
}

export {WorkingTimeAbsenceListPage};
