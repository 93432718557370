import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import {Help} from '@mui/icons-material';
import {IconButton, Typography} from '@mui/material';
import {QuickGuideContext} from 'components/QuickGuide/QuickGuideContext';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';

/**
 * Component that can be used to link to quick guides. Can be given text, for a full text link.
 * Without text, it renders an icon button
 * @param {object} props - props for the link
 * @param {string} props.id - id of the link to be opened by the
 * @param {string} [props.children] - text to display inside the link. If omitted, displays an icon button
 * @param {boolean} [props.isActionButton] - display as FormElementActionButtonProps
 * @param {import('components/Form/FormElements/formElement').FormElementActionButtonProps} [props.actionButtonProps] - props for the ActionButton component
 * @param {import('@mui/material').IconButtonProps} [props.iconButtonProps] - props for the IconButton component
 * @returns {React.ReactElement} element to be rendered
 */
function QuickGuideLink({
    id, iconButtonProps, isActionButton, actionButtonProps, children,
}) {
    const {openGuide} = useContext(QuickGuideContext);
    const [guideConfigurations, setGuideConfigurations] = useState(Object());

    useEffect(() => {
        // Loading guideConfigurations async to avoid cyclic dependency
        import('applications/configs').then((configs) => {
            setGuideConfigurations(configs.guideConfigurations);
        });
    }, [setGuideConfigurations]);

    const enabled = id in guideConfigurations;

    const onClick = useCallback(() => {
        openGuide(id);
    }, [id]);

    if (isActionButton) {
        return (
            <FormElementActionButton disabled={!enabled} callback={onClick} data-test="QuickGuideLink" data-guide-id={id} {...actionButtonProps}>
                <Help />
            </FormElementActionButton>
        );
    }

    if (typeof children === 'string') {
        return (
            <>
                {' '}
                <button
                    disabled={!enabled}
                    aria-disabled={!enabled}
                    style={{
                        cursor: enabled ? 'pointer' : 'text',
                        userSelect: 'text',
                        border: 'none',
                        background: 'transparent',
                        padding: 0,
                        margin: 0,
                    }}
                    data-test="QuickGuideLink"
                    data-guide-id={id}
                    type="button"
                    onClick={onClick}
                >
                    <Typography sx={{color: enabled && 'info.main'}}>
                        {children}
                    </Typography>
                </button>
                {' '}
            </>
        );
    }

    return (
        <IconButton {...iconButtonProps} disabled={!enabled} title="Quick Guide Anzeigen" onClick={onClick} data-test="QuickGuideLink" data-guide-id={id}>
            <Help />
        </IconButton>
    );
}

export {QuickGuideLink};
