import React, {
    useContext,
    useMemo,
} from 'react';
import {
    Refresh,
    AddCircleOutline,
    CheckCircleOutline,
    InfoOutlined,
    SaveAsOutlined,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterContext, ListFilterProvider} from 'components/Form/ListFilterProvider';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {CalendarListing} from 'components/Form/CalendarListing';
import {deleteWorkingTimeWorkLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {listWorkingTimeLogs} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {useGlobalState} from 'hooks/useGlobalState';
import {dateAtHour} from 'helper/date';
import {
    Box, LinearProgress, Tooltip, Typography,
} from '@mui/material';
import _ from 'lodash';
import {durationOfEvents} from 'helper/time-calc';
import {map} from 'rxjs';
import {useSubscribe} from 'hooks/useSubscribe';

/** @type {import('components/Form/ListData').ListLoadConfig} */
const loadConfig = {
    query: listWorkingTimeLogs,
    mask: {tenantId: true, userId: true, filter: false},
    variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
    preProcess: (variables) => ({
        ...variables,
        filter: {
            ...(variables.filter || {}),
            kinds: [
                'NORMAL',
                'TRAVEL_ACTIVE',
                'TRAVEL_PASSIVE',
                'BREAK',
            ],
        },
    }),
};

const routeConfig = {
    routeId: 'timebuddy_workingtime_work_log_route',
    routeParams: ({id}) => ({id}),
};

// eslint-disable-next-line jsdoc/valid-types
/** @type {import('components/Form/form').CalendarListingProps["itemToEvents"]} */
const itemToEvents = (item) => {
    const isTodaysDraft = item.draft
        && !item.endDateTime
        && dateAtHour(new Date(), 0, 0, 0, 0).toISOString() < item.startDateTime
        && item.startDateTime < new Date().toISOString();
    return [{
        id: item.id,
        start: item.startDateTime,
        end: isTodaysDraft ? new Date() : item.endDateTime,
        backgroundColor: item.draft ? 'orange' : 'blue',
        extendedProps: item,
    }];
};

/**
 * Amount of milliseconds. The exact specified value indicates 8 days difference.
 * One at the start of the week, and 7 during it.
 *
 * If the apparent difference in filter times matches it, we can expect the filter to be in week mode
 */
const weekDetectionNr = 1000 * 60 * 60 * 24 * 8;

/**
 * Shows a progress bar and value when the viewed time range is a week
 * @param {object} params - params passed to the week progress bar
 * @param {string} params.startDateFilterPath - start filter to read start date out of
 * @param {string} params.endDateFilterPath - end filter to read end date out of
 * @returns {React.ReactElement} progress bar
 */
function WeekProgressBar({
    startDateFilterPath,
    endDateFilterPath,
}) {
    const {data} = useContext(ListDataContext);
    const {filterValues$} = useContext(ListFilterContext);
    const {getGlobal} = useGlobalState();

    const user = getGlobal('user');

    const inWeekMode$ = useMemo(() => filterValues$.pipe(
        map((state) => {
            const weekStart = _.get(state, startDateFilterPath);
            const weekEnd = _.get(state, endDateFilterPath);
            const res = weekEnd - weekStart === weekDetectionNr;
            return res;
        }),
    ), [startDateFilterPath, endDateFilterPath]);
    const inWeekMode = useSubscribe(inWeekMode$, undefined, true);

    /** @type {import('../../../../../../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeModelEntity} */
    const userWorkingModel = user?.workingTimeModel;

    const hours = durationOfEvents(data, userWorkingModel);
    const weeklyHours = userWorkingModel?.weeklyHours;

    const progress = Math.min(1, hours.withTravel / ((inWeekMode && weeklyHours) ?? 0.0001));
    const workSpan = weeklyHours && inWeekMode
        ? `${hours.withTravel.toPrecision(2)}h / ${weeklyHours}h`
        : `${hours.withTravel.toPrecision(2)}h`;
    const hasDraft = _.some(data, (i) => i.draft);

    // eslint-disable-next-line no-nested-ternary
    const fontColor = (progress < 1 || hasDraft) ? 'warning.main' : 'inherit';
    // eslint-disable-next-line no-nested-ternary
    const progressColor = (progress < 1 || hasDraft) ? 'warning' : 'inherit';

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h2" fontWeight={500} color={fontColor}>
                    {workSpan}
                </Typography>
                <Box>
                    {progress === 1 && (<Tooltip title="Sollarbeitszeit erreicht"><CheckCircleOutline color="info" /></Tooltip>)}
                    {progress < 1 && (<Tooltip title="fehlende Zeitbuchungen"><InfoOutlined color="warning" /></Tooltip>)}
                    {hasDraft && <Tooltip title="Entwürfe vorhanden"><SaveAsOutlined /></Tooltip>}
                </Box>
            </Box>
            <LinearProgress variant="determinate" value={progress * 100} title={workSpan} color={progressColor} />
        </>
    );
}

/**
 * Formular for listing working time logs
 * @param {object} props - optional props for the formular
 * @param {boolean} [props.visible] - indicator that the form is visible. Relevant for action button display. True by default
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The WorkingTimeLogCalendarFormular component
 */
function WorkingTimeLogCalendarFormular({visible = true}) {
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');
    const initialFilters = useMemo(() => ({userId}), [userId]);
    const canCreateLogs = useCanAccess('createWorkingTimeLog');

    return (
        <ListData
            loadConfig={loadConfig}
            deleteConfig={{
                mutation: deleteWorkingTimeWorkLog,
                mask: {id: true},
            }}
        >
            {
                visible && (
                    <>
                        <FormElementActionButton
                            routeId="timebuddy_workingtime_work_log_route"
                            routeParams={{id: 'create'}}
                            portalAnchorSelector="#action-button-frame"
                            disabled={!canCreateLogs}
                        >
                            <AddCircleOutline />
                        </FormElementActionButton>
                        <FormElementActionButton
                            reload
                            portalAnchorSelector="#action-button-frame"
                        >
                            <Refresh />
                        </FormElementActionButton>
                    </>
                )
            }
            <ListFilterProvider
                id="working-time-log-filter"
                messageKey="WorkingTimeLog_List"
                initialFilters={initialFilters}
                skipLoad
            >
                <Box gap="1rem" display="flex" flexDirection="column">
                    <WeekProgressBar
                        startDateFilterPath="startDateTime.gte"
                        endDateFilterPath="startDateTime.lt"
                    />
                    <CalendarListing
                        id="working-time-log-calendar"
                        itemToEvents={itemToEvents}
                        startDateFilterPath="startDateTime.gte"
                        endDateFilterPath="startDateTime.lt"
                        routeConfig={routeConfig}
                    />
                </Box>
            </ListFilterProvider>
        </ListData>
    );
}

export {WorkingTimeLogCalendarFormular};
