import React from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Box, Grid,
} from '@mui/material';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {AddCircleOutline, Refresh} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/Group');

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').FormularProps} props - props passed to the component
 * @returns {React.ReactElement} The GroupFormular component.
 */
function GroupFormular({id, onSave, ...rest}) {
    const canCreateGroups = useCanAccess('createGroup');

    return (
        <FormWrapper
            {...rest}
            isNewItem={id === 'create'}
            validatorSchema={{
                schema: validatorSchema,
                type: (id !== 'create') ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'Group_Update' : 'Group_Create'}
            context={`Group#${id}Base`}
        >
            <FormReset shouldClear={id === 'create'} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >

                <FormElementActionButton
                    routeId="beyondbuddy_settings_general_group_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#GroupForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Group#${id}`}
                    disabled={id === 'create' || !canCreateGroups}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#GroupForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Group#${id}`}
                    disabled={id === 'create'}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid item xs={12}>
                            <FormElementTextField label="Name" attribute="name" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementLoadingButton />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </FormWrapper>
    );
}

export {GroupFormular};
