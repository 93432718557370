const {
    EMAIL_REGEX, NAME_REGEX, UUID_REGEX, EUROPEAN_VAT_REGEX, GENDER_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        companyName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        vat: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        companyRegisterNumber: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        titlePrefixed: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        firstName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        lastName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        titlePostfixed: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        gender: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        atHandsTitlePrefixed: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        atHandsFirstName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        atHandsLastName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        atHandsTitlePostfixed: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        atHandsGender: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format'],
            },
        ],
        eMailAddress: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        deliveryEMailAddress: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        address: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        zip: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        city: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        countryCode: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required'],
            },
        ],
        deliveryAddress: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        deliveryZip: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        deliveryCity: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        deliveryCountryCode: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['format', 'required'],
            },
        ],
        companyName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        vat: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        companyRegisterNumber: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        titlePrefixed: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        firstName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        lastName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format', 'required'],
            },
        ],
        titlePostfixed: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        gender: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        atHandsTitlePrefixed: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        atHandsFirstName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        atHandsLastName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        atHandsTitlePostfixed: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        atHandsGender: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        eMailAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        deliveryEMailAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        address: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required'],
            },
        ],
        zip: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required'],
            },
        ],
        city: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required'],
            },
        ],
        countryCode: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required'],
            },
        ],
        deliveryAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        deliveryZip: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        deliveryCity: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        deliveryCountryCode: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
    },

    attributes: {
        id: {
            format: UUID_REGEX,
            required: true, // "create", "update"
        },
        companyName: {
            type: 'String',
            length: {lte: 100},
        },
        vat: {
            type: 'String',
            length: {lte: 12},
            format: EUROPEAN_VAT_REGEX,
        },
        companyRegisterNumber: {
            type: 'String',
            length: {lte: 50},
        },
        titlePrefixed: {
            type: 'String',
            length: {lte: 50},
        },
        firstName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
        },
        lastName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
            required: true,
        },
        titlePostfixed: {
            type: 'String',
            length: {lte: 50},
        },
        gender: {
            type: 'String',
            format: GENDER_REGEX,
            required: true,
        },
        atHandsTitlePrefixed: {
            type: 'String',
            length: {lte: 50},
        },
        atHandsFirstName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
        },
        atHandsLastName: {
            type: 'String',
            length: {lte: 50},
            format: NAME_REGEX,
        },
        atHandsTitlePostfixed: {
            type: 'String',
            length: {lte: 50},
        },
        atHandsGender: {
            type: 'String',
            format: GENDER_REGEX,
        },
        eMailAddress: {
            type: 'String',
            length: {lte: 200},
            format: EMAIL_REGEX,
        },
        deliveryEMailAddress: {
            type: 'String',
            length: {lte: 200},
            format: EMAIL_REGEX,
        },
        address: {
            type: 'String',
            required: true,
        },
        zip: {
            type: 'Number',
            required: true,
        },
        city: {
            type: 'String',
            required: true,
        },
        countryCode: {
            type: 'String',
            required: true,
        },
        deliveryAddress: {
            type: 'String',
        },
        deliveryZip: {
            type: 'Number',
        },
        deliveryCity: {
            type: 'String',
        },
        deliveryCountryCode: {
            type: 'String',
        },
    },
};

module.exports = {schema};
