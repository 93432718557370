/* eslint-disable max-len */
import React, {
    useCallback, useContext, useMemo,
} from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme
    ,
} from '@mui/material';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {FormElementEntityLink} from 'components/Form/FormElements/FormElementEntityLink';
import {dataSchemas} from 'components/Form/FormElements/FormElementEntityChooserSchema';
import {defaultLinkSchemaOptions} from 'applications/configs';
import {RouteLink} from 'components/RouteLink';

/**
 * @typedef {import('applications/configuration').ManagedObject} ManagedObject
 * @typedef {"Objecttype" | ManagedObject} SelfType
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').EntityBase} EntityBase
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 */

/**
 * @typedef {import('applications/configuration').Permission} Permission
 * @typedef {import('applications/configuration').Permissions} Permissions
 * @typedef {import('applications/configuration').PermissionGroup} PermissionGroup
 * @typedef {import('applications/configuration').PermissionGroupDefinition} PermissionGroupDefinition
 * @typedef {import('applications/configuration').PermissionDependency} PermissionDependency
 * @typedef {import('applications/configuration').PermissionDependencies} PermissionDependencies
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').Links} Links
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').Link} Link
 * @typedef {import('components/Form/FormElements/FormElementEntityLink').LinkAttributes} LinkAttributes
 * @typedef {import('react').ReactNode} ReactNode
 * @typedef {import('../form').ChangeHandler} ChangeHandler
 */

/**
 * @typedef FormElementEntityLinkAttributesProps
 * @property {string} attribute - attribute to load/write data from/to
 * @property {"left" | "right"} [linkDirection] - direction of the attributes being read and set
 * @property {ManagedObject[]} availableEntities - the entities that can be chosen
 * @property {Partial<Record<ManagedObject, LinkAttributes>>} [defaultAttributes] - attribute object to be applied by default
 * @property {SelfType} selfType - type of the same entity being edited
 * @property {(entityType: ManagedObject)=>ReactNode} getTableHead - function to render the table head on a desktop view
 * @property {(item: any, currentLinks: Links, locked: boolean, changeHandler: ChangeHandler)=>ReactNode} getAttributeLine - function to render a table cell of an entity already chosen
 * @property {(isIncoming: boolean)=>Partial<Record<ManagedObject, Partial<FormElementEntityChooserDataSchemaOptions>>>} [schemaOptions] - options to enhance data schema
 * @property {boolean} [blockSelfReference] - flag to disable the option to link to the same entity
 * @property {string} [entityChooserLabel] - the label of the button, that opens the entity chooser (default Berechtigungsobjekte)
 * @property {boolean} [disabled] - flag to disable the entity chooser. Viewing and browsing is still possible
 * @property {string} [guideId] - id of the quick guide
 * @property {boolean} [showEntityLinks] - show the entities as links
 * @property {import('react').ReactElement} [controls] - additional controls
 * @property {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [actionButtonProps] - properties for actionButtons
 */

/**
 * FormElementEntityLinkAttributes
 *
 * Shows a EntityLink form element, for setting attributes (but no attributes)
 *
 * If items that have been selected in a previous session are supposed to be shown
 * the `permittedObjects` field should be set in the above `ItemData`
 * @param {FormElementEntityLinkAttributesProps} props - props of the component
 * @returns {React.ReactElement} - rendered FormElementEntityChooser
 */
function FormElementEntityLinkAttributes({
    attribute,
    availableEntities,
    disabled,
    getTableHead,
    getAttributeLine,
    blockSelfReference,
    selfType,
    schemaOptions,
    linkDirection = 'left',
    showEntityLinks = true,
    ...rest
}) {
    const {get, changeHandler} = useContext(FormContext);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isPortraitMobile = useMediaQuery(theme.breakpoints.down('sm'));

    /**
     * @type {{
     *  value: Links
     *  displayValue: Record<string, EntityBase & {type: ManagedObject}>,
     * }}
     */
    const {value, displayValue} = useMemo(() => {
        const data = get(attribute);

        return {
            value: _.cloneDeep(data?.value) ?? {links: []}, // need to be a new object for recognition of changes
            displayValue: data?.displayValue ?? {},
        };
    }, [attribute, get]);

    const schemaOptionsMemo = useMemo(
        () => _.merge(defaultLinkSchemaOptions(linkDirection === 'left'), schemaOptions?.(linkDirection === 'left')),
        [defaultLinkSchemaOptions, schemaOptions, linkDirection],
    );
    const ownId = useMemo(() => get('id')?.value, [get]);
    const ownEntityId = useMemo(() => (ownId ? `${selfType}#${ownId}` : undefined), [ownId, selfType]);

    /**
     * @type {"rightEntityTypeId" | "leftEntityTypeId"}
     */
    const sideKey = `${linkDirection}EntityTypeId`;

    /**
     * Get's keys of the specified entity type within the current specified value
     * @param {ManagedObject} type - type of entity to get
     * @returns {string[]} ids of relevant entities
     */
    const getKeys = useCallback((type) => _.chain(value.links)
        .filter(({[sideKey]: typeId}) => typeId?.startsWith(type))
        .map(({[sideKey]: typeId}) => typeId.split('#').at(1))
        .value(), [value.links, sideKey]);

    /** @type {{ManagedObject?: EntityBase[]}} */
    const entityLinks = useMemo(() => _.zipObject(
        availableEntities,
        _.map(
            availableEntities,
            (entityType) => _.pick(displayValue, getKeys(entityType)),
        ),
    ), [displayValue, getKeys, availableEntities]);

    const getEntityLine = useCallback(
        (item, entityType, entityDisabled) => {
            const currentLink = _.find(value.links, (link) => link[sideKey] === `${item.type}#${item.id}`);
            if (!currentLink) {
                throw new Error(`No value could be found for ${sideKey}`);
            }
            let locked = disabled;
            locked ||= entityDisabled;
            locked ||= currentLink.attributes?.locked ?? false;
            locked ||= (blockSelfReference && ownEntityId === currentLink[sideKey]);

            let avatarImgStyle = {};
            const position = item.image?.options?.image?.position;
            if (position) {
                const x = (position.x / position.width) * -40;
                const y = (position.y / position.height) * -40;
                avatarImgStyle = {
                    height: 40 / position.height,
                    width: 40 / position.width,
                    objectPosition: `${x}px ${y}px`,
                };
            }
            /** @type {import('./formElements').FormElementEntityChooserDataSchema} */
            const entityDataSchema = _.get(dataSchemas, entityType)(schemaOptionsMemo[entityType]);

            const entityInfo = (
                <>
                    <Typography fontWeight="bold">{entityDataSchema.getPrimaryValue(item)}</Typography>
                    { !!entityDataSchema.getSecondaryValue && <Typography paddingLeft="1rem" fontSize="0.9rem">{entityDataSchema.getSecondaryValue(item)}</Typography> }
                </>
            );

            return (
                <TableRow
                    key={item.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                    <TableCell>
                        <Box style={{
                            display: 'flex', gap: '1rem', minHeight: '4rem',
                        }}
                        >
                            {(!isPortraitMobile && _.has(item, 'image'))
                            && (
                                <Box
                                    style={{
                                        height: '40px',
                                        width: '40px',
                                        overflow: 'hidden',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        alt={item.id}
                                        src={item.image?.url}
                                        data-test={`FormElementEntityChooser_${attribute}_avatar`}
                                        style={{
                                            objectFit: 'cover',
                                            ...avatarImgStyle,
                                            display: item.image?.url ? 'inherit' : 'none',
                                        }}
                                    >
                                        {/* {_.get(avatarDefault, type, <HelpOutline />)} */}
                                    </Box>
                                </Box>
                            )}
                            <Box style={{
                                flexGrow: 1, minWidth: '10rem', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                            }}
                            >
                                {showEntityLinks
                                && (
                                    <RouteLink routeId={entityDataSchema.routeId} routeParams={{id: item.id}} style={{textDecoration: 'underline'}}>
                                        {entityInfo}
                                    </RouteLink>
                                )}
                                {!showEntityLinks && entityInfo}
                            </Box>
                        </Box>
                        {!isDesktop && (
                            <Box style={{marginTop: '2rem'}}>
                                {getAttributeLine(item, value, locked, changeHandler)}
                            </Box>
                        )}
                    </TableCell>
                    {isDesktop && getAttributeLine(item, value, locked, changeHandler)}
                </TableRow>
            );
        },
        [disabled, isDesktop, isPortraitMobile, dataSchemas, value, value.links,
            sideKey, blockSelfReference, ownEntityId, schemaOptionsMemo, changeHandler,
            getAttributeLine,
        ],
    );
    // eslint-disable-next-line function-paren-newline
    const getEntityTable = useCallback(
        /**
         *
         * @param {EntityBase[]} entities - entities to render
         * @param {ManagedObject} entityType - entity type
         * @returns {React.ReactElement} - table for the entity type
         */
        (entities, entityType) => {
            if (!_.get(dataSchemas, entityType)) {
                // eslint-disable-next-line no-console
                console.warn('schema missing', entityType);
                return null;
            }
            /** @type {import('./formElements').FormElementEntityChooserDataSchema} */
            const entityDataSchema = _.get(dataSchemas, entityType)(schemaOptionsMemo[entityType]);

            if (_.isEmpty(entities)) {
                return null;
            }
            return (
                <TableContainer
                    key={entityType}
                    component={Paper}
                    data-test={`FormElementEntityChooser_${attribute}_table_${_.camelCase(entityType)}`}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{backgroundColor: 'secondary.main'}}>
                                <TableCell>
                                    <Typography fontWeight="bold">{entityDataSchema.label}</Typography>
                                </TableCell>
                                {getTableHead(entityType)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(entities, (item) => getEntityLine(item, entityType, entityDataSchema.isDisabled?.(item)))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }, [dataSchemas, isDesktop, schemaOptionsMemo, getEntityLine, getTableHead]);

    return (
        <Box>
            <FormElementEntityLink
                attribute={attribute}
                linkDirection={linkDirection}
                availableEntities={availableEntities}
                disabled={disabled}
                blockSelfReference={blockSelfReference}
                schemaOptions={schemaOptionsMemo}
                {...rest}
            />
            <Box style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                {_.map(entityLinks, getEntityTable)}
            </Box>
        </Box>
    );
}

export {
    FormElementEntityLinkAttributes,
};
