const {EMAIL_REGEX, NAME_REGEX, UUID_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        name: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        contactFirstName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required', 'length', 'format'],
            },
        ],
        tosAccepted: [
            {
                formType: ['create'],
                actions: ['save'],
                configurations: ['required'],
            },
        ],
        contactLastName: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        contactEMailAddress: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'required', 'length', 'format'],
            },
        ],
        technicalContactEMailAddress: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        companyName: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        companyRegisterNumber: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
        companyTaxIdentificationId: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['type', 'length', 'required'],
            },
        ],
        name: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format', 'unique'],
            },
        ],
        contactFirstName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required', 'format', 'length'],
            },
        ],
        contactLastName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        contactEMailAddress: [
            {
                actions: ['create'],
                configurations: ['type', 'required', 'length', 'format', 'unique'],
            },
            {
                actions: ['update'],
                configurations: ['type', 'required', 'length', 'format'],
            },
        ],
        technicalContactEMailAddress: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length', 'format'],
            },
        ],
        companyName: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        companyRegisterNumber: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
        companyTaxIdentificationId: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'length'],
            },
        ],
    },
    attributes: {
        id: {
            required: true, // true, false
            length: {lte: 50}, // eq, lt, lte, gt, gte,
            unique: true,
            format: UUID_REGEX,
            type: 'String',
        },
        name: {
            length: {lte: 40},
            unique: true,
            format: /^[a-zA-Z]([-\da-zA-Z]*[a-zA-Z\d])?$/,
            type: 'String',
        },
        contactFirstName: {
            type: 'String',
            required: true, // true, false
            format: NAME_REGEX,
            length: {lte: 50},
            // message: 'Der Vorname darf maximal 50 Zeichen lang sein.',
        },
        contactLastName: {
            type: 'String',
            format: NAME_REGEX,
            length: {lte: 50},
            // message: 'Der Nachname darf maximal 50 Zeichen lang sein.',
        },
        contactEMailAddress: {
            type: 'String',
            required: true,
            length: {lte: 70},
            format: EMAIL_REGEX,
            unique: true,
            // message: 'Die E-Mail Adresse darf maximal 50 Zeichen lang sein.',
        },
        technicalContactEMailAddress: {
            type: 'String',
            required: true,
            length: {lte: 70},
            format: EMAIL_REGEX,
            unique: true,
            // message: 'Die E-Mail Adresse darf maximal 50 Zeichen lang sein.',
        },
        companyName: {
            type: 'String',
            length: {lte: 50},
        },
        companyRegisterNumber: {
            type: 'String',
            length: {lte: 50},
        },
        companyTaxIdentificationId: {
            type: 'String',
            length: {lte: 50},
        },
        tosAccepted: {
            required: true,
        },
    },
};

module.exports = {
    schema,
};
