import React from 'react';
import {Box, Typography} from '@mui/material';

/**
 * This page shows a quick guide on organizational structures
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideDashboardsPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Dashboards</Typography>
            <hr />
            <Box>
                Dashboards liefern wichtige Informationen und Funktionen auf einen Blick.
                Sie dienen dazu, Dir einen schnellen Überblick über Deine Aufgaben, Tätigkeiten und anstehenden Änderungen zu geben.
                In einem Dashboard können Quick-Links integriert sein, die Dir den Zugriff auf die wichtigsten Funktionen und Informationen erleichtern.
                Diese Quick-Links können beispielsweise Links zu häufig verwendeten Berichten oder Funktionen sein.
                <br />
                <br />
                Sollten Sie keinen Zugriff auf das Dashboard oder die darin verfügbaren Informationen haben, so kann dies von einem (Mandanten-) Administrator oder
                einem berechtigten Benutzer ermöglicht werden.
            </Box>
        </>
    );
}
export {GuideDashboardsPage};
