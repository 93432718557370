const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {UUID_REGEX, FILE_KEY_REGEX, ISO8601_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        vehicleKM: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
        eventDateTime: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        cause: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        description: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
    },
    backend: {
        userId: [
            {
                actions: ['create'],
                configurations: ['format', 'required'],
            },
        ],
        vehicleId: [
            {
                actions: ['create', 'update'],
                configurations: ['format', 'required'],
            },
        ],
        vehicleKM: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        eventDateTime: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        cause: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        description: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],

    },
    attributes: {
        userId: {
            required: true,
            format: UUID_REGEX,
        },
        vehicleId: {
            required: true,
            format: UUID_REGEX,
        },
        vehicleKM: {
            type: 'Number',
        },
        eventDateTime: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        cause: {
            type: 'String',
            required: true,
            format: /^(REFUEL|PARK|ACCIDENT|GARAGE|OTHER)$/,
        },
        description: {
            type: 'String',
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
