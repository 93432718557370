/**
 * getGroup
 * @property {string} id - ID of the requested group.
 * @property {string} tenantId - ID of the tenant.
 * @returns {object} The requested group.
 */
export const getGroup = /* GraphQL */ `
  query GetGroup(
      $id: ID!
    ) {
    getGroup(
      id: $id
    ) {
      id
      name
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * listGroups
 * @property {string} tenantId - id of the tenant
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} All groups in the given tenant.
 */
export const listGroups = /* GraphQL */ `
 query ListGroups (
      $tenantId: ID!
      $nextToken: String
      $filter: GroupListFilterInput
   ) {
   listGroups(
      tenantId: $tenantId
      nextToken: $nextToken
      filter: $filter
    ) {
     items {
       id
       name
       grants {
        deletable
        permissionsUpdatable
       }
     }
     nextToken
   }
 }
`;
