import React from 'react';
import {Box, IconButton} from '@mui/material';

import {Add, CloudUpload} from '@mui/icons-material';
import {FormElementFileDropzone} from 'components/Form/FormElements/FormElementFileDropzone';
import {FormElementFileUploadWrapper} from 'components/Form/FormElements//FormElementFileUploadWrapper';
import {FormElementFilesList} from 'components/Form/FormElements/FormElementFilesList';
import classes from 'components/Form/FormElements/formElementFiles.module.scss';
import {FILEEXTENSIONS_DOCUMENT, FILEEXTENSIONS_IMAGE} from 'beyond-validators/constants';
import {S3UploadProvider} from 'helper/bb-s3upload-provider';

/**
 * This element will provide an upload mechanism for multiple files
 * .Components.Form
 * @param {object} props - properties passed to the component.
 * @param {string} [props.attribute] - attribute key of the form source. The default value for the attribute is "attachmentKeys".
 * @param {string} [props.fileReference] - fileReference key of the form source. The default is "attachments"
 * @returns {React.ReactElement} The upload component for multiple files
 */
function FormElementFilesUpload({
    attribute = 'attachmentKeys', fileReference = 'attachments',
}) {
    const {upload, isUploading} = S3UploadProvider();
    return (
        <Box
            data-test="FormElementFilesUpload"
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.55rem',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1rem',
                }}
            >
                <Box className={classes.container}>
                    <Box style={{
                        width: '100%', display: 'grid', placeContent: 'center', padding: '1rem',
                    }}
                    >
                        <Add style={{width: '100%', height: '100%', opacity: 0.8}} />
                    </Box>
                    <FormElementFileDropzone attribute={attribute} fileReference={fileReference} multiple alwaysHot upload={upload} disabled={isUploading} />
                    <Box style={{
                        position: 'absolute',
                        bottom: '0.5rem',
                        right: '0.5rem',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        zIndex: 3,
                        gap: '0.7rem',
                    }}
                    >
                        <FormElementFileUploadWrapper
                            attribute={attribute}
                            fileReference={fileReference}
                            multiple
                            upload={upload}
                            uploading={isUploading}
                        >
                            <IconButton
                                color="inherit"
                                className={classes.control}
                                style={{pointerEvents: 'none'}}
                            >
                                <CloudUpload />
                            </IconButton>
                        </FormElementFileUploadWrapper>
                    </Box>
                </Box>
                <FormElementFilesList
                    variant="Image"
                    attribute={attribute}
                    fileReference={fileReference}
                    extensions={FILEEXTENSIONS_IMAGE}
                />
            </Box>
            <FormElementFilesList
                variant="Table"
                attribute={attribute}
                fileReference={fileReference}
                extensions={FILEEXTENSIONS_DOCUMENT}
            />
        </Box>
    );
}

export {FormElementFilesUpload};
