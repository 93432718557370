import interaction from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import dayGrid from '@fullcalendar/daygrid';
import React, {
    useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import {useTheme} from '@mui/material';
import {ListDataContext} from 'components/Form/ListData';
import {ListFilterContext} from 'components/Form/ListFilterProvider';
import {useLocation} from 'react-router-dom';
import {useGlobalState} from 'hooks/useGlobalState';
import {useFindRoute} from 'hooks/useFindRoute';
import {getRoutePath} from 'routes';
import {toDate} from 'helper/date';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {listWorkingTimeSchedulesInDetail} from 'graphql/timeBuddy/WorkingTimeSchedule/queries';
import {BeyondCalendar} from 'assets/theme/components/Calendar/BeyondCalendar';
import {durationOfEvents, weekdays} from 'helper/time-calc';

const mockables = {AWSAppSyncProvider};

/**
 * Generates the start and end of a time range given a date inside the range, and
 * the type of range
 * @param {Date} date - date that is indicated as start of the listing (may still be within the week due to hidden days)
 * @param {"week"| "day"| "month"} viewType - type of view range, determining the time window size
 * @returns {{start: Date, end: Date}} date range
 */
const generateDateRangeBoundary = (date, viewType) => {
    const start = new Date(date);
    if (viewType === 'month') {
        // Advancing by 1 week, to avoid edge cases like start of the month being 00:00 of the last month
        start.setDate(start.getDate() + 7);
    }
    if (viewType !== 'day') {
        start.setDate(viewType === 'month' ? 0 : start.getDate() + 1 - start.getDay());
    }
    const end = new Date(start);
    if (viewType === 'month') {
        end.setMonth(end.getMonth() + 1);
    } else {
        end.setDate(end.getDate() + (viewType === 'week' ? 7 : 1));
    }
    // reducing by one day to include records that began the day before
    start.setDate(start.getDate() - 1);
    return {start, end};
};

const now = new Date();
now.setHours(Math.max(0, now.getHours() - 2));
const scrollTime = `${now.getHours()}:${now.getMinutes().toString().padStart(2, '0')}`;
/** @type {Partial<import('@fullcalendar/core').CalendarOptions>} */
const staticConfig = {
    plugins: [timeGrid, dayGrid, interaction],
    eventMaxStack: 3,
    selectMirror: true,
    nowIndicator: true,
    scrollTime,
    initialView: 'timeGridWeek',
    locale: 'de-at',
    titleFormat: {day: '2-digit', month: '2-digit'},
    buttonText: {
        today: 'Heute',
        month: 'Monat',
        week: 'Woche',
        day: 'Tag',
    },
    hiddenDays: [0, 6],
    firstDay: 1,
    headerToolbar: {center: 'title', left: 'prev,today,next', right: 'dayGridMonth,timeGridWeek,timeGridDay'},
};

/**
 * Listing (presumably of events) that is displayed in form of a calendar.
 * Directly accesses an above list context
 * @param {import('./form').CalendarListingProps} props - props for the calendar listing
 * @returns {React.ReactElement} element to be rendered
 */
function CalendarListing({
    id,
    editConfig,
    createConfig,
    itemToEvents,
    startDateFilterPath,
    endDateFilterPath,
    extraEvents,
    routeConfig,
}) {
    const theme = useTheme();
    const {updateFilter} = useContext(ListFilterContext);
    const {data} = useContext(ListDataContext) ?? {};

    const {state} = useLocation();
    const initialDate = _.get(state, id);

    const {getGlobal} = useGlobalState();
    const user = getGlobal('user');
    const tenantId = getGlobal('tenantId');
    const userId = getGlobal('userId');
    const userWorkingModel = user?.workingTimeModel;
    /** @type {[undefined | number, React.Dispatch<number>]} */
    const [calendarStart, setStartEpoch] = useState();
    /** @type {[undefined | number, React.Dispatch<number>]} */
    const [endDateEpoch, setEndEpoch] = useState();

    const [userSchedules, setUserSchedules] = useState(null);
    const relevantShifts = useMemo(() => userSchedules
        ?.flatMap((schedule) => schedule.shifts
            .filter((shift) => shift.participantIds.includes(userId))), [userSchedules]);

    /** @type {import('@fullcalendar/core').EventInput[]} */
    const scheduledEvents = useMemo(() => relevantShifts?.map((shift) => ({
        start: shift.from,
        end: shift.until,
        extendedProps: shift,
        groupId: 'schedule',
        display: 'background',
        color: '#fed766',
    })) ?? [], [relevantShifts]);

    /** @type {import('@fullcalendar/core').EventInput[]} */
    const workingTimeModelEvents = useMemo(() => {
        if (!calendarStart) {
            return [];
        }
        const startDay = new Date(calendarStart);
        startDay.setDate(startDay.getDate() - startDay.getDay());
        return weekdays
            .flatMap((day, index) => _.times(5).flatMap((iteration) => {
                const referenceDate = new Date(startDay);
                referenceDate.setDate(referenceDate.getDate() + 7 * iteration + index);
                const referenceISO = referenceDate.toISOString();
                const schedule = userSchedules?.find(
                    (potentialSchedule) => (
                        referenceISO >= potentialSchedule.startDateTime
                        && referenceISO < potentialSchedule.endDateTime
                    ),
                );
                if (schedule) {
                    return [];
                }

                const agreedDayInfo = userWorkingModel?.agreedWorkingHours?.[day];
                /** @type {(time: string) => [number, number, number]} */
                // @ts-ignore
                const splitHours = (time) => time.split(':').map((n) => Number(n));
                /** @type {import('@fullcalendar/core').EventInput[]} */
                const events = agreedDayInfo?.map(({from, until}) => {
                    const fromTimestamp = referenceDate.setHours(...splitHours(from));
                    const untilTimestamp = referenceDate.setHours(...splitHours(until));
                    return ({
                        start: fromTimestamp,
                        end: untilTimestamp,
                        groupId: 'agreedWorkingHours',
                        display: 'background',
                    });
                }) ?? [];
                const fixedDayInfo = userWorkingModel?.fixedWorkingHours?.[day];

                return fixedDayInfo ? events.concat(fixedDayInfo?.map(({from, until}) => {
                    const fromTimestamp = referenceDate.setHours(...splitHours(from));
                    const untilTimestamp = referenceDate.setHours(...splitHours(until));
                    return ({
                        start: fromTimestamp,
                        end: untilTimestamp,
                        groupId: 'fixedWorkingHours',
                        display: 'background',
                    });
                })) : events;
            }));
    }, [userWorkingModel, calendarStart, userSchedules, relevantShifts]);

    const {listItems} = mockables.AWSAppSyncProvider();
    useEffect(() => {
        if (calendarStart && endDateEpoch) {
            const approxOneMonth = 30 * 24 * 60 * 60 * 1000;
            const start = new Date(calendarStart - approxOneMonth);
            listItems(listWorkingTimeSchedulesInDetail, {
                tenantId,
                filter: {
                    startDateTime: {gte: start.toISOString()},
                    participantId: userId,
                },
            }).then((schedules) => {
                setUserSchedules(schedules);
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Caught', err);
            });
        }
    }, [setUserSchedules, userId, tenantId, calendarStart, endDateEpoch]);

    /** @type {import('@fullcalendar/core').EventInput[]} */
    const itemEvents = useMemo(() => _.flatMap(data, itemToEvents), [data, itemToEvents]);

    const userModelHiddenDays = useMemo(() => {
        const modelTime = userWorkingModel?.agreedWorkingHours ?? userWorkingModel?.normalWorkingHours;
        const activeDays = modelTime
            ? _.entries(modelTime).filter((dayConfig) => dayConfig[1]?.length).map(_.first)
            // defaulting to all days visible, if no model is defined
            : weekdays;
        const indexedWeekdays = weekdays.map((day, i) => ({day, i}));
        const hiddenDays = _.differenceWith(indexedWeekdays, activeDays, (a, b) => a.day === b).map(({i}) => i);
        return hiddenDays;
    }, [userWorkingModel]);

    const eventDates = useMemo(() => itemEvents
        .map((event) => toDate(event.start)), [itemEvents]);

    const dataRequiredDays = useMemo(() => eventDates
        .map((date) => date.getDay()), [eventDates]);

    /** @type {{withoutTravel: Record<number, number>, withTravel: Record<number, number>}} */
    const hoursPerDay = useMemo(() => {
        const withoutTravel = Object();
        const withTravel = Object();
        const dayGroups = _.chain(itemEvents)
            .map('extendedProps')
            .groupBy((item) => new Date(toDate(item.startDateTime)).setHours(0, 0, 0, 0))
            .mapValues((group) => durationOfEvents(group, userWorkingModel))
            .value();
        _.forEach(dayGroups, (durations, day) => {
            withoutTravel[day] = durations.withoutTravel;
            withTravel[day] = durations.withTravel;
        });
        return {withoutTravel, withTravel};
    }, [itemEvents, userWorkingModel]);
    /** @type {import('@fullcalendar/core').EventInput[]} */
    const tooManyHours = useMemo(() => Object.entries(hoursPerDay.withoutTravel).filter(([, value]) => value > 12).map(([start]) => ({
        allDay: true, start: new Date(Number(start)), backgroundColor: theme.palette.error.dark, display: 'background',
    })), [hoursPerDay.withoutTravel]);

    const workingHoursByWeekday = useMemo(() => {
        const hoursConfig = userWorkingModel?.agreedWorkingHours
            ?? userWorkingModel?.normalWorkingHours;
        return _.mapValues(hoursConfig, (slots) => slots?.reduce((sum, {from, until}) => {
            /** @type {[number, number, number]} */
            const fromList = from.split(':').map((v) => Number(v));
            /** @type {[number, number, number]} */
            const untilList = until.split(':').map((v) => Number(v));
            const time = new Date();
            const difference = untilList.every((v) => v === 0)
                ? time.setHours(0, 0, 0) - time.setHours(...fromList)
                : time.setHours(...untilList) - time.setHours(...fromList);
            return sum + (difference / (60 * 60 * 1000));
        }, 0) ?? 0);
    }, [userWorkingModel]);

    // Switch out userModelHiddenDays if applicable
    const hiddenDays = useMemo(() => _.difference(userModelHiddenDays, dataRequiredDays), [dataRequiredDays, userModelHiddenDays]);

    const businessHours = useMemo(() => weekdays
        .flatMap((day, index) => {
            const dayInfo = userWorkingModel?.normalWorkingHours?.[day];
            return dayInfo?.map(({from, until}) => ({
                startTime: from,
                endTime: until,
                daysOfWeek: [index],
            })) ?? [];
        }), [userWorkingModel]);

    const onViewChange = useCallback(({start: allegedStart, view}) => {
        const viewType = _.lowerCase(view.type).split(' ').at(-1);
        // @ts-ignore
        const {start, end} = generateDateRangeBoundary(allegedStart, viewType);
        setStartEpoch(Number(start));
        setEndEpoch(Number(end));
        const stateBefore = window.history.state;
        stateBefore.usr ??= {};
        stateBefore.usr[id] = start;
        window.history.replaceState(stateBefore, '');
    }, [updateFilter, id, setStartEpoch, setEndEpoch]);

    /** @type {import('@fullcalendar/core').EventInput[]} */
    const dayHourReportEvents = useMemo(() => {
        const today = new Date().setHours(0, 0, 0, 0);
        const events = Object
            .entries(hoursPerDay.withTravel)
            .filter(([date]) => Number(date) <= today)
            .map(([date, hours]) => {
                const start = new Date(Number(date));
                const weekday = weekdays[start.getDay()];
                const workingHours = workingHoursByWeekday[weekday];
                return {
                    allDay: true,
                    start,
                    title: workingHours ? `${hours.toPrecision(2)} / ${workingHours.toPrecision(2)}` : hours.toPrecision(2),
                    backgroundColor: (!workingHours || hours < workingHours)
                        ? theme.palette.warning.dark
                        : 'transparent',
                    display: 'background',
                };
            });

        if (calendarStart) {
            const minEnd = Math.min(today, endDateEpoch);
            for (let start = calendarStart; start <= minEnd; start += 24 * 60 * 60 * 1000) {
                if (!_.has(hoursPerDay.withTravel, String(start))) {
                    const date = new Date(start);
                    const weekday = weekdays[date.getDay()];
                    if (workingHoursByWeekday[weekday]) {
                        const hours = workingHoursByWeekday[weekday];
                        events.push({
                            start: date,
                            allDay: true,
                            title: `${0} / ${hours.toPrecision(2)}`,
                            backgroundColor: theme.palette.warning.dark,
                            display: 'background',
                        });
                    }
                }
            }
        }

        return events;
    }, [hoursPerDay.withTravel, workingHoursByWeekday, theme.palette.warning.dark, calendarStart, endDateEpoch]);

    useEffect(() => {
        updateFilter(startDateFilterPath, calendarStart, true);
        updateFilter(endDateFilterPath, endDateEpoch);
    }, [calendarStart, endDateEpoch, startDateFilterPath, endDateFilterPath]);

    const findRoute = useFindRoute();

    return (
        <BeyondCalendar
            routeMapping={(event) => {
                if (event.id && routeConfig) {
                    return [
                        getRoutePath(findRoute(routeConfig.routeId), routeConfig.routeParams(event)),
                    ];
                }
                return undefined;
            }}
            calendarOptions={{
                allDayText: 'Stunden',
                ...staticConfig,
                customRenderingReplacesEl: true,
                dayMaxEvents: 3,
                hiddenDays,
                initialDate,
                datesSet: onViewChange,
                businessHours,
                selectAllow: useCallback(() => Boolean(createConfig), [createConfig]),
                selectable: Boolean(createConfig),
                editable: Boolean(editConfig),
                events: [
                    ...scheduledEvents,
                    ...workingTimeModelEvents,
                    ...extraEvents ?? [],
                    ...itemEvents,
                    ...tooManyHours,
                    ...dayHourReportEvents,
                ],
            }}
        />
    );
}

export {CalendarListing, mockables};
