/**
 * createEvacuation
 * @property {string} userId - id of the user for which to create the evacuation.
 * @property {string} tenantId - id of the tenant for which to create the evacuation.
 * @returns {object} - the created evacuation
 */
export const createEvacuation = /* GraphQL */ `
  mutation CreateEvacuation(
    $tenantId: ID!
    $userId: ID!
  ) {
    createEvacuation(
      tenantId: $tenantId
      userId: $userId
    ) {
      id
      presentEntries {
        arrivalTime
        evacuationPointId
        userId
      }
    }
  }
`;

/**
 * addUserToEvacuation
 */
export const addUserToEvacuation = /* GraphQL */ `
  mutation AddUserToEvacuation(
    $id: ID!
    $newEntry: EvacuationPresentEntryInput!
  ) {
    updateEvacuation(
      id: $id
      newEntry: $newEntry
    ) {
      id
      completedAt
      completedByUserId
      completedByUser{
        id
        contactFirstName
        contactLastName
        contactEMailAddress
      }
      presentEntries {
        arrivalTime
        evacuationPointId
        evacuationPoint{
          name
        }
        enteredByUserId
        enteredByUser{
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
        userId
        user{
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
      }
    }

  }
`;

/**
 * completeEvacuation
 * @property {string} id - id.
 * @returns {object} - the updated Evacuation
 */
export const completeEvacuation = /* GraphQL */ `
  mutation CompleteEvacuation(
    $id: ID!
  ) {
    updateEvacuation(id: $id completed: true) {
      id
      completedAt
      completedByUserId
      completedByUser{
        id
        contactFirstName
        contactLastName
        contactEMailAddress
      }
      presentEntries {
        arrivalTime
        evacuationPointId
        evacuationPoint{
          name
        }
        enteredByUserId
        enteredByUser{
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
        userId
        user{
          id
          contactFirstName
          contactLastName
          contactEMailAddress
        }
      }
    }
  }
`;

/**
 * updateEvacuation
 * @property {string} id - id.
 * @property {any[]} presentPersons - entries of present people in the evacuation.
 * @property {boolean} stopped - flag determines if the evacuation has stopped
 * @returns {object} - the updated Evacuation
 */
export const updateEvacuation = /* GraphQL */ `
  mutation UpdateEvacuation(
    $id: ID!
    $presentEntries: [EvacuationPresentEntryInput!]
    $completed: Boolean
  ) {
    updateEvacuation(
      id: $id
      presentEntries: $presentEntries
      completed: $completed
    ) {
      id
      presentEntries {
        arrivalTime
        evacuationPointId
        evacuationPoint{
          name
        }
        enteredByUserId
        userId
        user{
          contactFirstName
          contactLastName
          organizationalUnit{
            name
          }
          image{
            url
          }
        }
      }
    }
  }
`;

/**
 * deleteEvacuation
 * @property {string} id - Evacuation id.
 * @returns {object} The deleted Evacuation.
 */
export const deleteEvacuation = /* GraphQL */ `
  mutation DeleteEvacuation(
    $id: ID!
  ) {
    deleteEvacuation(id: $id) {
      id
    }
  }
`;
