import React, {useMemo, useRef} from 'react';
import {BehaviorSubject} from 'rxjs';

/**
 * @typedef TQuickGuideContext
 * @property {() => void} close - signals the quick guide display to close
 * @property {() => void} [back] - signals the quick guide display to go one back, if possible
 * @property {(guideId: string) => void} openGuide - signals the quick guide display to open a specific guide
 * @property {import("rxjs").Observable<string[]>} guideStack$ - observable of the currently open guides
 */

/**
 * Context that provides quick guide functions
 * @type {React.Context<TQuickGuideContext>}
 */
const QuickGuideContext = React.createContext(undefined);

/**
 * Provider for quick guides. Both renders the quickGuide drawer, and gives children
 * of the component the option to open and close quick guides
 * @param {object} props - props for the quick guide provider
 * @param {React.ReactNode} props.children - children to be displayed beneath the provider
 * @returns {React.ReactElement} element to be rendered
 */
function QuickGuideProvider({children}) {
    /** @type {import('react').MutableRefObject<BehaviorSubject<string[]>>} */
    const guideStack = useRef(undefined);
    if (!guideStack.current) {
        guideStack.current = new BehaviorSubject([]);
    }
    /** @type {TQuickGuideContext} */
    const value = useMemo(() => ({
        close: () => guideStack.current.next([]),
        back: () => guideStack.current.next(guideStack.current.value.slice(0, -1)),
        openGuide: (newGuideId) => guideStack.current.next([...guideStack.current.value, newGuideId]),
        guideStack$: guideStack.current,
    }), [guideStack]);
    return (
        <QuickGuideContext.Provider value={value}>
            {children}
        </QuickGuideContext.Provider>
    );
}

export {
    QuickGuideProvider,
    QuickGuideContext,
};
