// eslint-disable-next-line no-restricted-imports
import {FileInformationFragment} from '../fragments';

/**
 * Query to get file information for a specific file
 * @function
 */
export const getFileInformation = /* GraphQL */ `
 query GetFileInformation($key: ID!) {
   getFileInformation(key: $key) {
     ...FileInformation
   }
 }
 ${FileInformationFragment}
`;

/**
 * The GetPresignedUploadUrl query.
 * @function
 */
export const getPresignedUploadUrl = /* GraphQL */ `
 query GetPresignedUploadUrl($tenantId: ID!, $filename: String!) {
   getPresignedUploadUrl(tenantId: $tenantId, filename: $filename) {
     key
     url
     uploadUrl
   }
 }`;
