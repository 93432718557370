import React from 'react';
import {Box, Typography} from '@mui/material';

/**
 * This page shows a quick guide on the layout
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} the guide page
 */
function GuideDrivingRecordPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Fahrt</Typography>
            <hr />
            <Box>
                Ein Fahrtenbuch-Eintrag ist eine Aufzeichnung aller wichtigen Informationen über eine Fahrt, die mit einem Fahrzeug durchgeführt wurde.
                Fahrtenbuch-Einträge werden verwendet, um die Geschäftsnutzung eines Fahrzeugs für
                steuerliche Zwecke nachzuweisen oder um die private Nutzung eines Firmenfahrzeugs zu dokumentieren.
                <br />
                <br />
                Durch den Einsatz von künstlicher Intelligenz, wird die Erfassung einer Fahrt erheblich vereinfacht.
                Die KI hilft, füllt bestimmte Informationen automatisch im Formular ein, was den Aufwand für Dich stark reduziert.
                Hierfür nutzt die KI die Daten all Deiner gebuchten Fahrten (Entwürfe ausgenommen) und kalkuliert einen Vorschlag.
                Wenn Du beispielsweise regelmäßig zu einer bestimmten Zeit an einem bestimmten Wochentag an einen bestimmten Ort fährst, wird die KI diese Regelmäßigkeit erkennen.
                <br />
                <br />
                Durch die Verwendung von künstlicher Intelligenz, werden die Eingabe- und Verwaltungsaufgaben erheblich vereinfacht.
            </Box>
        </>
    );
}
export {GuideDrivingRecordPage};
