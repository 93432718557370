import React, {useEffect, useMemo, useRef} from 'react';
import {Box} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import _ from 'lodash';

/**
 * Controls which tab is active, and allows for navigation to the same tab
 *  @type {import("./layout").UseTabGroupFn}
 */
const useTabState = (tabs) => {
    const location = useLocation();
    const hash = location.hash.substring(1);

    const hashValid = (hash && hash in tabs && tabs[hash]) || false;
    useEffect(() => {
        if (hashValid) {
            document.getElementById(hash).scrollIntoView();
        }
    }, [hash, hashValid]);

    const navigate = useNavigate();
    return {
        selectedTab: (tabs[hash] && hash) || false,
        selectTab(id) {
            if (id in tabs) {
                navigate({hash: id}, {replace: true, preventScrollReset: true});
            }
        },
        tabPanelArgs: useMemo(() => _.mapValues(tabs, (available, id) => ({
            tabId: `${id}_tab`,
            id,
            active: available && id === hash,
        })), [tabs, hash]),
        tabArgs: useMemo(() => _.mapValues(tabs, (available, id) => ({
            value: id,
            'aria-controls': id,
            id: `${id}_tab`,
            style: {display: !available ? 'none' : 'unset'},
        })), [tabs]),
    };
};

/**
 * A wrapper for using MUI Tabs. This panel is the content for a tab.
 * @param {object} props - properties passed to the component
 * @param {boolean} props.active - flag that activates the tab
 * @param {string} props.id - id of the element that hosts the tab panel content
 * @param {string} props.tabId - id of the tab that is corresponding to the panel
 * @param {import('@mui/material').BoxProps} [props.boxProps] -  properties passed to the box component
 * @param {React.ReactNode} props.children - all form elements to be rendered in the tabpanel
 * @returns {React.ReactElement} - tabpanel for tabs
 */
function TabPanel({
    tabId, id, active, boxProps, children,
}) {
    const hasBeenActive = useRef(active);
    hasBeenActive.current = hasBeenActive.current || active;
    return (
        <Box
            id={id}
            role="tabpanel"
            {...boxProps}
            style={{
                ...boxProps?.style,
                display: active ? 'inherit' : 'none',
            }}
            aria-labelledby={tabId}
        >
            <Box>
                {hasBeenActive.current && children}
            </Box>
        </Box>
    );
}

export {TabPanel, useTabState};
