export const listDeceasedPersons = /* GraphQL */ `
  query ListDeceasedPersons (
    $tenantId: ID!
    $filter: DeceasedPersonsListFilterInput
    $nextToken: String
    ) {
      listDeceasedPersons(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: $filter
    ) {
      items {
        id
        tenantId
        referenceId
        gender
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        dateOfBirth
        dateOfDeath
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

export const listDeceasedPersonOptions = /* GraphQL */ `
  query ListDeceasedPersons (
    $tenantId: ID!
    $filter: String
    $nextToken: String
    ) {
      listDeceasedPersons(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: {
          search: $filter,
          burried: false
        }
    ) {
      items {
        id
        gender
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        dateOfBirth
        dateOfDeath
        grants {
          deletable
          permissionsUpdatable
        }
      }
      nextToken
    }
  }
`;

export const listDeceasedPersonsLink = /* GraphQL */ `
  query ListDeceasedPersons (
    $tenantId: ID!
    $filter: DeceasedPersonsListFilterInput
    ) {
      listDeceasedPersons(
        tenantId: $tenantId
        filter: $filter
    ) {
      items {
        id
        referenceId
        gender
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        lastDateOfFuneral
        burried
      }
    }
  }
`;

export const getDeceasedPerson = /* GraphQL */ `
  query GetDeceasedPerson(
      $id: ID!
    ) {
      getDeceasedPerson(
        id: $id
    ) {
      id
      tenantId
      referenceId
      gender
      titlePrefixed
      firstName
      lastName
      titlePostfixed
      nationalityCountryCode
      dateOfBirth
      placeOfBirth
      placeOfBirthCountryCode
      dateOfDeath
      stillbirth
      cremation
      ashCapsuleNr
      lastGraveRecord
      lastDateOfFuneral
      burried
      attachments{
        key
        filename
        description
        url
      }
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
        attributesReadable
        attributesUpdatable
      }
      subGrants {
        graveRecordReadable
      }
    }
  }
`;
