export const createProtocolEntry = /* GraphQL */ `
  mutation CreateProtocolEntry(
    $tenantId: ID!
    # $clerkUserId: ID
    $context: String!
    $entityTypeId: String!
    $protocolDate: AWSDateTime!
    $subject: String!
    $notes: String
    $attachmentKeys: AWSJSON
    $additionalAttributes: AWSJSON
    ) {
    createProtocolEntry(
      tenantId: $tenantId
      # clerkUserId: $clerkUserId
      context: $context
      entityTypeId: $entityTypeId
      protocolDate: $protocolDate
      subject: $subject
      notes: $notes
      attachmentKeys: $attachmentKeys
      additionalAttributes: $additionalAttributes
    ) {
      id
      tenantId
      clerkUserId
      clerkUser {
        id
        contactFirstName
        contactLastName
      }
      context
      entityTypeId
      protocolDate
      subject
      notes
      attachmentKeys
      attachments{
        key
        filename
        description
        url
      }
      additionalAttributes
    }
  }
`;

export const updateProtocolEntry = /* GraphQL */ `
  mutation UpdateProtocolEntry(
    $id: ID!
    # $clerkUserId: ID
    $protocolDate: AWSDateTime!
    # $subject: String!
    $notes: String
    $attachmentKeys: AWSJSON
    $additionalAttributes: AWSJSON
    ) {
    updateProtocolEntry(
      id: $id
      # clerkUserId: $clerkUserId
      protocolDate: $protocolDate
      # subject: $subject
      notes: $notes
      attachmentKeys: $attachmentKeys
      additionalAttributes: $additionalAttributes
    ) {
      id
      tenantId
      clerkUserId
      clerkUser {
        id
        contactFirstName
        contactLastName
      }
      context
      entityTypeId
      protocolDate
      subject
      notes
      attachmentKeys
      attachments{
        key
        filename
        description
        url
      }
      additionalAttributes
    }
  }
`;

export const deleteProtocolEntry = /* GraphQL */ `
 mutation DeleteProtocolEntry(
     $id: ID!
   ) {
   deleteProtocolEntry(
     id: $id,
     ) {
      id
   }
 }
`;
