import {
    IconButton, Box, Typography, Toolbar, Button, SwipeableDrawer,
} from '@mui/material';
import {ArrowBack, Close} from '@mui/icons-material';
import {guideConfigurations} from 'applications/configs';
import React, {
    useContext,
    useEffect, useState,
} from 'react';
import {QuickGuideContext} from 'components/QuickGuide/QuickGuideContext';
import _ from 'lodash';

/**
 * Drawer that renders open quick guides, should there be any.
 * Should only be used within a `QuickGuideContextProvider`.
 * @returns {React.ReactElement} drawer to be rendered
 */
function QuickGuideDrawer() {
    const {back, close, guideStack$} = useContext(QuickGuideContext);
    const [guideStack, setGuideStack] = useState([]);
    useEffect(() => {
        const subscription = guideStack$.subscribe((newGuideStack) => setGuideStack(newGuideStack));
        return () => subscription.unsubscribe();
    }, [guideStack$, setGuideStack]);

    const currentGuide = guideConfigurations[guideStack.at(-1)];
    return (
        <SwipeableDrawer
            // No swipe to open, since no specific guide would be selected + there is multiple drawers
            disableSwipeToOpen
            open={Boolean(guideStack.length)}
            onOpen={_.noop}
            variant="temporary"
            anchor="right"
            onClose={close}
            data-test="QuickGuideDrawer"
            data-guide={guideStack.at(-1)}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: 'min(40rem, 100vw)',
                    boxShadow: 2,
                    paddingX: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                },

            }}
        >
            <Toolbar variant="dense" style={{justifyContent: 'space-between'}}>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    {guideStack.length > 1 && (
                        <>
                            <IconButton onClick={back}>
                                <ArrowBack />
                            </IconButton>
                            <Typography>
                                Zurück zu&nbsp;
                                <i>{guideConfigurations[guideStack.at(-2)]?.title}</i>
                            </Typography>
                        </>
                    )}
                </Box>
                <Button data-test="closeQuickGuide" endIcon={<Close />} onClick={close}>Schließen</Button>
            </Toolbar>
            <Box style={{overflow: 'scroll'}}>
                {currentGuide?.content}
            </Box>
        </SwipeableDrawer>
    );
}

export {QuickGuideDrawer};
