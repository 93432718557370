import {completeWorkingTimeWorkLog, createWorkingTimeWorkLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {listWorkingTimeLogs} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {CancelException} from 'hooks/useCancellablePromise';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMessage} from 'hooks/useMessage';
import _ from 'lodash';
import {Exceptions} from 'messages/Exceptions';
import {Warnings} from 'messages/Warnings';
import {useCallback, useEffect, useState} from 'react';

/**
 * Hook specific to WorkingTimeLogs, that handles state and toggling of working state
 * according to any open working time logs
 * @returns {{tracking: boolean | null, toggleWorking: ()=>Promise<void>}} hook contents
 */
function useToggleWorking() {
    const {listItems, editItem} = AWSAppSyncProvider();
    const {getGlobal} = useGlobalState();
    const [workingTimeLog, setWorkingTimeLog] = useState(null);
    const {enqueueMessage} = useMessage();

    const tenantId = getGlobal('tenantId');
    const userId = getGlobal('userId');
    useEffect(() => {
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);
        listItems(listWorkingTimeLogs, {
            tenantId,
            filter: {
                // startDateTime: {gte: oneDayAgo.toISOString()},
                userId,
                draft: true,
                kinds: ['NORMAL', 'TRAVEL_PASSIVE', 'TRAVEL_ACTIVE', 'BREAK'],
            },
        }).then((result) => {
            const entries = result.filter((entry) => !entry.endDateTime && !entry.template?.checkoutEnforcement);
            if (entries.length) {
                setWorkingTimeLog(entries[0]);
            } else {
                setWorkingTimeLog({});
            }
        }).catch((error) => {
            if (error instanceof CancelException) {
                return;
            }
            throw error;
        });
    }, [setWorkingTimeLog, listItems, tenantId, userId]);

    const toggleWorking = useCallback(async () => {
        const currentId = workingTimeLog?.id;
        setWorkingTimeLog(null);
        const now = new Date().toISOString();
        const result = currentId
            ? editItem(completeWorkingTimeWorkLog, {
                id: currentId,
                endDateTime: now,
            })
            : editItem(createWorkingTimeWorkLog, {
                userId,
                startDateTime: now,
                kind: 'NORMAL',
                draft: true,
            });
        result.then((item) => {
            if (currentId) {
                setWorkingTimeLog({});
            } else {
                setWorkingTimeLog(item);
            }
        }).catch(async (error) => {
            if (error instanceof CancelException) {
                return;
            }

            // if error is "no open time period" save as a draft instead
            if (error?.errors instanceof Array && error.errors.length === 1 && error.errors[0].message.includes('Could not find OpenTimePeriod')) {
                const success = await editItem(completeWorkingTimeWorkLog, {
                    id: currentId,
                    endDateTime: now,
                    draft: true,
                }).then(() => true).catch(() => false);
                if (success) {
                    setWorkingTimeLog(currentId);
                    enqueueMessage('WorkTimeToggle_Open', Warnings.NO_COMPLETION_DUE_TO_TIME_PERIOD);
                    return;
                }
            }
            setWorkingTimeLog('');
            enqueueMessage(`WorkTimeToggle_${currentId ? 'Complete' : 'Open'}`, Exceptions.API_SAVE_ERROR);
        });
    }, [workingTimeLog, editItem, enqueueMessage, setWorkingTimeLog]);
    return {
        tracking: workingTimeLog !== null ? !_.isEmpty(workingTimeLog) : null,
        toggleWorking,
    };
}

export {useToggleWorking};
