import React, {useMemo, useState} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';
import {LockOutlined} from '@mui/icons-material';
import _ from 'lodash';

/**
 * @typedef {import('applications/configuration').Module} Module
 * @typedef {Array<{label: string, name: Module}>} ModuleListConfig
 * @typedef {import('applications/configuration').Application} Application
 */

/** @type {Partial<Record<Application, ModuleListConfig>>} */
const modulesByApplication = {
    DriveBuddy: [
        {label: 'Fahrtenbuch', name: 'Logbook'},
    ],
    TimeBuddy: [
        {label: 'Arbeitszeiten', name: 'WorkingTime'},
    ],
    PeaceBuddy: [],
};
/** @type {Application[]} */
// @ts-ignore
const availableApplications = Object.keys(modulesByApplication);

/**
 * @param {string} tabId the id of the tab
 * @param {object} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (tabId, props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        actionButtonProps={{portalAnchorSelector: `#BeyondBuddyModulePermissionsaction-button-frame-${tabId}-actions`}}
        entityChooserProps={{
            initialEntityType: 'Tenant',
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['access']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['access']),
            showCostsWarning: true,
        }}
        {...props}
    />
);

/**
 * @returns {React.ReactElement} The BeyondBuddyPermissionsPage component.
 */
function ModulesPage() {
    const [currentTab, setCurrentTab] = useState(0);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: 'BeyondBuddyModulePermissions',
            tabs: availableApplications.flatMap((application) => {
                const modules = modulesByApplication[application];
                if (_.isEmpty(modules)) {
                    return [{
                        id: application,
                        label: application,
                        icon: <LockOutlined />,
                        getChildren: () => getPermissionFormular(application, {entityTypeId: `Application#${application}`, context: `BeyondBuddyModulePermissions${application}`}),
                        formWrapperId: `BeyondBuddyModulePermissions${application}`,
                    }];
                }
                return modules.flatMap((module) => (
                    {
                        id: module.name,
                        label: module.label,
                        icon: <LockOutlined />,
                        getChildren: () => getPermissionFormular(module.name, {entityTypeId: `Module#${module.name}`, context: `BeyondBuddyModulePermissions${module.name}`}),
                        formWrapperId: `BeyondBuddyModulePermissions${module.name}`,
                    }
                ));
            }),
            currentTab,
            setCurrentTab,
        }), [currentTab, setCurrentTab, getPermissionFormular]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {ModulesPage};
