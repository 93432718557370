import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {EvacuationsListFormular} from 'applications/beyondbuddy/settings/forms/evacuation/EvacuationListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of Evacuations.
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The EvacuationsListPage component
 */
function EvacuationListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Evakuierungen</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <EvacuationsListFormular />
            </div>
        </LayoutContainer>
    );
}
export {EvacuationListPage};
