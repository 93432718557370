const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    UUID_REGEX,
    FILE_KEY_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema<import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/app/peaceBuddy/Grave').GraveEntity>}
 */
const schema = {
    frontend: {
        generalNr: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'length'],
            },
        ],
        restPeriod: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        units: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        type: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        kind: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format', 'dependencyFormat'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        generalNr: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'length', 'unique'],
            },
        ],
        restPeriod: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        units: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        type: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        kind: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format', 'dependencyFormat'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },

    attributes: {
        id: {
            format: UUID_REGEX,
            required: true,
        },
        generalNr: {
            type: 'String',
            length: {lte: 50},
            required: true,
            unique: ['cemeteryId'], // only unique within a cemetery (or without)
        },
        restPeriod: {
            type: 'Number',
            format: /^(100|[1-9][0-9]?|0?[1-9])$/,
            required: true,
        },
        units: {
            type: 'Number',
            required: true,
            format: /^(10|[1-9])$/,
        },
        type: {
            type: 'String',
            required: true,
            format: /^(COFFIN|URN)$/,
        },
        kind: {
            type: 'String',
            required: true,
            format: /^(SINGLE|FAMILY|CRYPT|EARTH|WALL|TREE|FOREST|GARDEN|ROCK|COLUMBARIUM|MEADOW)$/,
            dependencyFormat: {
                type: {
                    COFFIN: /^(SINGLE|FAMILY|CRYPT)$/,
                    URN: /^(CRYPT|EARTH|WALL|TREE|FOREST|GARDEN|ROCK|COLUMBARIUM|MEADOW)$/,
                },
            },
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
