import {
    Skeleton, Switch, FormControlLabel, Typography,
} from '@mui/material';
import React, {
    useCallback, useContext, useMemo,
    useEffect,
} from 'react';
import {FormContext} from 'components/Form/FormWrapper';

/**
 * @typedef FormElementSwitchProps
 * @property {string} attribute - attribute to load/write data from/to
 * @property {string} label - label to be shown instead of child content if none is given.
 * @property {boolean} [disabled] - flag to disable the switch
 * Also set as aria-label
 * @property {boolean} [defaultTrue] - flag to set the value to true, if none exists
 */

/**
 * ## FormElementSwitch
 *
 * Renders a toggle button, loads values from FormWrapper, and writes into it
 * @param {FormElementSwitchProps} props - props of the component
 * @returns {React.ReactElement} - element to be rendered
 */
function FormElementSwitch({
    attribute, label, disabled, defaultTrue,
}) {
    const {
        get, changeHandler, isLoading, isReadonly,
    } = useContext(FormContext);

    const elementData = useMemo(() => get(attribute), [attribute, get]);
    // Setting value to true when preEnabled, and value would otherwise be null
    useEffect(() => {
        if (defaultTrue && elementData.value == null && !elementData.interacted) {
            changeHandler({attribute, value: true});
        }
    }, [defaultTrue, elementData.value, elementData.interacted, attribute]);
    const onChange = useCallback(() => {
        changeHandler({
            attribute,
            value: !elementData.value,
            interacted: true,
        });
    }, [changeHandler, elementData.value, attribute, get]);

    if (isLoading.load) {
        return <Skeleton variant="rectangular" animation="wave" height="38px" />;
    }

    return (
        <FormControlLabel
            style={{width: 'calc(100% - 1rem)'}}
            label={<Typography noWrap>{label}</Typography>}
            control={(
                <Switch
                    data-test={`FormElementSwitch_${attribute}`}
                    checked={elementData.value ?? false}
                    onClick={onChange}
                    disabled={disabled || isReadonly}
                />
            )}
        />
    );
}

export {FormElementSwitch};
