/**
 * listDrivingRecords
 * @property {string} tenantId - tenant id.
 * @property {string} userId - user id.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} The driving records.
 */
export const listDrivingRecords = /* GraphQL */ `
  query ListDrivingRecords(
    $tenantId: ID!
    $userId: ID!
    $nextToken: String
    $filter: DrivingRecordsListFilterInput
  ) {
    listDrivingRecords(
    tenantId: $tenantId
    userId: $userId
    nextToken: $nextToken
    filter: $filter
    ) {
      items {
        id
        draft
        startDateTime
        endDateTime
        startAddress
        endAddress
        startKM
        endKM
        vehicle{
          name
        }
      }
      nextToken
    }
  }
`;

/**
 * getDrivingRecord
 * @property {string} id - driving record id.
 * @returns {object} The driving record and vehicle.
 */
export const getDrivingRecord = /* GraphQL */ `
  query GetDrivingRecord($id: ID!) {
    getDrivingRecord(id: $id) {
      id
      startDateTime
      endDateTime
      startAddress
      endAddress
      startKM
      endKM
      draft
      drivingRecordVehicleId
      drivingRecordUserId
      kind
      reason
      grants {
        updatable
      }
      vehicle{
        id
        name
      }
      user {
        id
        contactFirstName
        contactLastName
      }
    }
  }
`;

/**
 * listStartAddressRecommendations
 * @property {string} tenantId - tenant id.
 * @property {string} userId - user id.
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} The start address recommendations.
 */
export const listStartAddressRecommendations = /* GraphQL */ `
  query ListStartAddressRecommendations(
    $tenantId: ID!
    $userId: ID!
    $filter: String
    $vehicleId: ID
    $nextToken: String
  ) {
    listStartAddressRecommendations(
      tenantId: $tenantId
      userId: $userId
      filter: $filter
      vehicleId: $vehicleId
      nextToken: $nextToken
    ) {
      items {
        startAddress
        occurrence
        rank
      }
      nextToken
    }
  }
`;

/**
 * listEndAddressRecommendations
 * @property {string} tenantId - tenant id.
 * @property {string} userId - user id.
 * @property {string} filter - filter.
 * @property {string} nextToken - token to continue a listing
 * @returns {object} The end address recommendations.
 */
export const listEndAddressRecommendations = /* GraphQL */ `
  query ListEndAddressRecommendations(
    $tenantId: ID!
    $userId: ID!
    $filter: String
    $startAddress: String
    $vehicleId: ID
    $nextToken: String
  ) {
    listEndAddressRecommendations(
      tenantId: $tenantId
      userId: $userId
      filter: $filter
      vehicleId: $vehicleId
      startAddress: $startAddress
      nextToken: $nextToken
    ) {
      items {
        endAddress
        occurrence
        rank
      }
      nextToken
    }
  }
`;

export const listReasonRecommendations = /* GraphQL */ `
  query ListReasonRecommendations(
    $tenantId: ID!
    $userId: ID!
    $filter: String
    $startAddress: String
    $vehicleId: ID
    $endAddress: String
    $nextToken: String
  ) {
    listReasonRecommendations(
      tenantId: $tenantId
      userId: $userId
      filter: $filter
      vehicleId: $vehicleId
      startAddress: $startAddress
      endAddress: $endAddress
      nextToken: $nextToken
    ) {
      items {
        reason
        occurrence
        rank
      }
      nextToken
    }
  }
`;
