/* eslint-disable max-len */
import React from 'react';
import {
    Alert, Box, Divider, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

const getFormAttributeDescription = (label, description) => (
    <li>
        <strong>{label}</strong>
        {description && `: ${description}`}
    </li>
);

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideDeceasedPersonPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Verstorbene Person</Typography>
            <hr />
            <Box>
                Das Formular zur Anlage und zum Bearbeiten eines Grabs umfasst den Bereich der Basisinformationen,
                sowie bei bestehenden Verstorbenen zusätzlich die
                <QuickGuideLink id="peacebuddy_general_graveRecord"> Beisetzungen </QuickGuideLink>
                und
                <QuickGuideLink id="beyondbuddy_general_permissions"> (eingehenden) Berechtigungen</QuickGuideLink>
                .
                <br />
                <br />
                <Alert severity="info">Beim Erstellen einer verstorbenen Person, wird automatich der gesammte Mandant berechtigt diesen zu administrieren.</Alert>
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Geschlecht',
                    )}
                    {getFormAttributeDescription(
                        'Vorname',
                    )}
                    {getFormAttributeDescription(
                        'Nachname',
                    )}
                    {getFormAttributeDescription(
                        'Nationalität',
                    )}
                    {getFormAttributeDescription(
                        'Geburtsdatum',
                    )}
                    {getFormAttributeDescription(
                        'Sterbedatum',
                    )}
                    {getFormAttributeDescription(
                        'Aschekapselnummer',
                        'Im Fall einer Feuerbestattung',
                    )}
                </ul>
                <br />
                <Typography variant="h2" color="primary">Weitere Felder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Referenz',
                        'Fremdzahl zu Drittsystemen',
                    )}
                    {getFormAttributeDescription(
                        'Abteilung / Baumkreis',
                        'Dient der adressierung des Grabs',
                    )}
                    {getFormAttributeDescription(
                        'Titel (vorangestellt)',
                    )}
                    {getFormAttributeDescription(
                        'Titel (nachgestellt)',
                    )}
                    {getFormAttributeDescription(
                        'Geburtsland',
                    )}
                    {getFormAttributeDescription(
                        'Geburtsort',
                    )}
                    {getFormAttributeDescription(
                        'Totgeburt',
                    )}
                    {getFormAttributeDescription(
                        'Beigesetzt',
                        'Verstorbener wurde beigesetzt. Hilfreich bei Exhumierungen um den Verstorbenen einem weiteren Grabeintrag zuführen zu können.',
                    )}
                    {getFormAttributeDescription(
                        'Feuerbestattung',
                        'Der Verstorbene wurde entsprechend bestattet. Hierbei wird die Aschenkapselnummer verpflichtend',
                    )}
                </ul>

                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit multimediale Inhalte wie Dokumente oder weitere Bilder hochzuladen,
                um Informationen zum Grab bereitzustellen.

            </Box>
        </>
    );
}
export {GuideDeceasedPersonPage};
