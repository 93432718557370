/**
 * A download helper, that automatically downloads the provided url
 * @param {string} url - the url to download from
 * @param {string} filename - filename of the download
 */
const downloadURL = (url, filename) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

/**
 *
 * @param {HTMLElement} element - the svg element to download
 * @param {string} filename - filename of the download
 */
const downloadSVG = (element, filename) => {
    let xmlSource = new XMLSerializer().serializeToString(element);

    if (!xmlSource.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        xmlSource = xmlSource.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!xmlSource.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        xmlSource = xmlSource.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }
    // add xml declaration
    xmlSource = `<?xml version="1.0" standalone="no"?>\r\n${xmlSource}`;

    const svg64 = encodeURIComponent(xmlSource);
    const b64Start = 'data:image/svg+xml;charset=utf-8,';

    const image64 = b64Start + svg64;
    downloadURL(image64, filename);
};

/**
 *
 * @param {HTMLCanvasElement} element - the svg element to download
 * @param {string} filename - filename of the download
 */
const downloadCanvas = (element, filename) => {
    const image = element.toDataURL('image/png', 1);
    downloadURL(image, filename);
};

export {downloadCanvas, downloadSVG, downloadURL};
