import React, {useCallback} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';
import {Box} from '@mui/material';
import {DrivingRecordFormular} from 'applications/drivebuddy/modules/logbook/forms/drivingrecord/DrivingRecordFormular';

import createDrivingRecordImg from 'applications/drivebuddy/modules/logbook/pages/images/AdobeStock_421070547.jpg';
import updateDrivingRecordImg from 'applications/drivebuddy/modules/logbook/pages/images/AdobeStock_421070555.jpg';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a create and update form for a drivingrecord
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The DrivingRecordPage component.
 */
function DrivingRecordPage() {
    // getting the URL parameters
    const {id} = useParams();
    const navigate = useNavigate();
    /**
     * @type {import('routeinfo').RoutePathInfo}
     */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>{id === 'create' ? 'Neue Fahrt' : 'Fahrt bearbeiten'}</h2></Box>
                <Box>
                    <span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}}>
                        <QuickGuideLink id="driveBuddy_logbook_drivingRecord" isActionButton />
                    </span>
                </Box>
            </FormHeadContainer>
            <Box sx={{display: 'flex', gap: '2rem', marginTop: '1.5rem'}}>
                <Box
                    component="img"
                    src={id === 'create' ? createDrivingRecordImg : updateDrivingRecordImg}
                    alt="DriveBuddy Fahrt"
                    sx={{
                        maxWidth: '300px',
                        display: {xs: 'none', lg: 'block'},
                        marginBottom: 'auto',
                    }}
                />
                <Box sx={{flexGrow: 1}}>
                    <DrivingRecordFormular id={id} onSave={onSave} />
                </Box>
            </Box>
        </LayoutContainer>
    );
}
export {DrivingRecordPage};
