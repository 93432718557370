import React, {
    useCallback, useMemo, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {WorkingTimeLogTemplateFormular} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLogTemplate/WorkingTimeLogTemplateFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import {createWorkingTimeLogTemplate, updateWorkingTimeLogTemplate} from 'graphql/timeBuddy/WorkingTimeLogTemplate/mutations';
import {getWorkingTimeLogTemplate} from 'graphql/timeBuddy/WorkingTimeLogTemplate/queries';
import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {createPermissionsObject} from 'applications/configs';
import {organizationEntityKeys} from 'applications/beyondbuddy/config';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {
    BlurOnOutlined, LockOutlined,
} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';

const mask = {
    id: true,
    tenantId: true,
    name: true,
    validityStart: false,
    validityEnd: false,
    modifiable: false,
    checkoutEnforcement: true,
    comment: false,
    workplaceId: false,
    kind: true,
};

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the workingTimeLogTemplate formular
 */
const getWorkingTimeLogTemplateFormular = (props) => <WorkingTimeLogTemplateFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').FormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`WorkingTimeLogTemplate#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#WorkingTimeLogTemplateForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            guideId: '',
            initialEntityType: 'Tenant',
            availablePermissions: createPermissionsObject(organizationEntityKeys, ['read', 'updateGroup', 'createUpdateWorkingTimeLog']),
            defaultPermissions: createPermissionsObject(organizationEntityKeys, ['read']),
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a workingTimeLogTemplate.
 * Also other information as authorized customers, links and permissions are available.
 * @returns {React.ReactElement} The CemeteryPage component.
 */
function WorkingTimeLogTemplatePage() {
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        ...!isNewItem ? {
            mutation: updateWorkingTimeLogTemplate,
            mask,
        } : {
            mutation: createWorkingTimeLogTemplate,
            mask: _.omit(mask, 'id'),
        },
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getWorkingTimeLogTemplate,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} container properties */
        () => ({
            identifier: `WorkingTimeLogTemplateForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getWorkingTimeLogTemplateFormular({id, onSave}),
                formWrapperId: `WorkingTimeLogTemplate#${id}Base`,
            }, {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
                getChildren: (grants) => !isNewItem && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `WorkingTimeLogTemplate#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            },
            ],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab, setCurrentTab, onSave]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} />
        </LayoutContainer>
    );
}
export {WorkingTimeLogTemplatePage};
