/**
 * createWorkingTimeModelAssignment
 * @returns {object} The newly created WorkingTimeModelAssignment.
 */
export const createWorkingTimeModelAssignment = /* GraphQL */ `
  mutation CreateWorkingTimeModelAssignment(
    $modelId: ID!
    $userId: ID!
    $startDate: AWSDateTime!
    ) {
    createWorkingTimeModelAssignment(
        modelId: $modelId
        userId: $userId
        startDate: $startDate
    ) {
        id
        userId
        user {
            id
            contactFirstName
            contactLastName
            contactEMailAddress
        }
        startDate
    }
  }
`;

/**
 * deleteWorkingTimeModelAssignment
 * @returns {object} The deleted WorkingTimeModelAssignment.
 */
export const deleteWorkingTimeModelAssignment = /* GraphQL */ `
  mutation DeleteWorkingTimeModelAssignment(
    $id: ID!
  ) {
    deleteWorkingTimeModelAssignment(id: $id) {
      id
    }
  }
`;
