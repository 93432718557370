import React, {
    useCallback, useContext, useLayoutEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import {
    Box, CircularProgress, IconButton, Tooltip,
} from '@mui/material';
import {useRouteNavigate} from 'hooks/useRouteNavigation';
import {createPortal} from 'react-dom';
import {ListDataContext} from 'components/Form/ListData';
import {useGlobalState} from 'hooks/useGlobalState';

import classes from 'components/Form/FormElements/FormElementActionButton.module.scss';

/**
 * @typedef {React.Context<import('../form').TFormContext | import('../form').TListDataContext>} AllowedContext
 */

/**
 * FormElement ActionButton
 *
 * Used for various effects such as routing, side effects, reloading and indicating that
 * a load event is still ongoing
 * @param {import('./formElement').FormElementActionButtonProps} props - props for the action button
 * @returns {React.ReactElement} element to be rendered
 */
function FormElementActionButton({
    disabled = false,
    disableChangeCheck = false,
    context = ListDataContext,
    tooltip,
    children,
    callback = _.noop,
    routeId, routeParams, routeState,
    reload = false,
    portalAnchorSelector,
    formWrapperIdPattern,
    deleteformWrapperIdPatternOnAccept,
    dataTest,
}) {
    const {reload: reloadData, isLoading: rawIsLoading, ...ctxValue} = useContext(context);

    const [portalAnchorElement, setPortalAnchorElement] = useState(null);

    const {getGlobal, setGlobal, deleteGlobal} = useGlobalState();
    const formHasUnsavedChanges = useMemo(() => getGlobal('formHasUnsavedChanges'), [getGlobal]);

    // @ts-ignore
    const formWrapperId = ctxValue.id;

    useLayoutEffect(() => {
        if (portalAnchorSelector) {
            setPortalAnchorElement(document.querySelector(portalAnchorSelector));
        }
    }, [portalAnchorSelector]);

    const loading = typeof rawIsLoading === 'boolean'
        ? rawIsLoading
        : _.some(rawIsLoading);
    const navigate = useRouteNavigate();

    const onClick = useCallback(() => {
        callback();
        if (routeId) {
            navigate(routeId, routeParams, routeState);
        }
        if (reload) {
            reloadData();
        }
    }, [reloadData, routeId, routeParams, routeState, navigate, callback]);

    const hasChanged = useMemo(() => {
        if (disableChangeCheck) { return false; }
        const specificId = formWrapperId && _.has(formHasUnsavedChanges, formWrapperId);
        const pattern = formWrapperIdPattern && _.some(_.keys(formHasUnsavedChanges), (key) => key.includes(formWrapperIdPattern));
        // console.log(formWrapperId, specificId, pattern, specificId || pattern, formHasUnsavedChanges);
        return specificId || pattern;
    }, [disableChangeCheck, formHasUnsavedChanges, formWrapperId, formWrapperIdPattern]);

    const removeFormWrapperIds = useCallback(() => {
        const newFHC = _.omitBy(_.keys(formHasUnsavedChanges), (key) => key === formWrapperId || (deleteformWrapperIdPatternOnAccept && key.includes(formWrapperIdPattern)));
        // _.filter(formHasUnsavedChanges, (fId) => fId !== formWrapperId && !(deleteformWrapperIdPatternOnAccept && fId.match(formWrapperIdPattern)));
        if (_.isEmpty(newFHC)) {
            deleteGlobal('formHasUnsavedChanges');
        } else {
            setGlobal('formHasUnsavedChanges', newFHC);
        }
        onClick();
    }, [formHasUnsavedChanges, formWrapperId, formWrapperIdPattern, deleteGlobal, setGlobal, onClick]);

    const button = (
        <IconButton
            data-test={dataTest ?? 'FormElementActionButton'}
            disabled={disabled || (reload && loading)}
            className={classes.actionButton}
            onClick={() => {
                if (hasChanged) {
                    setGlobal('applicationModal', {
                        open: true,
                        onContinue: removeFormWrapperIds,
                    });
                } else if (onClick) {
                    onClick();
                }
            }}
        >

            {reload && loading
                ? (
                    <Box
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress size="1rem" color="inherit" />
                    </Box>
                ) : children}

        </IconButton>
    );
    const withPotentialTooltip = tooltip
        ? (
            <Tooltip title={tooltip}>
                {button}
            </Tooltip>
        ) : button;

    if (portalAnchorSelector) {
        return portalAnchorElement && createPortal(withPotentialTooltip, portalAnchorElement);
    }
    return withPotentialTooltip;
}

export {FormElementActionButton};
