const {ISO8601_REGEX} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        startDate: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        endDate: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        open: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type'],
            },
        ],
    },
    backend: {
        startDate: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        endDate: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'required'],
            },
        ],
        open: [
            {
                actions: ['create', 'update'],
                configurations: ['type'],
            },
        ],
    },
    attributes: {
        startDate: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        endDate: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            required: true,
        },
        open: {
            type: 'Boolean',
        },
    },
};

module.exports = {schema};
