import {listDeceasedPersonOptions} from 'graphql/peaceBuddy/DeceasedPerson/queries';
import {formatDateTimeString} from 'helper/date';
import {getFullName} from 'helper/name';
import _ from 'lodash';

/**
 * @typedef {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} AutoCompleteDataSchema
 */

/**
 * @type {import('components/FilterElements/FilterElementAutocomplete').DataSchema}
 */
export const deceasedPersonDataSchema = {
    query: listDeceasedPersonOptions,
    queryVariablesMask: {tenantId: true, filter: false},
    queryVariables: {global: {tenantId: 'tenantId'}},
    dataKey: 'id', // needed for merging
    getOptionLabel: (option) => {
        const fullName = getFullName(option);
        const birthDate = formatDateTimeString(option.dateOfBirth, 'dd.MM.yyyy');
        const deathDate = formatDateTimeString(option.dateOfDeath, 'dd.MM.yyyy');

        return `${fullName} (${_.join(_.compact([birthDate, deathDate]), ' - ')})`;
    },
    getOptionValue: (option) => option?.id,
};
