import style from 'assets/theme/style.module.scss';
import _ from 'lodash';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const theme = {
    typography: {
        fontSize: 14,
        allVariants: {
            fontFamily: style.fontFamily,
            color: style.white,
        },
        h1: {
            fontSize: style.H1FontSize,
            marginTop: '0.1rem',
            marginBottom: '0.3rem',
        },
        h2: {
            fontSize: style.H2FontSize,
            marginTop: '0.1rem',
            marginBottom: '0.3rem',
        },
        h3: {
            fontSize: style.H3FontSize,
            marginTop: '0.1rem',
            marginBottom: '0.3rem',
        },
        h4: {
            fontSize: style.H4FontSize,
            marginTop: '0.1rem',
            marginBottom: '0.3rem',
        },
        h5: {
            fontSize: style.H5FontSize,
            marginTop: '0.1rem',
            marginBottom: '0.3rem',
        },
        h6: {
            fontSize: style.H6FontSize,
            marginTop: '0.1rem',
            marginBottom: '0.3rem',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: Number(_.trimEnd(_.get(style, 'screenSmMin', '600'), 'px')),
            md: Number(_.trimEnd(_.get(style, 'screenMdMin', '900'), 'px')),
            lg: Number(_.trimEnd(_.get(style, 'screenLgMin', '1200'), 'px')),
            xl: Number(_.trimEnd(_.get(style, 'screenXlMin', '1536'), 'px')),
        },
    },
};

export {theme};
