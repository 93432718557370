/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schema = {
    frontend: {
        name: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'length'],
            },
        ],
    },
    backend: {
        name: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'length'],
            },
        ],
    },
    attributes: {
        name: {
            type: 'String',
            required: true,
            length: {gte: 3, lte: 50},
        },
    },
};

module.exports = {schema};
