import React, {
    useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import {useIsMounted} from 'hooks/useIsMounted';
import {AsyncListDataProvider} from 'helper/bb-asynclistdata-provider';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {TextField, Autocomplete} from '@mui/material';
import {ListFilterContext} from 'components/Form/ListFilterProvider';

// minimum amount of options to be offered (if possible) in an autocomplete
const autocompleteMinSuggestions = 100;
// time distance between the last user interaction and the loading of autocomplete option suggestions
// value is in ms
const interactionTimeoutForSuggestions = 1000;

/**
 * Variables that can be switched out for testing
 */
const mockables = {
    AWSAppSyncProvider,
};

/**
 * DataSchema Variable Specification
 * @typedef DataSchemaVariableSpecification
 * @property {Record<string, string>} [global] - variables to get out of the global state
 * @property {Record<string, any>} [direct] - variables that will be merged directly into
 * the submitted variables
 * @property {Record<string, string>} [sibling] - variables that will be loaded as the values from
 * other form elements in the same Formcontext
 */

/**
 * @typedef {import('components/Form/FormElements/FormElementAutocomplete').ListingResult} ListingResult
 * @typedef {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} DataSchema
 */

/**
 * @typedef FilterElementAutocompleteProps
 * @property {string} path - path on the filter object
 * @property {string} [optionReference] - option reference to load extra data from in the above context
 * @property {boolean} [usePopular] - if true, the popular options are used.
 * @property {boolean} [multiple] - if true, multiple options can be selected.
 * @property {boolean} [freeSolo] - if true, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
 * @property {boolean} [noFilter] - if true, the Autocomplete will not automatically filter the input on the client side, based on the current textValue
 * @property {string} [label] - label of the autocomplete.
 * @property {Function?} [optionsFilter] - optional filter function to restrict available options.
 * @property {DataSchema} [dataSchema] - schema for loading options
 * @property {Function} [orderByFunction] - a function that orders the options by a certain criteria.
 * @property {object} [additionalOrderByAttributes] - additional orderBy attributes.
 */

/**
 * It renders an autocomplete element, that is connected to the form context
 * @param {FilterElementAutocompleteProps} props - properties.
 * @example  <AutoComplete label="Fahrzeug" attribute="drivingRecordVehicleId" optionReference="vehicle" usePopular />
 * @returns {React.ReactElement}  A function that returns a component.
 */
function FilterElementAutocomplete({
    path,
    optionReference,
    noFilter,
    multiple,
    freeSolo,
    optionsFilter,
    dataSchema,
    orderByFunction: parentOrderByFunction,
    additionalOrderByAttributes,
    ...rest
}) {
    const [isLoadingOptions, setIsLoadingOptions] = useState(false); // indicates wether the element is loading options
    const isMounted = useIsMounted(); // indicates, wether the form is still mounted or not
    const [textValue, setTextValue] = useState(''); // counts the times, options were loaded
    const [searchValue, setSearchValue] = useState('');
    const [interacted, setInteracted] = useState(false);
    const {updateFilter, filterValues$} = useContext(ListFilterContext);

    useEffect(() => {
        const subscription = filterValues$.subscribe((values) => {
            setSearchValue(values?.search);
            const value = _.get(values, path);
            if (freeSolo && typeof value === 'string') {
                setTextValue(value);
            }
        });
        return () => subscription.unsubscribe();
    }, [filterValues$, setSearchValue, setTextValue, freeSolo, path]);

    const provider = mockables.AWSAppSyncProvider();
    // extracting the corresponding data from the dataSchema if available
    const {
        items: onlineOptions,
        data: additionalData,
        isFilterNew,
        loadMoreItems,
    } = AsyncListDataProvider(provider.call, dataSchema, !noFilter);

    /**
     * Get the label for a given option
     * It's used to fill the input (and the list box options if renderOption is not provided).
     * @param {*} option the autocomplete option to get the label from
     */
    const getOptionLabelHandler = useCallback((option) => {
        // an empty option can't be processed
        if (_.isEmpty(option)) {
            return ''; // return an empty string to the element!
        }
        // the option can be a simple string (in case of freeSolo flag)
        // the option can be an array (in case of multiple flag)
        if (_.isString(option) || _.isArray(option)) {
            return option;
        }
        // it should be an object here (if not an error will be thrown afterwards)
        // in case a function was provided in the dataSchema
        if (dataSchema.getOptionLabel) {
            return dataSchema.getOptionLabel(option);
        }
        if (_.isObject(option)) {
            throw new Error('Objects can only be evaluated when a getOptionLabel function is provided within the additional data schema');
        }
        // return the option as it is
        return option;
    }, [dataSchema.getOptionLabel]);

    /**
     * Get the value for a given option
     * It's used to get the identifier of complex data types
     * @param {*} option the autocomplete option to get the value from
     */
    const getOptionValueHandler = useCallback((option) => {
        // an empty option can't be processed
        if (_.isEmpty(option)) {
            return undefined; // return empty string to the element
        }
        // the option can be a simple string (in case of freeSolo flag)
        if (_.isString(option)) {
            return option;
        }
        // the option can be an array (in case of multiple flag)
        if (_.isArray(option)) {
            return _.map(option, (o) => getOptionValueHandler(o));
        }
        // in case a function was provided in the dataSchema
        if (dataSchema.getOptionValue) {
            // the option can be an array (in case of multiple flag)
            // if (_.isArray(option)) {
            //     return _.map(option, (o) => dataSchema.getOptionValue(o));
            // }
            return dataSchema.getOptionValue(option);
        }

        if (_.isObject(option)) {
            throw new Error('Objects can only be evaluated when a getOptionValue function is provided within the additional data schema');
        }
        // the option can be an array (in case of multiple flag)
        // if (_.isArray(option)) {
        //     return _.map(option, (o) => _.get(o, attribute));
        // }
        throw new Error('No value to return');
    }, [dataSchema.getOptionValue]);

    /**
     * Used to determine if the option represents the given value. Uses strict equality by default.
     * ⚠️ Both arguments need to be handled, an option can only match with one value.
     */
    const optionEqualityHandler = useCallback((acOption, acValue) => {
        // in case the value is an object (multiple autocomplete)
        if (_.isObject(acValue)) {
            return getOptionLabelHandler(acOption) === getOptionLabelHandler(acValue) // the options value matches the current value
              || getOptionLabelHandler(acOption) === getOptionValueHandler(acValue); // the value is actual the key of an option
        }
        return getOptionLabelHandler(acOption) === acValue // the options value matches the current value
          || getOptionValueHandler(acOption) === acValue; // the value is actual the key of an option
    }, [getOptionLabelHandler, getOptionValueHandler]);

    /**
     * Loads options for the autocomplete
     * @param {string} filter - value to send to the api for indicating a filter value
     * @returns {boolean} true if the call set a load operation in motion
     */
    const loadOptions = useMemo(() => _.debounce((filter) => {
        const {hasMoreItems} = additionalData;

        // async data loader is not ready yet
        if (!_.isFunction(isFilterNew)) {
            return false;
        }

        if (!filter && !hasMoreItems) {
            return false;
        }
        if (filter && !isFilterNew({filter})) {
            return false;
        }

        // set the loading indicator
        setIsLoadingOptions(true);

        // loading more items
        const loadPromise = loadMoreItems(() => {}, {filter});
        if (loadPromise) {
            loadPromise?.finally(() => {
                if (isMounted()) {
                    setIsLoadingOptions(false); // disable the loading indicator
                }
            });
            return true;
        }
        setIsLoadingOptions(false);
        return false;
    }, interactionTimeoutForSuggestions), [dataSchema.queryVariables?.sibling, isMounted, additionalData.hasMoreItems, loadMoreItems, isFilterNew, setIsLoadingOptions]);

    // When the autocomplete (not the input inside) changes, this handler will handle
    // the change and pass it to the form context.
    const autocompleteOnChangeHandler = useCallback((event, autoCompleteValue) => {
        // on clear
        if (!autoCompleteValue) {
            // Clearing out text value so it doesn't flash back
            updateFilter(path, null, true);
        } else {
            updateFilter(path, getOptionValueHandler(autoCompleteValue), true);
        }
    }, [updateFilter, getOptionValueHandler]);

    /**
     * To have always a good bunch of options available, they have to be loaded asynchronously.
     * This function checks, wether an asynchronous loading is necessary, based on time and filter criteria
     */
    const checkAvailableOptions = useCallback((v) => {
        // an empty value has no option
        if (!v) {
            return false;
        }
        // console.log('FormElement::AutoComplete::checkAvailableOptions', `optionsSource: ${optionsSourceId}`, v);
        // In case the value is from a multiple autocomplete, it is an array and must be seen individually
        if (_.isArray(v)) {
            // call checkAvailableOptions for each value
            return _.every(v, checkAvailableOptions);
        }

        // check how many options will be suggested, based on the current value
        const {true: suggested} = _.countBy(onlineOptions, (o) => _.includes(getOptionLabelHandler(o), v));
        // console.log('FormElement::AutoComplete::checkAvailableOptions', suggested, v);
        // if nothing is suggested or the suggestions are less than autocompleteMinSuggestions
        if (!suggested || suggested < autocompleteMinSuggestions) {
            loadOptions(v);
            // nothing or insufficient suggestions were found
            return false;
        }
        // enough suggestions are available
        return true;
    }, [getOptionLabelHandler, autocompleteOnChangeHandler, loadOptions, onlineOptions]);

    // In case the value of an autocomplete changes, the suggestions should be
    // checked and data should be loaded in case insufficient data is available
    // additionalDataOptions hold the options for an autocomplete - changes should also trigger a check
    useEffect(() => {
        if (!interacted) {
            return;
        }
        const loadPossible = !isLoadingOptions && isMounted();
        // Checking conditions that could rule out any updates taking place
        if (loadPossible && !noFilter && textValue) {
            checkAvailableOptions(textValue);
            // Check if freeSolo, and if the format indicates we have only used freeSolo so far
            if (freeSolo) {
                autocompleteOnChangeHandler(null, textValue);
            }
        } else if (loadPossible && noFilter && isFilterNew({filter: textValue})) {
            loadOptions(textValue);
            if (freeSolo && textValue) {
                autocompleteOnChangeHandler(null, textValue);
            }
        }
    }, [textValue, isMounted, interacted,
        checkAvailableOptions, isLoadingOptions, freeSolo,
        isFilterNew, loadOptions, autocompleteOnChangeHandler]);

    const orderByFunction = useCallback((data) => {
        if (parentOrderByFunction) {
            return parentOrderByFunction(data);
        }
        // return the recommendations sorted by rank
        return _.orderBy(
            data,
            _.concat(['rank', 'name'], _.get(additionalOrderByAttributes, 0)),
            _.concat(['asc', 'asc'], _.get(additionalOrderByAttributes, 1)),
        ); // default
    }, [additionalOrderByAttributes, parentOrderByFunction]);

    const filteredOptions = useMemo(() => {
        let filtered = onlineOptions;
        if (_.isFunction(optionsFilter)) {
            filtered = _.filter(onlineOptions, optionsFilter);
        }
        return orderByFunction(filtered);
    }, [optionsFilter, onlineOptions, orderByFunction]);

    return (
        <Autocomplete
            data-test={`FilterElementAutocomplete_${path}`}
            data-loading={isLoadingOptions}
            data-options={onlineOptions.length}
            multiple={multiple ?? false}
            freeSolo={freeSolo ?? false}
            options={filteredOptions}
            loading={isLoadingOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={
                        searchValue && freeSolo
                            ? {shrink: true}
                            : undefined
                    }
                    placeholder={searchValue ?? ''}
                    id={path}
                    onChange={(event) => {
                        const elementValue = event?.target?.value;
                        // log when the last input was made by a user interaction
                        setTextValue(elementValue ?? '');
                        // Clearing in case of freeSolo, where no option change is emitted
                        if (freeSolo && !elementValue) {
                            updateFilter(path, null, true);
                        }
                        setInteracted(true);
                    }}
                    label={rest?.label}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    {getOptionLabelHandler(option)}
                </li>
            )}
            onOpen={() => {
                if (onlineOptions.length || !additionalData.hasMoreItems) {
                    return;
                }
                setInteracted(true);
                loadOptions(null);
                loadOptions.flush();
            }}
            inputValue={freeSolo ? textValue : undefined}
            onChange={autocompleteOnChangeHandler}
            getOptionLabel={getOptionLabelHandler}
            isOptionEqualToValue={optionEqualityHandler}
            filterOptions={noFilter ? _.identity : undefined}
            {...rest}
        />
    );
}

export {FilterElementAutocomplete, mockables};
