import React, {useContext, useEffect} from 'react';
import {ItemDataContext} from 'components/Form/ItemData';
import {FormContext} from 'components/Form/FormWrapper';

/**
 * This form element has takes a clear indicator and clears the form data
 * @param {object} props - the properties of the component
 * @param {boolean} props.shouldClear - indicates to clear the form data
 * @param {boolean} [props.keepInitial] - indicates that when clearing, the initial data should be kept
 * @param {unknown} [props.id] - optional identifier that should trigger resets
 * @returns {React.ReactElement} - an empty fragment
 */
function FormReset({shouldClear, keepInitial, id}) {
    const {clear: clearForm} = useContext(FormContext);
    const {clear: clearItem} = useContext(ItemDataContext);
    useEffect(() => {
        if (shouldClear) {
            clearItem(keepInitial);
            clearForm();
        }
    }, [shouldClear, id]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<></>);
}

export {FormReset};
