import {Refresh} from '@mui/icons-material';
import {Alert, Box, Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {FormElementEntityLinkPermissions, postProcessLinks, preProcessLinks} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormReset} from 'components/Form/FormReset';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {ItemData} from 'components/Form/ItemData';
import {updateLinks} from 'graphql/beyondBuddy/Links/mutations';
import {getLinks} from 'graphql/beyondBuddy/Links/queries';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMessage} from 'hooks/useMessage';
import {Messages} from 'messages/Messages';
import React, {
    useMemo, useState, useEffect,
} from 'react';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {reevaluateUserAccess} from 'graphql/beyondBuddy/User/mutations';
import _ from 'lodash';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

const profileRefreshTimeout = 5000;

const sortPermissions = (arr) => arr?.map((item) => ({
    ...item,
    permissions: _.sortBy(item.permissions),
}));

/**
 * @typedef {import('applications/configuration').Permissions} Permissions
 * @property {string} prop - other
 */

/**
 * This page shows a formular where one can set permissions for an entity
 * @param {object} props - props for the component
 * @param {import('applications/configuration').EntityTypeId} props.entityTypeId - entityTypeId for which permissions are being set
 * @param {Partial<import('components/Form/FormElements/FormElementEntityLinkPermissions').FormElementEntityLinkPermissionsProps>} [props.entityChooserProps] - props for the entity chooser
 * @param {boolean} [props.isIncoming] - flag indicating that incoming permissions are being modified with the formular
 * @param {boolean} [props.disabled] - flag that removes the save button, and sets the entity chooser to disabled
 * @param {string} [props.context] - the context of the FormWrapper
 * @param {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [props.actionButtonProps] - properties for actionButtons
 * @returns {React.ReactElement} page component
 */
function PermissionFormular({
    entityTypeId, entityChooserProps, isIncoming, disabled, context, actionButtonProps,
}) {
    const id = entityTypeId.split('#').at(-1);
    const entityType = entityTypeId.split('#').at(0);

    const [shouldReevaluateUserAccess, setShouldReevaluateUserAccess] = useState(false);
    const [isReevaluateUserAccess, setIsdReevaluateUserAccess] = useState(false);
    const [hadReevaluatedUserAccess, setHadReevaluatedUserAccess] = useState(false);
    const [currentId, setCurrentId] = useState(id);
    const {getGlobal} = useGlobalState();
    const {editItem} = AWSAppSyncProvider();
    const refreshUserProfile = getGlobal('refreshUserProfile');

    useEffect(() => {
        // for refresh the form on id change
        setCurrentId(id);
    }, [id]);

    useEffect(() => {
        if (shouldReevaluateUserAccess) {
            const reevaluateAccess = async () => {
                if (entityType === 'Module') {
                    try {
                        setIsdReevaluateUserAccess(true);
                        await editItem(reevaluateUserAccess);
                        setHadReevaluatedUserAccess(true);
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    } finally {
                        setIsdReevaluateUserAccess(false);
                    }
                }
            };
            reevaluateAccess(); // async but the response is not necessary)
            setShouldReevaluateUserAccess(false);
        }
    }, [shouldReevaluateUserAccess, reevaluateUserAccess]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getLinks,
        variables: {
            direct: {
                isIncoming,
                entityTypeId, // : `Objecttype#${type}`,
            },
        },
        postProcess: (data) => postProcessLinks(data, id),
    }), [entityTypeId, isIncoming, id]);
    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        mutation: updateLinks,
        preProcess: (data) => {
            if (!hadReevaluatedUserAccess) {
                setShouldReevaluateUserAccess(true);
            }
            return preProcessLinks(data);
        },
        postProcess: (data) => postProcessLinks(data, id),
        variables: {
            direct: {
                isIncoming,
                entityTypeId, // : `Objecttype#${type}`,
            },
        },
        mask: {
            isIncoming: false,
            entityTypeId: true,
            links: true,
        },
    }), [entityTypeId, isIncoming, id]);

    const {enqueueMessage} = useMessage();

    /**
     *
     * @param {'text'|'icon'|'contained'} variant the type of the button
     * @returns {React.ReactElement} the controls for the formular
     */
    const controls = (variant) => (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* Span to avoid the actionbutton shifting to the right to take the buttons place */}
            <span style={{flex: 1}}>
                {!disabled && <FormElementLoadingButton variant={variant} />}
            </span>
        </Box>
    );

    return (
        <>
            {isIncoming
            && (
                <Alert severity="info">
                    <Typography component="span" style={{textAlign: 'justify'}}>
                        <Typography fontWeight="bold" sx={{color: 'primary.main'}}>Eingehende Berechtigungen</Typography>
                        regeln, wie Objekte der
                        <QuickGuideLink id="beyondbuddy_general_organization">Aufbauorganisation</QuickGuideLink>
                        (Benutzer, Gruppen, Organisationseinheiten, Mandant) auf das aktuelle Objekt zugreifen dürfen.
                        Die Berechtigungen legen fest, welche Aktionen (Zugreifen, Lesen, Schreiben, Berechtigen, etc.) ausgeführt werden können.
                    </Typography>
                </Alert>
            )}
            {!isIncoming
            && (
                <Alert severity="info">
                    <Typography component="span" style={{textAlign: 'justify'}}>
                        <Typography fontWeight="bold" sx={{color: 'primary.main'}}>Ausgehende Berechtigungen</Typography>
                        regeln, wie das aktuelle Objekt auf andere Objekte der
                        <QuickGuideLink id="beyondbuddy_general_organization">Aufbauorganisation</QuickGuideLink>
                        (Benutzer, Gruppen, Organisationseinheiten, Mandant) zugreifen darf.
                        Die Berechtigungen legen fest, welche Aktionen (Zugreifen, Lesen, Schreiben, Berechtigen, etc.)
                        das aktuelle Objekt auf anderen Objekten ausführen kann. Ausgehende Berechtigungen können ausschließlich auf Objekte der
                        <QuickGuideLink id="beyondbuddy_general_organization">Aufbauorganisation</QuickGuideLink>
                        direkt angewendet werden. Bei Objekten außerhalb der Aufbauorganisation muss die Berechtigung über eingehende Berechtigungen
                        am Objekt selbst gesetzt werden.
                    </Typography>
                </Alert>
            )}
            {isReevaluateUserAccess && <Alert severity="warning">Die Benutzerberechtigungen werden aktualisiert! Bitte warten!</Alert>}
            <ItemData
                loadConfig={loadConfig}
                saveConfig={saveConfig}
            >
                <FormWrapper
                    messageKey="Links"
                    onSaveCallback={() => {
                        if (refreshUserProfile) {
                            setTimeout(() => {
                                enqueueMessage('Permission_Refresh', Messages.PERMISSION_REFRESH_INFO);
                            }, 1500);
                            // Refreshing user profile to account for potential changes due to module access
                            setTimeout(refreshUserProfile, profileRefreshTimeout);
                        }
                    }}
                    context={context ?? `${entityTypeId}${isIncoming ? '' : 'Outgoing'}Permissions`}
                    hasDataChanged={(data, initial) => _.isEqual(sortPermissions(data?.links), sortPermissions(initial?.links))}
                >
                    <FormReset shouldClear={entityTypeId.endsWith('#create') || id !== currentId} keepInitial />
                    <Box sx={{
                        paddingY: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                    }}
                    >
                        <span style={{marginRight: '1rem'}}>
                            <FormElementActionButton
                                reload
                                context={FormContext}
                                {...actionButtonProps}
                            >
                                <Refresh />

                            </FormElementActionButton>
                        </span>
                        <FormElementEntityLinkPermissions
                            disabled={disabled}
                            attribute="links"
                            // @ts-ignore
                            selfType={entityTypeId.split('#').at(0)}
                            // controls={controls('icon')}
                            linkDirection={isIncoming ? 'left' : 'right'}
                            availablePermissions={{
                                Group: ['create', 'read', 'updateGroup'],
                                OrganizationalUnit: ['create', 'read', 'updateGroup'],
                                User: ['create', 'read', 'updateGroup'],
                                Tenant: ['create', 'read', 'updateGroup'],
                            }}
                            actionButtonProps={actionButtonProps}
                            {...entityChooserProps}
                        />
                        {controls('contained')}
                    </Box>
                </FormWrapper>
            </ItemData>
        </>
    );
}

export {
    PermissionFormular,
};
