import React, {useEffect, useMemo, useState} from 'react';
import _ from 'lodash';

const Context = React.createContext(null);

/**
 * This is the context provider for the AppContext.
 * It takes an initial state and children, and returns a context provider that stores the state in local storage.
 * @param {object} props - props that were passed to this component.
 * @param {object} [props.initialstate] - initial state of the context provider.
 * @param {React.ReactNode} props.children - children that are wrapped by this component.
 * @returns {React.ReactElement} The context provider for the AppContext.
 */
function AppContext({initialstate, children}) {
    const [data, setData] = useState(initialstate ?? {});

    const providerValue = useMemo(() => ({
        data,
        setData,
    }), [data, setData]);

    useEffect(() => {
        _.forEach(initialstate, (value, key) => {
            localStorage.setItem(key, JSON.stringify(value));
        });
    }, [initialstate]);

    return (
        <Context.Provider value={providerValue}>
            {children}
        </Context.Provider>
    );
}

export {AppContext, Context};
