/**
 * createTimePeriod
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the TimePeriod.
 * @property {string} managerUserId - the id of user that manages the TimePeriod.
 * @returns {object} The newly created group.
 */
export const createTimePeriod = /* GraphQL */ `
  mutation CreateTimePeriod(
    $tenantId: ID!
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
    $open: Boolean
    ) {
    createTimePeriod(
      tenantId: $tenantId,
      open: $open
      endDate: $endDate
      startDate: $startDate
    ) {
      id
      startDate
      endDate
      open
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * updateTimePeriod
 * @property {string} id - ID of the group.
 * @property {string} tenantId  - id of the tenant.
 * @property {string} name - name of the group.
 * @returns {object} The updated group.
 */
export const updateTimePeriod = /* GraphQL */ `
  mutation UpdateTimePeriod(
    $id: ID!
    $endDate: AWSDateTime!
    $startDate: AWSDateTime!
    $open: Boolean
  ) {
    updateTimePeriod(
      id: $id
      startDate: $startDate
      endDate: $endDate
      open: $open
    ) { 
      id
      startDate
      endDate
      open
      grants {
        updatable
        permissionsReadable
        permissionsUpdatable
      }
    }
  }
`;

/**
 * deleteTimePeriod
 * @property {string} id - ID of the time period.
 * @returns {object} The updated group.
 */
export const deleteTimePeriod = /* GraphQL */ `
  mutation DeleteTimePeriod(
    $id: ID!
  ) {
    deleteTimePeriod(
      id: $id
    ) {
      id
    }
  }
`;
