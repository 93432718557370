import React, {useContext, useMemo} from 'react';
import {useGlobalState} from 'hooks/useGlobalState';

import _ from 'lodash';
import {Button} from '@mui/material';
import {FormContext} from 'components/Form/FormWrapper';

/**
 * The wrapper for the Link component used in forms to navigate within forms (with sub-forms).
 * This component is used to prevent the user from navigating away from form if a form has unsaved changes.
 * @param {import('@mui/material').ButtonProps} props - properties of the component
 * @returns {React.ReactElement} - the component
 */
function FormLink({
    onClick, children, ...rest
}) {
    const {getGlobal, setGlobal, deleteGlobal} = useGlobalState();
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, id: formWrapperId,
    } = useContext(FormContext);

    const formHasUnsavedChanges = useMemo(() => getGlobal('formHasUnsavedChanges'), [getGlobal]);

    return (
        <Button
            {...rest}
            disabled={isLoading?.load || isLoading?.save || rest.disabled}
            onClick={(event) => {
                if (_.isObject(formHasUnsavedChanges) && _.has(formHasUnsavedChanges, formWrapperId)) {
                    setGlobal('applicationModal', {
                        open: true,
                        onContinue: () => {
                            const newFHC = _.omit(formHasUnsavedChanges, formWrapperId);
                            if (_.isEmpty(newFHC)) {
                                deleteGlobal('formHasUnsavedChanges');
                            } else {
                                setGlobal('formHasUnsavedChanges', newFHC);
                            }
                            if (onClick) { onClick(event); }
                        },
                    });
                    event.preventDefault();
                } else if (onClick) { onClick(event); }
            }}
        >
            {children}
        </Button>
    );
}

export {FormLink};
