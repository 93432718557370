import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {NotificationFormular} from 'applications/beyondbuddy/settings/forms/notification/NotificationFormular';

import {useParams} from 'react-router-dom';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a create or edit form for a notification.
 * .DriveBuddy.Pages
 * @returns {React.ReactElement} The NotificationPage.
 */
function NotificationPage() {
    // getting the URL parameters
    const {id} = useParams();

    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Benachrichtigung</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Box sx={{marginTop: '1.5rem'}}>
                <NotificationFormular id={id} />
            </Box>
        </LayoutContainer>
    );
}
export {NotificationPage};
