import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {useGlobalState} from 'hooks/useGlobalState';
import {TenantFormular} from 'applications/beyondbuddy/settings/forms/tenant/TenantFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a create and update form for a tenant
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The TenantPage component.
 */
function TenantPage() {
    // getting the URL parameters
    const {getGlobal} = useGlobalState();
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Ihr Mandant</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <Box sx={{display: 'flex', gap: '2rem', marginTop: '1.5rem'}}>
                {/* <Box
                    component="img"
                    src={tenantImg}
                    alt="Mandant"
                    sx={{
                        maxWidth: '300px',
                        display: {xs: 'none', lg: 'block'},
                        marginBottom: 'auto',
                    }}
                /> */}
                <Box sx={{flexGrow: 1}}>
                    <TenantFormular id={getGlobal('tenantId')} />
                </Box>
            </Box>
        </LayoutContainer>
    );
}
export {TenantPage};
