import React, {
    useContext, useMemo,
    useEffect,
    useRef,
} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';

/**
 * @typedef FormElementTestHelperProps
 * @property {string} attribute - attribute to load/write data from/to
 */

/**
 * ## FormElementTestHelper
 *
 * Does not render, but enables tests to read out and write a specified attribute,
 * as long as the value can be json stringified.
 * @param {FormElementTestHelperProps} props - props of the component
 * @returns {React.ReactElement} - element to be rendered
 */
function FormElementTestHelper({
    attribute,
}) {
    const {
        get, changeHandler, isReadonly,
    } = useContext(FormContext);

    const elementData = useMemo(() => get(attribute), [attribute, get]);

    const spanRef = useRef(null);

    useEffect(() => {
        /** @type {(event: CustomEvent) => void} */
        const handle = (event) => {
            const newValue = event.detail;
            if (!_.isEqual(elementData.value, newValue)) {
                changeHandler({
                    attribute,
                    value: newValue,
                    interacted: true,
                });
            }
        };
        spanRef.current.addEventListener('changeValue', handle);
        return () => spanRef.current?.removeEventListener('changeValue', handle);
    }, [elementData.value, changeHandler]);

    return (
        <span
            ref={spanRef}
            style={{display: 'none'}}
            data-test={`FormElementTestHelper_${attribute}`}
            data-value={JSON.stringify(elementData.value ?? null)}
            aria-readonly={isReadonly}
            aria-hidden
        />
    );
}

export {FormElementTestHelper};
