import React from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {DeceasedPersonListFormular} from 'applications/peacebuddy/settings/forms/deceasedPerson/DeceasedPersonListFormular';
import {Box} from '@mui/material';
import {FormHeadContainer} from 'components/Form/FormHeadContainer';

/**
 * This page shows a list of cemeteries
 * .BeyondBuddy.Pages
 * @returns {React.ReactElement} The DeceasedPersonListPage component
 */
function DeceasedPersonListPage() {
    return (
        <LayoutContainer>
            <FormHeadContainer>
                <Box><h2>Verstorbene Personen</h2></Box>
                <Box><span id="action-button-frame" style={{display: 'flex', gap: '1rem', marginRight: '1rem'}} /></Box>
            </FormHeadContainer>
            <div style={{marginTop: '1rem'}}>
                <DeceasedPersonListFormular />
            </div>
        </LayoutContainer>
    );
}
export {DeceasedPersonListPage};
