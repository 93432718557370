import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {deleteDeceasedPerson} from 'graphql/peaceBuddy/DeceasedPerson/mutations';
import {listDeceasedPersons} from 'graphql/peaceBuddy/DeceasedPerson/queries';

import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {getFullName} from 'helper/name';
import {formatDateTimeString} from 'helper/date';

/**
 * Formular for list cemeteries
 * @returns {React.ReactElement} The DeceasedPersonListFormular component
 */
function DeceasedPersonListFormular() {
    const messageKey = 'DeceasedPerson_List';
    return (
        <ListData
            loadConfig={{
                query: listDeceasedPersons,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteDeceasedPerson,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_deceasedPerson_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createDeceasedPerson')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="DeceasedPerson-unit-filter" messageKey={messageKey}>
                <FilterElementText label="Suche" path="search" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/Listing').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold" textOverflow="ellipsis">{getFullName(item)}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1, minWidth: '170px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Lebenszeit',
                                            renderItem: (item) => (
                                                <Typography noWrap textOverflow="ellipsis">
                                                    {`${formatDateTimeString(item.dateOfBirth, 'dd.MM.yyyy')} - ${formatDateTimeString(item.dateOfDeath, 'dd.MM.yyyy')}`}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '170px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Referenz',
                                            renderItem: (item) => (
                                                <Typography noWrap>{item.referenceId}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                // {
                                //     itemConfigurations: [
                                //         {
                                //             title: 'Adresse',
                                //             renderItem: (item) => <Typography noWrap textOverflow="ellipsis">{getFullAddressInformation(item)}</Typography>,
                                //         },
                                //     ],
                                //     boxProps: {sx: {flex: 1}},
                                // },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'peacebuddy_settings_deceasedPerson_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'peacebuddy_settings_deceasedPerson_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {DeceasedPersonListFormular};
