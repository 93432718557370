import React, {
    useEffect, useMemo, useCallback,
} from 'react';
import {
    Routes, Route, useLocation, matchPath, useNavigate,
} from 'react-router-dom';
import {ApplicationTemplateProvider, GeneralTemplateWrapper} from 'assets/theme';
import {useGlobalState} from 'hooks/useGlobalState';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {applicationConfigurations} from 'applications/configs';
import _ from 'lodash';
import {ApplicationDashboard} from 'applications/pages/ApplicationDashboard';
import {ErrorBoundary} from 'applications/pages/ErrorBoundary';
import {NotFound} from 'applications/pages/NotFound';
import {updateUserColorMode} from 'graphql/beyondBuddy/User/mutations';
import {Badge, IconButton, useMediaQuery} from '@mui/material';
import {ApplicationModal} from 'components/ApplicationModal';
import {QuickGuideDrawer} from 'components/QuickGuide/QuickGuide';
import {Feedback} from 'components/Feedback/Feedback';
import {
    EmergencyShare, Feedback as FeedbackIcon, Mail, MailOutline,
} from '@mui/icons-material';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';
import {useNotificationList} from 'helper/useNotificationList';
import {useCanAccess} from 'hooks/useCanAccess';

/**
 * @param {import("routeinfo").RoutePathInfo} route - information about the route
 * @param {Record<string, string>} [params] - replacement params for dynamic route paths
 * @param {import('routeinfo').QueryParams} [queryParams] - query and hash parameters for the page path
 * @returns {string} the enriched route path
 */
function getRoutePath(route, params, queryParams = {}) {
    if (!route) {
        return undefined;
    }
    let path = route?.path;
    const {
        hashFragment,
        query,
    } = queryParams;
    _.forEach(params, (v, k) => {
        path = path.replace(`:${k}`, v);
    });
    if (query) {
        // @ts-ignore URLSearchParamsInit is valid to create URLSearchParams
        path += `?${new URLSearchParams(query)}`;
    }
    if (hashFragment) {
        path += `#${hashFragment}`;
    }
    return path;
}

/**
 * Takes a route configuration and constructs a routes tree.
 * @param {import('routeinfo').ExtendedRouteConfiguration} routeConfiguration - the route configuration
 * @param {number} keyIndex - an index to make the jsx elements unique
 * @returns {import('routeinfo').RouteConstructionResult} - a constructed route with sub routes
 */
const constructRoute = (routeConfiguration, keyIndex) => {
    // console.log("constructRoute", routeConfiguration.path, routeConfiguration.application, routeConfiguration.module);
    /**
     * Initializing route info
     * @type {import('routeinfo').RoutePathInfo[]}
     */
    const routePathInfos = [];
    // Pushing current route in case it has an id (and should therefore be collected)
    // index routes have no id (and no path)
    if (routeConfiguration.id) {
        routePathInfos.push({
            id: routeConfiguration.id,
            path: routeConfiguration.path,
            applicationId: routeConfiguration.applicationId,
            moduleId: routeConfiguration.moduleId,
        });
    }

    // Recursively gathering routes & route information
    const subRoutes = _.map(
        routeConfiguration.routes,
        (r, i) => constructRoute(r, i),
    );
    routePathInfos.push(..._.flatMap(subRoutes, ({routes: sr}) => sr.map((subRoute) => ({
        ..._.pick(subRoute, 'id', 'applicationId', 'moduleId'),
        // injecting path of current route if it exists
        path: routeConfiguration.path
            ? `${routeConfiguration.path}/${subRoute.path}`
            : subRoute.path,
    }))));

    return {
        // eslint-disable-next-line no-nested-ternary
        jsx: subRoutes.length
            ? (
                <Route key={keyIndex} {..._.pick(routeConfiguration, 'path', 'element')}>
                    {_.map(subRoutes, 'jsx') ?? []}
                </Route>
            )
            : (routeConfiguration.path
                ? (<Route key={keyIndex} {..._.pick(routeConfiguration, 'path', 'element')} />)
                : (<Route key={keyIndex} element={routeConfiguration.element} index />)),
        routes: routePathInfos,
    };
};

/**
 * Build route information and route elements for an application configuration.
 * @param {import('applications/configs').ApplicationConfiguration} applicationConfiguration - configuration of the application
 * @param {number} keyIndex - index for setting key attributes on the route elements
 * @returns {import('routeinfo').RouteConstructionResult} - a constructed route with sub routes for the application
 */
function buildRouteTree({
    id: applicationId, route: applicationRoute, modules,
}, keyIndex) {
    /**
     * Enrich a RouteConfiguration with applicationId and moduleId
     * information to return a ExtendedRouteConfiguration.
     * @param {import('applications/configuration').RouteConfiguration} route - information of a specific route
     * @param {string} [moduleId] - unique identifier of a module
     * @returns {import('routeinfo').ExtendedRouteConfiguration} - enriched route configuration
     */
    const enrichRoute = (route, moduleId) => ({
        applicationId,
        moduleId,
        ...route,
        routes: _.map(route.routes, (r) => enrichRoute(r, moduleId)),
    });

    const enrichedApplicationRoute = enrichRoute(applicationRoute);
    const subRoutes = [
        ...enrichedApplicationRoute.routes,
        ..._.flatMap(modules, (module) => _.map(module.routes, (moduleRoute) => enrichRoute(moduleRoute, module.id))),
    ];

    return constructRoute({...enrichedApplicationRoute, routes: subRoutes}, keyIndex);
}

/**
 * Takes all routes and the corresponding application- and optional moduleids to
 * construct a menu structure for the passed menuConfiguration
 * @param {import('routeinfo').RoutePathInfo[]} routes - all route information of the application
 * @param {import('applications/configuration').EnablableMenuConfiguration} menuConfiguration - specific menu configuration
 * @returns {import('routeinfo').MenuInfo} - information object about the menu
 */
const constructMenu = (routes, menuConfiguration) => {
    const route = _.find(routes, (r) => r.id === menuConfiguration.routeId);
    const basePath = route?.path;
    const path = getRoutePath(route, menuConfiguration.routeParams);
    return {
        ..._.pick(menuConfiguration, 'id', 'icon', 'label', 'routeId', 'enabled'),
        basePath,
        path,
        menus: _.map(menuConfiguration.menus, (m) => constructMenu(routes, m)),
    };
};

/**
 * Takes all routes of an application to construct a menu structure for the passed applicationConfiguration
 * @param {import('routeinfo').RoutePathInfo[]} routes - all route information of the application
 * @param {import('applications/configs').ApplicationConfiguration} applicationConfiguration - configuration of an application
 * @returns {import('routeinfo').ApplicationMenuInfo[]} - menu information tree of the application
 */
const buildMenuTree = (routes, {
    id: applicationId, name: applicationName, description: applicationDescription, menu, modules,
}) => [
    {
        applicationId,
        applicationName,
        applicationDescription,
        menu: constructMenu(routes, menu),
        modules: modules.map(({id: moduleId, name: moduleName, menus}) => ({
            moduleId,
            moduleName,
            menus: menus.map((m) => constructMenu(routes, m)),
        })),
    },
];

/**
 * Route information of the current routers location
 * @returns {import('routeinfo').RoutePathInfo} - information of the current route
 */
function useCurrentRoute() {
    const location = useLocation();
    const {getGlobal} = useGlobalState();

    /**
     * @type {import('routeinfo').RoutePathInfo[]}
     */
    const routes = getGlobal('routes');
    return _.find(routes, (r) => !!matchPath(`/${r.path}`, location.pathname));
}

/**
 *
 * @returns {React.ReactElement} The AppRouter component.
 */
function AppRouter() {
    const {getGlobal, setGlobal} = useGlobalState();
    const userObj = getGlobal('user');

    const authorizedApplicationConfigurations = useMemo(() => {
        const userAccessGrants = userObj?.accessGrants;
        if (!userAccessGrants) {
            return [];
        }
        const applications = new Set(userAccessGrants.applications);
        const modules = new Set(userAccessGrants.modules);
        const objectAccess = new Set(userAccessGrants.objectAccess);
        const addAll = (role) => {
            applications.add(role);
            modules.add(role);
            objectAccess.add(role);
        };

        if (userAccessGrants.special?.tenantAdministrator) {
            addAll('TenantAdmin');
        }
        if (userAccessGrants.special?.lineManager) {
            addAll('LineManager');
        }
        if (userAccessGrants.special?.tenantReader) {
            addAll('TenantReader');
        }
        const hasAccess = (condition) => modules.has(condition) || applications.has(condition) || objectAccess.has(condition);
        /**
         * @param {import('applications/configuration').MenuConfiguration[]} menus - menus to be processed
         * @returns {import('applications/configuration').EnablableMenuConfiguration[]} processed menus
         */
        const processMenus = (menus) => menus
            .filter(({showConditions, enableConditions}) => !(showConditions || enableConditions)
             || showConditions?.every(hasAccess)
             || enableConditions?.every(hasAccess))
            .map((menu) => ({
                ...menu,
                menus: menu.menus && processMenus(menu.menus),
                enabled: menu.enableConditions?.every(hasAccess) ?? true,
            }));
        /**
         * @param {import("applications/configuration").RouteConfiguration[]} routes - routes to be processed
         * @returns {import("applications/configuration").RouteConfiguration[]} routes to be accessible
         */
        const processRoutes = (routes) => routes
            .filter(({accessConditions}) => !accessConditions || accessConditions.every(hasAccess))
            .map((route) => ({
                ...route,
                routes: route.routes && processRoutes(route.routes),
            }));
        const authorizedConfigs = applicationConfigurations
            .filter(({accessConditions}) => !accessConditions
                || accessConditions.every(hasAccess))
            .map((config) => ({
                ...config,
                modules: config.modules
                    .filter(({accessConditions}) => !accessConditions
                        || accessConditions.every(hasAccess))
                    .map((appModule) => ({
                        ...appModule,
                        routes: processRoutes(appModule.routes),
                        menus: processMenus(appModule.menus),
                    })),
            }));
        return _.orderBy(authorizedConfigs, 'order');
    }, [userObj]);

    useEffect(() => {
        setGlobal('authorizedApplicationConfigurations', authorizedApplicationConfigurations);
    }, [authorizedApplicationConfigurations]);
    const {editItem} = AWSAppSyncProvider();

    /**
     * @type {import('routeinfo').RouteConstructionResult[]}
     */
    const routes = useMemo(
        () => authorizedApplicationConfigurations.map(buildRouteTree),
        [authorizedApplicationConfigurations],
    );

    /**
     * @type {import('routeinfo').ApplicationMenuInfo[]}
     */
    const menus = useMemo(
        () => _.flatMap(authorizedApplicationConfigurations, (ac) => buildMenuTree(_.flatMap(routes, 'routes'), ac)),
        [routes, authorizedApplicationConfigurations],
    );

    useEffect(() => {
        setGlobal('routes', _.flatMap(routes, 'routes'));
    }, [routes, setGlobal]);
    const preferredColorMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

    const setMode = useCallback(async (colorMode) => {
        const newUserAttributes = {...userObj, colorMode, darkMode: colorMode === 'dark'};
        setGlobal('user', newUserAttributes);
        try {
            await editItem(updateUserColorMode, {id: userObj.id, darkMode: newUserAttributes.darkMode});
        } catch (e) {
            _.forEach(e.errors, (error) => {
                // eslint-disable-next-line no-console
                console.error(error.message);
            });
        }
    }, [userObj, setGlobal]);

    const navigate = useNavigate();

    const notificationList = useNotificationList();
    const canCreateEvakuation = useCanAccess('createEvacuation');

    return (
        <Routes>
            <Route
                path="/"
                errorElement={<GeneralTemplateWrapper mode={userObj?.colorMode ?? preferredColorMode}><ErrorBoundary /></GeneralTemplateWrapper>}
                element={(
                    <ApplicationTemplateProvider
                        mode={userObj?.colorMode ?? preferredColorMode}
                        setMode={setMode}
                        menus={menus}
                        mainProps={{
                            onDragOver: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setGlobal('dragging', true);
                            },
                            onDragLeave: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setGlobal('dragging', false);
                            },
                            onDrop: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setGlobal('dragging', false);
                            },
                        }}
                        toolbarChilds={(
                            <>
                                {canCreateEvakuation
                                && (
                                    <IconButton
                                        color="inherit"
                                        data-test="evacuation"
                                        title="Evakuierung"
                                        onClick={() => {
                                        // Falling back to navigate instead of route navigate, since route info is only now entered
                                            navigate('/settings/general/evacuation/find');
                                        }}
                                    >
                                        <EmergencyShare />
                                    </IconButton>
                                )}
                                <QuickGuideLink
                                    id="beyondbuddy_general_guides"
                                    iconButtonProps={{
                                        color: 'inherit',
                                        'aria-label': 'Hilfe öffnen',
                                    }}
                                />
                                <IconButton
                                    color="inherit"
                                    data-test="notification"
                                    aria-label="Benachrichtigungen"
                                    onClick={() => {
                                        // Falling back to navigate instead of route navigate, since route info is only now entered
                                        navigate('/settings/notification');
                                    }}
                                >
                                    <Badge badgeContent={notificationList?.filter(({viewed}) => !viewed).length} max={100} color="secondary">
                                        {notificationList?.length ? <Mail /> : <MailOutline />}
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    aria-label="Feedback geben"
                                    data-test="feedback"
                                    onClick={() => { setGlobal('showFeedback', true); }}
                                >
                                    <FeedbackIcon />
                                </IconButton>
                            </>
                        )}
                    >
                        <ApplicationModal />
                        <QuickGuideDrawer />
                        <Feedback />
                    </ApplicationTemplateProvider>
                )}
            >
                <Route
                    index
                    element={<ApplicationDashboard />}
                />
                <Route
                    path="*"
                    element={<NotFound />}
                />
                {_.map(routes, 'jsx')}
            </Route>
        </Routes>
    );
}

// default export for react lazy load
// eslint-disable-next-line import/no-default-export
export default AppRouter;
export {
    AppRouter, getRoutePath, useCurrentRoute,
};
